import omitBy from "lodash/omitBy";
import isUndefined from "lodash/isUndefined";
import useQueryParams from "./useQueryParams";
import { SearchNguoiDungType } from "../Types/nguoiDungType";
export type QueryConfig = {
  [key in keyof SearchNguoiDungType]: string;
};

export default function useQueryConfig() {
  const queryParams: QueryConfig = useQueryParams();
  const queryConfig: QueryConfig = omitBy(
    {
      pageSize: queryParams.pageSize || 10,
      pageIndex: queryParams.pageIndex || 1,
      maNhomQuyen: queryParams.maNhomQuyen || "USER",
      filter: queryParams.filter || "hoTen",
      tuKhoa: queryParams.tuKhoa || "",
    },
    isUndefined
  );
  return queryConfig;
}
