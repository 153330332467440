import React, { Fragment, useEffect, useState } from "react";
import {
  MentorChamBaiModel,
  NopBaiModel,
  trangThai,
} from "../../../Types/nopBaiType";
import styled from "styled-components";
import { CKEditor } from "ckeditor4-react";
import { Button, Image, InputNumber, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  GetDsNopBaiByMentorIdApi,
  putMentorChamBai,
  setNopBaiState,
} from "../../../redux/NopBaiReducer/nopBaiReducer";
import { Modal } from "antd";
import { RequireModel } from "../../../Types/RequireForStudent";
import { config } from "../../../util/config";
import { NguoiDungType } from "../../../Types/nguoiDungType";
import { getGroupForTaskApi } from "../../../redux/GroupForTaskReducer/GroupForTaskReducer";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { ChamBaiCSS } from "../../../pages/Mentor/BaiTap/BaiTap";
import { nopbaiService } from "../../../services/nopbaiService";
import { BulbOutlined, CodeOutlined, FileOutlined, FormOutlined } from "@ant-design/icons";


interface props {
  open: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  onCancel: () => void;
}
const ChamBai: React.FC<props> = ({ open, onCancel, setOpen }) => {
  const dispatch = useAppDispatch();
  const [nopBai, setNopBai] = useState<NopBaiModel>({
    chuyenDeId: 0,
    loaiModule: "",
    monHocId: 0,
    baiLam: "",
    daXoa: false,
    diem: 0,
    hanNopBai: "",
    id: 0,
    maLoai: "",
    ngayTao: "",
    nguoiCham: "",
    nguoiDungId: "",
    nhanXet: "",
    requireId: 0,
    taskId: 0,
    trangThai: "",
  });
  const handleImage = (domNode: any) => {
    if (domNode.name === "img") {
      const { src, alt, width } = domNode.attribs;
      return <Image src={src} alt={alt} width={width} />;
    }
  };

  const returnBaiLam = (bailam: string) => {
    let listBL: string[] = [];
    if (bailam) {
      try {
        listBL = [...(JSON.parse(bailam) as string[])];
      } catch (error) {
        console.log("lỗi parse json", error);
      }
    }
    return listBL.length > 0 ?
      <table className='table text-light table-bordered'>
        <thead>
          <tr>
            <th scope='col'>Loại link</th>
            <th scope='col'>Link nộp</th>
          </tr>
        </thead>
        <tbody>
          {listBL.length > 0 && listBL[0] && (
            <tr>
              <td>Source code</td>
              <td>
                <Link target='_blank' to={listBL[0]}>
                  Xem
                </Link>
              </td>
            </tr>
          )}
          {listBL.length > 1 && listBL[1] && (
            <tr>
              <td>Video</td>
              <td>
                <Link target='_blank' to={listBL[1]}>
                  Xem
                </Link>
              </td>
            </tr>
          )}
          {listBL.length > 2 && listBL[2] && (
            <tr>
              <th>Deploy</th>
              <td>
                <Link target='_blank' to={listBL[2]}>
                  Xem
                </Link>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      :
      <span>Học viên chưa nộp link bài tập</span>
  }

  const [danhGia, setDanhGia] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [chamlaiLoading, setChamlaiLoading] = useState(false);
  const [editorKey, setEditorKey] = useState(Date.now());
  const { NopBaiState, dsHocVienByMentorId } = useAppSelector(
    (state) => state.nopBaiReducer
  );

  const { dsBaiHocByDsId } = useAppSelector((e => e.baiHocReducer))

  const { dsLuyenTapByListId } = useAppSelector(
    (state) => state.LuyenTapReducer
  );
  const { dsMonhocTheoDSId } = useAppSelector((state) => state.monHocReducer);
  const [require, setRequire] = useState<RequireModel | null>(null);

  const [width, setWidth] = useState(window.innerWidth * 0.8);
  const yeucau = (
    idmonhoc: number,
    taskid: number,
    requireId: number,
    loaiModule: string
  ) => {
    if (loaiModule === "TASK" && dsMonhocTheoDSId.length) {
      const index = dsMonhocTheoDSId.findIndex((e) => e.id === idmonhoc);
      if (index > -1 && idmonhoc !== undefined) {
        const ind = dsMonhocTheoDSId[index].danhSachNoiDung.findIndex(
          (e) => e.id === taskid
        );
        if (ind !== -1) {
          const reId = parseInt(
            dsMonhocTheoDSId[index].danhSachNoiDung[ind].noiDungNhiemVu
          );
          const in2 = dsBaiHocByDsId.findIndex((e) => e.id === reId);
          if (in2 > -1) {
            const ind3 = dsBaiHocByDsId[in2].noiDung.findIndex(
              (e) => e.requireId === requireId
            );
            if (ind3 > -1) return dsBaiHocByDsId[in2].noiDung[ind3];
          }
        }
      }
    } else if (loaiModule === "PRACTICE" && dsLuyenTapByListId.length) {
      const index = dsLuyenTapByListId.findIndex((e) => e.id === idmonhoc);
      if (index > -1 && idmonhoc !== undefined) {
        const ind = dsLuyenTapByListId[index].danhSachNoiDung.findIndex(
          (e) => e.id === taskid
        );
        if (ind !== -1) {
          const reId = parseInt(
            dsLuyenTapByListId[index].danhSachNoiDung[ind].noiDungNhiemVu
          );
          const in2 = dsBaiHocByDsId.findIndex((e) => e.id === reId);
          if (in2 > -1) {
            const ind3 = dsBaiHocByDsId[in2].noiDung.findIndex(
              (e) => e.requireId === requireId
            );
            if (ind3 > -1) return dsBaiHocByDsId[in2].noiDung[ind3];
          }
        }
      }
    }
    return null;
  };

  const user = config.getStoreJson("USER_LOGIN") as NguoiDungType;
  const task = config.getStore("GROUP_FOR_TASK") as number;

  const chamBai = async (status: trangThai, nopBai: NopBaiModel) => {
    const MentorChamBai: MentorChamBaiModel = {
      id: nopBai.id,
      diem: Number(nopBai.diem),
      nguoiCham: user.id,
      nhanXet: nopBai.nhanXet ?? '',
      trangThai: status,
    };
    if (nopBai.diem > 0 || status == "NOP_LAI") {

      await nopbaiService.mentorChamBai(user.id, MentorChamBai);

      await dispatch(getGroupForTaskApi(task));
      await dispatch(GetDsNopBaiByMentorIdApi(user.id));
      setChamlaiLoading(false);
      setConfirmLoading(false);
    }
  };

  const onEditorChange = (evt: any) => {
    // console.log(evt.editor.getData());
    setDanhGia(evt.editor.getData())
    setNopBai((nb) => {
      return { ...nb, nhanXet: evt.editor.getData() ?? '' };
    });
  };
  const handleOkModal = () => {
    // console.log('Chấm bài');
    setConfirmLoading(true);
    chamBai("DA_CHAM", nopBai);

  };

  const handleChamLaiModal = () => {
    setChamlaiLoading(true);
    chamBai("NOP_LAI", nopBai);


  };
  const handleCancelModal = () => {
    // console.log("Clicked cancel button");
    setOpen(false);
  };
  const convertTenHocVien = (id: string) => {
    if (dsHocVienByMentorId.length) {
      const index = dsHocVienByMentorId.findIndex((e) => e.id === id);
      if (index !== -1) return dsHocVienByMentorId[index].hoTen;
    }
    return "";
  };

  const onchange = (value: number | null) => {
    setNopBai((nb) => {
      return value ? { ...nb, diem: value } : nb;
    });
  };
  // setEditorKey(Date.now());

  useEffect(() => {
    setEditorKey(Date.now());
  }, [NopBaiState.nhanXet]);

  useEffect(() => {

    if (dsBaiHocByDsId.length)
      setRequire((rq) => {
        return yeucau(
          NopBaiState.monHocId,
          NopBaiState.taskId,
          NopBaiState.requireId,
          NopBaiState.loaiModule
        )
          ? yeucau(
            NopBaiState.monHocId,
            NopBaiState.taskId,
            NopBaiState.requireId,
            NopBaiState.loaiModule
          )
          : null;
      });
    if (NopBaiState) {
      setNopBai((nb) => {
        return NopBaiState ? NopBaiState : nb;
      });
      setDanhGia(NopBaiState.nhanXet);
    }
  }, [dsBaiHocByDsId, NopBaiState, NopBaiState.id, NopBaiState.nhanXet]);

  useEffect(() => {



    const resize = () => {
      if (window.innerWidth >= 1000) {
        setWidth(window.innerWidth * 0.8)
      }
      else
        setWidth(window.innerWidth * 0.9)

    }
    resize()
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
    }

  }, [])

  return (
    <Modal
      style={{
        maxWidth: '1200px'
      }}
      className="mentor__modal"
      width={width ?? 600}
      title=""
      open={open}
      onOk={handleOkModal}
      onCancel={onCancel}
      footer={[

        <Button
          key="submit"
          type="primary"
          loading={confirmLoading}
          onClick={handleOkModal}
        >
          Chấm
        </Button>,
        <Button
          key="chamlai"
          type="dashed"
          loading={chamlaiLoading}
          onClick={handleChamLaiModal}
        >
          Nộp Lại
        </Button>,
      ]}
    >
      <ChamBaiCSS>
        <div className="row">
          <div className='debai text-white col-6'>

            <ul className='nav nav-pills mb-3' id='pills-tab' role='tablist'>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link chambaiItem active'
                  id='pills-debai-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-debai'
                  type='button'
                  role='tab'
                >
                  <div className='d-flex align-items-center gap-1'>
                   
                    <FileOutlined /> Đề bài
                  </div>
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link chambaiItem'
                  id='pills-dapan-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-dapan'
                  type='button'
                  role='tab'
                >
                 
                  <div className='d-flex align-items-center gap-1'>
                    <FormOutlined /> Đáp án
                  </div>
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link chambaiItem'
                  id='pills-goiy-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-goiy'
                  type='button'
                  role='tab'
                >
                  <div className='d-flex align-items-center gap-1'>
                    <BulbOutlined /> Gợi ý
                  </div>
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link chambaiItem'
                  id='pills-huongdan-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-huongdan'
                  type='button'
                  role='tab'
                >
                  <div className='d-flex align-items-center gap-1'>
                    <CodeOutlined /> Hướng dẫn
                  </div>
                </button>
              </li>
            </ul>
            <div className='tab-content' id='pills-tabContent'>
              <div className='tab-pane fade show active' id='pills-debai' role='tabpanel'>
                <div>
                  {require?.noiDung && parse(require?.noiDung)}
                </div>
              </div>
              <div className='tab-pane fade' id='pills-dapan' role='tabpanel' aria-labelledby='pills-profile-tab'>
                {require
                  ? parse(require.ketQua, {
                    replace: handleImage,
                  })
                  : ""}


              </div>
              <div className='tab-pane fade' id='pills-goiy' role='tabpanel' aria-labelledby='pills-contact-tab'>
                {require
                  ? parse(require.hint, {
                    replace: handleImage,
                  })
                  : ""}
              </div>{' '}
              <div
                className='tab-pane fade'
                id='pills-huongdan'
                role='tabpanel'
                aria-labelledby='pills-contact-tab'
              >
                {require?.solution && parse(require?.solution)}
              </div>
            </div>
          </div>

          <div className='col-6'>

            <div className="body">
              <div className="outcome">

                {returnBaiLam(NopBaiState.baiLam)}

              </div>
              <div className="">
                <label className="text-white h3 me-3">Điểm: </label>
                <InputNumber
                  style={{ width: "20%" }}
                  placeholder="Cho Điểm Từ 1 đến 100"
                  min={1}
                  max={100}
                  value={nopBai?.diem}
                  onChange={onchange}
                />
              </div>
              <div className="nhanxet">
                <label className="text-white h3">Nhận xét: </label>
                {open ? <CKEditor
                  key={NopBaiState?.id ?? 'a'}
                  initData={NopBaiState.nhanXet}
                  data={nopBai.nhanXet}
                  name="nhanXet"
                  config={{
                    toolbarGroups: [
                      {
                        name: "document",
                        groups: ["mode", "document", "doctools"],
                      },
                      { name: "clipboard", groups: ["clipboard", "undo"] },
                      {
                        name: "editing",
                        groups: ["find", "selection", "spellchecker"],
                      },
                      { name: "forms" },
                      "/",
                      { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
                      {
                        name: "paragraph",
                        groups: ["list", "indent", "blocks", "align", "bidi"],
                      },
                      { name: "links" },
                      { name: "insert" },
                      "/",
                      { name: "styles" },
                      { name: "colors" },
                      { name: "tools" },
                      { name: "others" },
                      { name: "about" },
                    ],
                    extraPlugins: "codesnippet", // Thêm plugin codesnippet vào danh sách extraPlugins
                  }}
                  onChange={onEditorChange}
                /> : <></>}
              </div>
            </div>
          </div>
        </div>
        {/*        
        <div className="head">
          <Tag color="purple">Yêu cầu : {NopBaiState?.requireId}</Tag>
          <div>{require?.noiDung && parse(require?.noiDung)}</div>
       
          <div className=" w-100">
            <div className="hint">
              <div>
                Hint:
                {require
                  ? parse(require.hint, {
                      replace: handleImage,
                    })
                  : ""}
              </div>
              <div>
                Kết Quả:{" "}
                {require
                  ? parse(require.ketQua, {
                      replace: handleImage,
                    })
                  : ""}
              </div>
            </div>
            <div className="outcome">{returnBaiLam(NopBaiState.baiLam)}</div>
          </div>
        </div>
        <div className="body">
          <div className="diem">
            <p>Điểm</p>
            <InputNumber
              style={{ width: "70%" }}
              placeholder="Cho Điểm Từ 1 đến 100"
              min={1}
              max={100}
              value={nopBai?.diem}
              onChange={onchange}
            />
          </div>
          <div className="nhanxet">
           {open? <CKEditor
              key={NopBaiState?.id??'a'}
              initData={NopBaiState.nhanXet}
              data={nopBai.nhanXet}
              name="nhanXet"
              config={{
                toolbarGroups: [
                  {
                    name: "document",
                    groups: ["mode", "document", "doctools"],
                  },
                  { name: "clipboard", groups: ["clipboard", "undo"] },
                  {
                    name: "editing",
                    groups: ["find", "selection", "spellchecker"],
                  },
                  { name: "forms" },
                  "/",
                  { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
                  {
                    name: "paragraph",
                    groups: ["list", "indent", "blocks", "align", "bidi"],
                  },
                  { name: "links" },
                  { name: "insert" },
                  "/",
                  { name: "styles" },
                  { name: "colors" },
                  { name: "tools" },
                  { name: "others" },
                  { name: "about" },
                ],
                extraPlugins: "codesnippet", // Thêm plugin codesnippet vào danh sách extraPlugins
              }}
              onChange={onEditorChange}
            />:<></>}
          </div>
        </div> */}
      </ChamBaiCSS>
    </Modal>
  );
};
export default ChamBai;