import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  GroupForTaskModel,
  GroupForTaskModel2,
  NguoiDungGroupForTask,
} from "../../Types/groupForTask";
import { BaiHocModel } from "../../Types/baiHocType";
import { DispatchType } from "../configStore";
import { groupForTaskService } from "../../services/groupForTaskService";
import {
  RequireForStudentModel,
  RequireModel,
} from "../../Types/RequireForStudent";
import { requireForStudentService } from "../../services/requireForStudentService";
import { nguoiDungService } from "../../services/nguoiDungService";
import { NguoiDungType } from "../../Types/nguoiDungType";
import { message } from "antd";

interface groupForTaskState {
  groupForTaskViewMentorModel: GroupForTaskModel[];
  groupForTaskViewMentorModel2: GroupForTaskModel2[];
  groupForTask: GroupForTaskModel;
  baiHoc: BaiHocModel;
  currentRequire: RequireModel;
  requires: RequireModel[];
  requireForStudents: RequireForStudentModel[];
  requireForStudentsByDsId: RequireForStudentModel[];
  checkRequireForStudents: boolean;
  arrDanhSachHocVien: string[];
  arrDSNguoiDung: NguoiDungGroupForTask[];
  thongTinMentor: NguoiDungGroupForTask;
}

const initialState: groupForTaskState = {
  groupForTaskViewMentorModel: [],
  groupForTaskViewMentorModel2: [],
  groupForTask: {
    id: 0,
    danhSachHocVien: "",
    mentorId: "",
    ngayTao: "",
    isDone: false,
    taskId: 0,
    chuyenDeId: 0,
    // loaiModule: "",
    maLoai: "",
    monHocId: 0,
    tenNhiemVu:"",
    tenBaiHoc:""
  },
  baiHoc: {
    id: 0,
    capDo: 0,
    ghiChu: "",
    maLoai: "",
    moTa: "",
    noiDung: "",
    tenBaiHoc: "",
    thoiLuong: "",
    xemDemo: false,
  },
  currentRequire: {
    requireId: 0,
    noiDung: "",
    ketQua: "",
    hint: "",
    solution: "",
    videoSolution: "",
  },
  requires: [],
  requireForStudents: [],
  requireForStudentsByDsId: [],
  checkRequireForStudents: false,
  arrDanhSachHocVien: [],
  arrDSNguoiDung: [],
  thongTinMentor: {
    id: "",
    avatar: "",
    hoTen: "",
    soDt: "",
    urls: "",
  },
};

const groupForTaskReducer = createSlice({
  name: "groupForTask",
  initialState,
  reducers: {
    GetGroupForTaskByMentorId: (
      state: groupForTaskState,
      action: PayloadAction<GroupForTaskModel[]>
    ) => {
      state.groupForTaskViewMentorModel = [...action.payload]
      state.groupForTaskViewMentorModel2 = [...state.groupForTaskViewMentorModel].map(e=>{
        let dsHV:string[];
        try {
          dsHV=JSON.parse(e.danhSachHocVien) as string[]
        } catch (error) {
          dsHV=[]
        }
        const newGFT:GroupForTaskModel2 ={
          id:e.id,
          danhSachHocVien:dsHV,
          isDone:e.isDone,
          maLoai:e.maLoai,
          mentorId:e.mentorId,
          ngayTao:e.ngayTao,
          chuyenDeId:e.chuyenDeId,
          monHocId:e.monHocId,
          taskId:e.taskId
        } 
        return newGFT
      })
      ;
    },
    getGroupForTask: (
      state: groupForTaskState,
      action: PayloadAction<GroupForTaskModel>
    ) => {
      state.groupForTask = action.payload;
      const newds: string[] = JSON.parse(
        state.groupForTask.danhSachHocVien
      ) as string[];

      state.arrDanhSachHocVien = newds.map((e) => e.trim());
    },
    getBaiHoc: (
      state: groupForTaskState,
      action: PayloadAction<BaiHocModel>
    ) => {
      state.baiHoc = { ...action.payload };
      // console.log(JSON.parse(action.payload.noiDung!))
    },
    setCurrentRequire: (
      state: groupForTaskState,
      action: PayloadAction<RequireModel>
    ) => {
      state.currentRequire = { ...action.payload };
    },
    setRequires: (state: groupForTaskState, action: PayloadAction<string>) => {
      const newRequire: RequireModel[] = JSON.parse(action.payload);
      // console.log("newRequires", newRequire);
      if (newRequire) state.requires = [...newRequire];
    },
    setRequireForStudentsByJson: (
      state: groupForTaskState,
      action: PayloadAction<string>
    ) => {
      const getTodate = () => {
        const currentDate = new Date();
        return currentDate.toISOString().slice(0, 19);
      };
      let count = 0;
      let sst = 0;
      const newRequireForStudent: RequireForStudentModel[] = JSON.parse(
        action.payload
      ).map((require: RequireModel) => {
        let hvId = "";
        if (count < state.arrDanhSachHocVien.length)
          hvId = state.arrDanhSachHocVien[count];
        else {
          count = 0;
          hvId = state.arrDanhSachHocVien[count];
        }
        count++;
        sst++;
        return {
          id: sst,
          studentId: hvId,
          noiDung: require.noiDung,
          deadline: getTodate(),
          isDone: false,
          requireId: require.requireId,
          groupForTaskId: state.groupForTask.id,
          ngayTao: getTodate(),
          daXoa: false,
        };
      }) as RequireForStudentModel[];
      // console.log(newRequireForStudent);
      if (newRequireForStudent.length !== 0)
        state.requireForStudents = [...newRequireForStudent];
    },
    setRequireForStudentByTaskId: (
      state: groupForTaskState,
      action: PayloadAction<RequireForStudentModel[]>
    ) => {
      if (action.payload.length !== 0) {
        state.requireForStudents = [...action.payload];
        state.checkRequireForStudents = true;
        // console.log(state.requireForStudents);
      } else state.checkRequireForStudents = false;
    },
    putRequireForStudent: (
      state: groupForTaskState,
      action: PayloadAction<{ id: string; studentId: string }>
    ) => {
      const index = state.requireForStudents.findIndex(
        (e) => e.id === action.payload.id
      );
      state.requireForStudents[index].studentId = action.payload.studentId;
    },
    getDsNguoiDung: (
      state: groupForTaskState,
      action: PayloadAction<NguoiDungGroupForTask[]>
    ) => {
      const arr = action.payload.sort((a, b) => {
        return (
          state.arrDanhSachHocVien.indexOf(a.id) -
          state.arrDanhSachHocVien.indexOf(b.id)
        );
      });
      state.arrDSNguoiDung = [...arr];
    },
    getThongTinMentor: (
      state: groupForTaskState,
      action: PayloadAction<NguoiDungGroupForTask>
    ) => {
      state.thongTinMentor = { ...action.payload };
    },
    getRequireForStudentsByDsId:( state: groupForTaskState,
      action: PayloadAction<RequireForStudentModel[]>)=>{
        state.requireForStudentsByDsId=[...action.payload]
    }
  },
});

export const {
  getBaiHoc,
  getGroupForTask,
  setRequires,
  setRequireForStudentsByJson,
  setRequireForStudentByTaskId,
  putRequireForStudent,
  getDsNguoiDung,
  getThongTinMentor,
  setCurrentRequire,
  GetGroupForTaskByMentorId,
  getRequireForStudentsByDsId
} = groupForTaskReducer.actions;
export default groupForTaskReducer.reducer;

//--------- action async ------------

export const getGroupForTaskApi = (id: number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await groupForTaskService.getGroupForTaskById(id);
      const action: PayloadAction<GroupForTaskModel> = getGroupForTask(
        result.data.content
      );
      if (result.data.content) dispatch(action);
    } catch (error) {
      message.error("Lỗi báo BE");
    }
  };
};
export const getBaiHocApi = (id: number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await groupForTaskService.getBaiHocById(id);
      const action: PayloadAction<BaiHocModel> = getBaiHoc(result.data.content);
      dispatch(action);
    } catch (error) {
      message.error("Lỗi báo BE");
      console.log(error);
    }
  };
};

export const getRequireForStudentByTaskIdApi = (id: number) => {
  return async (dispatch: DispatchType) => {
    try {
      const result =
        await requireForStudentService.getRequireForStudentByTaskId(id);
      // console.log("getRequireForStudentByTaskIdApi", result.data.content);
      const action: PayloadAction<RequireForStudentModel[]> =
        setRequireForStudentByTaskId(result.data.content);
      dispatch(action);
    } catch (error) {
      message.error("Lỗi báo BE")
    }
  };
};
export const GetDSRequireForStudentByDsGFTidApi = (ds: number[]) => {
  return async (dispatch: DispatchType) => {
    try {
      const result =
        await requireForStudentService.GetDSRequireForStudentByDsGFTid(ds);
      // console.log("getRequireForStudentByTaskIdApi", result.data.content);
      const action: PayloadAction<RequireForStudentModel[]> =
      getRequireForStudentsByDsId(result.data.content);
      dispatch(action);
    } catch (error) {
      message.error("Lỗi báo BE")
    }
  };
};

export const postOrPutRequireForStudentApi = (
  list: RequireForStudentModel[]
) => {
  return async () => {
    try {
      await requireForStudentService.postOrPutRequireForStudent(list);

      // const action: PayloadAction<RequireForStudentModel[]> =
      //   setRequireForStudentByTaskId(result.data.content);
      // dispatch(action);
    } catch (err) {
      message.error("postOrPutRequireForStudentApi Not success")
    }
  };
};

export const layDsNguoiDungByStringApi = (ds: string[]) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nguoiDungService.layDsNguoiDungByString(ds);
      const action: PayloadAction<NguoiDungGroupForTask[]> = getDsNguoiDung(
        result.data.content
      );
      if (result.data.content) dispatch(action);
    } catch (err) {
      message.error("layDsNguoiDungByString Not success")
    }
  };
};

export const getThongTinMentorApi = (id: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await groupForTaskService.getThongTinNguoiDung(id);
      const action: PayloadAction<NguoiDungGroupForTask> = getThongTinMentor(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      message.error("getThongTinMentorApi Not success")
    }
  };
};

export const GetGroupForTaskByMentorIdApi = (mentorId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await groupForTaskService.GetGroupForTaskByMentorId(
        mentorId
      );
      const action: PayloadAction<GroupForTaskModel[]> =
        GetGroupForTaskByMentorId(result.data.content);
      if (result.data.content) dispatch(action);
    } catch (err) {
      console.log(err);
    }
  };
};


