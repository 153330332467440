import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MonHocModel, NoiDungMonHoc } from "../../Types/monHocType";
import { DispatchType, RootState } from "../../redux/configStore";
import {
    Button,
    Drawer,
    Popconfirm,
    Select,
    Space,
    Table,
    TableColumnsType,
    Tabs,
    TabsProps,
    Tooltip,
    message,
} from "antd";

import MonHocForm from "../Task/MonHocForm";

import { monHocService } from "../../services/monHocService";
import { getMonHocApi } from "../../redux/MonHocReducer/monHocReducer";

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Row } from "../DragDrog/Row";
import { array_move } from "../../util/adminUti";
import { chuyenDeService } from "../../services/chuyenDeService";
import { ChuyenDeModel } from "../../Types/chuyenDeType";
import { getChuyenDeApi } from "../../redux/ChuyenDeReducer/chuyenDeReducer";
import { useNavigate, useParams } from "react-router-dom";
import { BackwardOutlined, PlusOutlined } from "@ant-design/icons";



export type listType = {
    value: string | number
    label: string | JSX.Element
}

type Props = {


};

let monHocEmpty: MonHocModel = {
    id: 0,
    danhSachNoiDung: "[]",
    danhSachTag: [],
    hinhAnh: "",
    moTa: "",
    tenMonHoc: "",
    videoGioiThieu: "",
};
export default function TaoMonHoc({

}: Props) {
    // create state
    const [open, setOpen] = useState(false);
    const [monHocDetail, setMonHocDetail] = useState<MonHocModel>(monHocEmpty);

    let { chuyenDeId } = useParams();
    const navigate = useNavigate();

    const { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);
    const { arrMonHoc } = useSelector((state: RootState) => state.monHocReducer);

    // get redux
    const dispatch: DispatchType = useDispatch();

    // function else
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    useEffect(() => { }, []);

    let chuyenDe = arrChuyenDe?.find(n => n.id == Number(chuyenDeId));


    const columns: TableColumnsType<MonHocModel> = [
        {
            key: 'sort',
        },
        { title: "id", dataIndex: "id" },

        {
            title: "Tên môn học",
            render: (_, record) => {
                let danhSachNoiDung = JSON.parse(record.danhSachNoiDung);
                return <>
                    {record.tenMonHoc} <label className="text-danger"> ( {danhSachNoiDung.length} task)</label>
                </>;
            }
        },

        {
            title: "Action",
            render: (_, record) => {
                return (
                    <>
                        <button className='btn btn-sm btn-info' onClick={() => {
                            navigate(`/themtask/${record.id}/${chuyenDeId}`);
                        }}>
                            <i className="fas fa-forward"></i>
                        </button>
                        <button
                            className="btn btn-sm btn-success mx-2"
                            onClick={() => {
                                let newData = { ...record };
                                newData.danhSachTag = JSON.parse(
                                    newData.danhSachTag.toString()
                                );

                                setMonHocDetail(newData);
                                showDrawer();
                            }}
                        >
                            <i className="fas fa-edit"></i>
                        </button>
                        <Popconfirm
                            title="Bạn có chắc muốn xóa ?"
                            onConfirm={() => {
                                if (chuyenDe) {


                                    // update noi dung danh sach
                                    let noiDung = JSON.parse(chuyenDe.danhSachNoiDung);
                                    let index = noiDung.danhSachMonHoc.findIndex((n: number) => n == record.id);
                                    noiDung.danhSachMonHoc.splice(index, 1);

                                    let setChuyenDe = { ...chuyenDe };
                                    setChuyenDe.danhSachNoiDung = JSON.stringify(noiDung);

                                    chuyenDeService.suaChuyenDe(setChuyenDe).then((res) => {
                                        dispatch(getChuyenDeApi());

                                        message.success("Đã lưu");
                                    }).catch((err) => {
                                        message.error("Lỗi Báo IT");
                                    });
                                }
                            }}
                        >
                            <button className="btn btn-sm btn-danger">
                                <i className="fas fa-times"></i>
                            </button>
                        </Popconfirm >
                    </>
                );
            },
        },
    ];


    // drag drog
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        let iOld = active.data.current?.sortable.index;
        let iNew = over?.data.current?.sortable.index;
        if (chuyenDe) {


            // update noi dung danh sach
            let noiDung = JSON.parse(chuyenDe.danhSachNoiDung);

            noiDung.danhSachMonHoc = array_move(noiDung.danhSachMonHoc, iOld, iNew)

            let setChuyenDe = { ...chuyenDe };
            setChuyenDe.danhSachNoiDung = JSON.stringify(noiDung);

            chuyenDeService.suaChuyenDe(setChuyenDe).then((res) => {
                dispatch(getChuyenDeApi());

                message.success("Đã lưu");
            }).catch((err) => {
                message.error("Lỗi Báo IT");
            });
        }
    };

    // them mon hoc vao chuyen de
    const themMonVaoChuyenDe = (monHocId: number) => {
        if (chuyenDe) {


            // update noi dung danh sach
            let noiDung = JSON.parse(chuyenDe.danhSachNoiDung);

            noiDung.danhSachMonHoc.push(monHocId);

            let setChuyenDe = { ...chuyenDe };
            setChuyenDe.danhSachNoiDung = JSON.stringify(noiDung);

            chuyenDeService.suaChuyenDe(setChuyenDe).then((res) => {
                dispatch(getChuyenDeApi());

                message.success("Đã lưu");
            }).catch((err) => {
                message.error("Lỗi Báo IT");
            });
        }
    }

    let lstMonHocOption: listType[] = [];

    
    arrMonHoc && arrMonHoc.map(item => {
        lstMonHocOption.push({ label: item.tenMonHoc, value: item.id });
    })
    let danhSachNoiDung = chuyenDe && JSON.parse(chuyenDe.danhSachNoiDung);

    let arrDataSource: MonHocModel[] = [];
    let danhSachMonHoc = danhSachNoiDung && danhSachNoiDung.danhSachMonHoc ? danhSachNoiDung.danhSachMonHoc : [];

    danhSachMonHoc.map((num: any) => {
        let checkMonHoc = arrMonHoc?.find(item => item.id == num);

        if (checkMonHoc) {
            arrDataSource.push(checkMonHoc);
        }
    })


    // xử lý lấy mon hoc tu danh sach
    let itemsKieuThem: TabsProps['items'] = [
        {
            key: '1',
            label: `Thêm mới`,
            children: <MonHocForm themMonVaoChuyenDe={themMonVaoChuyenDe} monHocDetail={monHocDetail} onClose={onClose} />,
        },
        {
            key: '2',
            label: `Chọn từ danh sách môn`,
            children: <>
                <Select
                    mode="multiple"
                    allowClear
                    optionFilterProp="label"

                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={danhSachNoiDung?.danhSachMonHoc}
                    onChange={(value) => {
                        if (chuyenDe) {

                            // update noi dung danh sach
                            let noiDung = JSON.parse(chuyenDe.danhSachNoiDung);

                            noiDung.danhSachMonHoc = value;

                            let setChuyenDe = { ...chuyenDe };
                            setChuyenDe.danhSachNoiDung = JSON.stringify(noiDung);

                            chuyenDeService.suaChuyenDe(setChuyenDe).then((res) => {
                                dispatch(getChuyenDeApi());

                                message.success("Đã lưu");
                            }).catch((err) => {
                                message.error("Lỗi Báo IT");
                            });
                        }
                    }}
                    options={lstMonHocOption}
                />
            </>

        },
    ];
    if (monHocDetail.id != 0) {
        itemsKieuThem = [
            {
                key: '1',
                label: `Chỉnh sửa`,
                children: <MonHocForm themMonVaoChuyenDe={themMonVaoChuyenDe} monHocDetail={monHocDetail} onClose={onClose} />,
            }
        ];
    }

    return (
        <div className=" p-3">
            <Space>
                <Button className="d-flex align-items-center bg-success mx-2 p-3" type="primary" style={{ fontSize: "16px", fontWeight: "bold" }} onClick={() => navigate(`/chuyende`)}>
                    <BackwardOutlined className='fs-5' />
                    <span>Back</span>
                </Button>
                <Button
                    className='d-flex align-items-center bg-primary my-3 p-3'
                    type="primary"
                    style={{ fontSize: "16px", fontWeight: "bold" }}
                    onClick={() => {
                        setMonHocDetail(monHocEmpty);
                        showDrawer();
                    }}>
                    <PlusOutlined />
                    <span>Thêm Môn</span>
                </Button>
            </Space>

            {arrMonHoc && (
                <DndContext onDragEnd={onDragEnd}>
                    <SortableContext

                        items={arrDataSource.map((i) => i.id)}
                        strategy={verticalListSortingStrategy}
                    >
                        <Table
                            components={{
                                body: {
                                    row: Row,
                                },
                            }}
                            columns={columns}
                            rowKey="id"
                            pagination={false}
                            dataSource={arrDataSource}
                        />
                    </SortableContext>
                </DndContext>
            )}


            <Drawer
                title="Quản lý môn học"
                width="40%"
                onClose={onClose}
                open={open}
                bodyStyle={{ paddingBottom: 80 }}
            >
                <Tabs defaultActiveKey="1" items={itemsKieuThem} />

            </Drawer>
        </div>
    );
}



