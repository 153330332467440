import React, { } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import styled from "styled-components";
import { getNopBaiCurrent } from "../../../../../redux/NopBaiReducer/nopBaiReducer";
import { setCurrentRequire } from "../../../../../redux/GroupForTaskReducer/GroupForTaskReducer";
import { Skeleton } from "antd";

const HeadRequireForTaskCSS = styled.section`
height: 130vh;
  .showlistRequire {
    text-align: center;
    border-right: 3px solid rgb(198, 147, 121);
    transition: all 0.5s;
    height: 130vh;

   
    .requireItemBG {
      width: 100%;
      height: 100px;
      

      .done{
        cursor: pointer;
      }
      .pending{
        opacity: 0.6;
      }

      .requireItem {
        width: 75px;
        height: 75px;
        border-radius: 50%;
        background-color: rgb(198, 147, 121);
        color: wheat;
        transition: all 0.5s;
      }

      :hover {
        .done {
          background-color: rgb(193, 124, 90);
          transform: scale(1.05);
        }
      }
      .active {
      border: 3px dashed rgb(150, 63, 42);
      background-color: rgb(193, 124, 90);
      
    }
    }
  }
  .positonCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
interface props {
  active: any,
  setActive: any
}

const HeadRequireForTask: React.FC<props> = (props) => {
  const dispatch = useAppDispatch();
  const { requireForStudents, requires } = useAppSelector(
    (state) => state.groupForTaskReducer
  );
  const { dsNopBaiByGroup } = useAppSelector(state => state.nopBaiReducer)

  const checkBaiLam = (requireId: number) => {
    if (dsNopBaiByGroup.length) {

      const index = dsNopBaiByGroup.findIndex(e => e.requireId === requireId)
      if (index === -1) return false
      else
        if (dsNopBaiByGroup[index].trangThai === 'CHO_CHAM') return true;
      // console.log(dsNopBaiByGroup[index])
    }
    return false;
  }

  const handleClick = (id: string) => {
    props.setActive(id);
  };
  const handleChangeCurrentRequire = (requireId: number) => {
    const index = requires.findIndex(e => e.requireId === requireId)
    if (index === -1) console.log('ko tim thấy require phù hợp')
    else {
      console.log(requires[index])
      dispatch(setCurrentRequire(requires[index]))
    }
  }

  const handleChangeCurrentNopBai = (requireId: number) => {
    const index = dsNopBaiByGroup.findIndex(e => e.requireId === requireId)
    if (index === -1) console.log('ko tim thấy nopbai phù hợp')
    else {
      dispatch(getNopBaiCurrent(dsNopBaiByGroup[index]))
    }
  }

  const checkNopBai = (requireId: number) => {
    return dsNopBaiByGroup.map(e => e.requireId).includes(requireId)
  }


  return (
    <HeadRequireForTaskCSS>
      {requires.length ? <div className="showlistRequire">
        {requireForStudents?.map((e, i) => {
          return (
            <div
              key={"require " + i}
              onClick={() => {
                if (checkNopBai(Number(e.requireId))) {
                  handleClick(e.id);
                  handleChangeCurrentNopBai(Number(e.requireId));
                  handleChangeCurrentRequire(Number(e.requireId));
                }

              }}
              className={`requireItemBG positonCenter `}
            >
              <p className={`requireItem positonCenter ${checkNopBai(Number(e.requireId)) ? "done" : "pending"} ${e.id === props.active ? "active" : ""
                }`}> {e.requireId} </p>
            </div>
          );
        })}
      </div> : <Skeleton active />}
    </HeadRequireForTaskCSS>
  );
};

export default HeadRequireForTask;
