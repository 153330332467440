import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { monHocService } from "../../services/monHocService";
import { http } from "../../util/config";
import { DispatchType } from "../configStore";
import { MonHocModel, MonHocViewMentorModel } from "../../Types/monHocType";

type monHocState = {
  arrMonHoc: MonHocModel[] | null;
  dsMonhocTheoDSId: MonHocViewMentorModel[];
};
const initialState: monHocState = {
  arrMonHoc: [],
  dsMonhocTheoDSId: [],
};

const monHocReducer = createSlice({
  name: "monHocReducer",
  initialState,
  reducers: {
    getMonHocAction: (
      state: monHocState,
      action: PayloadAction<MonHocModel[]>
    ) => {
      state.arrMonHoc = action.payload;
    },
    getDSMonhocTheoDSId: (
      state: monHocState,
      action: PayloadAction<MonHocViewMentorModel[]>
    ) => {
      state.dsMonhocTheoDSId = [
        ...(JSON.parse(
          JSON.stringify(action.payload)
        ) as MonHocViewMentorModel[]),
      ];
    },
  },
});

export const { getMonHocAction, getDSMonhocTheoDSId } = monHocReducer.actions;
export default monHocReducer.reducer;
//--------- action async ------------

export const getMonHocApi = () => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await monHocService.layDanhSachMonHoc();
      const action: PayloadAction<MonHocModel[]> = getMonHocAction(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log(error);
    }
  };
};
export const getDSMonhocTheoDSIdApi = (ds: number[]) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await monHocService.GetMonHocsByListIdService(ds);
      const action: PayloadAction<MonHocViewMentorModel[]> =
        getDSMonhocTheoDSId(result.data.content);
      dispatch(action);
    } catch (error) {
      console.log("lỗi getDSMonhocTheoDSIdApi");
      console.log(error);
    }
  };
};
