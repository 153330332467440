export const API_URL_DOMAIN: string = "https://api.techx.edu.vn/";
export const API_RUNCODE_DOMAIN = "https://runcode.cyberlearn.vn";


// export const API_URL_DOMAIN: string = "https://localhost:5001";

export const URL_PAGE_LEARN: string = "https://login.techx.edu.vn";


export const API_URL_VIDEO: string = `https://record.techx.edu.vn/api/file/ftp-video`;
export const API_URL_RECORD: string =  `https://record.techx.edu.vn/api/file/ftp-video-record`;
export const API_URL_SOLUTION: string = `https://record.techx.edu.vn/api/file/ftp-video-solution`;
// export const API_URL_SOLUTION: string = `https://localhost:5001/api/file/ftp-video-solution`;


