import React, { useState } from 'react'
import { HeaderCSS, PopconfirmBodyCss } from '../../assets/style/HeaderCss'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import {
  Avatar,
  Badge,
  MenuProps,
  Button,
  FloatButton,
  Form,
  Input,
  Menu,
  Popconfirm,
  message,
  Switch,
  Drawer,
  Tooltip,
  theme,
  Layout
} from 'antd'
import {
  ApartmentOutlined,
  BellOutlined,
  BookOutlined,
  CheckSquareOutlined,
  CommentOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  ReadOutlined,
  UploadOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined
} from '@ant-design/icons'
import { config, removeStore } from '../../util/config'
import ResponsiveItem from '../../hoc/ResponsiveItem'
import Loading from '../../hoc/loading/Loading'
import BottomTab from '../../components/BottomTab'
import StackChat from '../../components/StackChat/StackChat'
import HeaderHome from '../../components/HeaderHome'
import './mentor.css'
import { nguoiDungService } from '../../services/nguoiDungService'
import Search from 'antd/es/input/Search'
import { NguoiDungType } from '../../Types/nguoiDungType'
import LoadAvatar from '../../components/DanhSachChat/components/LoadAvatar'
import { splitFullName } from '../../util/functionUtils'
import PopupStackChat from '../../components/Popupchat/PopupStackChat'
type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem
}
type Props = {}
const divFunc = (props: Props) => {
  return <div></div>
}

let items: MenuItem[] = [
  getItem(
    <NavLink className='text-decoration-none' to='/mentor'> Chấm bài</NavLink>, '1', <HomeOutlined />
  ),
  getItem('QL Học tập', 'sub3', <BookOutlined />, [
    getItem(<NavLink className="text-decoration-none" to="/chuyende"> Chuyên đề </NavLink>, '2', <ApartmentOutlined />),
    getItem(<NavLink className="text-decoration-none" to="/class"> Lớp </NavLink>, '3', <ReadOutlined />),
    getItem(<NavLink className="text-decoration-none" to="/intern"> Thực tập </NavLink>, '4', <ReadOutlined />),
    getItem(
      <NavLink className='text-decoration-none' to='/baihoc'>Nhiệm vụ</NavLink>, '5', <ProfileOutlined />
    )

  ]),
  getItem(
    <NavLink
      onClick={() => {
        removeStore('USER_LOGIN')
        removeStore('path')
      }}
      className='text-decoration-none'
      to='/login'
    >
      {' '}
      LogOut !
    </NavLink>,
    '6',
    <LogoutOutlined />
  )
  // getItem(
  //   <NavLink className='text-decoration-none' to='/review-task'>
  //     {' '}
  //     Review Task
  //   </NavLink>,
  //   '6',
  //   <CheckSquareOutlined />
  // ),
  // getItem(
  //   <NavLink className='text-decoration-none' to='/quiz-test'>
  //     {' '}
  //     Bài Test
  //   </NavLink>,
  //   '7',
  //   <ContainerOutlined />
  // )
]
const { Sider } = Layout
function Mentor() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [valueSearch, setSearchTerm] = useState('')
  const [themeA, setTheme] = useState(true)
  const myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType;
  const hoTen = myUser?.hoTen.split(' ')
  const ten = hoTen[hoTen.length - 1]
  const [active, setActive] = useState(false)
  const [activeAvatar, setAvartar] = useState(true)
  const [collapsed, setCollapsed] = useState(false)
  const {
    token: { colorBgContainer }
  } = theme.useToken()


  const onClose = () => {
    setOpen(false)
  }

  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value)
  }

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      console.log(valueSearch)
    }
  }
  const handleSetActiveAvatar = () => {
    setAvartar(false)
    return false
  }

  // const draw = (
  //   <Drawer
  //     title=''
  //     className='MenuAdmin'
  //     placement={'left'}
  //     closable={false}
  //     onClose={onClose}
  //     open={open}
  //     key={'drawer'}
  //   >
  //     <Menu className='itemMenu' style={{ height: '100vh' }} mode='inline' theme='dark' items={items} />
  //   </Drawer>
  // )

  // const menuAdmin = () => {
  //   const MyFunctionalComponent: React.FC = () => {
  //     // Bất kỳ logic nào ở đây
  //     return (
  //       <div>
  //         {HeaderHome({
  //           switch: rederSwitch()
  //         })}
  //       </div>
  //     )
  //   }

  //   return (
  //     <div className='d-flex'>
  //       <div style={{ width: '10%' }}>
  //         <Menu style={{ height: '100vh' }} mode='inline' theme='dark' items={items} />
  //       </div>
  //       <div style={{ width: '100%' }}>
  //         <ResponsiveItem component={MyFunctionalComponent} mobileComponent={divFunc} />
  //         {/* <HeaderHome /> */}
  //         <div style={{ minHeight: 600 }}>
  //           <Outlet />
  //         </div>
  //       </div>

  //       <ResponsiveItem component={divFunc} mobileComponent={BottomTab} />
  //       <Loading />
  //     </div>
  //   )
  // }
  // const rederSwitch = () => {
  //   return (
  //     <Switch
  //       checked={menuMentor}
  //       checkedChildren='Admin'
  //       unCheckedChildren='Mentor'
  //       onChange={() => {
  //         if(!menuMentor)navigate('/home')
  //         else {navigate('/mentor')
  //       config.removeStore('path')}
  //         setMenu(!menuMentor)

  //       }}
  //     />
  //   )
  // }

  return (
    <div>
      <PopupStackChat />

      <HeaderCSS>
        <div className='bg__header'>
          <div
            className='header__logo'
            onClick={() => {
              navigate('/mentor')
            }}
          >
            <div className='logo'>
              <img src='https://techx.edu.vn/img/logo/logo-cyber.svg' alt='Cybersoft_logo' />
            </div>
          </div>
          {/* <div className='menu menuItem cursor_pointed btnHover'
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div> */}
          <div className='search'>
            { /* <i className='fa-solid fa-magnifying-glass menuItem cursor_pointed btnHover'></i>
           // <input onChange={handleInputChange} onKeyPress={handleKeyPress} placeholder='search...' />*/}
          </div>
          {/* <div className='themes  menuItem btnHover cursor_pointed'>
            {themeA ? <i className='fa-regular fa-moon'></i> : <i className='fa-regular fa-sun'></i>}
          </div> */}
          {/* <div className='lang'></div> */}
          {/* <div className='mail'></div> */}
          <div className='thongBao menuItem btnHover'>
            <div
              className={`nofication ${active ? 'active' : ''}`}
              onClick={() => {
                setActive(!active)
              }}
            >
              <Popconfirm
                className='PopconfirmMentor'
                placement='bottomRight'
                title={<h5 style={{ color: '#ffffff', paddingBottom: '10px' }}>Thông Báo</h5>}
                description={
                  <PopconfirmBodyCss>
                    <div className='d-flex justify-content-lg-between align-items-center popconfirm__item'>
                      <p>Tổng Bài Tập Cần Chấm</p> <p>15</p>
                    </div>
                    <div className='d-flex justify-content-lg-between align-items-center popconfirm__item'>
                      <p>Tổng issue bạn cần hỗ trợ</p> <p>15</p>
                    </div>
                    <div className='d-flex justify-content-lg-between align-items-center popconfirm__item'>
                      <p>Số Tin Nhắn Bạn Cần Trả Lời</p>
                      <p>15</p>
                    </div>
                  </PopconfirmBodyCss>
                }
                //  disabled

                showCancel={false}
                showArrow={false}
              >
                <Badge count={active ? 0 : 1}>
                  <BellOutlined />
                </Badge>
              </Popconfirm>
            </div>
          </div>
          {/* <div className='user menuItem btnHover cursor_pointed'>
            <Tooltip title={ten ?? 'Mentor'}>
              <div className='header__avatar'>
                <LoadAvatar url={myUser.avatar} conent={ten}/>
              
              </div>
            </Tooltip>
          </div> */}
          {/* <div className='setting menuItem btnHover cursor_pointed'>
            <i className='fa-solid fa-gear'></i>
          </div> */}
        </div>
        {/* <div className=''>
        
          <Layout>
            <Header style={{ padding: 0, background: colorBgContainer }}>
              <Button
                type='text'
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64
                }}
              />
              
            </Header>
            
            <Content
            // style={{
            //   margin: '24px 16px',
            //   padding: 24,
            //   minHeight: 280,
            //   background: colorBgContainer,
            // }}
            >
           
            </Content>
          </Layout>
        </div> */}
      </HeaderCSS>
      <div className=''>
        <ResponsiveItem component={divFunc} mobileComponent={BottomTab} />
        <Loading />
        <div className='d-flex ' style={{ height: '100%' }}>
          <div style={{ backgroundColor: 'var(--color-sub-title)', width: collapsed ? 80 : 260, transition: 'all .3s', position: 'relative' }}>
            <Sider style={{ top: '60px', position: 'sticky' }} className='slider_mentor' trigger={null} width={260} collapsible collapsed={collapsed}>
              <Menu
                className='menuMentor'
                theme='dark'
                mode='inline'

                defaultSelectedKeys={['1']}
                // items={[
                //   {
                //     key: '1',
                //     icon: <HomeOutlined />,
                //     label: 'nav 1'
                //   },
                //   {
                //     key: '2',
                //     icon: <VideoCameraOutlined />,
                //     label: 'nav 2'
                //   },
                //   {
                //     key: '3',
                //     icon: <UploadOutlined />,
                //     label: 'nav 3'
                //   }
                // ]}
                items={items}
              />
            </Sider>
          </div>
          <div className='flex-fill'>
            {' '}
            <Outlet />
          </div>
        </div>
      </div>

      {/* test chat học viên  */}
      {/* <StackChat/> */}
      {/* <div className='Alo'>
        <Drawer
          title=''
          className='MenuAdmin'
          placement={'left'}
          closable={false}
          onClose={onClose}
          open={open}
          key={'drawer'}
          width={260}
        >
          <Menu className='itemMenu' style={{ height: '100vh' }} mode='inline' theme='dark' items={items} />
        </Drawer>
      </div> */}
    </div>
  )
}

export default Mentor
