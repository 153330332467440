import { BaiHocModel } from '../Types/baiHocType'
import { http } from '../util/config'

const GET_PATH: string = '/api/baihoc'

const layDanhSachBaiHoc = () => {
  return http.get(GET_PATH)
}

const themBaiHoc = (data: BaiHocModel | any) => {
  return http.post(GET_PATH, data)
}
const suaBaiHoc = (data: BaiHocModel | any) => {
  return http.put(`${GET_PATH}/${data.id}`, data)
}
const xoaBaiHoc = (id: number) => {
  return http.delete(`${GET_PATH}/${id}`)
}

const postDanhSachBaiHocByListId = (ds: number[]) => {
  return http.post(`${GET_PATH}/dsbaihocbylistid`, ds)
}

export const baiHocService = {
  layDanhSachBaiHoc,
  themBaiHoc,
  suaBaiHoc,
  xoaBaiHoc,
  postDanhSachBaiHocByListId
}
