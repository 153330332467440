import { http } from "../util/config";

const GET_PATH: string = '/api/file/image';

const uploadImage = async (file: File): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append("files", file);

    const response = await http.post(GET_PATH, formData);

    return response.data[0];
  } catch (error) {
    // Xử lý lỗi nếu có
    console.error("Đã xảy ra lỗi khi tải lên hình ảnh:", error);
    throw error;
  }
};

export const uploadImageService = {
  uploadImage,
}
