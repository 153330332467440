import { Select, Tabs, TabsProps, Tag, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import { API_URL_DOMAIN, URL_PAGE_LEARN } from '../../util/urlDomain';

// import * as jwt from 'jose';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/configStore';

import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { dinhDangNgay } from '../../util/dateFormat';
import { BaiTestModel } from '../../Types/heThongType';
import { heThongService } from '../../services/heThongService';
import { useNavigate } from 'react-router-dom';

type Props = {

}

const BaiTestPhongVan = ({ }: Props) => {

    // search
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Tìm
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <b>{searchText}</b>
            ) : (
                text
            ),
    });
    // end search

    const getLabelLop = (value: string) => {
        switch (value) {
            case "CS3":
                return "Lớp CS3";

            case "CS2":
                return "Lớp CS2";

            case "CS1":
                return "Lớp CS1";

            default:
                return "Không đủ điều kiện";

                break;
        }

    }
    //end search


    const [lopHoc, setLopHoc] = useState("0");
    const { arrBaiTest } = useSelector((state: RootState) => state.heThongReducer);


    let newArrBaiTest = arrBaiTest?.map((item: any) => {
        let thongTinThem = JSON.parse(item.thongTinThem);

        if (thongTinThem.maBaiTest == "JS_HV_PV") {

            return item;
        }

    })

    const columns: ColumnsType<any> = [

        {
            title: 'Email',
            key: 'action',
            ...getColumnSearchProps('email'),
            render: (_, record) =>
                <>
                    {record.email}
                </>

        },
        {
            title: 'Kết quả',
            key: 'action',
            render: (_, record) => {
                let linkNopBai = JSON.parse(record.linkNopBai);

                let html = linkNopBai.noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "HTML");
                let boot = linkNopBai.noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "BS");
                let sass = linkNopBai.noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "SASS");
                let git = linkNopBai.noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "GIT");
                let js = linkNopBai.noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "JS_ES6");
                let ts = linkNopBai.noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "JS_OOP");
                let react = linkNopBai.noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "REACT");

                let phanTram = (linkNopBai.soCauDung * 100 / linkNopBai.noiDungBaiLam.length).toFixed(0);

                return <table className="table ">
                    <tr>
                        <td>
                            Thời gian làm: <b>60 phút</b> <br />
                            Tổng câu đúng: <b> {`${linkNopBai.soCauDung} / ${linkNopBai.noiDungBaiLam.length}`} câu</b> <br />
                            {+phanTram >= 80 ? <Tag color='green'>Đạt</Tag> : <Tag color='red'>Không đạt</Tag>}
                            <Tooltip title="Đạt khi đúng 80% !">
                                <i className="fa-solid fa-circle-question"></i>
                            </Tooltip>
                        </td>
                        <td>
                            <Tag className='m-1'>   HTML/CSS: <b>{html.filter((n: any) => n.luaChon == true).length + "/" + html.length}</b></Tag>
                            <Tag className='m-1'>  Bootstrap:  <b>{boot.filter((n: any) => n.luaChon == true).length + "/" + boot.length}</b></Tag>
                            <Tag className='m-1'>   SASS:  <b>{sass.filter((n: any) => n.luaChon == true).length + "/" + sass.length}</b></Tag>
                            <br />
                            <Tag className='m-1'>  GIT:  <b>{git.filter((n: any) => n.luaChon == true).length + "/" + git.length}</b></Tag>

                            <Tag className='m-1'>  JavaScript:  <b>{js.filter((n: any) => n.luaChon == true).length + "/" + js.length}</b></Tag>

                            <Tag className='m-1'> TypeScript/OOP:  <b>{ts.filter((n: any) => n.luaChon == true).length + "/" + ts.length}</b></Tag>
                            <br />
                            <Tag className='m-1'>  React:  <b>{react.filter((n: any) => n.luaChon == true).length + "/" + react.length}</b></Tag>
                        </td>
                    </tr>
                </table>


            }


        },
        {
            title: 'Ngày làm',
            key: 'action',
            render: (_, record) => {

                return <Tag>{dinhDangNgay(record.ngayTao)}</Tag>


            }
        }
    ];
    const xuatReport = () => {

        let arrModel: BaiTestModel[] = []

        newArrBaiTest?.map(item => {
            let model: BaiTestModel = {
                id: 0,
                email: item.email,
                linkNopBai: "",
                thongTinThem: getLabelLop(item.lopHoc),
                ngayTao: item.ngayTao
            }
            arrModel.push(model);
        })

        heThongService.layExportExelTest(arrModel).then(res => {
            if (res.data.content == "1")
                window.location.href = API_URL_DOMAIN + "/files/DS_test.xlsx"
        })
    }


    let navigate = useNavigate();

    return (
        <div className='p-3'>
            <h3 className='p-3'>Kết quả test đầu vào
                <a href='#' className='text-primary text-decoration-none' onClick={() => {
                    navigate("/quiz-test")
                }}> Computer Science</a>
                |
                Phỏng vấn
            </h3>

            <Tooltip title="Đã copy" trigger="click">
                <a className="btn btn-primary me-2" onClick={async () => {

                    // Copy the text inside the text field
                    navigator.clipboard.writeText(`${URL_PAGE_LEARN}/quiz-test`);

                }}>Bài test</a>
            </Tooltip>
            {/* <Select
                defaultValue="0"
                style={{ width: 120 }}
                onChange={(value) => setLopHoc(value)}
                options={[
                    { value: '0', label: 'Tất cả' },
                    { value: 'CS1', label: 'CS 1' },
                    { value: 'CS2', label: 'CS 2' },
                    { value: 'CS3', label: 'CS 3' },
                    { value: 'CS', label: 'Không đạt' }

                ]}
            /> */}

            {/* <button className='btn btn-outline-primary ms-2' onClick={() => xuatReport()}>Xuất report</button> */}

            <Table columns={columns} dataSource={newArrBaiTest
                ? newArrBaiTest.filter(n => {
                    if (lopHoc == "0")
                        return n;
                    else
                        return n.lopHoc == lopHoc;
                })
                : []} />
        </div>
    )
}

export default BaiTestPhongVan
