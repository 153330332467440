import { Upload, UploadFile, UploadProps, message } from 'antd'
import { CKEditor } from 'ckeditor4-react'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { uploadImageService } from '../../services/uploadImagService'
import { InboxOutlined } from '@ant-design/icons';
import { API_URL_DOMAIN } from '../../util/urlDomain';

const { Dragger } = Upload;

type Props = {
    noiDungTaiLieu: string,
    setNoiDungTaiLieu: Dispatch<SetStateAction<string>>

}

const DocTaiLieu = ({
    noiDungTaiLieu,
    setNoiDungTaiLieu
}: Props) => {
    const [key, setKey] = useState(Date.now());

    useEffect(() => {
        setKey(Date.now());

    }, [])


    //set data ck editor bth
    const onEditorChange = (evt: any) => {

        setNoiDungTaiLieu(evt.editor.getData());

    }



    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        action: `${API_URL_DOMAIN}/api/file`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                
                setNoiDungTaiLieu(info.file.response[0]);
                message.success(`${info.file.response[0]} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <div>
            <a href={`${API_URL_DOMAIN}/${noiDungTaiLieu}`} target='_blank'> <h3>File đã up: {noiDungTaiLieu}</h3></a>

            <Dragger {...uploadProps} >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Upload or drag file</p>
            </Dragger>

            {/* <CKEditor
                key={key}
                initData={noiDungTaiLieu}

                config={{
                    toolbarGroups: [
                        { name: 'document', groups: ['mode', 'document', 'doctools'] },
                        { name: 'clipboard', groups: ['clipboard', 'undo'] },
                        { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                        { name: 'forms' },
                        '/',
                        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                        { name: 'links' },
                        { name: 'insert' },
                        '/',
                        { name: 'styles' },
                        { name: 'colors' },
                        { name: 'tools' },
                        { name: 'others' },
                        { name: 'about' }
                    ]
                }}
                name="ghiChu"
                onChange={onEditorChange}
            /> */}
        </div>
    )
}

export default DocTaiLieu
