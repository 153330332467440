
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { DispatchType, RootState } from '../../../redux/configStore'
import { useDispatch } from 'react-redux'
import { getChuyenDeChiTietApi, getUserChuyenDeApi } from '../../../redux/ChuyenDeReducer/chuyenDeReducer'
import { Col, Divider, Drawer, Input, Modal, Row, Skeleton, Table, message } from 'antd'
import { useSelector } from 'react-redux'
import { ColumnsType } from 'antd/es/table'
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { dinhDangNgay } from '../../../util/dateFormat'
import { nopbaiService } from '../../../services/nopbaiService'
import { GetDsNopBaiByMentorIdApi } from '../../../redux/NopBaiReducer/nopBaiReducer'
import { config } from '../../../util/config'

const { Search } = Input;

type Props = {
    chuyenDeId: number,
    metaData: any,
    listNopBai: any

}
type TypeData = {
    [key: string]: any
}


const ChuyenDeChiTiet = ({
    chuyenDeId,
    metaData,
    listNopBai
}: Props) => {


    const [open, setOpen] = useState(false);

    const dispatch: DispatchType = useDispatch();
    const { chuyenDeChiTiet, arrUserChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);

    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [data, setData] = useState<any>({ nameSearch: "" });

    const showChildrenDrawer = () => {
        setChildrenDrawer(true);
    };

    const onChildrenDrawerClose = () => {
        setChildrenDrawer(false);
    };


    let metaDatachuyenDe = metaData ? JSON.parse(metaData) : null;

    const columns: ColumnsType<any> = [
        {
            title: 'Môn học',
            dataIndex: "monHoc",
            render: (_, record) => {

                return <label>
                    {record.tenMonHoc}
                </label>
            }
        },


    ];

    const columnsChild: ColumnsType<any> = [
        {
            title: 'Task',
            dataIndex: "task",
            render: (_, record) => {

                return <label>
                    {record.tenNhiemVu}
                </label>
            }
        },

        {
            title: 'Action',
            dataIndex: "action",
            width: 200,
            render: (_, record) => {


                return <>
                    <button className='btn btn-sm btn-primary' onClick={() => {
                        showChildrenDrawer()
                        setData({ ...data, infoTask: record })
                    }}>Kích hoạt</button>
                </>
            }
        },


    ];



    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
    const checkAll = arrUserChuyenDe?.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < arrUserChuyenDe?.length;

    const onChangeCheckBox = (e: CheckboxChangeEvent, dangKyHocId: number,) => {

        let checkedListTemp = [...checkedList];
        if (e.target.checked) {
            checkedListTemp.push(dangKyHocId)
        } else {
            checkedListTemp = checkedListTemp.filter(n => n != dangKyHocId)
        }
        setCheckedList(checkedListTemp);
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        let plainOptions: any = [];
        arrUserChuyenDe?.map((item: any) => {
            plainOptions.push(item.dangKyHocId)
        })
        setCheckedList(e.target.checked ? plainOptions : []);
    };

    return (
        <div className='col-2'>
            {
                metaDatachuyenDe && metaDatachuyenDe.loaiChuyenDe == "INTERN" && <button className='btn btn-sm btn-success mb-3' onClick={() => {
                    setOpen(true)
                    dispatch(getUserChuyenDeApi(Number(chuyenDeId)))

                    dispatch(getChuyenDeChiTietApi(Number(chuyenDeId)))

                }}> Kích hoạt bài tập </button>
            }


            <Modal
                title="Chi tiết chuyên đề"

                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={"60%"}
                footer={null}
            >
                {chuyenDeChiTiet && chuyenDeChiTiet.danhSachMonHoc ?
                    <Table
                        columns={columns}
                        rowKey="monHocId"
                        dataSource={chuyenDeChiTiet.danhSachMonHoc}
                        expandable={{
                            expandedRowRender: (record, index) => {

                                let chilTable: any[] = record.danhSachNoiDung.filter((item: any) => item.maLoaiNhiemVu == "BAITAP" || item.maLoaiNhiemVu == "DUAN" || item.maLoaiNhiemVu == "HOMEWORK" || item.maLoaiNhiemVu == "CAPS");

                                return <Table
                                    columns={columnsChild}
                                    dataSource={chilTable}
                                />
                            }
                        }}
                    />

                    :

                    <Skeleton />
                }


            </Modal>

            <Drawer
                title="Danh sách user"
                width="40%"
                closable={false}
                onClose={onChildrenDrawerClose}
                open={childrenDrawer}
            >

                <Row >
                    <Col span={24}>
                        <div className='row'>
                            <div className='col-4'>
                                <Search placeholder="Nhập tên" onSearch={value => {
                                    setData({
                                        ...data, nameSearch: value
                                    })
                                }} enterButton />

                            </div>
                            <div className='col-2'>
                                <button className='btn btn-sm btn-primary' onClick={() => {
                                    let div_loading = document.getElementsByClassName("div_loading");
                                    div_loading[0].removeAttribute("hidden");
                                    let model = {
                                        lstDangKyHocId: checkedList,
                                        chuyenDeId: data.infoTask.chuyenDeId,
                                        monHocId: data.infoTask.monHocId,
                                        taskId: data.infoTask.id
                                    }

                                    nopbaiService.kichHoatBaiTap(model).then(res => {
                                        const user = config.getStoreJson('USER_LOGIN')
                                        dispatch(GetDsNopBaiByMentorIdApi(user.id))
                                        message.success("Đã lưu")
                                    }).catch(err => message.error("Đã xảy ra lỗi !")).finally(() => div_loading[0].setAttribute("hidden", ""))

                                }}>Kích hoạt</button>
                            </div>
                        </div>


                        <Divider />
                    </Col>
                    <Col span={10}>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            Check all
                        </Checkbox>
                    </Col>
                    <Col span={7}>
                        <b>  Ngày kích hoạt</b>

                    </Col>
                    <Col span={7}>
                        <b> Deadline</b>

                    </Col>


                </Row>
                <Divider />

                {
                    arrUserChuyenDe?.filter((item: any) => item.hoTen.toLowerCase().indexOf(data?.nameSearch?.toLowerCase().trim()) != -1).map((item: any) => {


                        let userNopBai = listNopBai.find((n: any) => n.nguoiDungId == item.nguoiDungId && n.chuyenDeId == data?.infoTask?.chuyenDeId && n.monHocId == data?.infoTask?.monHocId && n.taskId == data?.infoTask?.id)


                        return <>


                            <Row >

                                <Col span={10}>
                                    <Checkbox checked={checkedList.find(n => n == item.dangKyHocId) ? true : false} onChange={(event) => onChangeCheckBox(event, item.dangKyHocId)}>
                                        <b>Họ tên: </b> {item?.hoTen}
                                        <br />
                                        <b>Email: </b> {item?.email}
                                        <br />
                                        <b>Tham gia: </b> {dinhDangNgay(item?.ngayBatDauHoc)}
                                    </Checkbox>
                                </Col>
                                <Col span={7}>
                                    {userNopBai && dinhDangNgay(userNopBai?.ngayTao)}

                                </Col>
                                <Col span={7}>
                                    {userNopBai && dinhDangNgay(userNopBai?.hanNopBai)}


                                </Col>


                            </Row>

                            <Divider />
                        </>
                    })
                }

            </Drawer>
        </div>
    )
}

export default ChuyenDeChiTiet