import React, { useEffect } from 'react'
import { AuthProvider } from '../../hooks/useHub'
import GroupChat from '../../components/GroupChat/GroupChat';
import { useNavigate } from 'react-router-dom'

import useAuth from '../../hooks/useAuth';
import { history } from '../../index';

export default function Chat() {

  return (
    <AuthProvider>
      <GroupChat />
    </AuthProvider>
  )
}
