import React from "react";
import ProjectOutcomes from "./LayoutForInstructions/ProjectOutcomes/ProjectOutcomes";
import ProjectGuide from "./LayoutForInstructions/ProjectGuide";
import {ProjectOutlined } from "@ant-design/icons";
const ProjectInstructions:React.FC=()=>{
    return (
        <div className="ProjectInstructions">
            <h3 className="heading"> <ProjectOutlined /> Project Instructions</h3>
            <div className="row">
                <div className="col">
                    <ProjectOutcomes/>
                </div>
                <div className="col-5">
                    <ProjectGuide/>
                </div>
            </div>
        </div>
    )
}
export default ProjectInstructions;
