import { Col, Input, Modal, Row, Form, message } from "antd";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { getStoreJson, removeStore } from "../../util/config";
import { nguoiDungService } from "../../services/nguoiDungService";
import { useNavigate } from "react-router-dom";
type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ModalDoiMatKhau = ({ open, setOpen }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      matKhau: "",
      xacNhanMatKhau: "",
    },
    validationSchema: Yup.object({
      matKhau: Yup.string().required("Không bỏ trống mật khẩu"),
      xacNhanMatKhau: Yup.string().required("Không bỏ trống"),
    }),
    onSubmit: (values) => {
      if (values.matKhau !== values.xacNhanMatKhau) {
        messageApi.open({
          type: "error",
          content: "Mật khẩu không khớp",
        });
      } else {
        const nguoiDung = getStoreJson("USER_LOGIN");
        nguoiDungService
          .updateNguoiDung({
            ...nguoiDung,
            matKhau: values.matKhau,
            maGioiThieu: nguoiDung.maGioiThieu ? nguoiDung.maGioiThieu : "",
          })
          .then((res) => {
            messageApi.open({
              type: "success",
              content: "Đổi mật khẩu thành công",
            });
            removeStore("USER_LOGIN");
            navigate("/login");
          })
          .catch((err) => {
            messageApi.open({
              type: "error",
              content: "Đổi mật khẩu thất bại",
            });
          });
      }
    },
  });
  const { handleChange, handleBlur, handleSubmit } = formik;

  return (
    <>
      {contextHolder}
      <Modal
        open={open}
        title="Đổi mật khẩu"
        onCancel={() => {
          setOpen(!open);
        }}
        onOk={() => {
          handleSubmit();
        }}
      >
        <Form layout="vertical" hideRequiredMark onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="matKhau" label="Mật khẩu mới">
                <Input.Password
                  name="matKhau"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Vui lòng nhập mật khẩu"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="xacNhanMatKhau" label="Xác nhận mật khẩu">
                <Input.Password
                  name="xacNhanMatKhau"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Vui lòng nhập mật khẩu"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ModalDoiMatKhau;
