export const fake_Data = [
  {
    question_id: 21,
    input_format: "array.integer nums",
    output_format: "Integer",
    sample_input: "[19, 17, 19, 68, 68]",
    sample_output: "17",
    _level: "easy",
    en: {
      question_title: "Unique Number",
      problem:
        "Given an array of integers in which every numbers appears twice except for one, your taks is to find that unique element.",
      explanation:
        "For arr = [19, 17, 19, 68, 68], the output should be uniqueNumber(arr) = 17.",
      constraint_input: "1 ≤ nums.length < 104\n-109 ≤ nums[i] ≤ 109",
      constraint_output: "Return an integer.",
      constraint_time: "1 second",
    },
    vi: {
      question_title: "Số duy nhất",
      problem:
        'Cho một mảng gồm nhiều các số nguyên, trong đó tất cả các số đều xuất hiện 2 lần trừ 1 số duy nhất xuất hiện 1 lần. Nhiệm vụ của bạn là tìm số duy nhất xuất hiện 1 lần đó"',
      explanation:
        "Với dữ liệu arr = [19, 17, 19, 68, 68], kết quả phải là uniqueNumber (arr) = 17.",
      constraint_input: "1 ≤ nums.length <104\n-109 ≤ num [i] ≤ 109",
      constraint_output: "Trả về một số nguyên.",
      constraint_time: "1 giây",
    },
    test_case: [
      {
        testcase_id: 1,
        _input: "[19, 17, 19, 68, 68]",
        _output: "17",
      },
      {
        testcase_id: 2,
        _input: "[34, 76, 76]",
        _output: "34",
      },
      {
        testcase_id: 3,
        _input: "[46, 46, 11, 11, 59, 59, 55, 55, 35]",
        _output: "35",
      },
      {
        testcase_id: 4,
        _input: "[19, 1, 41, 41, 94, 1, 94, 80, 19]",
        _output: "80",
      },
      {
        testcase_id: 5,
        _input: "[86, 51, 51, 72, 57, 57, 78, 78, 86]",
        _output: "72",
      },
      {
        testcase_id: 6,
        _input: "[45, 90, 45, 90, 26, 38, 49, 26, 38]",
        _output: "49",
      },
    ],
    init_code: [
      {
        _language: "cpp",
        tle: 1000,
        _function: "int uniqueNumber(vector<int> arr) {\n}",
        base_code:
          "#include <iostream>\n#include <vector>\n#include <algorithm>\n#include <sstream>\n#include <iterator>\nusing namespace std;\nvector<int> handleArrayInput(string input) {\n    vector<int> result;\n    stringstream text_stream(input);\n    string item;\n    while (std::getline(text_stream, item, ',')) {\n        result.push_back(stoi(item));\n    }\n    return result;\n}\n__function__\nint main(int argc, char** argv) {\n    string param = __test_case__;\n    cout << uniqueNumber(handleArrayInput(param));\n    return 0;\n}",
        _answer:
          "int uniqueNumber(vector<int> arr) {\n    sort(arr.begin(), arr.end());\n    for (int i = 0; i < arr.size() - 1; i++) {\n        if (arr[i] == arr[i+1]) {\n            i++;\n        }\n        else return arr[i];\n    }\n}",
      },
      {
        _language: "java",
        tle: 1000,
        _function: "int uniqueNumber(int[] arr) {\n}",
        base_code:
          "import java.io.*;\nimport java.lang.*;\nimport java.util.*;\npublic class Solution {\n    public static __function__\n    public static void main(String[] args) {\n        int[] param = {__test_case__};\n        System.out.println(uniqueNumber(param));\n    }\n}",
        _answer:
          "int uniqueNumber(int[] arr) {\n\tArrays.sort(arr);\n\tint result = 0;\n\tfor(int i = 0; i < arr.length; i += 2) {\n\t\tif (arr[i] != arr[i + 1]) {\n\t\t\tresult = arr[i];\n\t\t\tbreak;\n\t\t}\n\t}\n\treturn result;\n}",
      },
      {
        _language: "js",
        tle: 1000,
        _function: "function uniqueNumber(arr) {\n}",
        base_code: "__function__\nconsole.log(uniqueNumber([__test_case__]))",
        _answer:
          "function uniqueNumber(arr) {\n    for (let i = 0; i < arr.length; i++) {\n        if (arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])) return arr[i]\n    }\n}",
      },
      {
        _language: "py",
        tle: 1000,
        _function: "def uniqueNumber(arr):",
        base_code: "__function__\nprint(uniqueNumber([__test_case__]))",
        _answer:
          "def uniqueNumber(arr):\n\tfor x in arr:\n\t\tif arr.count(x) == 1:\n\t\t\treturn x",
      },
    ],
  },
  {
    question_id: 22,
    input_format: "String str",
    output_format: "Integer",
    sample_input: "1365",
    sample_output: "1",
    _level: "easy",
    en: {
      question_title: "Check Number",
      problem:
        "Given a string s containing characters and numbers, check to see if s is represented as a decimal integer or not. Returns 1 if true, -1 if false.",
      explanation:
        'For s = "a123" then checkNumber(s) = false.\nExplanation: "a123" cannot be represented as a decimal integer.',
      constraint_input: "str.Length ≤ 50",
      constraint_output:
        "Return 1 if s is represented as a decimal integer, -1 otherwise.",
      constraint_time:
        "0.5 seconds for C++\n3 seconds for Java\n4 seconds for Python and JavaScript",
    },
    vi: {
      question_title: "Kiểm tra số nguyên",
      problem:
        "Cho một chuỗi s gồm các ký tự và số, kiểm tra xem chuỗi s có phải là một chuỗi số nguyên hay không. Trả về 1 nếu true và -1 nếu false",
      explanation:
        'Cho chuỗi s = "a123", kết quả trả về là checkNumber(s) = false.\nGiải thích: "a123" không thể đại diện cho số nguyên nào do nó có ký tự \'a\' trong đó',
      constraint_input: "str.Length ≤ 50",
      constraint_output:
        "Trả về 1 nếu s được biểu thị dưới dạng số nguyên -1 nếu không.",
      constraint_time:
        "0,5 giây cho C++\n3 giây cho Java\n4 giây cho Python và JavaScript",
    },
    test_case: [
      {
        testcase_id: 1,
        _input: "1365",
        _output: "1",
      },
      {
        testcase_id: 2,
        _input: "ab2",
        _output: "-1",
      },
      {
        testcase_id: 3,
        _input: "123412312441231312413134124",
        _output: "1",
      },
    ],
    init_code: [
      {
        _language: "cpp",
        tle: 500,
        _function: "int checkNumber(string s) {\n}",
        base_code:
          "#include <iostream>\nusing namespace std;\n__function__\nint main(int argc, char** argv) {\n    cout << checkNumber(__test_case__);\n    return 0;\n}",
        _answer:
          "int checkNumber(string s) {\n    if (s == \"\") return -1;\n    if (s[0] == '-') {\n        for (int i = 1; i < s.size(); i++) {\n            if (!isdigit(s[i])) return -1;\n        }\n        return 1;\n    }\n    else {\n        for (char c : s) {\n            if (!isdigit(c)) return -1;\n        }\n        return 1;\n    }\n}",
      },
      {
        _language: "java",
        tle: 3000,
        _function: "int checkNumber(String s) {\n}",
        base_code:
          'import java.io.*;\nimport java.lang.*;\nimport java.util.*;\npublic class Solution {\n    public static __function__\n    public static void main(String[] args) {\n        System.out.println(checkNumber("__test_case__"));\n    }\n}',
        _answer:
          'int checkNumber(String s) {\n    return s.matches("^[-\\\\d]+$") ?  1 : -1;\n}',
      },
      {
        _language: "js",
        tle: 4000,
        _function: "function checkNumber(s) {\n}",
        base_code: '__function__\nconsole.log(checkNumber("__test_case__"))',
        _answer: "function checkNumber(s) {\n    return !Number(s) ? -1 : 1\n}",
      },
      {
        _language: "py",
        tle: 4000,
        _function: "def checkNumber(s):",
        base_code: '__function__\nprint(checkNumber("__test_case__"))',
        _answer:
          "def checkNumber(s):\n    try:\n    \tfloat(s)\n    \treturn 1\n    except:\n        return -1",
      },
    ],
  },
  {
    question_id: 23,
    input_format: "String str",
    output_format: "String",
    sample_input: "test case",
    sample_output: "TestCase",
    _level: "easy",
    en: {
      question_title: "Upper Case",
      problem:
        "Given a string str containing the name of a function, each single word is space-separated. Return the function name according to the UpperCase rule - Every single word must be capitalized first.",
      explanation:
        'For str = "hello case" then upper_case(str) = "HelloCase"\nFor str = "camel case word" then upper_case(str) = "CamelCaseWord"',
      constraint_input: "0 < str.length ≤ 10^6",
      constraint_output: "The function name follows the UpperCase principle.",
      constraint_time:
        "0.5 seconds for C++\n3 seconds for Java\n4 seconds for  Python and JavaScript",
    },
    vi: {
      question_title: "Upper Case - In hoa đầu từ",
      problem:
        "Cho một chuỗi str chứa tên của một hàm, mỗi từ được phân tách bằng dấu cách. Trả về tên hàm theo quy tắc UpperCase - mỗi ký tự đầu tiên của mỗi từ phải in hoa.",
      explanation:
        'Đối với chuỗi str = "hello case" kết quả là upper_case(str) = "HelloCase"\nĐối với chuỗi str = "camel case word" kết quả là upper_case(str) = "CamelCaseWord"',
      constraint_input: "0 <str.length ≤ 10 ^ 6",
      constraint_output:
        "Chuỗi được viết theo nguyên tắc in hoa đầu từ UpperCase.",
      constraint_time:
        "0,5 giây cho C++\n3 giây cho Java\n4 giây cho Python và JavaScript",
    },
    test_case: [
      {
        testcase_id: 1,
        _input: "test case",
        _output: "TestCase",
      },
      {
        testcase_id: 2,
        _input: "camel case method",
        _output: "CamelCaseMethod",
      },
      {
        testcase_id: 3,
        _input: "say hello",
        _output: "SayHello",
      },
      {
        testcase_id: 4,
        _input: " camel case word",
        _output: "CamelCaseWord",
      },
      {
        testcase_id: 5,
        _input: "",
        _output: "",
      },
    ],
    init_code: [
      {
        _language: "cpp",
        tle: 500,
        _function: "string upperCase(string string) {\n}",
        base_code:
          "#include <iostream>\nusing namespace std;\n__function__\nint main(int argc, char** argv){\n    cout << upperCase(__test_case__);\n    return 0;\n}",
        _answer:
          "string upperCase(string strings) {\n    string str = \"\";\n    if(islower(strings[0])) str += toupper(strings[0]);\n    for(int i = 1; i < strings.length(); i++){\n        if(strings[i] == ' ') continue;\n        else if(strings[i-1] == ' ' && islower(strings[i]))\n        \tstr += toupper(strings[i]);\n        else str += strings[i];\n    }\n    return str;\n}",
      },
      {
        _language: "java",
        tle: 3000,
        _function: "String upperCase(String string) {\n}",
        base_code:
          'import java.io.*;\nimport java.lang.*;\nimport java.util.*;\npublic class Solution {\n    public static __function__\n\tpublic static void main(String[] args) {\n\t\tSystem.out.println(upperCase("__test_case__"));\n\t}\n}',
        _answer:
          'String upperCase(String string) {\n\tstring = string.trim();\n\tif(string.isEmpty()) return string;\n\tString[] arr = string.split(" ");\n\tString result = "";\n\tfor(int i = 0; i < arr.length; i++) {\n\t\tarr[i] = arr[i].substring(0,1).toUpperCase() + arr[i].substring(1).toLowerCase();\n\t\tresult += arr[i];\n\t}\n\treturn result;\n}',
      },
      {
        _language: "js",
        tle: 4000,
        _function: "function upperCase(string) {\n}",
        base_code: '__function__\nconsole.log(upperCase("__test_case__"));',
        _answer:
          'function upperCase(string) {\n    return string\n    .replace(/(?:^\\w|[A-Z]|\\b\\w)/g, function (w) {\n    return w[0].toUpperCase() + w.slice(1).toLowerCase();\n    })\n    .replace(/\\s+/g, "");\n}',
      },
      {
        _language: "py",
        tle: 4000,
        _function: "def upper_case(string):",
        base_code: '__function__\nprint (upper_case("__test_case__"))',
        _answer:
          "def upper_case(string):\n\treturn string.title().replace(' ', '')",
      },
    ],
  },
  {
    question_id: 24,
    input_format: "String str",
    output_format: "Integer",
    sample_input: "abc",
    sample_output: "99",
    _level: "easy",
    en: {
      question_title: "Check Max",
      problem:
        "Given a string str. Find the character which has the greatest decimal value in str (in ASCII table) and return that decimal value.",
      explanation:
        "For s1 = \"abc\", the output should be checkMax(s1)= 99.\nBecause 'c' has the greatest decimal value in s1 and the decimal value of 'c' is 99.",
      constraint_input: "1 ≤ str.length ≤ 20000",
      constraint_output: "Return the greatest decimal value.",
      constraint_time:
        "0.1 seconds for C++\n0.6 seconds for Java\n0.8 seconds for Python and JavaScript",
    },
    vi: {
      question_title: "Tìm phần tử lớn nhất",
      problem:
        "Cho một chuỗi str. Tìm ký tự có giá trị thập phân lớn nhất (trong bảng ASCII) trong chuỗi str đó và trả về giá trị thập phân đó.",
      explanation:
        "Đối với s1 = \"abc\", đầu ra phải là checkMax (s1) = 99.\nBởi vì 'c' có giá trị thập phân lớn nhất trong chuỗi s1 và giá trị thập phân của 'c' (trong bảng ASCII) là 99.",
      constraint_input: "1 ≤ str.length ≤ 20000",
      constraint_output:
        "Trả về giá trị thập phân lớn nhất (trong bảng ASCII).",
      constraint_time:
        "0,1 giây cho C++\n0,6 giây cho Java\n0,8 giây cho Python và JavaScript",
    },
    test_case: [
      {
        testcase_id: 1,
        _input: "abc",
        _output: "99",
      },
      {
        testcase_id: 2,
        _input: "fdhfghfgh",
        _output: "104",
      },
      {
        testcase_id: 3,
        _input: "slfjlJKHKJkk",
        _output: "115",
      },
    ],
    init_code: [
      {
        _language: "cpp",
        tle: 100,
        _function: "int checkMax(string str) {\n}",
        base_code:
          "#include <iostream>\nusing namespace std;\n__function__\nint main(int argc, char** argv) {\n    cout << checkMax(__test_case__);\n    return 0;\n}",
        _answer:
          "int checkMax(string str) {\n    char answer = str[0];\n    for (int i = 1; i < str.size(); i++)\n    if (str[i] > answer) answer = str[i];\n    return answer;\n}",
      },
      {
        _language: "java",
        tle: 600,
        _function: "int checkMax(String str) {\n}",
        base_code:
          'import java.io.*;\nimport java.lang.*;\nimport java.util.*;\npublic class Solution {\n    public static __function__\n\tpublic static void main(String[] args) {\n\t\tSystem.out.println(checkMax("__test_case__"));\n\t}\n}',
        _answer:
          "int checkMax(String str) {\n\tchar max = str.charAt(0);\n\tfor (int i = 1; i < str.length(); i++) {\n\t\tif (str.charAt(i) > max) {\n\t\t\tmax = str.charAt(i);\n\t\t}\n\t}\n\treturn (int)max;\n}",
      },
      {
        _language: "js",
        tle: 800,
        _function: "function checkMax(str) {\n}",
        base_code: '__function__\nconsole.log(checkMax("__test_case__"))',
        _answer:
          "function checkMax(str) {\n    let max = str.charCodeAt(0);\n    for (let i = 1; i < str.length; i++) {\n        if (str.charCodeAt(i) > max) {\n            max = str.charCodeAt(i);\n        }\n    }\n    return max;\n}",
      },
      {
        _language: "py",
        tle: 800,
        _function: "def checkMax(str):",
        base_code: '__function__\nprint(checkMax("__test_case__"))',
        _answer:
          "def checkMax(str1):\n\tlst = list(str1)\n\tascii = []\n\tfor x in lst:\n    \tascii.append(ord(x))\n\treturn max(ascii)",
      },
    ],
  },
  {
    question_id: 72,
    input_format: "Void",
    output_format: "Object",
    sample_input: "",
    sample_output: '{\nkey1: 1, \nkey2: 2, \nakey: "abc", \nbkey: "mno"\n}',
    _level: "easy",
    en: {
      question_title: "Object",
      problem:
        'Create object with key: key1, key2, akey, bkey. And data is 1, 2, "abc", "mno"',
      explanation:
        'Return object example {\nkey1: 1,\nkey2: 2,\nakey: "abc",\nbkey: "mno"\n}',
      constraint_input: "",
      constraint_output: "Return object with any order of key",
      constraint_time: "",
    },
    vi: {
      question_title: "Object",
      problem:
        'Khởi tạo 1 object với key: key1, key2, akey, bkey. Và có giá trị lần lượt là 1, 2, "abc", "mno"',
      explanation:
        'Ví dụ trả về 1 Object như sau {\nkey1: 1,\nkey2: 2,\nakey: "abc", \nbkey: "mno"\n}',
      constraint_input: "",
      constraint_output: "Trả về object với thứ tự của key bất kỳ",
      constraint_time: "",
    },
    test_case: [
      {
        testcase_id: 1,
        _input: "",
        _output:
          '{\n"key1": 1, \n"key2": 2, \n"akey": "abc", \n"bkey": "mno"\n}',
      },
      {
        testcase_id: 2,
        _input: "",
        _output: '{"key1": 1, \n"bkey": "mno",\n"key2": 2, \n"akey": "abc"}',
      },
      {
        testcase_id: 3,
        _input: "",
        _output: '{\n"bkey": "mno",\n"akey": "abc",\n"key2": 2,\n"key1": 1\n}',
      },
    ],
    init_code: [
      {
        _language: "js",
        tle: 800,
        _function: "function createObject () {\n}",
        base_code:
          "__function__    \nconsole.log(JSON.stringify(createObject()))",
        _answer:
          'function createObject () {\n    const object = {\n        "key1": 1, \n        "key2": 2, \n        "akey": "abc",\n        "bkey": "mno"\n    }\n    return object\n}',
      },
    ],
  },
];
