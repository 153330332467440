import { Avatar } from 'antd'
import React, { Fragment, useState } from 'react'
import { getHinhAnhVer2 } from '../../../util/GetHinhanh';
const arrColor = [
  {backgroundColor: '#f56a00'}, 
  { backgroundColor: '#fde3cf', color: '#f56a00' },
  { backgroundColor: '#1677ff', color: 'white' },
   { backgroundColor: '#87d068' }]
function LoadAvatar({ url, conent }) {
  const getRandomElement = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };
  
  // Sử dụng hàm để lấy ngẫu nhiên một đối tượng từ mảng arrColor
  const randomColor = getRandomElement(arrColor);
  const [img, setImg] = useState(true);
  return (
    <Fragment>
      {img ? <Avatar src={<img onError={() => { setImg(false) }} src={url?getHinhAnhVer2(url):""} alt="avatar" />}></Avatar> : <Avatar style={randomColor??{ backgroundColor: '#fde3cf', color: '#f56a00' }}>{conent}</Avatar>}
    </Fragment>
  )
}

export default LoadAvatar