import React, { Fragment, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import BottomTab from "../components/BottomTab";
import HeaderHome from "../components/HeaderHome";
import ResponsiveItem from "../hoc/ResponsiveItem";
import { useAppSelector } from "../redux/hooks";
import { getStore, getStoreJson, removeStore, setStore } from "../util/config";
import { nguoiDungService } from "../services/nguoiDungService";
import {
  BellOutlined,
  CommentOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  HomeOutlined,
  ReadOutlined,
  ApartmentOutlined,
  ProfileOutlined,
  UserOutlined,
  UsergroupAddOutlined,
  CheckSquareOutlined,
  BookOutlined,
} from "@ant-design/icons";

import Loading from "../hoc/loading/Loading";
import "../assets/style/popconfirm.css";
import {
  MenuProps,
  message,
} from "antd";
import { Menu } from "antd";
import Mentor from "../pages/Mentor/Mentor";
type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

let items: MenuItem[] = [
  getItem(<NavLink className="text-decoration-none" to="/home"> Home</NavLink>, '1', <HomeOutlined />),

  getItem('QL module', 'sub1', <ReadOutlined />, [
    getItem(<NavLink className="text-decoration-none" to="/ql-chuyende"> Module </NavLink>, '2', <ApartmentOutlined />),
    getItem(<NavLink className="text-decoration-none" to="/monhoc"> Môn học </NavLink>, '3', <ReadOutlined />),
    getItem(<NavLink className="text-decoration-none" to="/baihoc"> Nhiệm vụ </NavLink>, '4', <ProfileOutlined />),

  ]),
  getItem('QL Học tập', 'sub3', <BookOutlined />, [
    getItem(<NavLink className="text-decoration-none" to="/chuyende"> Chuyên đề </NavLink>, '5', <ApartmentOutlined />),
    getItem(<NavLink className="text-decoration-none" to="/class"> Lớp </NavLink>, '6', <ReadOutlined />),
    getItem(<NavLink className="text-decoration-none" to="/intern">  Code with mentor </NavLink>, '7', <ReadOutlined />),

  ]),
  getItem('Người dùng', 'sub2', <UserOutlined />, [
    getItem(<NavLink className="text-decoration-none" to="/quan-li-user"> QL người dùng </NavLink>, '8', <UsergroupAddOutlined />),

  ]),

  getItem(<NavLink className="text-decoration-none" to="/review-task"> Review Task</NavLink>, '9', <CheckSquareOutlined />),
  getItem(<NavLink className="text-decoration-none" to="/quiz-test"> Bài Test</NavLink>, '10', <ContainerOutlined />),
  getItem(
    <NavLink className='text-decoration-none' to='/mentor'> Chấm bài</NavLink>, '11', <HomeOutlined />
  ),
];

let userLogin = localStorage.getItem("USER_LOGIN");
let nhomQuyen = userLogin ? JSON.parse(userLogin).maNhomQuyen : null;

if (nhomQuyen == "LECTURE") {
  items = [getItem(<NavLink className="text-decoration-none" to="/home"> Home</NavLink>, '1', <HomeOutlined />),
  getItem('QL Lộ trình', 'sub1', <ReadOutlined />, [
    getItem(<NavLink className="text-decoration-none" to="/baihoc"> Nhiệm vụ </NavLink>, '2', <ProfileOutlined />),

  ]),
  getItem('QL Học tập', 'sub3', <BookOutlined />, [
    getItem(<NavLink className="text-decoration-none" to="/chuyende"> Chuyên đề </NavLink>, '3', <ApartmentOutlined />),
    getItem(<NavLink className="text-decoration-none" to="/class"> Lớp </NavLink>, '4', <ReadOutlined />),
    getItem(<NavLink className="text-decoration-none" to="/intern"> Code with mentor </NavLink>, '5', <ReadOutlined />),


  ]),
  getItem(
    <NavLink className='text-decoration-none' to='/mentor'> Chấm bài</NavLink>, '1', <HomeOutlined />
  ),
  ]

}

type Props = {};

const divFunc: React.FC = (props) => {
  return <div></div>;
};

const HomeTemplate = (props: Props) => {
  const navigate = useNavigate();

  const { userLogin } = useAppSelector((state) => state.userReducer);

  //   useEffect(() => {
  //     // Lưu đường dẫn URL vào localStorage
  //     localStorage.setItem("lastPath", location.pathname);
  //   }, [location]);

  // useEffect(() => {
  //   const path = window.location.pathname;

  //   if (path !== "/home" && path !== "/login" && userLogin?.maNhomQuyen==="ADMIN") {
  //     setStore("path", path);
  //     navigate("/");
  //   }
  // }, []);


  // useEffect(() => {
  //   if (!getStore("USER_LOGIN")) {
  //     navigate("/login");
  //   }
  // }, []);

  useEffect(() => {
    if (!getStore("USER_LOGIN")) {
      navigate("/login");
    } else {
      try {
        nguoiDungService.checkPassNguoiDung().then((res) => {
          if (res?.data?.content === "0") {
            removeStore("USER_LOGIN");
            removeStore('path')
            navigate("/login");
          }
        });
      } catch (error) {
        console.log('checkPassNguoiDung', error);
        navigate("/login");
      }
    }
  }, [window.location.pathname]);

  useEffect(() => {

    const path = window.location.pathname;
    if (path !== "/home" && path !== "/login") {
      setStore("path", path);
      navigate("/home");
    }
  }, []);


  // useEffect(() => {
  //   if (!getStore("USER_LOGIN")) {
  //     navigate("/login");
  //   }
  // }, []);

  //* Điều hướng trang theo role
  // useEffect(() => {
  //   if (!getStore("USER_LOGIN")) {
  //     navigate("/login");
  //   }
  //    else {
  //     const user = getStoreJson("USER_LOGIN") as NguoiDungType; // Giả sử vai trò của người dùng được lưu trong biến ROLE
  //     // if (user.maNhomQuyen === "ADMIN") {
  //     //   navigate("/home");
  //     // } else
  //     if (user.maNhomQuyen === "MENTOR") {
  //       navigate("/mentor");
  //     }
  //     else
  //     navigate("/")
  //   }
  // }, []);

  //Mỗi khi chuyển url, Nếu chưa đăng nhập thì điều hướng về login
  // useEffect( () => {
  //   // const path = window.location.pathname;
  //   // if (path !== "/home" && path !== "/login" && path !== "/mentor") {
  //   //   if(getStore('path'))
  //   //   setStore("prevPath",getStore('path'))
  //   //   setStore("path", path);
  //   // }

  //   if (!getStore("USER_LOGIN")) {
  //     navigate("/login");
  //   } else {
  //     try {
  //       nguoiDungService.checkPassNguoiDung().then((res) => {
  //         if (res?.data?.content === "0") {
  //           removeStore("USER_LOGIN");
  //           navigate("/login");
  //         }
  //       });
  //     } catch (error) {
  //       console.log('checkPassNguoiDung',error);
  //       navigate("/login");
  //     }
  //   }
  // }, [window.location.pathname]);

  return (
    <Fragment>
      {userLogin?.maNhomQuyen === "MENTOR" ? (
        <Mentor />
      ) : (
        <div className="d-flex">
          <div style={{ width: "10%" }}>
            <Menu
              style={{ height: "100vh" }}
              mode="inline"
              theme="dark"
              items={items}
            />
          </div>
          <div style={{ width: "90%" }}>
            <ResponsiveItem component={HeaderHome} mobileComponent={divFunc} />
            {/* <HeaderHome /> */}
            <div style={{ minHeight: 600 }}>
              <Outlet />
            </div>
          </div>

          <ResponsiveItem component={divFunc} mobileComponent={BottomTab} />
          <Loading />
        </div>
      )}
    </Fragment>
  );
};

export default HomeTemplate;
