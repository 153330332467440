import { Button, Input, InputRef, Space, Table, TableColumnsType } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { NopBaiModel } from '../../../Types/nopBaiType'
import { ColumnType, ColumnsType } from 'antd/es/table'
import { TableCSS } from '../../../assets/style/CustomAntdCss'
import { NopBaiViewMentor } from '../../../Types/nopBaiType'
import { history } from '../../../index'
import {
  GFTWithKey,
  NopBaiViewMentorWithKey,
  converTime,
  customSorter,
  dataIndex
} from '../../../pages/Mentor/Dashboard/DashBoard'
import { FilterConfirmProps } from 'antd/es/table/interface'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'

interface props {
  showModal?: () => void
  columns?: TableColumnsType<NopBaiViewMentor>
  dataNB?: NopBaiModel[]
  dataSource: NopBaiViewMentor[]
  newData?: readonly any[] | undefined
  setNopBai?: React.Dispatch<React.SetStateAction<NopBaiModel>>
  IsBaiTap?: boolean
  IsTask?: boolean
  gft?: boolean
  loadingTable?: boolean
  setData?: React.Dispatch<React.SetStateAction<NopBaiViewMentor | null>>
}
function TableChamBai({
  columns,
  dataSource,
  newData,
  IsBaiTap,
  IsTask,
  showModal,
  loadingTable,
  setData,
  gft
}: props) {
  const [loading, setLoading] = useState(true)
  const searchInput = useRef<InputRef>(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: dataIndex
  ) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters: () => void) => {
    clearFilters()
    setSearchText('')
  }
  const pushTask = (taskId: number, idmonhoc: number, chuyendeid: number, tenNhiemVu: string, maLoai: string) => {
    console.log(taskId)
    history.push({
      pathname: '/baitap/' + taskId + '/' + idmonhoc + '/' + chuyendeid + '/' + tenNhiemVu + '/' + maLoai
    })
  }
  const pushGFT = (gftId: number) => {
    console.log(gftId)
    history.push({
      pathname: '/task/' + gftId
    })
  }
  const getColumnSearchProps = (dataIndex: dataIndex): ColumnType<NopBaiViewMentor> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters && handleReset(clearFilters)} size='small' style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({ closeDropdown: false })
              setSearchText((selectedKeys as string[])[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button> */}
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#da69ff', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  const countTotal = (
    data: NopBaiViewMentor[],
    chuyenDeId: number,
    taskId: number,
    monHocId: number,
    maLoai: string,
    loaiModule: string
  ) => {
    return data?.filter(
      (e) =>
        e.chuyenDeId === chuyenDeId &&
        e.taskId === taskId &&
        e.monHocId === monHocId &&
        e.maLoai === maLoai &&
        e.loaiModule === loaiModule
    ).length
  }
  const countChuaCham = (
    data: NopBaiViewMentor[],
    chuyenDeId: number,
    taskId: number,
    monHocId: number,
    maLoai: string,
    loaiModule: string
  ) => {
    return data?.filter(
      (e) =>
        e.chuyenDeId === chuyenDeId &&
        e.taskId === taskId &&
        e.monHocId === monHocId &&
        e.maLoai === maLoai &&
        e.loaiModule === loaiModule &&
        e.trangThai === 'CHO_CHAM'
    ).length
  }
  const dataFilter = (data: NopBaiViewMentor[]) => {
    const newData = data.filter((e, i, self) => {

      return i === self.findIndex(
        (item) => item.monHocId === e.monHocId

      )

    })


    return newData.map((item, index) => {
      return { key: index + 1, ...item }
    })
  }
  const dataFilterExpan = (data: NopBaiViewMentor[], monHocId: number) => {

    const newData = data.filter((e, i, self) => {
      return i === self.findIndex(
        (item) => item.monHocId == monHocId && item.taskId == e.taskId

      )
    })

    return newData.map((item, index) => {
      return { key: index + 1, ...item }
    })
  }

  const expandedRowRender = (data: NopBaiViewMentor[]) => {
    const newColumns: ColumnsType<NopBaiViewMentor> = [
      // STT
      // Tên bài
      // Môn học
      // Tổng bài
      // Chưa chấm
      // Hạn nộp
      // Loại bài
      // Module
      {
        title: 'STT',
        key: 'STT',
        render: (_, __, index) => {
          return index + 1
        },
        width: '80px'
      },

      // {
      //   title: 'Môn học',
      //   dataIndex: 'tenMonHoc',
      //   key: 'tenMonHoc',
      //   ...getColumnSearchProps('tenMonHoc'),
      //   sorter: (a, b) => a.monHocId - b.monHocId
      //   // sortDirections: ['descend', 'ascend']
      // },
      {
        title: 'Tên bài học',
        dataIndex: 'tenNhiemVu',
        key: 'tenNhiemVu',
        // width: '20%',
        ...getColumnSearchProps('tenNhiemVu'),
        sorter: (a, b) => a.taskId - b.taskId
      },
      {
        title: 'Tổng bài',
        key: 'tongBai',
        sorter: (a, b) =>
          countTotal(dataSource, a.chuyenDeId, a.taskId, a.monHocId, a.maLoai, a.loaiModule) -
          countTotal(dataSource, b.chuyenDeId, b.taskId, b.monHocId, b.maLoai, b.loaiModule),
        render: (value, record, index) => {
          const { chuyenDeId, taskId, monHocId, maLoai, loaiModule } = record
          return countTotal(dataSource, chuyenDeId, taskId, monHocId, maLoai, loaiModule)
        }
      },
      {
        title: 'Chưa chấm',
        key: 'chuaCham',
        sorter: (a, b) =>
          countChuaCham(dataSource, a.chuyenDeId, a.taskId, a.monHocId, a.maLoai, a.loaiModule) -
          countChuaCham(dataSource, b.chuyenDeId, b.taskId, b.monHocId, b.maLoai, b.loaiModule),
        render: (value, record) => {
          const { chuyenDeId, taskId, monHocId, maLoai, loaiModule } = record
          return countChuaCham(dataSource, chuyenDeId, taskId, monHocId, maLoai, loaiModule)
        }
      },
      // {
      //   title: 'Hạn nộp ',
      //   dataIndex: 'hanNopBai',
      //   key: 'hanNopBai',
      //   ...getColumnSearchProps('hanNopBai'),
      //   sorter: customSorter,
      //   sortDirections: ['descend', 'ascend'],
      //   render: (value) => converTime(value) ?? value
      // },
      {
        title: 'Loại bài',
        dataIndex: 'maLoai',
        key: 'maLoai',
        // ...getColumnSearchProps('hanNopBai'),
        render: (_, r) => _,
        sorter: (a, b) => a.hanNopBai.length - b.hanNopBai.length,
        sortDirections: ['descend', 'ascend'],
        onFilter: (value: string | number | boolean, record) => {
          const stringValue = String(value)
          return String(record.maLoai).indexOf(stringValue) === 0
        },
        filters: [
          {
            text: 'Bài Tập',
            value: 'BAITAP'
          },
          {
            text: 'Dự Án',
            value: 'DUAN'
          },
          {
            text: 'Capstone',
            value: 'CAPS'
          }
        ]
      }
      // {
      //   title: 'Module',
      //   dataIndex: 'loaiModule',
      //   key: 'loaiModule',
      //   ...getColumnSearchProps('hanNopBai'),
      //   // render:(_,r)=>_,
      //   sorter: (a, b) => a.loaiModule.length - b.loaiModule.length,
      //   sortDirections: ['descend', 'ascend']
      // }
    ]

    return (
      <TableCSS>
        <Table
          columns={newColumns}
          dataSource={data}
          // pagination={false}
          rowClassName={(_, index) => (index % 2 === 0 ? 'rowLe' : 'rowChan')}
          onRow={(record, rowIndex) => {
            const { ...nopBai } = record
            return {
              onClick: (event) => {
                if (nopBai && IsBaiTap && 'tenNhiemVu' in nopBai) {
                  console.log(nopBai)
                  pushTask(nopBai.taskId, nopBai.monHocId, nopBai.chuyenDeId, nopBai.tenNhiemVu, nopBai.maLoai)
                } else if (gft && nopBai) {
                  pushGFT(nopBai.id)
                }
              },
              style: { cursor: 'pointer' } // click row
              // onDoubleClick: (event) => {
              //   console.log("2 click");
              // }, // double click row
              // onContextMenu: (event) => {}, // right button click row
              // onMouseEnter: (event) => {}, // mouse enter row
              // onMouseLeave: (event) => {}, // mouse leave row
            }
          }}
        />
      </TableCSS>
    )
  }

  useEffect(() => {
    if (dataSource?.length) setLoading(false)
    else {
      setTimeout(() => {
        setLoading(false)
      }, 20000)
    }
  }, [dataSource, dataSource?.length])

  return (
    <TableCSS>
      <Table
        loading={loading}
        onRow={(record, rowIndex) => {
          return {
            // onClick: (event) => {

            // }, // click row
            // onDoubleClick: (event) => {}, // double click row
            // onContextMenu: (event) => {}, // right button click row
            // onMouseEnter: (event) => {}, // mouse enter row
            // onMouseLeave: (event) => {}, // mouse leave row
            style: { cursor: 'pointer' }
          }
        }}
        columns={columns}
        dataSource={dataFilter(dataSource)}
        style={{ background: 'transparent' }}
        expandable={{
          expandedRowRender: (record, index) => {
            const { monHocId } = record

            const newData = dataFilterExpan(dataSource, monHocId)
            return expandedRowRender(newData)
          },

          defaultExpandedRowKeys: ['0'],
          expandRowByClick: true
        }}
      />
    </TableCSS>
  )
}

export default TableChamBai
