import { CreatePracticeModel, PracticeModel } from "../Types/practiceType";
import { http } from "../util/config";

const GET_PATH: string = '/api/vatpham';
// const GET_PATH: string = '/api/vatpham';

const getListItems = () => {
  return http.get(`${GET_PATH}/danh-sach-vatpham`);
}


const getListQuaUser = (userId: string) => {
  return http.get(`${GET_PATH}/lay-qua-user/${userId}`);
}

const doiQuaService = (data: any) => {
  return http.post(`/api/lichsutuongtac/luu-lich-su-doi-qua`,data);
}



export const vatPhamService = {
  getListItems,
  getListQuaUser,
  doiQuaService
}



