import React, { useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../../redux/hooks";
import HeadRequireForTask from "./Layout/HeadRequireForTask";
import ContentRequireForTask from "./Layout/ContentRequireForTask";
import { GroupOutlined } from "@ant-design/icons";
const ProjectOutcomesCSS = styled.div``;
const ProjectOutcomes: React.FC = () => {
  const [active, setActive] = useState<string>("");

  return (
    <div>
      <h4 className="heading">
        {" "}
        <GroupOutlined /> Chấm Bài Tập
      </h4>
      <div className="row">
        <div className="col-3">
          <HeadRequireForTask active={active} setActive={setActive} />
        </div>
        <div className="col-9">
          <ContentRequireForTask active={active} setActive={setActive} />
        </div>
      </div>
    </div>
  );
};
export default ProjectOutcomes;
