import React, { ChangeEvent, useEffect, useState } from 'react'

import { CKEditor } from 'ckeditor4-react'
import { Button, DatePicker, InputNumber, Modal, Select, Space, Upload, message } from 'antd'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useDispatch } from 'react-redux'
import { DispatchType, RootState } from '../../redux/configStore'
import { useSelector } from 'react-redux'
import { ChuyenDeModel } from '../../Types/chuyenDeType'
import { chuyenDeService } from '../../services/chuyenDeService'
import { getChuyenDeApi } from '../../redux/ChuyenDeReducer/chuyenDeReducer'
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import axios from 'axios'
import { uploadImageService } from '../../services/uploadImagService';
import { API_URL_DOMAIN } from '../../util/urlDomain';
import dayjs from 'dayjs';


type TypeData = {
    [key: string]: any
}

type listType = {
    value: string | number
    label: string | JSX.Element
}

type Props = {
    chuyenDeDetail: ChuyenDeModel
    onClose: () => void
}

const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const ChuyenDeForm = ({
    chuyenDeDetail,
    onClose

}: Props) => {
    // state
    const [data, setData] = useState<TypeData>({});
    const [metaData, setMetaData] = useState<TypeData>({});
    const [validate, setValidate] = useState("")

    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('')

    const [key, setKey] = useState(Date.now());

    const { arrTag } = useSelector((state: RootState) => state.chuyenDeReducer);
    let tag = arrTag.find(n => n.maTag == "SKILL");
    let tagSkill: listType[] = tag ? JSON.parse(tag?.noiDung) : [];

    const { danhSachMentor } = useSelector((state: RootState) => state.userReducer);
    const { danhSachGiangVien } = useSelector((state: RootState) => state.userReducer);


    // update change 
    if (chuyenDeDetail.id != data.id) {
        // set key để load lại CKEditor để gán lại data mới
        setKey(Date.now());

        let newChuyenDeDetail: ChuyenDeModel = { ...chuyenDeDetail };
        newChuyenDeDetail.danhSachTag = newChuyenDeDetail.danhSachTag ? JSON.parse(newChuyenDeDetail.danhSachTag) : [];
        newChuyenDeDetail.danhSachMentor = newChuyenDeDetail.danhSachMentor ? JSON.parse(newChuyenDeDetail.danhSachMentor) : [];

        newChuyenDeDetail.danhSachGiangVien = newChuyenDeDetail.danhSachGiangVien ? JSON.parse(newChuyenDeDetail.danhSachGiangVien) : [];

        let metaData = newChuyenDeDetail.metaData ? JSON.parse(newChuyenDeDetail.metaData) : {};
        setMetaData(metaData)

        setData(newChuyenDeDetail);
        if (chuyenDeDetail.id == 0) {

            setValidate("");
        }


    }


    // get redux
    const dispatch: DispatchType = useDispatch();


    // function else
    const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;

        setData(prevData => ({
            ...prevData,
            [name]: value
        }));

    }
    const onChangeValue = (value: any, name: string) => {
        setData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const onEditorChange = (evt: any) => {

        setData(prevData => ({
            ...prevData,
            [evt.editor.name]: evt.editor.getData()
        }));
    }

    const saveData = () => {
        if (data) {

            let model = { ...data };

            if (!data.tenChuyenDe) {
                setValidate("Hãy nhập đầy đủ: tên chuyên đề");
                return;
            }

            model.danhSachTag = JSON.stringify(model.danhSachTag);
            model.danhSachMentor = JSON.stringify(model.danhSachMentor);
            model.danhSachGiangVien = JSON.stringify(model.danhSachGiangVien);

            let userLogin = localStorage.getItem("USER_LOGIN");
            let nguoiTao = userLogin ? JSON.parse(userLogin).id : null;
            let dataMeta: {
                nguoiTao: string;
            } = {
                nguoiTao: nguoiTao,
            };
            metaData.nguoiTao = nguoiTao;
            model.metaData = JSON.stringify(metaData);


            if (imageUrl)
                model.hinhAnh = imageUrl;

            let div_loading = document.getElementsByClassName("div_loading");
            div_loading[0].removeAttribute("hidden");
            if (data.id == 0) {
                chuyenDeService.themChuyenDe(model).then(res => {
                    onClose();
                    dispatch(getChuyenDeApi());

                    message.success("Thêm thành công");
                }).catch(err => {
                    message.error("Lỗi Báo IT");

                }).finally(() => div_loading[0].setAttribute("hidden", ""))
            } else {
                chuyenDeService.suaChuyenDe(model).then(res => {
                    onClose();

                    dispatch(getChuyenDeApi());

                    message.success("Cập nhật thành công");
                }).catch(err => {
                    message.error("Lỗi Báo IT");

                }).finally(() => div_loading[0].setAttribute("hidden", ""))
            }


        }


    }

    let lstMentorOption: listType[] = [];
    let lstGiangVienOption: listType[] = [];

    danhSachMentor.map(item => {
        lstMentorOption.push({ label: item.hoTen, value: item.id });
    })

    danhSachMentor.map(item => {
        lstGiangVienOption.push({ label: item.hoTen, value: item.id });
    })


    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }

        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };

    const handleCancel = () => setPreviewOpen(false);

    const handleChange: UploadProps['onChange'] = async ({ fileList: newFileList }) => {
        try {
            setFileList(newFileList);
            if (newFileList.length > 0 && newFileList[0].originFileObj) {
                const imageUrl = await uploadImageService.uploadImage(newFileList[0].originFileObj);
                setImageUrl(imageUrl);
            }
        } catch (error) {
            // Xử lý lỗi nếu có
            console.error("Đã xảy ra lỗi:", error);
        }
    };

    const handleRemove = (file: UploadFile) => {
        const index = fileList.indexOf(file);
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        setImageUrl("")
        setFileList([]);
    };

    const createUploadFileFromURL = (url: string): UploadFile => {
        const file: UploadFile = {
            uid: '-1',
            name: url.split("/images/")[1],
            status: 'done',
            url: `${API_URL_DOMAIN}${url}`,
        };
        return file;
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );


    useEffect(() => {
        // Tạo đối tượng UploadFile từ URL
        const file = data && data.hinhAnh && createUploadFileFromURL(data.hinhAnh);

        // Gán đối tượng UploadFile vào mảng fileList
        const updatedFileList = [file];

        // Cập nhật state fileList với mảng mới
        setFileList(updatedFileList);

    }, [data]);

    return (
        <div className='row'>
            <div className="col-6 mb-3">
                <label className="form-label">Tên môn học</label>
                <input className="form-control" onChange={onChangeInput} name="tenChuyenDe" value={data.tenChuyenDe} />
            </div>
            <div className="col-6 mb-3">
                <label className="form-label">Tag</label>
                <Select
                    mode="multiple"
                    allowClear
                    optionFilterProp="label"

                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={data.danhSachTag}
                    onChange={(value) => onChangeValue(value, "danhSachTag")}
                    options={tagSkill}
                />
            </div>
            <div className="col-6 mb-3">
                <label className="form-label">Thời gian học</label>
                <InputNumber value={data.thoiHan} style={{ width: "100%" }} min={1} onChange={(value) => onChangeValue(value, "thoiHan")} />
            </div>
            <div className="col-6 mb-3">
                <div>
                    <Upload
                        // {...props}
                        name="image"
                        listType="picture-circle"
                        action={`${API_URL_DOMAIN}/api/file/image`}
                        className="upload-list-inline"
                        maxCount={1}
                        onPreview={handlePreview}
                        onChange={handleChange}
                        onRemove={handleRemove}
                        fileList={fileList}
                    >
                        {fileList.length > 1 ? null : uploadButton}
                    </Upload>
                    <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                </div>
            </div>

            {
                metaData.loaiChuyenDe == "CLASS" &&
                <>
                    <div className=' col-6 mb-3'>
                        <label className="form-label">Ngày bắt đầu:</label>
                        <DatePicker value={metaData.ngayBatDau ? dayjs(metaData.ngayBatDau, "DD/MM/YYYY") : dayjs()} format="DD/MM/YYYY" onChange={(date, dateString) => {

                            setMetaData({ ...metaData, ngayBatDau: dateString })
                        }} />
                    </div>

                    <div className=' col-6 mb-3'>
                        <label className="form-label">Ngày kết thúc:</label>
                        <DatePicker value={metaData.ngayKetThuc ? dayjs(metaData.ngayKetThuc, "DD/MM/YYYY") : dayjs()} format="DD/MM/YYYY" onChange={(date, dateString) => {
                            setMetaData({ ...metaData, ngayKetThuc: dateString })
                        }} />
                    </div>
                </>

            }
            <div className="col-12 mb-3">
                <label className="form-label">Giới thiệu</label>
                <CKEditor
                    key={key}
                    initData={data.gioiThieu}

                    config={{
                        toolbarGroups: [
                            { name: 'document', groups: ['mode', 'document', 'doctools'] },
                            { name: 'clipboard', groups: ['clipboard', 'undo'] },
                            { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                            { name: 'forms' },
                            '/',
                            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                            { name: 'links' },
                            { name: 'insert' },
                            '/',
                            { name: 'styles' },
                            { name: 'colors' },
                            { name: 'tools' },
                            { name: 'others' },
                            { name: 'about' }
                        ],
                        extraPlugins: 'codesnippet', // Thêm plugin codesnippet vào danh sách extraPlugins
                    }}
                    name="moTa"
                    onChange={onEditorChange}
                />
            </div>

            <div className="col-6 mb-3">
                <label className="form-label">Mentor</label>
                <Select
                    mode="multiple"
                    allowClear
                    optionFilterProp="label"

                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={data.danhSachMentor}
                    onChange={(value) => onChangeValue(value, "danhSachMentor")}
                    options={lstMentorOption}
                />
            </div>

            <div className="col-6 mb-3">
                <label className="form-label">Giảng viên</label>
                <Select
                    mode="multiple"
                    allowClear
                    optionFilterProp="label"

                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={data.danhSachGiangVien}
                    onChange={(value) => onChangeValue(value, "danhSachGiangVien")}
                    options={lstGiangVienOption}
                />
            </div>

            <b className='text-danger'>{validate}</b>
            <button className='btn btn-primary' onClick={saveData}>Lưu</button>
        </div>
    )
}

export default ChuyenDeForm
