import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaiHocModel } from "../../Types/baiHocType";
import { DispatchType, RootState } from "../../redux/configStore";
import { Drawer } from "antd";
import BaiHocAbove from "../../components/HocTap/BaiHocAbove";
import DanhSachTask from "../../components/Task/DanhSachTask";
import { RuncodeModel } from "../../Types/runCodeType";
import { getBaiHocApi } from "../../redux/BaiHocReducer/BaiHocReducer";
import useQueryConfig from "../../hooks/useQueryConfig";
import { NguoiDungType } from "../../Types/nguoiDungType";
import { config } from "../../util/config";
import { getChuyenDeApi, getDSChuyenDeMentorApi, getListTagApi } from "../../redux/ChuyenDeReducer/chuyenDeReducer";
import { getMonHocApi } from "../../redux/MonHocReducer/monHocReducer";
import { getPracticeApi } from "../../redux/PracticeReducer/practiceReducer";
import { getDanhSachGiangVienApi, getDanhSachMentorApi, getNguoiDungPhanTrangApi } from "../../redux/UserReducer/userReducer";
import { fetchCategories, fetchQuestions } from "../../redux/RunCodeReducer/runcodeReducer";
import { getBaiTestApi, getNhomQuyenApi } from "../../redux/HeThongReducer/heThongReducer";
import { getListItemsApi } from "../../redux/VatPhamReducer/vatPhamReducer";

type Props = {};

type danhSachNoiDung = {
  danhSachMonHoc: string;
};

export default function BaiHoc({ }: Props) {
  // create state
  const [open, setOpen] = useState(false);
  const [taskDetail, setTaskDetail] = useState<BaiHocModel>({ id: 0 });
  const [runcodeDetail, setRuncodeDetail] = useState<RuncodeModel>({ id: 0 });


  // get props reducer
  let { arrBaiHoc } = useSelector((state: RootState) => state.baiHocReducer);

  // function else
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setTaskDetail({ id: 0 })
  };

  const dispatch: DispatchType = useDispatch();
  const queryConfig = useQueryConfig();
  const role = config.getStoreJson('USER_LOGIN') as NguoiDungType
  // life cycle
  useEffect(() => {
    // if (role && (role.maNhomQuyen === 'ADMIN' || role.maNhomQuyen === 'LECTURE')) {
    //     dispatch(getChuyenDeApi());
    //   }
    //   else {
    //     dispatch(getDSChuyenDeMentorApi(role?.id))
    //   }
      dispatch(getChuyenDeApi());
      dispatch(getMonHocApi());
      dispatch(getPracticeApi());
      dispatch(getBaiHocApi());
      dispatch(getNguoiDungPhanTrangApi(queryConfig));
      dispatch(fetchQuestions())
      dispatch(fetchCategories())
      dispatch(getListTagApi());
      dispatch(getDanhSachMentorApi());
      dispatch(getDanhSachGiangVienApi());
  
      dispatch(getBaiTestApi());
      dispatch(getListItemsApi());
      dispatch(getNhomQuyenApi());
}, []);

  let opSegmented = [
    {
      label: (
        <>
          <i className="fas fa-video"></i> Video
        </>
      ),
      value: "VIDEO",
    },

    {
      label: (
        <>
          <i className="fas fa-file-signature"></i> Bài tập nộp
        </>
      ),
      value: "BAITAP",
    },
    {
      label: (
        <>
          <i className="fa-solid fa-house-laptop"></i> Về nhà
        </>
      ),
      value: 'HOMEWORK'
    },
    // {
    //   label: (
    //     <>
    //       <i className="fas fa-user-friends"></i> Thảo luận
    //     </>
    //   ),
    //   value: "DISCUSS",
    // },
    {
      label: (
        <>
          <i className="fas fa-object-group"></i> Capstone
        </>
      ),
      value: "CAPS",
    },
    {
      label: (
        <>
          <i className="fas fa-object-group"></i> Dự án cuối khóa
        </>
      ),
      value: "DUAN",
    },
    {
      label: (
        <>
          <i className="fab fa-dochub"></i> Tài liệu
        </>
      ),
      value: "TAILIEU",
    },
    {
      label: (
        <>
          <i className="fas fa-check-double"></i> Trắc nghiệm
        </>
      ),
      value: "QUIZ",
    },
    {
      label: (
        <>
          <i className="fa fa-code"></i> Runcode
        </>
      ),
      value: "RUNCODE",
    },
  ];


  let userLogin = localStorage.getItem("USER_LOGIN");
  let nguoiDung = userLogin ? JSON.parse(userLogin) : null;


  if (nguoiDung && (nguoiDung.maNhomQuyen == "MENTOR" || nguoiDung.maNhomQuyen == "LECTURE")) {
    if (arrBaiHoc) {
      arrBaiHoc = arrBaiHoc?.filter(item => {
            let metaData = item.metaData ? JSON.parse(item.metaData) : null;

            if (metaData && nguoiDung.id == metaData.nguoiTao) {

                return item

            }
        })

    }
}

  return (
    <div className=' p-3'>
      <button
        className='btn btn-success mx-2'
        onClick={() => {
          //fix lỗi ko re-render
          setTaskDetail({ id: 0, maLoai: 'VIDEO' })
          showDrawer()
        }}
      >
        Tạo task
      </button>
      <DanhSachTask
        opSegmented={opSegmented}
        setTaskDetail={setTaskDetail}
        taskDetail={taskDetail}
        setRuncodeDetail={setRuncodeDetail}
        setOpen={setOpen}
        arrBaiHoc={arrBaiHoc ?? []}
      />

      {/* {taskDetail.maLoai === "RUNCODE" ? (
        <Modal title='Chi tiết task' open={open} onCancel={onClose} width='80%' centered footer={null}>
          <BaiHocAbove
            opSegmented={opSegmented}
            onClose={onClose}
            runcodeDetail={runcodeDetail}
            taskDetail={taskDetail}
          />
        </Modal>
      ) : (
        <Drawer title='Tạo mới task' width={'90%'} onClose={onClose} open={open} bodyStyle={{ paddingBottom: 80 }}>
          <BaiHocAbove
            opSegmented={opSegmented}
            onClose={onClose}
            runcodeDetail={runcodeDetail}
            taskDetail={taskDetail}
          />
        </Drawer>
      )} */}
      <Drawer title='Tạo mới task' width={'90%'} onClose={onClose} open={open} bodyStyle={{ paddingBottom: 80 }}>
        <BaiHocAbove
          opSegmented={opSegmented}
          onClose={onClose}
          runcodeDetail={runcodeDetail}
          taskDetail={taskDetail}
        />
      </Drawer>


    </div>
  )
}
