import { IssueModel, IssueModelCreate } from "../Types/issueType";
import { http } from "../util/config";

const GET_PATH: string = "/api/issue";

const getAllIssue = () => {
  return http.get(GET_PATH);
};

const getIssueById = (id: number) => {
  return http.get(`${GET_PATH}/${id}`);
};
const postIssue = (data: IssueModelCreate) => {
  return http.post(`${GET_PATH}`, data);
};
const putIssue = (id: number) => {
  return http.put(`${GET_PATH}/putoptional/${id}`);
};
const delIssue = (id: number) => {
  return http.delete(`${GET_PATH}/${id}`);
};
const getAllIssueByMentor=(mentorId:string)=>{
    return http.get(`${GET_PATH}/getbymentor/`+mentorId);
}
const putoptional = (id:number,data:IssueModel) => {
  return http.put(`${GET_PATH}/putoptional/${id}`,data);
};

export const issueService = {
  getAllIssue,
  getIssueById,
  postIssue,
  putIssue,
  delIssue,
  getAllIssueByMentor,
  putoptional
};
