import { Card, Col, Radio, Row, Segmented, Select, Steps, message } from "antd";
import React, { useState } from "react";
import { BaiHocModel } from "../../Types/baiHocType";
import parse from "html-react-parser";
import { NavLink } from "react-router-dom";
import { baiHocService } from "../../services/baiHocService";
import { useAppDispatch } from "../../redux/hooks";
import { getBaiHocApi } from "../../redux/BaiHocReducer/BaiHocReducer";
import SegementItemBaiTap from "./SegementItemBaiTap";
import moment from "moment";
interface Props {
  dataTask: BaiHocModel[] | null;
  maLoai: string;
}

export default function StepBaiTapNop({ dataTask, maLoai }: Props) {
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState(0);

  const renderReviewCapstone = () => {
    return dataTask?.map((item) => {
      // console.log(maLoai);
      if (item.maLoai === maLoai && item.noiDung && JSON.parse(item.noiDung)) {
        // console.log(item);
        const danhSachYeuCau = JSON.parse(item.noiDung);
        // console.log(danhSachYeuCau);
        // console.log(current);
        //render các step yêu cầu của bài tập
        const totalStep = danhSachYeuCau.map((item: any) => {
          return {
            title: "Yêu cầu số " + item.requireId,
            // description: item.noiDung,
          };
        });

        return (
          <Col span={24} className="mb-3">
            <Card
              title={
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <span>Id: {item.id}</span>
                    <div>
                      <p className="mb-0">
                        Ngày tạo: {moment(item.ngayTao).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="mb-0 fs-5">Loại câu: {item.tenBaiHoc}</p>
                  </div>
                  <div>
                    <NavLink
                      className="btn btn-danger"
                      // onClick={() => {
                      //   // window.location.href = `${API_URL_DOMAIN}/baihoc?td=${item.id}`;
                      //   window.location.href = `https://localhost:3000/baihoc?td=${item.id}`;
                      // }}
                      to={`/baihoc?idCauHoi=${item.id}`}
                    >
                      <i className="fas fa-edit"></i>
                    </NavLink>
                  </div>
                </div>
              }
              headStyle={{
                backgroundColor: "#2f8281",
                color: "white",
                fontSize: "20px",
              }}
              bordered={true}
              bodyStyle={{ border: "1px solid gray", padding: "20px 20px" }}
            >
              <Row gutter={16}>
                <Col span={4}>
                  <Steps
                    direction="vertical"
                    current={current}
                    onChange={(current) => setCurrent(current)}
                    items={totalStep}
                  />
                  <div>
                    <h4 className="mb-3">Trạng thái</h4>
                    <Select
                      options={[
                        { value: "chưa chuẩn", label: "Chưa chuẩn" },
                        { value: "đã chuẩn", label: "Đã chuẩn" },
                        { value: "chưa kiểm tra", label: "Chưa kiểm tra" },
                      ]}
                      value={
                        item.metaData &&
                        item.metaData !== "" &&
                        JSON.parse(item.metaData)?.verify
                      }
                      onChange={(value) => {
                        baiHocService
                          .suaBaiHoc({
                            ...item,
                            metaData: JSON.stringify({
                              verify: value,
                            }),
                          })
                          .then((res) => {
                            dispatch(getBaiHocApi());
                            message.success("Cập nhật trạng thái thành công");
                          })
                          .catch((err) => {
                            console.log(err);
                            message.error("Lỗi Báo IT");
                          });
                      }}
                    />
                    {/* <Radio.Group
                      optionType="button"
                      buttonStyle="solid"
                      value={
                        item.metaData &&
                        item.metaData !== "" &&
                        JSON.parse(item.metaData)?.verify
                      }
                      onChange={(event) => {
                        baiHocService
                          .suaBaiHoc({
                            ...item,
                            metaData: JSON.stringify({
                              verify: event.target.value,
                            }),
                          })
                          .then((res) => {
                            dispatch(getBaiHocApi());

                            message.success("Cập nhật trạng thái thành công");
                          })
                          .catch((err) => {
                            message.error("Lỗi Báo IT");
                          });
                      }}
                    >
                      <Radio value={"chưa chuẩn"}>Chưa chuẩn</Radio>
                      <Radio value={"đã chuẩn"}>Đã chuẩn</Radio>
                      <Radio value={"chưa kiểm tra"}>Chưa kiểm tra</Radio>
                    </Radio.Group> */}
                  </div>
                </Col>
                <Col span={10}>
                  <div>{danhSachYeuCau[current]?.noiDung}</div>
                </Col>
                <Col span={10}>
                  <SegementItemBaiTap
                    danhSachYeuCau={danhSachYeuCau}
                    current={current}
                  />
                  {/* <Segmented
                    value={sege}
                    options={[
                      { label: "Hint", value: "hint" },
                      { label: "Kết quả", value: "ketQua" },
                      { label: "Solution", value: "solution" },
                      { label: "Video Solution", value: "videoSolution" },
                    ]}
                    onChange={(value) => {
                      setSege(value as string);
                    }}
                  />
                  <div className="w-100 overflow-auto">
                    {danhSachYeuCau[current] &&
                      parse(danhSachYeuCau[current][sege])}
                  </div> */}
                </Col>
              </Row>
            </Card>
          </Col>
        );
      }
    });
  };

  return dataTask ? <>{renderReviewCapstone()}</> : <div></div>;
}
