import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MonHocModel, NoiDungMonHoc } from "../../Types/monHocType";
import { DispatchType, RootState } from "../../redux/configStore";
import {
  Drawer,
  Popconfirm,
  Table,
  TableColumnsType,
  Tooltip,  
  message,
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MonHocForm from "../../components/Task/MonHocForm";
import ThemTaskForm from "../../components/Task/ThemTaskForm";
import { monHocService } from "../../services/monHocService";
import { getMonHocApi } from "../../redux/MonHocReducer/monHocReducer";

type Props = {};

let monHocEmpty: MonHocModel = {
  id: 0,
  danhSachNoiDung: "",
  danhSachTag: [],
  hinhAnh: "",
  moTa: "",
  tenMonHoc: "",
  videoGioiThieu: "",
};
export default function MonHoc({ }: Props) {
  // create state
  const [open, setOpen] = useState(false);
  const [monHocDetail, setMonHocDetail] = useState<MonHocModel>(monHocEmpty);

  const { arrMonHoc } = useSelector((state: RootState) => state.monHocReducer);

  // get redux
  const dispatch: DispatchType = useDispatch();

  // function else
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => { }, []);

  const columns: TableColumnsType<MonHocModel> = [
    { title: "id", dataIndex: "id" },

    { title: "Tên môn học", dataIndex: "tenMonHoc" },

    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            <button
              className="btn btn-sm btn-success mx-2"
              onClick={() => {
                let newData = { ...record };
                newData.danhSachTag = JSON.parse(
                  newData.danhSachTag.toString()
                );

                setMonHocDetail(newData);
                showDrawer();
              }}
            >
              <i className="fas fa-edit"></i>
            </button>
            <Popconfirm
              title="Bạn có chắc muốn xóa ?"
              onConfirm={() =>
                monHocService
                  .xoaMonHoc(record.id)
                  .then((res) => {
                    dispatch(getMonHocApi());

                    message.success("Xóa thành công");
                  })
                  .catch((err) => {
                    message.error("Lỗi Báo IT");
                  })
              }
            >
              <button className="btn btn-sm btn-danger">
                <i className="fas fa-trash"></i>
              </button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  return (
    <div className=" p-3">
      <button
        className="btn btn-success my-3"
        onClick={() => {
          setMonHocDetail(monHocEmpty);
          showDrawer();
        }}
      >
        Tạo môn học
      </button>

      {arrMonHoc && (
        <Table
          columns={columns}
          rowKey="id"
          
          dataSource={arrMonHoc}
        />
      )}

      <Drawer
        title="Quản lý môn học"
        width="40%"
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <MonHocForm monHocDetail={monHocDetail} onClose={onClose} />
      </Drawer>
    </div>
  );
}
