import React, { ChangeEvent, Dispatch, IframeHTMLAttributes, SetStateAction, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DispatchType, RootState } from '../../redux/configStore'
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  Input,
  InputNumber,
  InputRef,
  message,
  Segmented,
  Select,
  Slider,
  Switch,
  Tabs,
  TabsProps,
  Tag,
  Tooltip,
  Form,
  Row,
  Col,
  Typography,
  Upload,
  Modal
} from 'antd'
import { CKEditor } from 'ckeditor4-react'
import { listLoaiTaskCreate, getListTag } from '../../util/SelectOption'
import BaiTapViet from './BaiTapViet'
import BaiTapTracNghiem from './BaiTapTracNghiem'
import { baiHocService } from '../../services/baiHocService'
import { BaiHocModel } from '../../Types/baiHocType'
import { getBaiHocApi } from '../../redux/BaiHocReducer/BaiHocReducer'
import { danhSachTagService } from '../../services/danhSachTagService'
import { getListTagApi } from '../../redux/ChuyenDeReducer/chuyenDeReducer'
import DocTaiLieu from './DocTaiLieu'
import XemVideo from './XemVideo'
import { LanguageData, RuncodeData, RuncodeModel, TestCase } from '../../Types/runCodeType'
import ThongTinChiTiet from '../Runcode/ThongTinChiTiet'
import CodeAndSolution from '../Runcode/CodeAndSolution'
import Testcase from '../Runcode/Testcase'
import { runcodeService } from '../../services/runcodeService'
// import { getQuestionApi } from "../../redux/RunCodeReducer/runcodeReducer";
import { NoiDungChuongHoc } from '../../Types/monHocType'
import { ChuyenDeModel } from '../../Types/chuyenDeType'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { PlusOutlined } from '@ant-design/icons'
import axios from 'axios'
import _isEqual from 'lodash/isEqual'
import { uploadImageService } from '../../services/uploadImagService'
import { API_URL_DOMAIN } from '../../util/urlDomain'
import { baiTapType } from '../../Types/baiTapType'
import { isSuccessfulHTTPStatus } from '../../constant/status'
import { isJSON } from '../Runcode/Editor/EditorJS'
import { fetchQuestions } from '../../redux/RunCodeReducer/runcodeReducer'
import XemRecord from './XemRecord'

const { TextArea } = Input
const { Title } = Typography

// type
type TypeData = {
  [key: string]: any
}
type listType = {
  value: string | number
  label: string | JSX.Element
}

// props
type Props = {
  opSegmented: listType[]
  onClose: () => void
  setMaLoaiBaiTap?: (maLoai: string) => void
  taskDetail: BaiHocModel
  runcodeDetail: RuncodeModel
  updateTaskVaoMonHoc?: (baiHoc: BaiHocModel, maLoai: string, isRemove: boolean, chuongId: number) => void
  updateBestPracticeVaoChuyenDe?: (baiHoc: BaiHocModel, maLoai: string, isRemove: boolean, chuongId: number) => void
}


const BaiHocAbove = ({
  opSegmented,
  onClose,
  setMaLoaiBaiTap,
  taskDetail,
  runcodeDetail,
  updateTaskVaoMonHoc,
  updateBestPracticeVaoChuyenDe
}: Props) => {
  // create state
  const [data, setData] = useState<TypeData>({})
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  // xu lý BAITAP, CASPS, DUAN
  const [lstBaiTap, setLstBaiTap] = useState<baiTapType[]>([])
  const [currentStep, setCurrentStep] = useState(0)
  // phan task
  const [lstPhanTask, setLstPhanTask] = useState([])


  // tema size
  const [teamSize, setTeamSize] = useState(0)

  //xu ly QUIZ
  const [lstTracNghiem, setLstTracNghiem] = useState([])
  const [hint, setHint] = useState<String>('')

  // xu ly TAILIEU, VIDEO
  const [noiDungTaiLieu, setNoiDungTaiLieu] = useState('')

  // Xu ly RUNCODE
  const { arrCategory, arrQuestion, javascriptSwitch, pythonSwitch, javaSwitch, cppSwitch } = useSelector((state: RootState) => state.runcodeReducer)
  const [category, setCategory] = useState<number[]>([]) // Danh sách category
  const [codeAndSolution, setCodeAndSolution] = useState<LanguageData[]>([])
  const [questionInfor, setQuestionInfor] = useState<RuncodeData>({
    question_title_vi: '',
    problem_vi: '',
    explanation_vi: '',
    constraint_input_vi: '',
    constraint_output_vi: '',
    question_title_en: '',
    problem_en: '',
    explanation_en: '',
    constraint_input_en: '',
    constraint_output_en: '',
    constraint_time: 0,
    input_format: '',
    output_format: '',
    sample_input: '',
    sample_output: '',
    _level: 0
  })
  const [testcase, setTestCase] = useState<TestCase[]>([
    { _input: '', _output: '' },
    { _input: '', _output: '' }
  ])
  const [reward, setReward] = useState<TypeData>({
    coin: 0,
    kinhNghiem: 0,
    tichCuc: 0
  })

  const [validate, setValidate] = useState('')

  const dispatch: DispatchType = useDispatch()
  const { arrTag } = useSelector((state: RootState) => state.chuyenDeReducer)
  const [keyEditer, setKey] = useState(Date.now());


  // get props reducer
  const getProps = async () => {
    if (taskDetail.id !== data.id || (updateTaskVaoMonHoc && taskDetail.maLoai !== data.maLoai)) {
      // set key để load lại CKEditor để gán lại data mới
      setKey(Date.now())

      if (taskDetail.id === 0) {
        setData({
          id: 0,
          tenBaiHoc: '',
          capDo: 1,
          tagSkill: '',
          tag: [],
          ghiChu: '',
          moTa: '',
          thoiLuong: '',
          xemDemo: false,
          maLoai: taskDetail.maLoai,
          phanYeuCau: '',
          coin: 0,
          kinhNghiem: 0,
          tichCuc: 0,
          loaiBaiTap: [],
          metaData: ''
        })

        setTeamSize(0)
        setLstPhanTask([])
        setLstBaiTap([])
        setLstTracNghiem([])
        setValidate('')
        setCurrentStep(0)
        setHint('')

        // Runcode
        setQuestionInfor({
          question_title_vi: '',
          problem_vi: '',
          explanation_vi: '',
          constraint_input_vi: '',
          constraint_output_vi: '',
          question_title_en: '',
          problem_en: '',
          explanation_en: '',
          constraint_input_en: '',
          constraint_output_en: '',
          constraint_time: 0,
          input_format: '',
          output_format: '',
          sample_input: '',
          sample_output: '',
          _level: 0
        })
        setCodeAndSolution([])
        setCategory([])
        setTestCase([
          { _input: '', _output: '' },
          { _input: '', _output: '' }
        ])
        setReward({ coin: 0, kinhNghiem: 0, tichCuc: 0 })
      } else {
        //parse tag

        let newTaskDetail: BaiHocModel = { ...taskDetail }

        newTaskDetail.tagSkill = newTaskDetail.tagSkill ? JSON.parse(newTaskDetail.tagSkill) : []
        newTaskDetail.tag = newTaskDetail.tag ? JSON.parse(newTaskDetail.tag) : []
        newTaskDetail.loaiBaiTap = newTaskDetail.loaiBaiTap ? JSON.parse(newTaskDetail.loaiBaiTap) : []


        setData((pre) => {
          return { ...newTaskDetail }
        })

        let noiDung: any = ''
        switch (taskDetail.maLoai) {
          case 'RECORD':
            noiDung = taskDetail.noiDung && taskDetail.noiDung
            setNoiDungTaiLieu(noiDung)
            break;
          case 'VIDEO':
            noiDung = taskDetail.noiDung && taskDetail.noiDung
            setNoiDungTaiLieu(noiDung)
            break
          case 'QUIZ':
            noiDung = taskDetail.noiDung && JSON.parse(taskDetail.noiDung)
            setLstTracNghiem(noiDung)

            let hint: any = ''
            hint = taskDetail.metaData && JSON.parse(taskDetail.metaData).hint
            setHint(hint)

            break
          case 'TAILIEU':
            noiDung = taskDetail.noiDung && taskDetail.noiDung
            setNoiDungTaiLieu(noiDung)
            break
          case 'RUNCODE':
            noiDung = taskDetail.noiDung && JSON.parse(taskDetail.noiDung)
            const res = arrQuestion.find(item => item.question_id === noiDung[0])

            if (res) {
              let question: RuncodeModel = {
                ...res,
                id: res.question_id || 0
              }

              if (question) {
                // Set danh sách category
                const cates = question.categories?.map((cate) => cate.id).filter((id) => typeof id === 'number')
                const newCategory: number[] = cates?.filter((id) => id !== undefined) as number[]
                setCategory((prevCategory) => {
                  if (JSON.stringify(prevCategory) !== JSON.stringify(newCategory)) {
                    return newCategory;
                  }
                  return prevCategory;
                });

                // Set dữ liệu code và solution
                const initCode =
                  question.init_code?.map((code: any) => ({
                    _language: code._language,
                    tle: code.tle,
                    _function: code._function,
                    base_code: code.base_code,
                    _answer: code._answer,
                    hint: code.hint?.content,
                    videoSolution: code.video_solution
                  })) ?? []
                setCodeAndSolution((prevCodeAndSolution) => {
                  if (JSON.stringify(prevCodeAndSolution) !== JSON.stringify(initCode)) {
                    return [...initCode];
                  }
                  return prevCodeAndSolution;
                });

                // Set thông tin câu hỏi
                const levelValue: number =
                  question._level === 'easy' ? 1 : question._level === 'medium' ? 2 : question._level === 'hard' ? 3 : 0

                setQuestionInfor((prevQuestionInfor) => {
                  const newQuestionInfor = {
                    question_title_vi: question.vi?.question_title ?? '',
                    problem_vi: question.vi?.problem ?? '',
                    explanation_vi: question.vi?.explanation ?? '',
                    constraint_input_vi: question.vi?.constraint_input ?? '',
                    constraint_output_vi: question.vi?.constraint_output ?? '',
                    question_title_en: question.en?.question_title ?? '',
                    problem_en: question.en?.problem ?? '',
                    explanation_en: question.en?.explanation ?? '',
                    constraint_input_en: question.en?.constraint_input ?? '',
                    constraint_output_en: question.en?.constraint_output ?? '',
                    constraint_time: typeof question.vi?.constraint_time !== 'number' ? 0 : question.vi?.constraint_time,
                    input_format: question.input_format ?? '',
                    output_format: question.output_format ?? '',
                    sample_input: question.sample_input ?? '',
                    sample_output: question.sample_output ?? '',
                    _level: levelValue
                  };
                  if (JSON.stringify(prevQuestionInfor) !== JSON.stringify(newQuestionInfor)) {
                    return newQuestionInfor;
                  }
                  return prevQuestionInfor;
                });

                // // Set danh sách test cases
                setTestCase((prevTestCase) => {
                  const newTestCases = question.test_case?.map((testCase: any) => ({
                    _input: testCase?._input ?? '',
                    _output: testCase?._output ?? ''
                  })) ?? [];
                  if (JSON.stringify(prevTestCase) !== JSON.stringify(newTestCases)) {
                    return newTestCases;
                  }
                  return prevTestCase;
                });

              }

              setReward((prevReward) => {
                const newReward = {
                  coin: taskDetail.coin,
                  kinhNghiem: taskDetail.kinhNghiem,
                  tichCuc: taskDetail.tichCuc
                };
                if (JSON.stringify(prevReward) !== JSON.stringify(newReward)) {
                  return newReward;
                }
                return prevReward;
              });

            }

            break

          default:
            noiDung = taskDetail.noiDung && JSON.parse(taskDetail.noiDung)

            setLstBaiTap(noiDung)

            // phan task
            let metaData = taskDetail.metaData && JSON.parse(taskDetail.metaData)
            let noiDungPhanTask = metaData.phanTask ? metaData.phanTask : []
            setLstPhanTask(noiDungPhanTask)
            setTeamSize(metaData.teamSize)
            break
        }
      }
    }
  }
  getProps()

  // life cycle

  // function else

  //set data ck editor bth
  const onEditorChange = (evt: any) => {
    setData((prevData) => ({
      ...prevData,
      [evt.editor.name]: evt.editor.getData()
    }))
  }

  const onChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target

    setData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }
  const onChangeValue = (value: any, name: string) => {
    setData((prevData) => ({
      ...prevData,
      [name]: value
    }))
  }

  //Nội dung show
  const loadChiTietTask = () => {
    if (data && data.maLoai) {
      switch (data.maLoai) {
        case 'VIDEO':

          return <XemVideo noiDungTaiLieu={noiDungTaiLieu} setNoiDungTaiLieu={setNoiDungTaiLieu} />
        case 'RECORD':

          return <XemRecord noiDungTaiLieu={noiDungTaiLieu} setNoiDungTaiLieu={setNoiDungTaiLieu} />
        case 'QUIZ':
          return (
            <BaiTapTracNghiem
              lstTracNghiem={lstTracNghiem}
              setLstTracNghiem={setLstTracNghiem}
              hint={hint}
              setHint={setHint}
            />
          )

        case 'TAILIEU':
          return <DocTaiLieu noiDungTaiLieu={noiDungTaiLieu} setNoiDungTaiLieu={setNoiDungTaiLieu} />

        default:
          return (
            <BaiTapViet
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              lstBaiTap={lstBaiTap}
              setLstBaiTap={setLstBaiTap}

              data={data}
              // phan task
              lstPhanTask={lstPhanTask}
              setLstPhanTask={setLstPhanTask}

              // team size
              setTeamSize={setTeamSize}
              teamSize = {teamSize}
            />
          )
      }
    }
  }

  const getTimeBaiTap = () => {
    let time = data && data.maLoai ? listLoaiTaskCreate.find((n) => n.value == data.maLoai)?.time : 'phút'
    return time
  }

  const inputRef: any = useRef({ tag: '', loaiBaiTap: '' })

  const addItem = (maTag: string, loaiTag: string) => {
    let data: string = inputRef.current[loaiTag]

    if (data == '') {
      message.error('Nhập tên tag')
      return
    }

    let tagDetail = arrTag.find((n) => n.maTag == maTag)
    if (tagDetail) {
      let noiDung = tagDetail ? JSON.parse(tagDetail?.noiDung) : []
      noiDung.sort((a: any, b: any) => a.value - b.value)
      noiDung = [...noiDung, { value: noiDung[noiDung.length - 1].value + 1, label: data }]

      let model = { ...tagDetail }
      model.noiDung = JSON.stringify(noiDung)

      let div_loading = document.getElementsByClassName('div_loading')
      div_loading[0].removeAttribute('hidden')
      danhSachTagService
        .updateDanhSachTag(model)
        .then((res) => {
          dispatch(getListTagApi())
        })
        .catch((err) => {
          message.error('Lỗi Báo IT')
        })
        .finally(() => div_loading[0].setAttribute('hidden', ''))
    }
  }

  
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Thông tin chính`,
      children: (
        <div className='row'>
          <div className='col-md-3 mb-3'>
            <label className='form-label'>Tiêu đề</label>
            <Input onChange={onChangeInput} name='tenBaiHoc' value={data.tenBaiHoc} />
          </div>
          <div className='col-md-2 mb-3'>
            <label className='form-label'>
              Thời gian làm
              <Tooltip
                title={
                  <>
                    Lưu ý điền đúng theo loại bài tập:
                    <br />
                    {listLoaiTaskCreate.map((item) => (
                      <span>
                        - {item.label}: {item.time} <br />
                      </span>
                    ))}
                  </>
                }
                color='blue'
              >
                <b className='text-danger'> ({getTimeBaiTap()}) (?)</b>
              </Tooltip>
            </label>
            <InputNumber
              value={data.thoiLuong}
              style={{ width: '100%' }}
              min={1}
              onChange={(value) => onChangeValue(value, 'thoiLuong')}
            />
          </div>
          {/* {data.maLoai === "BAITAP" || data.maLoai === "DISCUSS" || data.maLoai === "CAPS" || data.maLoai === "DUAN" ? (
                <div className="col-md-2 mb-3">
                    <label className="form-label">Tag Skill</label>
                    <br />
                    <Select
                        value={data.tagSkill}
                        style={{ width: "100%" }}
                        showSearch
                        placeholder="Chọn tag skill"
                        optionFilterProp="children"
                        onChange={(value) => onChangeValue(value, "tagSkill")}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={getListTag(arrTag, "SKILL")}
                    />
                </div>
            ) : ""} */}
          <div className='col-md-2 mb-3'>
            <label className='form-label'>Tag</label>
            <br />
            <Select
              mode='multiple'
              value={data.tag}
              style={{ width: '100%' }}
              showSearch
              placeholder='Chọn tag'
              optionFilterProp='children'
              onChange={(value) => onChangeValue(value, 'tag')}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={getListTag(arrTag, 'EXTEND')}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />

                  <div className='input-group mb-3 input-group-sm '>
                    <input
                      className='form-control'
                      placeholder='Thêm tag'
                      onChange={(e) => {
                        inputRef.current = { tag: e.target.value }
                      }}
                    />
                    <button className='btn btn-outline-success' onClick={() => addItem('EXTEND', 'tag')}>
                      <i className='fas fa-plus'></i>{' '}
                    </button>
                  </div>
                </>
              )}
            />
          </div>
          <div className='col-md-1 mb-3'>
            <label className='form-label'>Xem demo</label>
            <br />
            <Switch onChange={(checked) => onChangeValue(checked, 'xemDemo')} />
          </div>
          <div className='col-md-2 mb-3'>
            <label className='form-label'>Level</label>
            <Slider
              marks={{
                1: '1',
                2: '2',
                3: '3'
              }}
              min={1}
              max={3}
              onChange={(value) => onChangeValue(value, 'capDo')}
              value={data.capDo}
            />
          </div>
          <div className='col-md-12 mb-3'></div>
          <div className='col-md-1 mb-3'>
            <label className='form-label'>Coin </label>
            <InputNumber
              value={data.coin}
              style={{ width: '100%' }}
              min={1}
              onChange={(value) => onChangeValue(value, 'coin')}
            />
          </div>
          <div className='col-md-1 mb-3'>
            <label className='form-label'>Kinh Nghiệm </label>
            <InputNumber
              value={data.kinhNghiem}
              style={{ width: '100%' }}
              min={1}
              onChange={(value) => onChangeValue(value, 'kinhNghiem')}
            />
          </div>
          <div className='col-md-1 mb-3'>
            <label className='form-label'>Tích cực </label>
            <InputNumber
              value={data.tichCuc}
              style={{ width: '100%' }}
              min={1}
              onChange={(value) => onChangeValue(value, 'tichCuc')}
            />
          </div>
          <div className='col-md-2 mb-3'>
            <label className='form-label'>Loại bài tập</label>
            <br />
            <Select
              mode='multiple'
              value={data.loaiBaiTap}
              style={{ width: '100%' }}
              showSearch
              placeholder='Chọn tag'
              optionFilterProp='children'
              onChange={(value) => onChangeValue(value, 'loaiBaiTap')}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={getListTag(arrTag, 'LOAIBT')}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />

                  <div className='input-group mb-3 input-group-sm '>
                    <input
                      className='form-control'
                      placeholder='Thêm loại bài tập'
                      onChange={(e) => {
                        inputRef.current = { loaiBaiTap: e.target.value }
                      }}
                    />
                    <button className='btn btn-outline-success' onClick={() => addItem('LOAIBT', 'loaiBaiTap')}>
                      <i className='fas fa-plus'></i>{' '}
                    </button>
                  </div>
                </>
              )}
            />
          </div>
          <div className='col-md-12 mb-3'></div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>Mô tả</label>

            <CKEditor
              key={keyEditer}
              initData={data.moTa}
              config={{
                toolbarGroups: [
                  { name: 'document', groups: ['mode', 'document', 'doctools'] },
                  { name: 'clipboard', groups: ['clipboard', 'undo'] },
                  { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                  { name: 'forms' },
                  '/',
                  { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                  { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                  { name: 'links' },
                  { name: 'insert' },
                  '/',
                  { name: 'styles' },
                  { name: 'colors' },
                  { name: 'tools' },
                  { name: 'others' },
                  { name: 'about' }
                ],
                extraPlugins: 'codesnippet' // Thêm plugin codesnippet vào danh sách extraPlugins
              }}
              name='moTa'
              onChange={onEditorChange}
            />
          </div>
          <div className='col-md-6 mb-3'>
            <label className='form-label'>Ghi chú</label>
            {/* <CKEditor
                    key={key}
                    initData={data.ghiChu}

                    config={{
                        toolbarGroups: [
                            { name: 'document', groups: ['mode', 'document', 'doctools'] },
                            { name: 'clipboard', groups: ['clipboard', 'undo'] },
                            { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                            { name: 'forms' },
                            '/',
                            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                            { name: 'links' },
                            { name: 'insert' },
                            '/',
                            { name: 'styles' },
                            { name: 'colors' },
                            { name: 'tools' },
                            { name: 'others' },
                            { name: 'about' },
                        ]
                    }}
                    name="ghiChu"
                    onChange={onEditorChange}
                /> */}
            <CKEditor
              key={keyEditer}
              initData={data.ghiChu}
              config={{
                toolbarGroups: [
                  { name: 'document', groups: ['mode', 'document', 'doctools'] },
                  { name: 'clipboard', groups: ['clipboard', 'undo'] },
                  { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                  { name: 'forms' },
                  '/',
                  { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                  { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                  { name: 'links' },
                  { name: 'insert' },
                  '/',
                  { name: 'styles' },
                  { name: 'colors' },
                  { name: 'tools' },
                  { name: 'others' },
                  { name: 'about' }
                ],
                extraPlugins: 'codesnippet' // Thêm plugin codesnippet vào danh sách extraPlugins
              }}
              name='ghiChu'
              onChange={onEditorChange}
            />
          </div>
        </div>
      )
    },
    {
      key: '2',
      label: `Nội dung`,
      children: loadChiTietTask()
    }
  ]

  const problemDetailItems: TabsProps['items'] = [
    {
      key: 'problem_detail',
      label: 'Thông tin chính',
      children: (
        <>
          <ThongTinChiTiet
            data={questionInfor}
            setData={setQuestionInfor}
            category={category}
            arrCategories={arrCategory}
            setCategory={setCategory}
            reward={reward}
            setReward={setReward}
          />
        </>
      )
    }
  ]

  const codeItems: TabsProps['items'] = [
    {
      key: 'problem',
      label: 'Code and Solution',
      children: (
        <>
          <CodeAndSolution data={codeAndSolution} setData={setCodeAndSolution} dataId={data.id} />
        </>
      )
    }
  ]

  const testcaseItems: TabsProps['items'] = [
    {
      key: 'testcase',
      label: 'Testcase',
      children: (
        <>
          <Testcase testcase={testcase} setTestCase={setTestCase} />
        </>
      )
    }
  ]

  // thêm data vào db
  const saveData = () => {
    if (data) {
      if (!data.tenBaiHoc || !data.thoiLuong) {
        setValidate('Hãy nhập đầy đủ: tiêu đề, thời lượng   ')
        return
      }

      let model = { ...data }

      let tagSkillForTask = model.tagSkill
      let hanNopTemp = model.hanNop

      let noiDung = ''
      switch (data.maLoai) {
        case 'VIDEO':
          noiDung = noiDungTaiLieu;
          break
        case 'RECORD':
          noiDung = noiDungTaiLieu;
          break
        case 'QUIZ':
          if (lstTracNghiem.length == 0) {
            setValidate('Hãy thêm nội dung cho trắc nghiệm')

            return
          }
          let checkDapAn: any = lstTracNghiem[0]
          if (checkDapAn.dapAn.length == 0) {
            setValidate('Hãy thêm đáp án cho trắc nghiệm !')
            return
          }

          noiDung = JSON.stringify(lstTracNghiem)
          break
        case 'TAILIEU':
          noiDung = noiDungTaiLieu
          break
        default:
          if (lstBaiTap.length == 0) {
            setValidate('Hãy thêm nội dung cho bài tập này')

            return
          }
          noiDung = JSON.stringify(lstBaiTap)
          break
      }
      model.tagSkill = ''
      model.tag = model.tag ? JSON.stringify(model.tag) : "[]"
      model.noiDung = noiDung
      model.loaiBaiTap = model.loaiBaiTap ? JSON.stringify(model.loaiBaiTap) : "[]"
      model.moTa = model.moTa ? model.moTa : ""
      model.ghiChu = model.ghiChu ? model.ghiChu : ""
      model.phanYeuCau = model.phanYeuCau ? model.phanYeuCau : ""

      // Image
      let userLogin = localStorage.getItem('USER_LOGIN')
      let nguoiTao = userLogin ? JSON.parse(userLogin).id : null

      let dataMeta: {
        hinhAnh: string | null
        nguoiTao: any
        hint?: String
        phanTask?: any
        teamSize?: number
      } = {
        hinhAnh: imageUrl ? imageUrl : '',
        nguoiTao: nguoiTao
      }

      if (data.maLoai === 'QUIZ') {
        dataMeta.hint = hint
      }



      dataMeta.phanTask = lstPhanTask
      dataMeta.teamSize = teamSize

      model.metaData = JSON.stringify(dataMeta)

      let div_loading = document.getElementsByClassName('div_loading')
      div_loading[0].removeAttribute('hidden')

      if (data.id === 0) {

        model.ngayTao = new Date()

        baiHocService.themBaiHoc(model).then((res) => {
            onClose()
            dispatch(getBaiHocApi())
            setData({})

            if (updateTaskVaoMonHoc)
              updateTaskVaoMonHoc({ ...res.data.content, tagSkill: tagSkillForTask, hanNop: hanNopTemp }, model.maLoai, false, 0)
            if (updateBestPracticeVaoChuyenDe) {
              updateBestPracticeVaoChuyenDe(res.data.content, 'TAILIEU', false, 0)
            }

            message.success('Thêm thành công')
          })
          .catch((err) => {
            message.error('Lỗi Báo IT baihocAbove')
          })
          .finally(() => div_loading[0].setAttribute('hidden', ''))

        //TODO: Cập nhập thành công như ko cập nhập ở input
      } else {

        baiHocService.suaBaiHoc(model).then((res) => {
            if (updateTaskVaoMonHoc) {
              updateTaskVaoMonHoc({ ...res.data.content, tagSkill: tagSkillForTask, hanNop: hanNopTemp }, model.maLoai, false, 0)
            }
            onClose()
            dispatch(getBaiHocApi())
            // setData({})

            // message.success('Cập nhật thành công !!')
          })
          // .catch((err) => {
          //   message.error('Lỗi Báo IT baihocAbove')
          // })
          .finally(() => div_loading[0].setAttribute('hidden', ''))
      }
    }
  }

  

  const mapLevelToText = (level: number) => {
    switch (level) {
      case 1:
        return 'easy'
      case 2:
        return 'medium'
      case 3:
        return 'hard'
      default:
        return ''
    }
  }

  const mapLevelToCapDo = (_level: string) => {
    switch (_level.toLowerCase()) {
      case 'easy':
        return 1
      case 'medium':
        return 2
      case 'hard':
        return 3
      default:
        return 2
    }
  }

  const saveDataRuncode = async () => {
    if (data) {
      let validationError = ''
      let model = { ...data }

      if (questionInfor.question_title_vi === '' || questionInfor.question_title_en === '') {
        validationError = 'Hãy nhập đầy đủ thông tin cho câu hỏi Runcode.'
        message.info(validationError)
        return
      }


      const codeAndSolutionData = codeAndSolution
        .filter(item => {
          switch (item._language) {
            case 'js':
              return javascriptSwitch;
            case 'py':
              return pythonSwitch;
            case 'java':
              return javaSwitch;
            case 'cpp':
              return cppSwitch;
            default:
              return true;  // Mặc định giữ lại nếu không thuộc bốn ngôn ngữ trên
          }
        })
        .map(item => {
          return {
            _language: item._language,
            tle: 10000,
            base_code: isJSON(item.base_code ?? '')
              ? JSON.parse(item.base_code ?? '')
              : (item.base_code ?? '').replace(/'/g, '"'),
            _function: isJSON(item._function ?? '')
              ? JSON.parse(item._function || "")
              : (item._function ?? '').replace(/'/g, '"'),
            _answer: isJSON(item._answer ?? '')
              ? JSON.parse(item._answer || "")
              : (item._answer ?? '').replace(/'/g, '"'),

            hint: {
              type: "text",
              content: item?.hint || ""
            },
            videoSolution: item.videoSolution || ""
          }
        });

      // Gom dữ liệu từ ProblemDetail
      const problemDetailDataVi = {
        question_title: questionInfor.question_title_vi,
        problem: questionInfor.problem_vi,
        explanation: questionInfor.explanation_vi,
        constraint_input: questionInfor.constraint_input_vi,
        constraint_output: questionInfor.constraint_output_vi,
        constraint_time: 10000,
        locale: 'vi'
      }

      const problemDetailDataEn = {
        question_title: questionInfor.question_title_en,
        problem: questionInfor.problem_en,
        explanation: questionInfor.explanation_en,
        constraint_input: questionInfor.constraint_input_en,
        constraint_output: questionInfor.constraint_output_en,
        constraint_time: 10000,
        locale: 'en'
      }

      // Gom dữ liệu từ Problem
      const problemData = {
        input_format: questionInfor.input_format,
        output_format: questionInfor.output_format,
        sample_input: questionInfor.sample_input,
        sample_output: questionInfor.sample_output,
        _level: mapLevelToText(questionInfor._level)
      }

      // Gom dữ liệu từ Testcase
      const testcaseData = testcase.map((item) => {
        const newInput = item._input.replace(/'/g, '"')
        const newOutput = item._output.replace(/'/g, '"')
        return {
          _input: newInput,
          _output: newOutput
        }
      })

      // Gom dữ liệu từ Data
      const newData = {
        problem: problemData,
        problem_detail: [problemDetailDataVi, problemDetailDataEn],
        testcaseArr: testcaseData,
        initCodeArr: codeAndSolutionData,
        categories: [...category]
      }


      let tagSkillForTask = model.tagSkill

      if (data.id == 0) {
        // Gửi dữ liệu qua API hoặc thực hiện các xử lý khác
        const res = await runcodeService.addQuestion(newData)


        const currentDate = new Date()
        const formattedDate = currentDate.toISOString() // Định dạng ngày theo chuẩn ISO (YYYY-MM-DDTHH:mm:ss.sssZ)

        let { vi, _level } = res.data

        const capDo = mapLevelToCapDo(_level)

        const { coin, kinhNghiem, tichCuc } = reward

        if (isSuccessfulHTTPStatus(res.status)) {
          let userLogin = localStorage.getItem('USER_LOGIN')
          let nguoiTao = userLogin ? JSON.parse(userLogin).id : null

          let dataMeta: {
            hinhAnh: string | null
            nguoiTao: any
            hint?: String
          } = {
            hinhAnh: '',
            nguoiTao: nguoiTao
          }

          const defaultBaiHoc: BaiHocModel = {
            id: 0, // Gán giá trị của question_id
            tenBaiHoc: vi.question_title,
            capDo: capDo,
            tagSkill: '',
            tag: '',
            ghiChu: '',
            moTa: '',
            noiDung: JSON.stringify([res.data.question_id]),
            thoiLuong: 0,
            xemDemo: false,
            maLoai: 'RUNCODE',
            coin: coin,
            kinhNghiem: kinhNghiem,
            tichCuc: tichCuc,
            phanYeuCau: '',
            loaiBaiTap: '',
            metaData: JSON.stringify(dataMeta),
            ngayTao: formattedDate
          }

          const newRes = await baiHocService.themBaiHoc(defaultBaiHoc)

          if (isSuccessfulHTTPStatus(newRes.status)) {
            if (updateTaskVaoMonHoc) {
              updateTaskVaoMonHoc({ ...newRes.data.content, tagSkill: tagSkillForTask }, model.maLoai, false, 0)
              onClose();
              // setData({})
            }
            else {
              await Promise.all([dispatch(fetchQuestions()), dispatch(getBaiHocApi())])
              // setData({})
              onClose()
              message.success('Thêm thành công')
            }
          }
        }
      } else {
        let userLoginData = JSON.parse(localStorage.getItem('USER_LOGIN') || '{}')
        let nguoiTao = userLoginData.id || null

        const res = await runcodeService.updateQuestion(JSON.parse(model.noiDung)[0], newData)

        const { vi, _level } = res.data

        const capDo = _level.toLowerCase() === 'easy' ? 1 : _level === 'medium' ? 2 : _level === 'hard' ? 3 : 2;

        const { coin, kinhNghiem, tichCuc } = reward;

        if (isSuccessfulHTTPStatus(res.status)) {
          const dataMeta = {
            hinhAnh: '',
            nguoiTao: nguoiTao
          }

          const defaultBaiHoc: BaiHocModel = {
            id: model.id,
            tenBaiHoc: vi.question_title,
            capDo: capDo,
            tagSkill: '',
            tag: '',
            ghiChu: '',
            moTa: '',
            noiDung: JSON.stringify([res.data.question_id]),
            thoiLuong: 0,
            xemDemo: false,
            maLoai: 'RUNCODE',
            coin: coin,
            kinhNghiem: kinhNghiem,
            tichCuc: tichCuc,
            phanYeuCau: '',
            loaiBaiTap: '',
            metaData: JSON.stringify(dataMeta),
            ngayTao: model.ngayTao
          }

          const ress = await baiHocService.suaBaiHoc(defaultBaiHoc)

          if (isSuccessfulHTTPStatus(ress.status)) {
            if (updateTaskVaoMonHoc) {
              updateTaskVaoMonHoc({ ...ress.data.content, tagSkill: tagSkillForTask }, model.maLoai, false, 0)
              onClose()
            }
            else {
              onClose()
              dispatch(fetchQuestions())
              dispatch(getBaiHocApi())
              message.success('Update thành công')
            }

          }
        }
      }
    }
  }

  return (
    <div>
      <p className='mb-3'>
        <button className='btn btn-success my-2' onClick={data.maLoai === 'RUNCODE' ? saveDataRuncode : saveData}>
          Lưu tác vụ
        </button>
        <b className='text-danger'>{validate}</b>
      </p>
      <Segmented
          size='large'
          options={opSegmented}
          value={taskDetail.maLoai}
          onChange={(value: any) => {
            onChangeValue(value, 'maLoai')

            if (setMaLoaiBaiTap) setMaLoaiBaiTap(value)
          }}
        />
      {/* {data.id == 0 ? (
        <Segmented
          size='large'
          options={opSegmented}
          onChange={(value: any) => {
            onChangeValue(value, 'maLoai')

            if (setMaLoaiBaiTap) setMaLoaiBaiTap(value)
          }}
        />
      ) : (
        <Tag color='red'>Loại task: {opSegmented.find((n) => n.value == data.maLoai)?.label}</Tag>
      )} */}

      <Tabs
        defaultActiveKey='1'
        items={data.maLoai === 'RUNCODE' ? [...problemDetailItems, ...codeItems, ...testcaseItems] : items}
      />
    </div>
  )
}

export default BaiHocAbove
