import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baiHocService } from "../../services/baiHocService";
import { http } from "../../util/config";
import { DispatchType } from "../configStore";
import { BaiHocModel, BaiHocViewMentorModel } from "../../Types/baiHocType";
import moment from "moment";
import { IssueModel } from "../../Types/issueType";
import { issueService } from "../../services/issueService";

type issueState = {
  issueState: IssueModel;
  issuesMentorPhuTrach: IssueModel[];
};
const initialState: issueState = {
  issueState: {
    id: 0,
    chuyenDeId: 0,
    hocVienId: "",
    ngayTao: "",
    noiDung: "",
    title: "",
    trangThai: "DANG_HO_TRO",
    path:""
    
  },

  issuesMentorPhuTrach: [],
};

const issueReducer = createSlice({
  name: "issueReducer",
  initialState,
  reducers: {
    getIssueAllMentorPhuTrach: (
      state: issueState,
      action: PayloadAction<IssueModel[]>
    ) => {
      state.issuesMentorPhuTrach = [...action.payload];
    },
    setIssueState:(state:issueState,action:PayloadAction<IssueModel>)=>{
      state.issueState={...action.payload}
    }
  },
});

export const { getIssueAllMentorPhuTrach,setIssueState } = issueReducer.actions;
export default issueReducer.reducer;
//--------- action async ------------

export const getIssueAllMentorPhuTrachApi = (mentorId: string) => {
  return async (dispatch: DispatchType) => {
   try {
    const result = await issueService.getAllIssueByMentor(mentorId);
    const action: PayloadAction<IssueModel[]> = getIssueAllMentorPhuTrach(
      result.data.content
    );
    dispatch(action);
   } catch (error) {
    console.log('lỗi getIssueAllMentorPhuTrachApi');
    console.log(error);
   }
  };
};

export const PostIssueApi=(data:IssueModel)=>{
  return async ()=>{
    try {
      const rs = await issueService.postIssue(data);
      if(rs.status==200||rs.status==201){
        console.log('Tạo issue thành công');
      }
    } catch (error) {
      console.log('Tạo ko thành công',error);
    }
  }
}
export const putoptionalApi=(id:number,data:IssueModel)=>{
  return async ()=>{
    try {
      const res = await issueService.putoptional(id,data);
      if(res?.status==200)
        console.log('update issue success');
    } catch (error) {
      console.log('putoptional',error);
    }
  }
}