import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../redux/configStore';
import { useDispatch } from 'react-redux';
import { Data, InitCode, RuncodeModel, categories } from '../../Types/runCodeType';
import { ColumnsType } from 'antd/es/table';
import { useSearchParams } from 'react-router-dom';
import { Select, Tag, Table, Tooltip, message, Input, Button, InputRef } from 'antd';
import { toNonAccentVietnamese } from '../../util/functionUtils';
import moment from 'moment';
import { fetchQuestions } from '../../redux/RunCodeReducer/runcodeReducer';
import { getBaiHocApi } from '../../redux/BaiHocReducer/BaiHocReducer';
import { baiHocService } from '../../services/baiHocService';
import { BaiHocModel } from '../../Types/baiHocType';
import Icon from '@ant-design/icons/lib/components/Icon';

type Props = {}

const RuncodeSource = (props: Props) => {
  const dispatch: DispatchType = useDispatch();

  const { arrQuestion, arrCategory } = useSelector((state: RootState) => state.runcodeReducer);

  const searchInputRef = useRef<InputRef>(null);


  const addFromRuncodeSource = (question: RuncodeModel) => {

    let { question_id, vi, created_at } = question;

    let userLogin = localStorage.getItem("USER_LOGIN");
    let nguoiTao = userLogin ? JSON.parse(userLogin).id : null;

    let dataMeta: { hinhAnh?: string | null; nguoiTao: any; hint?: String } = {
      nguoiTao: nguoiTao
    };

    const defaultBaiHoc: BaiHocModel = {
      id: 0, // Gán giá trị của question_id
      tenBaiHoc: vi?.question_title || "",
      capDo: 0,
      tagSkill: "",
      tag: "",
      ghiChu: "",
      moTa: "",
      noiDung: JSON.stringify([question_id]),
      thoiLuong: 0,
      xemDemo: false,
      maLoai: "RUNCODE",
      coin: 0,
      kinhNghiem: 0,
      tichCuc: 0,
      phanYeuCau: "",
      loaiBaiTap: "",
      metaData: JSON.stringify(dataMeta),
      ngayTao: created_at,
    };


    // Lưu câu hỏi runcode vào codingcamp
    baiHocService.themBaiHoc(defaultBaiHoc).then(res => {
      if (res.status >= 200 && res.status < 300) {
        // Xử lý thành công
        dispatch(fetchQuestions());
        dispatch(getBaiHocApi());
        message.success("Thêm thành công");
      } else {
        // Xử lý lỗi
        message.error("Lỗi Báo IT Runcode");
      }
    }).catch(err => {
      message.error("Lỗi Báo IT Runcode");
    })
  }




  const columns_runcode: ColumnsType<RuncodeModel> = [
    {
      title: "ID",
      dataIndex: "question_id",
      key: "id",
      width: 50,
      align: "center",
      defaultSortOrder: 'ascend',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder="Tìm kiếm ID"
            value={selectedKeys[0]}
            onChange={e => {
              // Chỉ cho phép nhập số
              const val = e.target.value.replace(/[^0-9]/g, "");
              setSelectedKeys(val ? [val] : []);
            }}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Tìm
          </Button>
          <Button onClick={() => { clearFilters?.(); }} size="small" style={{ width: 90 }}>
            Xóa
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) => record.question_id == value,
      sorter: (a, b) => {
        return (a.question_id ? a.question_id : 0) - (b.question_id ? b.question_id : 0);
      }
    },
    {
      title: "Tiêu đề",
      dataIndex: ["vi", "question_title"],
      key: "question_title",
      defaultSortOrder: 'ascend',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputRef}
            placeholder="Tìm kiếm tiêu đề"
            value={selectedKeys[0]}
            onChange={e => {
              console.log(e.target.value)
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }}
            onPressEnter={() => confirm()}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => confirm()}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Tìm
          </Button>
          <Button onClick={() => { clearFilters?.(); }} size="small" style={{ width: 90 }}>
            Xóa
          </Button>
        </div>
      ),
      onFilter: (value: any, record: RuncodeModel) => {
        const title = record.vi?.question_title || '';
        const normalizedTitle = toNonAccentVietnamese(title);
        const normalizedSearch = toNonAccentVietnamese(value);
        return normalizedTitle.includes(normalizedSearch);
      },
      sorter: (a, b) => {
        const titleA = toNonAccentVietnamese(a.vi?.question_title || "") || "";
        const titleB = toNonAccentVietnamese(b.vi?.question_title || "") || "";
        return titleA.localeCompare(titleB);
      },
    },
    {
      title: "Problem",
      dataIndex: ["vi", "problem"],
      key: "problem",
    },
    {
      title: "Category",
      dataIndex: "categories",
      key: "categories",
      width: 200,
      render: (categories, record, index) => {
        return (
          <div key={index}>
            {categories?.map((item: categories) => (
              <Tag
                color="blue"
                key={item?.id}
                className="my-1"
                style={{ width: "100px", textAlign: "center" }}
              >
                {item?.name ? (
                  item.name.length > 15 ? item.name.substring(0, 13) + "..." : item.name
                ) : (
                  ""
                )}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "Ngôn ngữ hỗ trợ",
      dataIndex: "init_code",
      key: "init_code",
      width: 200,
      render: (init_code, record, index) => {
        return (
          <div key={index}>
            {init_code?.map((lang: InitCode) => (
              <Tag
                color="blue"
                key={lang?._language}
                className="my-1"
                style={{ width: "100px", textAlign: "center" }}
              >
                {lang?._language}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "Create At",
      dataIndex: "created_at",
      key: "created_at",
      defaultSortOrder: 'ascend',
      sorter: (a, b) => {
        return moment(a.created_at).valueOf() - moment(b.created_at).valueOf();
      },
      render: (_, record) => {
        const currentTime = moment();
        const createdAt = moment(record.created_at);
        const duration = moment.duration(currentTime.diff(createdAt));

        let displayText = '';
        if (duration.asDays() >= 1) {
          displayText = `${Math.floor(duration.asDays())} ngày trước`;
        } else if (duration.asHours() >= 1) {
          displayText = `${Math.floor(duration.asHours())} giờ trước`;
        } else {
          displayText = `${Math.floor(duration.asMinutes())} phút trước`;
        }

        return (
          <Tooltip title={`Tạo lúc: ${createdAt.format('HH:mm DD/MM/YYYY')}`}>
            <Tag color="blue">{displayText}</Tag>
          </Tooltip>
        );
      }
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      key: "action",
      width: 250,
      render: (_, record) => {

        return (
          <>
            <button
              className="btn btn-sm btn-success mx-2"
              onClick={() => {
                addFromRuncodeSource(record)
              }}
            >
              <i className="fas fa-plus"></i> Thêm
            </button>

          </>
        );
      },
    }
  ];

  let dataRuncode = [...arrQuestion];

  dataRuncode.sort((a, b) => {
    if (a.created_at && b.created_at) {
      const dateA = new Date(a.created_at).getTime();
      const dateB = new Date(b.created_at).getTime();
      return dateA - dateB; // sắp xếp tăng dần; nếu bạn muốn sắp xếp giảm dần, hãy đảo ngược thành `dateB - dateA`
    }
    return 0;
  });



  return (
    <>
      <div className=" row">
        <div className="col-12">
          {dataRuncode && (
            <Table
              columns={columns_runcode}
              dataSource={dataRuncode}
            />
          )}
        </div>
      </div >
    </>
  )
}

export default RuncodeSource
