import React, { useState } from "react";
import { BaiHocModel } from "../../Types/baiHocType";
import ListTaskReview from "../../components/ListTaskReview/ListTaskReview";

type Props = {};

const ReviewTask = (props: Props) => {
  // create state
  const [open, setOpen] = useState(false);
  const [taskDetail, setTaskDetail] = useState<BaiHocModel>({ id: 0 });

  let opSegmented = [
    {
      label: (
        <>
          <i className="fas fa-video"></i> Video
        </>
      ),
      value: "VIDEO",
    },
    {
      label: (
        <>
          <i className="fas fa-file-signature"></i> Bài tập nộp
        </>
      ),
      value: "BAITAP",
    },
    // {
    //   label: (
    //     <>
    //       <i className="fas fa-user-friends"></i> Thảo luận
    //     </>
    //   ),
    //   value: "DISCUSS",
    // },
    {
      label: (
        <>
          <i className="fas fa-object-group"></i> Capstone
        </>
      ),
      value: "CAPS",
    },
    {
      label: (
        <>
          <i className="fab fa-dochub"></i> Tài liệu
        </>
      ),
      value: "TAILIEU",
    },
    {
      label: (
        <>
          <i className="fas fa-check-double"></i> Trắc nghiệm
        </>
      ),
      value: "QUIZ",
    },
  ];

  return (
    <div className=" p-3">
      <ListTaskReview
        opSegmented={opSegmented}
        setTaskDetail={setTaskDetail}
        setOpen={setOpen}
      />
    </div>
  );
};

export default ReviewTask;
