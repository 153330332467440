import { Button, Popconfirm, Switch, Tag, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { NguoiDungType } from "../../Types/nguoiDungType";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getNguoiDungChinhSuaAction,
  getNguoiDungPhanTrangApi,
} from "../../redux/UserReducer/userReducer";
import FilterTable from "../../components/FilterTable/FilterTable";
import TableQuanLiUser from "../../components/TableQuanLiUser/TableQuanLiUser";
import useQueryConfig from "../../hooks/useQueryConfig";
import moment from "moment";
import { nguoiDungService } from "../../services/nguoiDungService";
import DrawerChinhSuaNguoiDung from "../../components/DrawerChinhSuaNguoiDung/DrawerChinhSuaNguoiDung";
import { FacebookOutlined } from "@ant-design/icons";
import TabsQuanLiUser from "../../components/TabsQuanLiUser/TabsQuanLiUser";
type Props = {};

const QuanLiUser = (props: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  // cột của table
  const columns: ColumnsType<NguoiDungType> = [
    {
      title: "Họ Tên",
      dataIndex: "hoTen",
      key: "hoTen",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Số Đt",
      dataIndex: "soDt",
      key: "soDt",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Facebook",
      dataIndex: "urls",
      key: "urls",
      render: (text) => {
   
        return <a href={text} target="_blank" style={{ textDecoration: "none" }} >
          <Tag icon={<FacebookOutlined />} color="#3b5999">
            Facebook
          </Tag>
        </a >
      },
    },
    {
      title: "Ngày tạo",
      dataIndex: "ngayTao",
      key: "ngayTao",
      render: (text) => (
        <div>
          <span>{moment(text).format("DD-MM-YYYY")}</span>
        </div>
      ),
    },
    {
      title: <div className="text-center">Trạng thái</div>,
      dataIndex: "kichHoat",
      key: "kichHoat",
      render: (content, record, index) => {
        // console.log(record);
        return (
          <div className="text-center">
            <Switch
              checked={content ? true : false}
              onChange={(checked) => {
                handleUpdateKichHoat({ ...record, kichHoat: checked });
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Hành động",
      dataIndex: "id",
      key: "id",
      render: (id, record, index) => {
        return (
          <div>
            <Button
              onClick={() => {
                setOpen(!open);
                dispatch(getNguoiDungChinhSuaAction(record));
              }}
              type="primary"
              icon={<i className="fas fa-pen"></i>}
            ></Button>
            <Popconfirm
              title="Xoá học viên"
              description="Chắc chắn muốn xoá học viên này ?"
              onConfirm={() => {
                handleDeleteUser(id);
              }}
              // onCancel={cancel}
              okText="Có"
              cancelText="Không"
            >
              <Button
                danger
                className="ms-2"
                type="primary"
                icon={<i className="fas fa-trash"></i>}
              // loading={loadings[2]}
              // onClick={() => enterLoading(2)}
              />
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const dispatch = useAppDispatch();
  const queryConfig = useQueryConfig();
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  //gọi lấy danh sách người dùng
  useEffect(() => {
    dispatch(getNguoiDungPhanTrangApi(queryConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    queryConfig.keyword,
    queryConfig.pageIndex,
    queryConfig.pageSize,
    queryConfig.maNhomQuyen,
    queryConfig.tuKhoa,
  ]);

  // xoá người dùng
  const handleDeleteUser = (id: number) => {
    nguoiDungService
      .deleteNguoiDung(id)
      .then((res) => {
        dispatch(getNguoiDungPhanTrangApi(queryConfig));
        messageApi.open({
          type: "success",
          content: "Xoá Thành Công",
        });
      })
      .catch((err) => {
        console.log(err);
        messageApi.open({
          type: "success",
          content: "Có lỗi xảy ra vui lòng thử lại",
        });
      });
  };

  // update trạng thái người dùng
  const handleUpdateKichHoat = (data: NguoiDungType) => {
    nguoiDungService
      .updateNguoiDung(data)
      .then((res) => {
        // console.log(res);
        dispatch(getNguoiDungPhanTrangApi(queryConfig));
        messageApi.open({
          type: "success",
          content: "Cập nhật trạng thái thành công",
        });
      })
      .catch((err) => {
        messageApi.open({
          type: "error",
          content: "Có lỗi xảy ra vui lòng thử lại",
        });
      });
  };

  return (
    <>
      {contextHolder}
      <DrawerChinhSuaNguoiDung open={open} onClose={onClose} />

      <div className="p-4">
        <FilterTable queryConfig={queryConfig} />
        <TabsQuanLiUser open={open} setOpen={setOpen} />
      </div>
      {/* <TableQuanLiUser columns={columns} userPhanTrang={userPhanTrang} /> */}
    </>
  );
};

export default QuanLiUser;
