//dinh dang ngay de so sanh (MM/DD/YYYY)
export const dinhDangNgayCheck = (inputdate: any) => {
  var date = new Date(inputdate);
  var aaaa = date.getFullYear();
  var gg: any = date.getDate();
  var mm: any = date.getMonth() + 1;

  if (gg < 10) gg = "0" + gg;

  if (mm < 10) mm = "0" + mm;

  var cur_day = mm + "/" + gg + "/" + aaaa;

  return cur_day;
};

// (DD/MM/YYYY)
export const dinhDangNgay = (inputdate: any) => {
  var date = new Date(inputdate);
  var aaaa = date.getFullYear();
  var gg: any = date.getDate();
  var mm: any = date.getMonth() + 1;

  if (gg < 10) gg = "0" + gg;

  if (mm < 10) mm = "0" + mm;

  var cur_day = gg + "/" + mm + "/" + aaaa;

  return cur_day;
};

