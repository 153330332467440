import React, { useEffect } from 'react'
import { DispatchType, RootState } from '../../redux/configStore'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
type Props = {}

const HomeMobile: React.FC = (props: Props): JSX.Element => {
    const dispatch: DispatchType = useDispatch();
    useEffect(() => {
    }, []);
    //-------ui----------
    const renderProduct = () => {
        return "";
    }

    return (
        <div className='container'>
            <h3>Product features</h3>
            {renderProduct()}
        </div>
    )
}

export default HomeMobile




// const ComponentA:React.FC  = (prop) => {
//   return <div>
//     abc
//   </div>
// }


// const ComponentInstance: JSX.Element = <ComponentA />