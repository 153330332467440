import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DispatchType } from '../configStore';
import { CategoryModel, Data, RuncodeModel } from '../../Types/runCodeType';
import { runcodeService } from '../../services/runcodeService';

type runcodeState = {
  arrQuestion: RuncodeModel[];
  arrCategory: CategoryModel[];
  javascriptSwitch: boolean;
  pythonSwitch: boolean;
  javaSwitch: boolean;
  cppSwitch: boolean
};

const initialState: runcodeState = {
  arrQuestion: [],
  arrCategory: [],
  javascriptSwitch: false,
  pythonSwitch: false,
  javaSwitch: false,
  cppSwitch: false
};

const runcodeReducer = createSlice({
  name: 'runcodeReducer',
  initialState,
  reducers: {
    setQuestions: (state, action: PayloadAction<RuncodeModel[]>) => {
      state.arrQuestion = action.payload;
    },
    setCategories: (state, action: PayloadAction<CategoryModel[]>) => {
      state.arrCategory = action.payload;
    },
    setJavascriptSwitch: (state, action: PayloadAction<boolean>) => {
      state.javascriptSwitch = action.payload;
    },
    setPythonSwitch: (state, action: PayloadAction<boolean>) => {
      state.pythonSwitch = action.payload;
    },
    setJavaSwitch: (state, action: PayloadAction<boolean>) => {
      state.javaSwitch = action.payload;
    },
    setCppSwitch: (state, action: PayloadAction<boolean>) => {
      state.cppSwitch = action.payload;
    },
  }
});

export const { setQuestions, setCategories, setCppSwitch, setJavaSwitch, setPythonSwitch, setJavascriptSwitch } = runcodeReducer.actions;
export default runcodeReducer.reducer;

// Async actions
export const fetchQuestions = () => {
  return async (dispatch: DispatchType) => {
    try {
      const { data } = await runcodeService.getQuestionList();
      dispatch(setQuestions(data));
    } catch (error) {
      console.error("Error fetching questions:", error);
      // Optionally, you can dispatch an error action here to handle the error in your UI.
    }
  };
};

export const fetchCategories = () => {
  return async (dispatch: DispatchType) => {
    try {
      const { data } = await runcodeService.getCategoryList();
      dispatch(setCategories(data));
    } catch (error) {
      console.error("Error fetching categories:", error);
      // Optionally, you can dispatch an error action here to handle the error in your UI.
    }
  };
};
