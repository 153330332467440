import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { removeStore } from "../util/config";
import { useAppSelector } from "../redux/hooks";
import { nguoiDungService } from "../services/nguoiDungService";
import { Modal } from "antd";
import ModalDoiMatKhau from "./ModalDoiMatKhau/ModalDoiMatKhau";
type Props = {
  switch?:JSX.Element
};

const HeaderHome = (props: Props) => {
  const navigate = useNavigate();
  const { userLogin } = useAppSelector((state) => state.userReducer);
  const [open, setOpen] = useState(false);

  
  return (
    <>
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark">
        <button
          className="navbar-toggler d-lg-none"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavId"
          aria-controls="collapsibleNavId"
          aria-expanded="false"
          aria-label="Toggle navigation"
        />
        <div className="collapse navbar-collapse" id="collapsibleNavId">
          <ul className="navbar-nav me-auto mt-2 mt-lg-0">
            <li className="nav-item">
              <a className="nav-link"> 
              {props?.switch}
              </a>
            </li>

          </ul>
          <ul className="navbar-nav ">
            <li className="nav-item dropdown dropstart">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {userLogin?.hoTen}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><a className="dropdown-item" href="#"   onClick={() => {
                      setOpen(!open);
                    }}> Đổi mật khẩu</a></li>
                <li><a className="dropdown-item" href="#"  onClick={() => {
                      removeStore("USER_LOGIN");
                      removeStore('path')
                      navigate("/login");
                      // nguoiDungService.checkPassNguoiDung();
                    }}> Logout</a></li>
              </ul>
            </li>

            
          </ul>
        </div>
      </nav>
      <ModalDoiMatKhau open={open} setOpen={setOpen} />
    </>
  );
};

export default HeaderHome;
