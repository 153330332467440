import { http } from "../util/config";
import { MentorChamBaiModel, NopBaiModel } from "../Types/nopBaiType"

const GET_PATH = "/api/nopbai";

const GetDSByGtaskId = (gtaskId: number, mentorId: string) => {
    return http.get(`${GET_PATH}/get-ds-gtaskid-mentorid?gtaskid=${gtaskId}&mentorId=${mentorId}`)
}
const mentorChamBai = (mentorId: string, mentorChamBai: MentorChamBaiModel) => {
    return http.put(`${GET_PATH}/mentorchambai?mentorId=${mentorId}`, mentorChamBai)
}
const GetNopBaisByNguoiDungService = (mentorId: string) => {
    return http.get(`${GET_PATH}/mentorchambai?mentorId=${mentorId}`)
}
const GetMentorChambaiViewmodel = (mentorId: string) => {
    return http.get(`${GET_PATH}/mentorchambaiviewmodel?mentorId=${mentorId}`)
}

const kichHoatBaiTap = (model: any) => {
    return http.post(`${GET_PATH}/kich-hoat-baitap`, model);
}

export const nopbaiService = {
    GetDSByGtaskId, mentorChamBai, GetMentorChambaiViewmodel, GetNopBaisByNguoiDungService,
    kichHoatBaiTap
}