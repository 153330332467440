import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { baiHocService } from "../../services/baiHocService";
import { http } from "../../util/config";
import { DispatchType } from "../configStore";
import { BaiHocModel, BaiHocViewMentorModel } from "../../Types/baiHocType";
import moment from "moment";

type baiHocState = {
  arrBaiHoc: BaiHocModel[] | null;
  dsBaiHocByDsId:BaiHocViewMentorModel[]
  dsIdBaiHoc:number[]
};
const initialState: baiHocState = {
  arrBaiHoc: [],
  dsBaiHocByDsId:[],
  dsIdBaiHoc:[]
};

const baiHocReducer = createSlice({
  name: "baiHocReducer",
  initialState,
  reducers: {
    getBaiHocAction: (
      state: baiHocState,
      action: PayloadAction<BaiHocModel[]>
    ) => {
      state.arrBaiHoc = [...action.payload];
    },
    getBaiHocByListId:(state:baiHocState,action:PayloadAction<BaiHocViewMentorModel[]>)=>{
      state.dsBaiHocByDsId=[...state.dsBaiHocByDsId,...action.payload]
      state.dsIdBaiHoc=[...state.dsBaiHocByDsId,...action.payload].map(e=>e.id)
    }
  },
}); 

export const { getBaiHocAction,getBaiHocByListId } = baiHocReducer.actions;
export default baiHocReducer.reducer;
//--------- action async ------------

export const getBaiHocApi = () => {
  return async (dispatch: DispatchType) => {
   try {
    const result = await baiHocService.layDanhSachBaiHoc();
    const action: PayloadAction<BaiHocModel[]> = getBaiHocAction(
      result.data.content
    );
    dispatch(action);
   } catch (error) {
    console.log('getBaiHocApi',error);
   }
  };
};

export const getBaiHocByListIdApi = (ds:number[]) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await baiHocService.postDanhSachBaiHocByListId(ds);

    const action: PayloadAction<BaiHocViewMentorModel[]> = getBaiHocByListId(
      result.data.content
    );
    dispatch(action);
    } catch (error) {
      console.log('getBaiHocByListIdApi',error);
    }
  };
}; 
