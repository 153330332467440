import { Col, Input, Row, Switch, Tag } from 'antd'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import EditorJS from './Editor/EditorJS';
import EditorPY from './Editor/EditorPY';
import EditorJava from './Editor/EditorJava';
import EditorCPP from './Editor/EditorCPP';
import { LanguageData } from '../../Types/runCodeType';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/configStore';
import { useDispatch } from 'react-redux';
import { setCppSwitch, setJavaSwitch, setJavascriptSwitch, setPythonSwitch } from '../../redux/RunCodeReducer/runcodeReducer';

const { TextArea } = Input;

type TypeData = {
  [key: string]: any
}

type Props = {
  data: LanguageData[],
  dataId: number,
  setData: Dispatch<SetStateAction<LanguageData[] | any>>
}

const CodeAndSolution = ({ data, dataId, setData }: Props) => {
  const dispatch = useDispatch();

  const { javascriptSwitch, pythonSwitch, javaSwitch, cppSwitch } = useSelector((state: RootState) => state.runcodeReducer)

  const [functionName, setFunctionName] = useState<string>("");

  const handleFunctionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFunctionName(event.target.value.trim());
  };

  function generateFunctionName(language: string, nameFunction?: string) {
    let baseCode = '';
    let functionCode = '';
    let answerCode = '';

    if (nameFunction) {
      switch (language) {
        case 'js':
          baseCode = `__function__\nconsole.log(${nameFunction}(__test_case__));`;
          functionCode = `function ${nameFunction}() {\n  // Your code here\n}`;
          answerCode = `function ${nameFunction}() {\n  // Your code here\n}`;
          break;
        case 'py':
          baseCode = `__function__\nprint(${nameFunction}(__test_case__))`;
          functionCode = `def ${nameFunction}():\n  # Your code here`;
          answerCode = `def ${nameFunction}():\n  # Your code here\n`;
          break;
        case 'java':
          baseCode = `import java.util.*;\nclass Main {\n  public static void main(String[] args) {\n    System.out.println(${nameFunction}(__test_case__));\n  }\n  __function__ \n}`;
          functionCode = `public static int ${nameFunction}() {\n    // Your code here\n    return 0;\n  }`;
          answerCode = `${functionCode}`;
          break;
        case 'cpp':
          baseCode = `#include <iostream>\nusing namespace std;\n\n__function__\n\nint main() {\n  cout << ${nameFunction}(__test_case__);\n  return 0;\n}`;
          functionCode = `int ${nameFunction}() {\n  // Your code here\n  return 0;\n}`;
          answerCode = `${functionCode}`;
          break;
        default:
          // console.log('Invalid Language');
          break;
      }
    }

    return {
      baseCode,
      functionCode,
      answerCode,
    };
  }

  const onChangeTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const [property, language] = name.split('_') as [string, string];
    setData((prevData: LanguageData[]) => {
      const newData = [...prevData];
      const languageIndex = newData.findIndex((item) => item._language === language);
      if (languageIndex !== -1) {
        newData[languageIndex] = {
          ...newData[languageIndex],
          [property]: value
        };
      }
      return newData;
    });
  };

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const [property, language] = name.split('_') as [string, string];
    setData((prevData: LanguageData[]) => {
      const newData = [...prevData];
      const languageIndex = newData.findIndex((item) => item._language === language);
      if (languageIndex !== -1) {
        newData[languageIndex] = {
          ...newData[languageIndex],
          [property]: value
        };
      }
      return newData;
    });
  };



  const handleEditorChange = (language: string, key: string, value: string) => {
    setData((prevData: LanguageData[]) => {
      const updatedData = prevData.map((item) => {
        if (item._language === language) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  }

  const handleSwitchToggle = (language: string) => {
    let languageIndex = data.findIndex(obj => obj._language === language);

    switch (language) {
      case 'js':
        dispatch(setJavascriptSwitch(!javascriptSwitch));
        if (!javascriptSwitch) {
          let { baseCode, functionCode, answerCode } = generateFunctionName('js', functionName);
          if (languageIndex !== -1) {
            setData(data.map((obj, index) => index === languageIndex ? { ...obj, base_code: baseCode, _function: functionCode, _answer: answerCode } : obj));
          } else {
            setData([{ _language: 'js', tle: 0, base_code: baseCode, _function: functionCode, _answer: answerCode, hint: '', videoSolution: '' }, ...data]);
          }
        }
        break;
      case 'py':
        dispatch(setPythonSwitch(!pythonSwitch));
        if (!pythonSwitch) {
          let { baseCode, functionCode, answerCode } = generateFunctionName('py', functionName);
          if (languageIndex !== -1) {
            setData(data.map((obj, index) => index === languageIndex ? { ...obj, base_code: baseCode, _function: functionCode, _answer: answerCode } : obj));
          } else {
            setData([{ _language: 'py', tle: 0, base_code: baseCode, _function: functionCode, _answer: answerCode, hint: '', videoSolution: '' }, ...data]);
          }
        }
        break;
      case 'java':
        dispatch(setJavaSwitch(!javaSwitch));
        if (!javaSwitch) {
          let { baseCode, functionCode, answerCode } = generateFunctionName('java', functionName);
          if (languageIndex !== -1) {
            setData(data.map((obj, index) => index === languageIndex ? { ...obj, base_code: baseCode, _function: functionCode, _answer: answerCode } : obj));
          } else {
            setData([{ _language: 'java', tle: 0, base_code: baseCode, _function: functionCode, _answer: answerCode, hint: '', videoSolution: '' }, ...data]);
          }
        }
        break;
      case 'cpp':
        dispatch(setCppSwitch(!cppSwitch));
        if (!cppSwitch) {
          let { baseCode, functionCode, answerCode } = generateFunctionName('cpp', functionName);
          if (languageIndex !== -1) {
            setData(data.map((obj, index) => index === languageIndex ? { ...obj, base_code: baseCode, _function: functionCode, _answer: answerCode } : obj));
          } else {
            setData([{ _language: 'cpp', tle: 0, base_code: baseCode, _function: functionCode, _answer: answerCode, hint: '', videoSolution: '' }, ...data]);
          }
        }
        break;
      default:
        break;
    }
  };






  useEffect(() => {
    // Check and update data
    if (data.some(obj => obj._language === 'js')) {
      dispatch(setJavascriptSwitch(true));
    }
    else {
      dispatch(setJavascriptSwitch(false));
    }
    if (data.some(obj => obj._language === 'py')) {
      dispatch(setPythonSwitch(true));
    } else {
      dispatch(setPythonSwitch(false));
    }
    if (data.some(obj => obj._language === 'java')) {
      dispatch(setJavaSwitch(true));
    } else {
      dispatch(setJavaSwitch(false));
    }
    if (data.some(obj => obj._language === 'cpp')) {
      dispatch(setCppSwitch(true));
    } else {
      dispatch(setCppSwitch(false));
    }
  }, [data, dataId]);


  return (
    <div className="container-fluid mt-4">
      <Row className='mb-4'>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Input
            value={functionName}
            onChange={handleFunctionNameChange}
            placeholder="Nhập tên function để tạo"
            style={{
              width: '100%',
            }}
          />
        </Col>
      </Row>
      <div className="row justify-content-center">
        <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title d-flex justify-content-between align-items-center">
                <Tag className='fs-5 p-1' color="blue">JavaScript</Tag>
                <Switch checked={javascriptSwitch} onChange={() => handleSwitchToggle('js')} />
              </h2>
              {javascriptSwitch && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Base Code:</label>
                      <EditorJS
                        onChange={(value: any) => handleEditorChange('js', 'base_code', value)}
                        value={(data.find((item) => item._language === 'js'))?.base_code || ''}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Function:</label>
                      <EditorJS
                        onChange={(value: any) => handleEditorChange('js', '_function', value)}
                        value={(data.find((item) => item._language === 'js'))?._function || ''}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Answer:</label>
                      <EditorJS
                        onChange={(value: any) => handleEditorChange('js', '_answer', value)}
                        value={(data.find((item) => item._language === 'js'))?._answer || ''}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='fs-6 fw-bold'>Video Solution:</label>
                      <Input
                        onChange={onChangeInput}
                        name="videoSolution_js"
                        value={data.find((item) => item._language === 'js')?.videoSolution || ''}
                      />
                    </Col>
                    <Col span={24}>
                      <label className='fs-6 fw-bold'>Hint:</label>
                      <TextArea
                        onChange={onChangeTextArea}
                        name="hint_js"
                        value={data.find((item) => item._language === 'js')?.hint || ''}
                      />
                    </Col>
                  </Row>

                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title d-flex justify-content-between align-items-center">
                <Tag className='fs-5 p-1' color="blue">Java</Tag>
                <Switch checked={javaSwitch} onChange={() => handleSwitchToggle('java')} />
              </h2>
              {javaSwitch && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Base Code:</label>
                      <EditorJava
                        onChange={(value: any) => handleEditorChange('java', 'base_code', value)}
                        value={(data.find((item) => item._language === 'java'))?.base_code || ''}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Function:</label>
                      <EditorJava
                        onChange={(value: any) => handleEditorChange('java', '_function', value)}
                        value={(data.find((item) => item._language === 'java'))?._function || ''}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Answer:</label>
                      <EditorJava
                        onChange={(value: any) => handleEditorChange('java', '_answer', value)}
                        value={(data.find((item) => item._language === 'java'))?._answer || ''}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='fs-6 fw-bold'>Video Solution:</label>
                      <Input
                        onChange={onChangeInput}
                        name="videoSolution_java"
                        value={data.find((item) => item._language === 'java')?.videoSolution || ''}
                      />
                    </Col>
                    <Col span={24}>
                      <label className='fs-6 fw-bold'>Hint:</label>
                      <TextArea
                        onChange={onChangeTextArea}
                        name="hint_java"
                        value={data.find((item) => item._language === 'java')?.hint || ''}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title d-flex justify-content-between align-items-center">
                <Tag className='fs-5 p-1' color="blue">Python</Tag>
                <Switch checked={pythonSwitch} onChange={() => handleSwitchToggle('py')} />
              </h2>
              {pythonSwitch && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Base Code:</label>
                      <EditorPY
                        onChange={(value: any) => handleEditorChange('py', 'base_code', value)}
                        value={(data.find((item) => item._language === 'py'))?.base_code || ''}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Function:</label>
                      <EditorPY
                        onChange={(value: any) => handleEditorChange('py', '_function', value)}
                        value={(data.find((item) => item._language === 'py'))?._function || ''}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Answer:</label>
                      <EditorPY
                        onChange={(value: any) => handleEditorChange('py', '_answer', value)}
                        value={(data.find((item) => item._language === 'py'))?._answer || ''}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='fs-6 fw-bold'>Video Solution:</label>
                      <Input
                        onChange={onChangeInput}
                        name="videoSolution_py"
                        value={data.find((item) => item._language === 'py')?.videoSolution || ''}
                      />
                    </Col>
                    <Col span={24}>
                      <label className='fs-6 fw-bold'>Hint:</label>
                      <TextArea
                        onChange={onChangeTextArea}
                        name="hint_py"
                        value={data.find((item) => item._language === 'py')?.hint || ''}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title d-flex justify-content-between align-items-center">
                <Tag className='fs-5 p-1' color="blue">C++</Tag>
                <Switch checked={cppSwitch} onChange={() => handleSwitchToggle('cpp')} />
              </h2>
              {cppSwitch && (
                <>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Base Code:</label>
                      <EditorCPP
                        onChange={(value: any) => handleEditorChange('cpp', 'base_code', value)}
                        value={(data.find((item) => item._language === 'cpp'))?.base_code || ''}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Function:</label>
                      <EditorCPP
                        onChange={(value: any) => handleEditorChange('cpp', '_function', value)}
                        value={(data.find((item) => item._language === 'cpp'))?._function || ''}
                      />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                      <label className='fs-6 fw-bold'>Answer:</label>
                      <EditorCPP
                        onChange={(value: any) => handleEditorChange('cpp', '_answer', value)}
                        value={(data.find((item) => item._language === 'cpp'))?._answer || ''}
                      />
                    </Col>
                  </Row>


                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <label className='fs-6 fw-bold'>Video Solution:</label>
                      <Input
                        onChange={onChangeInput}
                        name="videoSolution_cpp"
                        value={data.find((item) => item._language === 'cpp')?.videoSolution || ''}
                      />
                    </Col>
                    <Col span={24}>
                      <label className='fs-6 fw-bold'>Hint:</label>
                      <TextArea
                        onChange={onChangeTextArea}
                        name="hint_cpp"
                        value={data.find((item) => item._language === 'cpp')?.hint || ''}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>

  )

}

export default CodeAndSolution;

