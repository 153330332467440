import { Button, Input, message, Modal, Popconfirm, Segmented, Select, Table, Tag } from 'antd'
import { SegmentedValue } from 'antd/es/segmented'
import { ColumnsType } from 'antd/es/table'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getBaiHocApi } from '../../redux/BaiHocReducer/BaiHocReducer'
import { DispatchType, RootState } from '../../redux/configStore'
import { baiHocService } from '../../services/baiHocService'
import { BaiHocModel } from '../../Types/baiHocType'

import { getListTag, listLoaiTaskCreate } from '../../util/SelectOption'
import { runcodeService } from '../../services/runcodeService'
import { fetchQuestions } from '../../redux/RunCodeReducer/runcodeReducer'
import { SelectValue } from 'antd/es/tree-select'
import moment from 'moment'
import { history } from '../..'
import RuncodeSource from '../Runcode/RuncodeSource'
import { RuncodeModel } from '../../Types/runCodeType'

type Props = {
  opSegmented: listType[]
  setTaskDetail: (obj: BaiHocModel) => void
  setRuncodeDetail: Dispatch<SetStateAction<RuncodeModel>>
  setOpen: Dispatch<SetStateAction<boolean>>
  taskDetail: BaiHocModel
  arrBaiHoc: BaiHocModel[]
}

type listType = {
  value: string | number
  label: string | JSX.Element
}

const DanhSachTask = ({ opSegmented, setTaskDetail, setRuncodeDetail, setOpen, arrBaiHoc, taskDetail }: Props) => {
  // const { arrBaiHoc } = useSelector((state: RootState) => state.baiHocReducer);
  let [searchParams, setSearchParams] = useSearchParams()
  const [maLoaiLoc, setMaLoaiLoc] = useState<SegmentedValue>('')


  const dispatch: DispatchType = useDispatch()
  const { arrTag } = useSelector((state: RootState) => state.chuyenDeReducer)
  const { arrQuestion } = useSelector((state: RootState) => state.runcodeReducer)
  const { arrCategory } = useSelector((state: RootState) => state.runcodeReducer)

  const [isModalRuncodeSource, setIsModalRuncodeSource] = useState(false)

  const modalRuncodeSourceClose = () => {
    setIsModalRuncodeSource(false)
  }

  // xoa cau hoi
  const xoaTask = (id: number) => {
    baiHocService
      .xoaBaiHoc(id)
      .then((res) => {
        dispatch(getBaiHocApi())

        message.success('Xóa thành công')
      })
      .catch((err) => {
        message.error('Lỗi Báo IT')
      })
  }

  // xoa question (runcode)
  const xoaCauHoi = (id: number, question_id: number) => {
    baiHocService
      .xoaBaiHoc(id)
      .then((res) => {
        runcodeService
          .deleteQuestion(question_id)
          .then((res) => {
            dispatch(fetchQuestions())
            dispatch(getBaiHocApi())
            message.success('Xóa thành công')
          })
          .catch((err) => {
            message.error('Lỗi Báo IT')
          })
      })
      .catch((err) => {
        message.error('Lỗi Báo IT')
      })
  }

  const columns: ColumnsType<BaiHocModel> = [
    {
      key: 'id',
      title: 'Id',
      dataIndex: 'id'
    },
    {
      key: 'tenBaiHoc',
      title: 'Tiêu đề',
      dataIndex: 'tenBaiHoc'
    },
    {
      key: 'capDo',
      title: 'Level',
      dataIndex: 'capDo'
    },
    {
      key: 'thoiLuong',
      title: 'Thời lượng',
      render: (_: any, record: BaiHocModel) => {
        let loaiTask = listLoaiTaskCreate.find((n) => n.value == record.maLoai)
        if (loaiTask)
          return (
            <Tag>
              {record.thoiLuong} {loaiTask.time}
            </Tag>
          )
      }
    },
    {
      title: 'Loại task',
      render: (_, record: BaiHocModel) => {
        let loaiTask = listLoaiTaskCreate.find((n) => n.value == record.maLoai)
        if (loaiTask) return <Tag color='blue'>{loaiTask.label}</Tag>
      }
    },
    {
      title: 'Action',
      render: (_, record: BaiHocModel) => {
        return (
          <>
            <button
              className='btn btn-sm btn-success mx-2'
              onClick={() => {
                setOpen(true)
                setTaskDetail(record)
              }}
            >
              <i className='fas fa-edit'></i>
            </button>
            <Popconfirm title='Bạn có chắc muốn xóa ?' onConfirm={() => xoaTask(record.id)}>
              <button className='btn btn-sm btn-danger'>
                <i className='fas fa-trash'></i>
              </button>
            </Popconfirm>
          </>
        )
      }
    }
  ]

  const columns_runcode: ColumnsType<BaiHocModel> = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'tenBaiHoc',
      key: 'tenBaiHoc'
    },
    {
      title: 'Level',
      dataIndex: 'capDo'
    },
    {
      title: 'Thời lượng',
      render: (_: any, record: BaiHocModel) => {
        let loaiTask = listLoaiTaskCreate.find((n) => n.value == record.maLoai)
        if (loaiTask)
          return (
            <Tag>
              {record.thoiLuong} {loaiTask.time}
            </Tag>
          )
      }
    },
    {
      title: 'Loại task',
      render: (_, record: BaiHocModel) => {
        let loaiTask = listLoaiTaskCreate.find((n) => n.value == record.maLoai)
        if (loaiTask) return <Tag color='blue'>{loaiTask.label}</Tag>
      }
    },
    {
      title: 'Category',
      dataIndex: 'categories',
      key: 'categories',
      width: 200,
      align: 'center',
      render: (_: any, record: BaiHocModel) => {
        let runcodeId = record.noiDung && JSON.parse(record.noiDung)[0]
        let question = arrQuestion.find((item) => item.question_id === runcodeId)
        return (
          question && (
            <div key={question.id}>
              {question.categories?.map((item) => (
                <Tag
                  color='blue'
                  key={item?.id + ' key'}
                  className='my-1'
                  style={{ width: '100px', textAlign: 'center' }}
                >
                  {item?.name ? (item.name.length > 15 ? item.name.substring(0, 13) + '...' : item.name) : ''}
                </Tag>
              ))}
            </div>
          )
        )
      }
    },
    {
      title: 'Ngôn ngữ hỗ trợ',
      dataIndex: 'init_code',
      align: 'center',
      width: 200,
      render: (_: any, record: BaiHocModel) => {
        let runcodeId = record.noiDung && JSON.parse(record.noiDung)[0]

        let question = arrQuestion.find((item) => item.question_id === runcodeId)

        return (
          question && (
            <div key={record.id}>
              {question.init_code?.map((lang) => (
                <Tag
                  color='blue'
                  key={lang?._language}
                  className='my-1'
                  style={{ width: '100px', textAlign: 'center' }}
                >
                  {lang?._language}
                </Tag>
              ))}
            </div>
          )
        )
      }
    },
    {
      title: 'Action',
      align: 'center',
      render: (_: any, record: BaiHocModel) => {
        let { id } = record

        let runcodeId = record.noiDung && JSON.parse(record.noiDung)[0]

        return (
          record && (
            <>
              <button
                className='btn btn-sm btn-success mx-2'
                onClick={() => {
                  if (id !== taskDetail.id) {
                    setTaskDetail(record)
                  }
                  setOpen(true)
                }}

              >
                <i className='fas fa-edit'></i>
              </button>
              <Popconfirm title='Bạn có chắc muốn xóa ?' onConfirm={() => xoaCauHoi(id, runcodeId)}>
                <button className='btn btn-sm btn-danger'>
                  <i className='fas fa-trash'></i>
                </button>
              </Popconfirm>
              <button
                className='btn btn-sm btn-info mx-2'
                onClick={() => {
                  history.push(`/preview/${runcodeId}`)
                }}
              >
                <i className='fa fa-eye'></i>
              </button>
              <p className='text-xs mt-3'>{moment(record.ngayTao).format('DD/MM/YY')}</p>
            </>
          )
        )
      }
    }
  ]

  const headerRow = [
    {
      key: 'header',
      render: () => (
        <th>
          <Button onClick={() => console.log('first')} type='primary'>
            Thêm Dữ Liệu
          </Button>
        </th>
      )
    },
    // Các phần tử tiêu đề cột khác
    ...columns_runcode.map((column) => ({
      key: column.key,
      props: {}
    }))
  ]

  let dataTask = [...(arrBaiHoc ?? [])]

  let tieuDe = searchParams.get('td')
  let tagFilter = searchParams.get('tag')
  let idCauHoi = searchParams.get('idCauHoi')

  dataTask =
    dataTask && dataTask?.filter((n) => n.tenBaiHoc?.toLowerCase().indexOf(tieuDe ? tieuDe.toLowerCase() : '') != -1)

  if (maLoaiLoc != '') {
    dataTask = dataTask && dataTask?.filter((n) => n.maLoai == maLoaiLoc)
  }

  let cusOpSegmented = [
    {
      label: <> Tất cả</>,
      value: ''
    },
    ...opSegmented
  ]

  let cusListTag = [
    { label: 'Tất cả', value: 'n' },
    { label: 'Chưa có tag', value: '' },
    ...getListTag(arrTag, 'EXTEND')
  ]

  if (tagFilter != 'n') {
    if (tagFilter == '') dataTask = dataTask && dataTask.filter((n) => n.tag == '[]')
    else dataTask = dataTask && dataTask.filter((n) => n.tag?.indexOf(tagFilter ? tagFilter : '') != -1)
  }

  if (idCauHoi) {
    dataTask = dataTask && dataTask.filter((n) => n.id == Number(idCauHoi))
  }

  // let dataRuncode = arrQuestion;

  // if (maLoaiLoc === "RUNCODE" && dataTask) {
  //   let noiDung = dataTask?.map(task => {
  //     let data = { id: task.id, question_id: Number(task.noiDung && JSON.parse(task.noiDung)[0]) }
  //     return data
  //   })

  //   dataRuncode = arrQuestion?.filter(runcode => noiDung.some(data => data.question_id === runcode.question_id))

  //   // Gộp các trường 'id' từ 'noiDung' vào 'dataRuncode'
  //   dataRuncode = dataRuncode.map(runcode => {
  //     const correspondingData = noiDung.find(data => data.question_id === runcode.question_id)
  //     if (correspondingData) {
  //       return { ...runcode, id: correspondingData.id }
  //     }
  //     return runcode
  //   })

  //   // Sắp xếp lại mảng 'dataRuncode' theo thuộc tính 'id'
  //   dataRuncode.sort((a, b) => a.id - b.id);

  // }

  // let languageFilter: any = null;
  // let categoryFilter: any = null;

  // if (tieuDe) {
  //   dataRuncode =
  //     dataRuncode &&
  //     dataRuncode?.filter(
  //       (question) =>
  //         question?.vi?.question_title
  //           ?.toLowerCase()
  //           .indexOf(tieuDe ? tieuDe.toLowerCase() : "") != -1 ||
  //         question?.en?.question_title
  //           ?.toLowerCase()
  //           .indexOf(tieuDe ? tieuDe.toLowerCase() : "") != -1

  //     );
  // }

  // if (languageFilter) {
  //   dataRuncode =
  //     dataRuncode &&
  //     dataRuncode.filter((question) =>
  //       question?.init_code?.some((lang) => lang?._language?.toLowerCase() === languageFilter?.toLowerCase())
  //     );
  // }

  // if (categoryFilter) {
  //   dataRuncode =
  //     dataRuncode &&
  //     dataRuncode.filter((question) =>
  //       question?.categories?.some((category) => category.name === categoryFilter)
  //     );
  // }

  return (
    <>
      <Segmented
        size='large'
        options={cusOpSegmented}
        value={maLoaiLoc}
        onChange={(value: SegmentedValue) => {
          setMaLoaiLoc(value)
          searchParams.delete('idCauHoi')
        }}
      />
      <div className=' row'>
        <div className='col-2 py-2'>
          <label className='form-label'>Lọc tiêu đề: </label>

          <div className='input-group mb-3'>
            <input id='txt-search' type='text' className='form-control' placeholder='Tiêu đề' />
            <button
              className='btn btn-primary'
              onClick={() => {
                let element: any = document.querySelector('#txt-search')
                searchParams.set('td', element.value)
                setSearchParams(searchParams)
              }}
            >
              Search
            </button>
          </div>
        </div>

        {maLoaiLoc !== 'RUNCODE' ? (
          <div className='col-2 py-2'>
            <label className='form-label'>Lọc Tag: </label>
            <Select
              style={{ width: '100%' }}
              showSearch
              placeholder='Chọn tag skill'
              optionFilterProp='children'
              onChange={(value) => {
                searchParams.set('tag', value)
                setSearchParams(searchParams)
              }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={cusListTag}
            />
          </div>

        ) : (
          // <Select
          //   style={{ width: '100%' }}
          //   showSearch
          //   placeholder='Chọn tag skill'
          //   optionFilterProp='children'
          //   onChange={(value: SelectValue, option: any) => {
          //     searchParams.set('tag', JSON.stringify(option))
          //     setSearchParams(searchParams)
          //   }}
          //   filterOption={(input, option) => {
          //     return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          //   }}
          //   options={[
          //     { label: 'Category', options: arrCategoryOptions },
          //     { label: 'Language', options: arrLanguageOptions }
          //   ]}
          // />
          <></>
        )}
        <div className='col-12'>
          {dataTask && (
            <>
              {maLoaiLoc === 'RUNCODE' ? (
                <Button className='mb-2' onClick={() => setIsModalRuncodeSource(true)}>
                  + Nguồn
                </Button>
              ) : (
                <></>
              )}

              <Table columns={maLoaiLoc === 'RUNCODE' ? columns_runcode : columns} dataSource={dataTask} />
            </>
          )}
        </div>
      </div>
      <Modal open={isModalRuncodeSource} onCancel={modalRuncodeSourceClose} onOk={modalRuncodeSourceClose} width='80%' centered>
        <RuncodeSource />
      </Modal>
    </>
  )
}

export default DanhSachTask
