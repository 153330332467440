import styled from 'styled-components'

export const DrawerBodyCss = styled.section`
  .inputSearch {
    .ant-input {
      background: radial-gradient(215.31% 215.31% at 57.96% -48.44%, #b1b1b1 0%, rgba(177, 177, 177, 0) 100%);
      color: white;
      ::placeholder {
        color: #b1b1b1;
      }
      :focus {
        border-color: #d9d9d9;
      }
    }
    button.ant-btn {
      background: radial-gradient(215.31% 215.31% at 57.96% -48.44%, #b1b1b1 0%, rgba(177, 177, 177, 0) 100%);
      /* color: white; */
      span.ant-btn-icon {
        color: white;
        font-size: 20px;
      }
    }
    :hover button.ant-btn,
    :hover .ant-input {
      border-color: #dbb845;
      span.ant-btn-icon {
        color: #dbb845;
      }
    }
  }
  .dschat {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 1px;
    gap: 2px;
    cursor: pointer;

    .person {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      width: 100%;
      padding: 15px 10px;
      border: 1px solid #dbb845;
      transition: all 0.5s;
      cursor: pointer;

      /* :hover {
        border: 2px dashed #dbb845;
        background: linear-gradient(
          rgb(212, 149, 72) 0%,
          rgb(225, 176, 110) 45.9%,
          rgb(140, 93, 49) 81.45%,
          rgb(192, 118, 57) 119.74%
        );
        .person__name {
          color: black;
        }
        .icon {
          color: blue;
        }
      } */
      .person__name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        

        color: #ffffff;
      }
      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        .anticon {
          font-size: 20px;
        }
        .anticon-exclamation {
          transform: skew(-20deg);
        }
      }
      
      .icon.mess {
        .anticon {
          font-size: 20px;
          color: #dbb845;
        }
      }
    }
  }
`

export const TableCSS = styled.div`
  

  .ant-table-wrapper {
    .ant-table-tbody{ 
      .ant-table-cell.ant-table-row-expand-icon-cell{
        color: var(--color-bg);
      }
      .ant-table-placeholder{
      background-color: var(--color-bg);
      border-color: var(--color-sub-border);

        

      .ant-table-cell {
        border-left: 1px solid var(--color-sub-border);
        border-color: var(--color-sub-border);
       :hover{
        background-color: var(--color-sub-bg);
       }
    }
  }
    }
  
    .ant-table-container {
      .ant-table-cell {
        background-color: var(--color-bg);
        border-color: var(--color-sub-border);
        color: white;
        border-right: 1px solid var(--color-sub-border);
        /* border: none; */

        .ant-empty-description {
          color: white;
        }
      }
      .ant-table-cell.ant-table-cell-row-hover {
        background-color: var(--color-sub-bg);
      }

      .ant-table-thead {
        .ant-table-cell {
          background-color: var(--color-main-title);
          color: white;
          ::before{
           display: none;
          }
          :hover{
            opacity: 0.9;
          }
        }
      }
      
     
    }
    
  }
  .ant-pagination-item.ant-pagination-item-active{
    background: var(--color-main-title);

    .ant-collapse-content.ant-collapse-content-active{
      border-top: none;
    }
  }
  .ant-spin-container{ .anticon{
        color: white;
      }}
  .ant-pagination-prev{
    .ant-pagination-item-link{
      span{
        display: none;
      }
      ::before{
        content: "Previous";
        color: white;
      }
    }
  }
  .ant-pagination-next{
    .ant-pagination-item-link{
      span{
        display: none;
      }
      ::before{
        content: "Next";
        color: white;
      }
    }
  }
  .ant-pagination-disabled{
    opacity: 0.5;
  }

  .ant-pagination-item{
    color: white;
  }
  .ant-pagination-item-active{
    color: white;
  }
  .ant-collapse-content{
    border-top: none;
  }

  
`

