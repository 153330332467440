import { CreatePracticeModel, PracticeModel } from "../Types/practiceType";
import { http } from "../util/config";

const GET_PATH: string = '/api/practice/';

const layDanhSachPractice = () => {
  return http.get(GET_PATH);
}
const themPractice = (data: CreatePracticeModel | any) => {
  return http.post(GET_PATH, data);
}
const suaPractice = (data: PracticeModel | any) => {
  return http.put(GET_PATH, data);
}
const xoaPractice = (id: number) => {
  return http.delete(`${GET_PATH}/${id}`);
}

export const practiceService = {
  layDanhSachPractice,
  themPractice,
  suaPractice,
  xoaPractice
}
