import { StudentCSS } from "../../../assets/style/StudentCss";

const Student = () => {
  return (
    <StudentCSS>
      <div className="top">
        <div className="overview">
          <div className="overview__left col-5">
            <div className="title">
              <i className="fa-regular fa-star" style={{ color: "#ffffff" }} />

              <span>Tổng Quan Về Tình Hình Phụ Trách Học Viên</span>
            </div>
            <div className="event">
              <div className="title">
                <i
                  className="fa-light fa-calendar-week"
                  style={{ color: "#ffffff" }}
                />
                <span> Các Sự Kịene Gần Đây</span>
              </div>
              <div className="content">
                <div className="item">
                    <span>Học Viên <span style={{color:'#EEBE47'}}>Lưu Sơn Bá </span> Join Front End Từ Zero Tới Đi làm</span>
                    <span>30/05/2023</span>
                </div>
              </div>
            </div>
          </div>
          <div className="overview__right col-7"></div>
        </div>
      </div>
      <div className="bottom"></div>
    </StudentCSS>
  );
};
export default Student;
