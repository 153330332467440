import { Select, Tabs, TabsProps, Tag, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import { API_URL_DOMAIN, URL_PAGE_LEARN } from '../../util/urlDomain';

// import * as jwt from 'jose';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/configStore';

import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Space, Table } from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import { dinhDangNgay } from '../../util/dateFormat';
import { BaiTestModel } from '../../Types/heThongType';
import { heThongService } from '../../services/heThongService';
import { useNavigate } from 'react-router-dom';

type Props = {

}

const BaiTest = ({ }: Props) => {

    // search
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: any,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Tìm
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible: any) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text: any) =>
            searchedColumn === dataIndex ? (
                <b>{searchText}</b>
            ) : (
                text
            ),
    });
    const getLabelLop = (value: string) => {
        switch (value) {
            case "CS3":
                return "Lớp CS3";

            case "CS2":
                return "Lớp CS2";

            case "CS1":
                return "Lớp CS1";

            default:
                return "Không đủ điều kiện";

                break;
        }

    }
    //end search


    const [lopHoc, setLopHoc] = useState("0");
    const { arrBaiTest } = useSelector((state: RootState) => state.heThongReducer);


    // duyet phan thu dua vao dung group 
    // LOGICAL, GMAT, CAREER,  PYTHON_ADV, PYTHON_BASIC, JS_ES6, JS_OOP

    // PYTHON_CAP2 => tổng >=7
    // PYTHON_HV_OLD => PYTHON_ADV=> 8/10, PYTHON_BASIC: 8/9, GMAT, CAREER: 5/6
    // JS_HV_OLD  => JS_OOP=> 8/10, JS_ES6: 8/9, GMAT, CAREER: 5/6
    // JV_HV_OLD => OOP 4/5 , ALGO 4/5 => CS3 , CORE 8/9  => CS2

    let newArrBaiTest = arrBaiTest?.map((item: any) => {
        let thongTinThem = JSON.parse(item.thongTinThem);
        switch (thongTinThem.maBaiTest) {
            case "PYTHON_CAP2": {
                let { noiDungBaiLam } = JSON.parse(item.linkNopBai);

                if (noiDungBaiLam && noiDungBaiLam.length > 0) {


                    let dem = noiDungBaiLam.filter((n: any) => n.luaChon == true).length;
                    if (dem >= 7) {
                        return { ...item, lopHoc: "CS1" };
                    }


                    return { ...item, lopHoc: "CS" }
                }
                break;
            }
            case "PYTHON_HV_OLD": {
                let { noiDungBaiLam } = JSON.parse(item.linkNopBai);

                if (noiDungBaiLam && noiDungBaiLam.length > 0) {

                    //dem OOP
                    let dem = noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "PYTHON_ADV" && n.luaChon == true).length;
                    if (dem >= 8) {
                        return { ...item, lopHoc: "CS3" };
                    }

                    // dem  ES6
                    dem = noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "PYTHON_BASIC" && n.luaChon == true).length;
                    if (dem >= 8) {
                        return { ...item, lopHoc: "CS2" };
                    }
                    // dem career
                    dem = noiDungBaiLam.filter((n: any) => (n.tieuDe.tag == "GMAT" || n.tieuDe.tag == "CAREER") && n.luaChon == true).length;
                    if (dem >= 5) {
                        return { ...item, lopHoc: "CS1" };
                    }

                    return { ...item, lopHoc: "CS" }
                }
                break;

            }
            case "JS_HV_OLD": {
                let { noiDungBaiLam } = JSON.parse(item.linkNopBai);

                if (noiDungBaiLam && noiDungBaiLam.length > 0) {

                    //dem OOP
                    let dem = noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "JS_OOP" && n.luaChon == true).length;
                    if (dem >= 8) {
                        return { ...item, lopHoc: "CS3" };
                    }

                    // dem  ES6
                    dem = noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "JS_ES6" && n.luaChon == true).length;
                    if (dem >= 8) {
                        return { ...item, lopHoc: "CS2" };
                    }
                    // dem career
                    dem = noiDungBaiLam.filter((n: any) => (n.tieuDe.tag == "GMAT" || n.tieuDe.tag == "CAREER") && n.luaChon == true).length;
                    if (dem >= 5) {
                        return { ...item, lopHoc: "CS1" };
                    }

                    return { ...item, lopHoc: "CS" }
                }
                break;

            }

            case "JV_HV_OLD": {
                let { noiDungBaiLam } = JSON.parse(item.linkNopBai);

                if (noiDungBaiLam && noiDungBaiLam.length > 0) {

                    //dem OOP
                    let dem = noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "JV_OOP" && n.luaChon == true).length;
                    if (dem >= 4) {
                        return { ...item, lopHoc: "CS3" };
                    }

                    // dem  thuat toan
                    dem = noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "JV_ALGO" && n.luaChon == true).length;
                    if (dem >= 4) {
                        return { ...item, lopHoc: "CS3" };
                    }

                    // dem  core
                    dem = noiDungBaiLam.filter((n: any) => n.tieuDe.tag == "JV_CORE" && n.luaChon == true).length;
                    if (dem >= 8) {
                        return { ...item, lopHoc: "CS2" };
                    }
                    // dem career
                    dem = noiDungBaiLam.filter((n: any) => (n.tieuDe.tag == "GMAT" || n.tieuDe.tag == "CAREER") && n.luaChon == true).length;
                    if (dem >= 5) {
                        return { ...item, lopHoc: "CS1" };
                    }

                    return { ...item, lopHoc: "CS" }
                }
                break;

            }
            default: {
                break;
            }
        }

        return item;
    })

    const columns: ColumnsType<any> = [

        {
            title: 'Email',
            key: 'action',
            ...getColumnSearchProps('email'),
            render: (_, record) =>
                <>
                    {record.email}
                </>

        },
        {
            title: 'Kết quả',
            key: 'action',
            render: (_, record) => {
                let thongTinThem = JSON.parse(record.thongTinThem);

                return record.lopHoc == "CS" ? <Tag color="red"> Không đủ điều kiện</Tag>
                    :
                    <Tag color='blue'>{getLabelLop(record.lopHoc)}</Tag>
            }


        },
        {
            title: 'Ngày làm',
            key: 'action',
            render: (_, record) => {

                return <Tag>{dinhDangNgay(record.ngayTao)}</Tag>


            }
        }
    ];
    const xuatReport = () => {

        let arrModel: BaiTestModel[] = []

        newArrBaiTest?.map(item => {
            let model: BaiTestModel = {
                id: 0,
                email: item.email,
                linkNopBai: "",
                thongTinThem: getLabelLop(item.lopHoc),
                ngayTao: item.ngayTao
            }
            arrModel.push(model);
        })
        console.log(arrModel)
        heThongService.layExportExelTest(arrModel).then(res => {
            if (res.data.content == "1")
                window.location.href = API_URL_DOMAIN + "/files/DS_test.xlsx"
        })
    }

    // let token = await new jwt.SignJWT({ 'data': item.key })
    // .setProtectedHeader({ alg: 'HS256', typ: 'JWT' })
    // .setIssuedAt()
    // .setExpirationTime(item.time)
    // .sign(new TextEncoder().encode('JAVA_TEST_QUIZ'))

    let navigate = useNavigate();

    return (
        <div className='p-3'>
            <h3 className='p-3'>Kết quả test đầu vào Computer Science
                |
                <a href='#' className='text-primary text-decoration-none' onClick={() => {
                    navigate("/quiz-test-phong-van")
                }}>Phỏng vấn</a>
            </h3>

            <Tooltip title="Đã copy" trigger="click">
                <a className="btn btn-primary me-2" onClick={async () => {

                    // Copy the text inside the text field
                    navigator.clipboard.writeText(`${URL_PAGE_LEARN}/quiz-test`);

                }}>Bài test</a>
            </Tooltip>
            <Select
                defaultValue="0"
                style={{ width: 120 }}
                onChange={(value) => setLopHoc(value)}
                options={[
                    { value: '0', label: 'Tất cả' },
                    { value: 'CS1', label: 'CS 1' },
                    { value: 'CS2', label: 'CS 2' },
                    { value: 'CS3', label: 'CS 3' },
                    { value: 'CS', label: 'Không đạt' }

                ]}
            />

            <button className='btn btn-outline-primary ms-2' onClick={() => xuatReport()}>Xuất report</button>

            <Table columns={columns} dataSource={newArrBaiTest
                ? newArrBaiTest.filter(n => {
                    if (lopHoc == "0")
                        return n;
                    else
                        return n.lopHoc == lopHoc;
                })
                : []} />
        </div>
    )
}

export default BaiTest
