import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChuyenDeModel } from '../../Types/chuyenDeType'
import { DispatchType, RootState } from '../../redux/configStore'
import { Card, Collapse, Drawer, Input, Modal, Popconfirm, Table, TableColumnsType, message } from 'antd'
import { chuyenDeService } from '../../services/chuyenDeService'
import { getChuyenDeApi, getChuyenDeHocApi, getDSChuyenDeMentorApi, getListTagApi } from '../../redux/ChuyenDeReducer/chuyenDeReducer'
import ChuyenDeForm from '../../components/ChuyenDe/ChuyenDeForm'
import { useNavigate } from 'react-router-dom'
import { NguoiDungType } from '../../Types/nguoiDungType'
import { config } from '../../util/config'
import { getMonHocApi } from '../../redux/MonHocReducer/monHocReducer'
import { getPracticeApi } from '../../redux/PracticeReducer/practiceReducer'
import { getBaiHocApi } from '../../redux/BaiHocReducer/BaiHocReducer'
import {
  getDanhSachGiangVienApi,
  getDanhSachMentorApi,
  getNguoiDungPhanTrangApi
} from '../../redux/UserReducer/userReducer'
import { fetchCategories, fetchQuestions } from '../../redux/RunCodeReducer/runcodeReducer'
import { getBaiTestApi, getNhomQuyenApi } from '../../redux/HeThongReducer/heThongReducer'
import { getListItemsApi } from '../../redux/VatPhamReducer/vatPhamReducer'
import useQueryConfig from '../../hooks/useQueryConfig'

type Props = {}

const { Panel } = Collapse

type danhSachNoiDung = {
  danhSachMonHoc: string
}
let chuyenDeEmpty: ChuyenDeModel = {
  id: 0,
  tenChuyenDe: '',
  danhSachNoiDung: '{"danhSachMonHoc":[]}',
  moTa: '',
  thoiHan: 0,
  tenChungChi: '',
  gioiThieu: '',
  danhSachTag: '[]',
  hinhAnh: '',
  danhSachMentor: '[]',
  danhSachGiangVien: '[]'
}

export default function ChuyenDe({ }: Props) {
  const myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType
  const [open, setOpen] = useState(false)
  const [clas, setClass] = useState<ChuyenDeModel | null>(null)
  const [nameClass, setNameClass] = useState('')

  const [chuyenDeDetail, setChuyenDeDetail] = useState<ChuyenDeModel>(chuyenDeEmpty)
  const navigate = useNavigate()

  let { arrChuyenDeHoc } = useSelector((state: RootState) => state.chuyenDeReducer)
  let { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer)


  // get redux
  const dispatch: DispatchType = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleChange = (e: any) => {
    setNameClass(e.target.value)
  }

  const handleOk = async () => {
    let newClass: ChuyenDeModel
    if (clas) {
      newClass = { ...clas }
      newClass.metaData = JSON.stringify({ nguoiTao: myUser.id, loaiChuyenDe: "ROADMAP" })
      newClass.tenChuyenDe = nameClass
      newClass.id = 0
      if (nameClass.length > 1) {

        let div_loading = document.getElementsByClassName('div_loading')
        div_loading[0].removeAttribute('hidden')
        if (newClass.id == 0) {
          chuyenDeService
            .themChuyenDe(newClass)
            .then((res) => {
              onClose()
              dispatch(getChuyenDeApi())

              message.success('Thêm thành công')
              setNameClass('')
              setIsModalOpen(false)
            })
            .catch((err) => {
              message.error('Lỗi Báo IT')
            })
            .finally(() => div_loading[0].setAttribute('hidden', ''))
        } else {
          chuyenDeService
            .suaChuyenDe(newClass)
            .then((res) => {
              onClose()

              dispatch(getChuyenDeApi())

              message.success('Cập nhật thành công')
              setNameClass('')
              setIsModalOpen(false)
            })
            .catch((err) => {
              message.error('Lỗi Báo IT')
            })
            .finally(() => div_loading[0].setAttribute('hidden', ''))
        }
      }
    }
  }

  const handleCancel = () => {
    setNameClass('')
    setIsModalOpen(false)
  }

  // function else
  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }
  const queryConfig = useQueryConfig()

  const role = config.getStoreJson('USER_LOGIN') as NguoiDungType
  useEffect(() => {
    if (role && (role.maNhomQuyen === 'ADMIN' || role.maNhomQuyen === 'LECTURE')) {
      dispatch(getChuyenDeHocApi())
    } else {
      dispatch(getDSChuyenDeMentorApi(role?.id))
    }

    dispatch(getMonHocApi())
    dispatch(getPracticeApi())
    dispatch(getBaiHocApi())
    dispatch(getNguoiDungPhanTrangApi(queryConfig))
    dispatch(fetchQuestions())
    dispatch(fetchCategories())
    dispatch(getListTagApi())
    dispatch(getDanhSachMentorApi())
    dispatch(getDanhSachGiangVienApi())

    dispatch(getBaiTestApi())
    dispatch(getListItemsApi())
    dispatch(getNhomQuyenApi())
  }, [])



  let userLogin = localStorage.getItem('USER_LOGIN')
  let nguoiDung = userLogin ? JSON.parse(userLogin) : null

  let dataChuyenDe: ChuyenDeModel[] | null = arrChuyenDeHoc

  if (nguoiDung && nguoiDung.maNhomQuyen == 'LECTURE') {
    if (arrChuyenDeHoc) {
      dataChuyenDe = arrChuyenDeHoc?.filter((item) => {
        if (item.danhSachGiangVien) {
          let danhSachGiangVien = JSON.parse(item.danhSachGiangVien)

          if (danhSachGiangVien.find((n: any) => n == nguoiDung.id)) {
            return item
          }
        }
      })
    }
  }

  if (nguoiDung && nguoiDung.maNhomQuyen == 'MENTOR') {
    dataChuyenDe = arrChuyenDe;
  }

  return (
    <div className=' p-3'>


      {dataChuyenDe &&
        dataChuyenDe
          .filter((item) => {

            if (item.metaData) {
              try {
                const parsedItem = JSON.parse(item.metaData);

                if (parsedItem.loaiChuyenDe && parsedItem.loaiChuyenDe == "ROADMAP") {
                  return item
                } else {
                  console.log('isClass is true or not present', parsedItem);
                }
              } catch (error) {
                console.error('Invalid JSON');
                return item

              }
            }
            else return item

          })
          .map((item, index) => {
            let { danhSachMonHoc } = JSON.parse(item.danhSachNoiDung)

            return (
              <Card className='mb-2 bg-light'>
                <div className='d-flex justify-content-between'>
                  <p>
                    {item.tenChuyenDe} <label className='text-danger'> ({danhSachMonHoc.length} môn học)</label>
                  </p>
                  <div>

                    <button
                      className='btn btn-sm btn-info'
                      onClick={() => {
                        navigate('/monhoc/' + item.id)
                      }}
                    >
                      <i className='fas fa-forward'></i>
                    </button>
                    {role && role.maNhomQuyen === 'ADMIN' ? (
                      <>
                        {' '}
                        <button
                          className='btn btn-sm btn-success mx-2'
                          onClick={(event) => {
                            event.stopPropagation()

                            setChuyenDeDetail(item)
                            showDrawer()
                          }}
                        >
                          <i className='fas fa-edit'></i>
                        </button>
                        <Popconfirm
                          title='Bạn có chắc muốn xóa ?'
                          onConfirm={() =>
                            chuyenDeService
                              .xoaChuyenDe(item.id)
                              .then((res) => {
                                dispatch(getChuyenDeApi())

                                message.success('Xóa thành công')
                              })
                              .catch((err) => {
                                message.error('Lỗi Báo IT')
                              })
                          }
                        >
                          <button
                            className='btn btn-sm btn-danger'
                            onClick={(event) => {
                              event.stopPropagation()
                            }}
                          >
                            <i className='fas fa-trash'></i>
                          </button>
                        </Popconfirm>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Card>
            )
          })}

      <Drawer title='Quản lý chuyên đề' width='40%' onClose={onClose} open={open} bodyStyle={{ paddingBottom: 80 }}>
        <ChuyenDeForm chuyenDeDetail={chuyenDeDetail} onClose={onClose} />
      </Drawer>
      <Modal title='Tạo lớp học' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {isModalOpen && <Input key={clas?.id} placeholder='Tên lớp học...' onChange={handleChange} />}
      </Modal>
    </div>
  )
}
