import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { monHocService } from '../../services/monHocService';
import { http } from '../../util/config';
import { DispatchType } from '../configStore';
import { MonHocModel, MonHocViewMentorModel } from '../../Types/monHocType';
import { LuyenTapViewMentorModel } from '../../Types/luyenTapType';
import { luyenTapService } from '../../services/luyenTapService';


type luyenTapState = {
   dsLuyenTapByListId:LuyenTapViewMentorModel[]
}
const initialState: luyenTapState = {
   dsLuyenTapByListId:[]
}

const luyenTapReducer = createSlice({
    name: 'luyenTapReducer',
    initialState,
    reducers: {
       getDsLuyenTapByListId:(state:luyenTapState,action:PayloadAction<LuyenTapViewMentorModel[]>)=>{
        state.dsLuyenTapByListId=[...action.payload];
       }
    }
});

export const {getDsLuyenTapByListId} = luyenTapReducer.actions
export default luyenTapReducer.reducer
//--------- action async ------------

// export const getMonHocApi = () => {

//     return async (dispatch: DispatchType) => {
//         const result = await monHocService.layDanhSachMonHoc();
//         const action: PayloadAction<MonHocModel[]> = getMonHocAction(result.data.content);
//         dispatch(action);
//     }
// }
export const getDsLuyenTapByListIdApi = (ds:number[]) => {

    return async (dispatch: DispatchType) => {
       try {
        const result = await luyenTapService.postDSLuyenTapById(ds)
        const action: PayloadAction<LuyenTapViewMentorModel[]> = getDsLuyenTapByListId(result.data.content);
        dispatch(action);
       } catch (error) {
        console.log('lỗi getDsLuyenTapByListIdApi');
        console.log(error);
       }
    }
}