import { DangKyNguoiDung, NguoiDungType } from "../Types/nguoiDungType";
import { QueryConfig } from "../hooks/useQueryConfig";
import { getStoreJson, http } from "../util/config";

const GET_PATH: string = "/api/nguoidung";


const getDanhSachMentor = () => {
  return http.get(`${GET_PATH}/get-list-mentor`);
};

const getDanhSachGiangVien = () => {
  return http.get(`${GET_PATH}/get-list-giangvien`);
};


const dangNhap = (data: { email: string; matKhau: string }) => {
  return http.post(`api/nguoidung/login`, data);
};

const layThongTinUser = (id: number) => {
  return http.get(`${GET_PATH}/laythongtinuser/${id}`);
};

const getNguoiDungPhanTrang = (data: QueryConfig) => {
  // return http.get(
  //   `${GET_PATH}/phan-trang?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}&keyword=${data.loaiFilter}%20LIKE%20N%27%40%40${data.keyword}%40%40%27`
  // );
  return http.get(
    `${GET_PATH}/phan-trang?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}&keyword=maNhomQuyen%20LIKE%20%27%40%40${data.maNhomQuyen}%40%40%27%20%20AND%20${data.filter}%20LIKE%20N%27%40%40${data.tuKhoa}%40%40%27`
  );
};

const dangKyNguoiDung = (data: DangKyNguoiDung) => {
  return http.post(`${GET_PATH}`, data);
};

const updateNguoiDung = (data: NguoiDungType) => {
  return http.put(`${GET_PATH}/${data.id}`, data);
};

const deleteNguoiDung = (id: number) => {
  return http.delete(`${GET_PATH}/${id}`);
};

const checkPassNguoiDung = () => {
  const id = getStoreJson("USER_LOGIN").id;
  const matKhau = getStoreJson("USER_LOGIN").matKhau;
  let data = {
    id: id,
    email: "string",
    hoTen: "string",
    soDt: "string",
    avatar: "string",
    urls: "string",
    thongTinMoRong: "string",
    maNhomQuyen: "string",
    kichHoat: true,
    ngayTao: "2023-04-06T03:44:12.756Z",
    maGioiThieu: "string",
    iPaddress: "string",
    matKhau: matKhau,
    nuocNgoai: true,
    guiMail: true,
    kinhNghiem: 0,
    tichCuc: 0,
    coin: 0,
    ngayDangNhap: "2023-04-06T03:44:12.756Z",
  };

  data = { ...getStoreJson("USER_LOGIN"), facebookId: "" }

  return http.post(`${GET_PATH}/check-pass`, data);
};

const layDsNguoiDungByString = (ds: string[]) => {
  return http.post(`${GET_PATH}/laydanhsachnguoidungbystring`, ds);
}

const GetDSByMentorIdService = (mentorId: string) => {
  return http.get(`${GET_PATH}/getdshocvienbymentorid?mentorId=${mentorId}`)
}
const GetDSByMentorIdAddChuyenDeIdService = (mentorId: string) => {
  return http.get(`${GET_PATH}/GetDSByMentorIdAddChuyenDeId?mentorId=${mentorId}`)
}

const getUserByEmail = (email: string) => {
  return http.get(`${GET_PATH}/get-by-email/${email}`);
}


const getDiemDanhUser = (userId: string) => {
  return http.get(`api/diemdanh/get-diem-danh-user/${userId}`);
}

const themDiemDanhghiChu = (model: any) => {
  return http.post(`api/diemdanh/diem-danh-ghi-chu/`, model);
}


export const nguoiDungService = {
  themDiemDanhghiChu,
  getDiemDanhUser,
  getDanhSachMentor,
  getDanhSachGiangVien,
  dangNhap,
  layThongTinUser,
  getNguoiDungPhanTrang,
  deleteNguoiDung,
  updateNguoiDung,
  dangKyNguoiDung,
  checkPassNguoiDung,
  layDsNguoiDungByString,
  GetDSByMentorIdService,
  GetDSByMentorIdAddChuyenDeIdService,
  getUserByEmail
};
