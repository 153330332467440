import React, { useEffect, useMemo } from 'react'
import { config } from '../util/config'
import { useAppDispatch, useAppSelector } from '../redux/hooks'
import {
  GetDSByMentorIdAddChuyenDeIdApi,
  GetDSByMentorIdApi,
  GetDsNopBaiByMentorIdApi,
  GetNopBaisByNguoiDungViewModel,
  GetNopBaisByNguoiDungViewModelApi
} from '../redux/NopBaiReducer/nopBaiReducer'
import {
  GetDSRequireForStudentByDsGFTidApi,
  GetGroupForTaskByMentorIdApi
} from '../redux/GroupForTaskReducer/GroupForTaskReducer'
import { getIssueAllMentorPhuTrachApi } from '../redux/IssueReducer/IssueReducer'
import { getDSChuyenDeMentorPhuTrachApi } from '../redux/ChuyenDeReducer/chuyenDeReducer'
import { NguoiDungType } from '../Types/nguoiDungType'
import { mergeArraysBaiHoc, mergeArraysMonHocFromChuyenDe } from '../pages/Mentor/Dashboard/DashBoard'
import { getDSMonhocTheoDSIdApi } from '../redux/MonHocReducer/monHocReducer'
import { getBaiHocByListIdApi } from '../redux/BaiHocReducer/BaiHocReducer'
import { NopBaiViewMentor } from '../Types/nopBaiType'
import { GetDanhSachDanhGiaByNguoiDungIdApi } from '../redux/DanhGiaReducer/danhGiaReducer'
import { ChuyenDeMentorPhuTrachModel } from '../Types/chuyenDeType'
import { getDsLuyenTapByListIdApi } from '../redux/LuyenTapReducer/LuyenTapReducer'
import { GetDsMentorApi } from '../redux/StackChatReducer/StackChatReducer'

const ComponentAddApi = () => {
  const user = config.getStoreJson('USER_LOGIN') as NguoiDungType
  const { dsNopBaiByMentorId, dsHocVienByMentorId } = useAppSelector((state) => state.nopBaiReducer)
  const { dsBaiHocByDsId } = useAppSelector((state) => state.baiHocReducer)
  const { DSChuyenDeMentorPhuTrach, dsIdMentor } = useAppSelector((state) => state.chuyenDeReducer)
  const { groupForTaskViewMentorModel } = useAppSelector((e) => e.groupForTaskReducer)
  const { dsLuyenTapByListId } = useAppSelector((state) => state.LuyenTapReducer)
  const { dsMonhocTheoDSId } = useAppSelector((state) => state.monHocReducer)
  const dispatch = useAppDispatch()

  const timTenNguoiDung = (id: string) => {

    const index = dsHocVienByMentorId.findIndex(e => e.id === id)
    if (index !== -1)
      return dsHocVienByMentorId[index]

    return null
  }
  const timTenChuyenDe = (id: number) => {
    const index = DSChuyenDeMentorPhuTrach.findIndex(e => e.id === id)
    if (index !== -1)
      return DSChuyenDeMentorPhuTrach[index]
    return null
  }
  const timTenMonHoc = (id: number) => {
    const idex = dsMonhocTheoDSId.findIndex(e => e.id === id)
    if (idex !== -1) {
      return dsMonhocTheoDSId[idex]
    }
    return null
  }
  const timTenNhiemVu = (id: number, idMonHoc: number) => {
    const monHoc = timTenMonHoc(idMonHoc)
    if (monHoc) {
      const index = monHoc.danhSachNoiDung.findIndex(e => e.id === id)
      if (index !== -1) {
        return monHoc.danhSachNoiDung[index]
      }
    }
    return null
  }
  const timTenYeuCau = (id: number, idTask: number, idMonHoc: number) => {
    const task = timTenNhiemVu(idTask, idMonHoc)
    if (task) {
      const index = dsBaiHocByDsId.findIndex(e => e.id === Number(task.noiDungNhiemVu))
      if (index !== -1) {
        const indexYC = dsBaiHocByDsId[index].noiDung.findIndex(e => e.requireId === id)
        if (indexYC !== -1)

          return dsBaiHocByDsId[index].noiDung[indexYC]
      }
    }
    return null
  }

  const mergeArraysLuyenTapFromChuyenDe = (
    chuyende: ChuyenDeMentorPhuTrachModel[]
  ): number[] => {
    const mergedArray: number[] = [];
    for (const iterator of chuyende) {
      mergedArray.push(...iterator.danhSachNoiDung.danhSachPractice);
    }
    return mergedArray;
  };
  const callAPI = async () => {
    if (user) {
      dispatch(GetDsNopBaiByMentorIdApi(user.id))
      //?lấy thông tin mentor
      dispatch(GetDSByMentorIdApi(user.id))
      dispatch(GetDSByMentorIdAddChuyenDeIdApi(user.id))
      //?lấy thông tin nhóm task theo mentor
      dispatch(GetGroupForTaskByMentorIdApi(user.id))
      //?Lấy isuue mentor phụ trach
      dispatch(getIssueAllMentorPhuTrachApi(user.id))
      //?chuyendementorphutrach
      dispatch(getDSChuyenDeMentorPhuTrachApi(user.id))

      // dispatch(GetNopBaisByNguoiDungViewModelApi(user.id))
    }
  }

  // render show bai tập
  useEffect(() => {

    // if (dsNopBaiByMentorId.length && dsMonhocTheoDSId.length && dsBaiHocByDsId.length && dsHocVienByMentorId.length && DSChuyenDeMentorPhuTrach.length) {

      if (dsNopBaiByMentorId.length ) {

      const newDSNopBaiViewMentorModel = [...dsNopBaiByMentorId] as NopBaiViewMentor[]
      const newDS = newDSNopBaiViewMentorModel.map((e) => {
        const newBT = { ...e }
        if (newBT.nguoiDungId)
          newBT.tenNguoiDung = timTenNguoiDung(newBT.nguoiDungId)?.hoTen ?? ''
        if (newBT.chuyenDeId)
          newBT.tenChuyenDe = timTenChuyenDe(newBT.chuyenDeId)?.tenChuyenDe ?? ''
        if (newBT.chuyenDeId && newBT.monHocId)
          newBT.tenMonHoc = timTenMonHoc(newBT.monHocId)?.tenMonHoc ?? ''
        if (newBT.taskId && newBT.monHocId)
          newBT.tenNhiemVu = timTenNhiemVu(newBT.taskId, newBT.monHocId)?.tenNhiemVu ?? ''
        if (newBT.requireId && newBT.taskId && newBT.monHocId)
          newBT.tenYeuCau = timTenYeuCau(newBT.requireId, newBT.taskId, newBT.monHocId)?.noiDung ?? ''
        return newBT
      })
      dispatch(GetNopBaisByNguoiDungViewModel(newDS))

    }

  }, [
    dsHocVienByMentorId,
    dsHocVienByMentorId.length,
    DSChuyenDeMentorPhuTrach,
    DSChuyenDeMentorPhuTrach.length,
    dsNopBaiByMentorId,
    dsNopBaiByMentorId.length,
    dsMonhocTheoDSId,
    dsMonhocTheoDSId.length,
    dsBaiHocByDsId,
    dsBaiHocByDsId.length
  ])

  useEffect(() => {
    if (user.id) {
      // dispatch(GetNopBaisByNguoiDungViewModelApi(user.id));
      dispatch(GetDanhSachDanhGiaByNguoiDungIdApi(user.id))
      // dispatch(GetDanhSachDanhGiaByNguoiDungIdApi("string"))
      callAPI()
    }
    const fetch = setInterval(callAPI, 30000)

    return () => {
      clearInterval(fetch)
    }
  }, [user.id])

  //Get ds môn học mà mentor có thể phụ trách
  useEffect(() => {
    // DSChuyenDeMentorPhuTrach.reduce((rs,cur)=>rs.concat(cur.danhSachNoiDung),[])
    const dsidmonHoc = mergeArraysMonHocFromChuyenDe(DSChuyenDeMentorPhuTrach)
    // const dsidluyentap = mergeArraysLuyenTapFromChuyenDe(
    //   DSChuyenDeMentorPhuTrach
    // );
    dispatch(getDSMonhocTheoDSIdApi(dsidmonHoc))
    // dispatch(getDsLuyenTapByListIdApi(dsidluyentap));
  }, [DSChuyenDeMentorPhuTrach.length, DSChuyenDeMentorPhuTrach])

  //fetch ds các bài học mà mentor có thể join vào
  useEffect(() => {
    if (
      dsMonhocTheoDSId.length ||
      dsLuyenTapByListId.length
      // && mergeArraysBaiHoc(dsMonhocTheoDSId, dsLuyenTapByListId)
    )
      dispatch(getBaiHocByListIdApi(mergeArraysBaiHoc(dsMonhocTheoDSId, dsLuyenTapByListId)))
  }, [dsMonhocTheoDSId.length, dsLuyenTapByListId.length, dsMonhocTheoDSId, dsLuyenTapByListId])

  //fetch data groupfortask
  useEffect(() => {
    if (groupForTaskViewMentorModel)
      dispatch(GetDSRequireForStudentByDsGFTidApi(groupForTaskViewMentorModel.map((e) => e.id)))
  }, [groupForTaskViewMentorModel])

  useEffect(() => {
    if (dsIdMentor && dsIdMentor.length) {
      dispatch(GetDsMentorApi(dsIdMentor))
    }
  }, [dsIdMentor])

  return { callAPI }
}

export default ComponentAddApi
