import React, { Fragment, useState } from 'react'
import { config } from '../../util/config'
import StackChat from '../StackChat/StackChat'
import DanhSachChat from '../DanhSachChat/DanhSachChat'
import { NguoiDungType } from '../../Types/nguoiDungType'

function PopupStackChat() {
  const [open, setOpen] = useState(false)
  const user = config.getStoreJson('USER_LOGIN') as NguoiDungType;

  const showDrawer = () => {
    setOpen(true)
  }
  const closeDrawer = () => {
    setOpen(false)
  }
  return (
    <Fragment>
      {user ? <StackChat showDrawer={showDrawer} /> : <></>}
      {/* {user ? <DanhSachChat open={open} closeDrawer={closeDrawer} /> : <></>} */}
    </Fragment>
  )
}

export default PopupStackChat
