import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Card, DatePicker, DatePickerProps, Popconfirm, Tag, Tooltip } from "antd";
import { NoiDungMonHoc } from "../../Types/monHocType";
import { BaiHocModel } from "../../Types/baiHocType";
import { listLoaiTask } from "../../util/SelectOption";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/configStore";
import dayjs from "dayjs";


type Props = {
    task: NoiDungMonHoc,
    showDrawer: (task: NoiDungMonHoc) => void,
    xoaTaskKhoiMon: (taskId: number) => void,
    updateHanNop: any
}

export const SortableItem = ({
    task, showDrawer, xoaTaskKhoiMon, updateHanNop
}: Props) => {


    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: task.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: 999,
        cursor: "grab"
    }
    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {

        task.hanNop = date;

        updateHanNop(task);
    };
    return (
        <div className=" m-3 row ">

            <div className="p-3 border col-10 bg-light rounded-start drag-hover" ref={setNodeRef} style={style} {...attributes} {...listeners}>

                <div >

                    {`${task.id} - ${task.tenNhiemVu}`} <Tag color="red"  >{listLoaiTask.find(n => n.value == task.maLoaiNhiemVu)?.label}</Tag>
                </div>
            </div >
            <div className="col-2 border bg-light py-3 rounded-end">

                {
                    // ["HOMEWORK", "DUAN", "CAPS", "BAITAP"].find(n => n == task.maLoaiNhiemVu) &&
                    // <p>
                    //     <Tooltip title="Gia hạn deadline !">

                    //         <DatePicker format={"DD/MM/YYYY"} defaultValue={dayjs(task.hanNop)} onChange={onChangeDate} />
                    //     </Tooltip>
                    // </p>
                }

                <button className='btn btn-sm btn-success me-2 mt-2' onClick={() => {

                    showDrawer({ ...task })
                }}><i className="fas fa-edit"></i></button>
                <Popconfirm
                    title="Bạn có chắc muốn xóa ?"
                    onConfirm={() => xoaTaskKhoiMon(task.id)}
                >
                    <button className='btn btn-sm btn-danger mt-2'><i className="fas fa-times"></i></button>
                </Popconfirm>
            </div>
        </div >
    );
}
