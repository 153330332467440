import React, { useEffect, useState } from 'react'
import TabChat from './TabChat';
import { notification } from 'antd';
import UseChatConnection from '../../../../hooks/useChatHub';
import { useAppSelector,useAppDispatch } from '../../../../redux/hooks';



const TabsChat = () => {
  const [previousNotification, setPreviousNotification] = useState('');
  const { messages, sendNotify } = UseChatConnection();



  const openNotification = (data) => {
   if(data){
    const newNotification = `${data?.message}-${data?.description}`;
    const { message, description } = data;

    
    if (newNotification !== previousNotification) {
      notification.open({
        message,
        // icon:<></>,
        description,
        placement: 'topLeft',
        duration: 5,
        className: 'NotificationTABCHAT',
        onClose: () => {
          setTimeout(() => {

            sendNotify("","")
            setPreviousNotification("")
          }, 10000);
        }
      });
      setPreviousNotification(newNotification);
    }
   }
  };
  const { ActiveChat1, ActiveChat2, ActiveCurrent, dataChat1, dataChat2 } =
  useAppSelector((state) => state.StackChatReducer);

  const [numTab, setNumTab] = useState(2);
  // const [width,setWidth]=useState(window.innerWidth)
  useEffect(() => {
    console.log('Hiên thỉ chat', messages);
    if (messages.message) {
      console.log(true);
      openNotification(messages)
    }
    else {
      console.log(false);
    }
  }, [messages.message,messages.description])
  const renderTab = () => {
    return numTab === 2 ?
      //current == false là chat 1 và true là chat 2
      <>
        {ActiveChat1 && <TabChat activeChat={ActiveChat1} data={dataChat1} current={false} key={'chat' + 1} />}
        {ActiveChat2 && <TabChat activeChat={ActiveChat2} data={dataChat2} current={true} key={'chat' + 2} />}
      </>
      : <>
        <TabChat activeChat={ActiveChat1} key={'chat' + 1} />
      </>
  }


  //    useEffect(()=>{
  //     setWidth(window.innerWidth)

  //     const resize = ()=>{
  //         setWidth(window.innerWidth)
  //         if(window.innerWidth>992){
  //             setNumTab(2)
  //         }
  //         else {
  //             setNumTab(1)
  //         }
  //     }
  //     window.onresize = resize
  //     return () => {
  //         window.removeEventListener('resize',resize);
  //     }
  //    }) 

  return (
    <div className='TabsChat'>
      {renderTab()}
    </div>
  )
}

export default TabsChat