import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserLoginForm } from "../../pages/Login/Login";
import { getStoreJson, setStoreJson } from "../../util/config";
import { DispatchType } from "../configStore";
import { nguoiDungService } from "../../services/nguoiDungService";
import { NguoiDungType } from "../../Types/nguoiDungType";
import { QueryConfig } from "../../hooks/useQueryConfig";
import { NavigateFunction } from "react-router-dom";
import { MessageInstance } from "antd/es/message/interface";
export interface UserProfile {
  ordersHistory: OrdersHistory[];
  email: string;
  name: string;
  password: null;
  gender: boolean;
  phone: string;
  facebookId: string;
  deleted: boolean;
  avatar: string;
}

export interface OrdersHistory {
  orderDetail: OrderDetail[];
  id: number;
  date: Date;
  status: null;
  email: string;
  alias: string;
}

export interface OrderDetail {
  name: string;
  alias: string;
  shortDescription: string;
  quantity: number;
  price: number;
  image: string;
  description: string;
}

export type UserLogin = {
  email: string;
  accessToken: string;
};

type UserState = {
  userLogin: NguoiDungType | null;
  userProfile: UserProfile | null;
  userPhanTrang: NguoiDungType[] | null;
  userChinhSua: NguoiDungType | null | any;
  totalRowPhanTrang: number | null;
  danhSachMentor: NguoiDungType[];
  danhSachGiangVien: NguoiDungType[];
};

//state reducer
const initialState: UserState = {
  userLogin: getStoreJson("USER_LOGIN"),
  userProfile: null,
  userPhanTrang: null,
  userChinhSua: null,
  totalRowPhanTrang: null,
  danhSachMentor: [],
  danhSachGiangVien: [],

};
const userReducer = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    loginAction: (state: UserState, action: PayloadAction<NguoiDungType>) => {
      state.userLogin = action.payload;
    },
    getProfileAction: (
      state: UserState,
      action: PayloadAction<UserProfile>
    ) => {
      state.userProfile = action.payload;
    },
    getNguoiDungChinhSuaAction: (state, action) => {
      state.userChinhSua = action.payload;
    },
    getDanhSachMentorAction: (state: UserState, action: PayloadAction<NguoiDungType[]>) => {
      state.danhSachMentor = action.payload;
    },
    getDanhSachGiangVienAction: (state: UserState, action: PayloadAction<NguoiDungType[]>) => {
      state.danhSachGiangVien = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNguoiDungPhanTrangApi.fulfilled, (state, action) => {
      state.userPhanTrang = action.payload.data;
      state.totalRowPhanTrang = action.payload.totalRow;
    });
  },

});

export const { loginAction, getProfileAction, getNguoiDungChinhSuaAction, getDanhSachMentorAction, getDanhSachGiangVienAction } =
  userReducer.actions;

export default userReducer.reducer;

// ------------------- async action -----------------

// Người dùng đăng nhập vào hệ thống
export const loginApi = (
  userLogin: UserLoginForm,
  navigate: NavigateFunction,
  messageApi: MessageInstance
) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await nguoiDungService.dangNhap(userLogin);

      if (!result.data.content.maNhomQuyen) {
        messageApi.error("Tài khoản không tồn tại !");
        return;
      }

      setStoreJson("USER_LOGIN", result.data.content);
      const action: PayloadAction<NguoiDungType> = loginAction(
        result.data.content
      );
      dispatch(action);
      messageApi.success("Đăng nhập thành công");
      navigate("/home");
    } catch (error) {
      messageApi.error("Sai mật khẩu");
    }
  };
};

export const getProfileApi = (id: number) => {
  return async (dispatch: DispatchType) => {
    const result = await nguoiDungService.layThongTinUser(id);
    const action: PayloadAction<UserProfile> = getProfileAction(
      result.data.content
    );
    dispatch(action);
  };
};

export const getNguoiDungPhanTrangApi = createAsyncThunk(
  "userReducer/getNguoiDungPhanTrangApi",
  async (data: QueryConfig) => {
    const result = await nguoiDungService.getNguoiDungPhanTrang(data);
    return result.data.content;
  }
);

export const getDanhSachMentorApi = () => {

  return async (dispatch: DispatchType) => {
    try {
      const result = await nguoiDungService.getDanhSachMentor();
      const action: PayloadAction<NguoiDungType[]> = getDanhSachMentorAction(result.data.content);
      dispatch(action);
    } catch (error) {
      console.log('lỗi getDanhSachMentorApi');
      console.log(error);
    }
  }
}

export const getDanhSachGiangVienApi = () => {

  return async (dispatch: DispatchType) => {
    try {
      const result = await nguoiDungService.getDanhSachGiangVien();
      const action: PayloadAction<NguoiDungType[]> = getDanhSachGiangVienAction(result.data.content);
      dispatch(action);
    } catch (error) {

      console.log(error);
    }
  }
}

