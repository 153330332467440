import { Card, InputNumber, Modal, Popconfirm, Steps, Tabs, Tag, Tooltip } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextEditor } from "../../hoc/TextEditor";
import { DispatchType, RootState } from "../../redux/configStore";
import { baiTapType } from "../../Types/baiTapType";
import { config } from "../../util/config";
import parse from "html-react-parser";
import { changeUrlImage } from "../../util/adminUti";
import "../../assets/css/baitapnop.css";
import PhanTaskUser from "./PhanTask/PhanTaskUser";
import { VideoSolution } from "./VideoSolution";
import { fileService } from "../../services/fileService";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import HLSSource from "../VideoPlayer/HLSSource";

type Props = {
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  lstBaiTap: baiTapType[];
  setLstBaiTap: Dispatch<SetStateAction<baiTapType[]>>;

  data: any;
  //phan task
  lstPhanTask: any;
  setLstPhanTask: Dispatch<SetStateAction<any>>;

  // team size
  setTeamSize: Dispatch<SetStateAction<any>>;
  teamSize: any;
};

export default function BaiTapViet({
  currentStep,
  setCurrentStep,
  lstBaiTap,
  setLstBaiTap,

  data,

  lstPhanTask,
  setLstPhanTask,

  setTeamSize,
  teamSize
}: Props) {
  // create state

  const [open, setOpen] = useState(false);
  // xử lý load editor có finder load trước
  const [hideEditor, setHideEditor] = useState<any>("hidden");

  const [keyState, setKeyState] = useState("");
  const [dataEditor, setDataEditor] = useState("");
  const [sourceRecord, setSourceRecord] = useState("");

  // const [requiers,setRequires]= useState(lstBaiTap);
  // const [hintCoin,setHintCoin]=useState(0)
  // const [solutionCoin,setVideoSolutionCoin]=useState(0)
  // const [solutionCoin,setvideoSolutionCoin]=useState(0)

  const [coint, setCoin] = useState({
    hintCoin: 0,
    solutionCoin: 0,
    videoSolutionCoin: 0,
  });

  const [openTask, setOpenTask] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);

  const changCoin = (event: any) => {
    const { name, value } = event.target;

    setCoin({ ...coint, [name]: value });

    // Tạo một biến mới tên là newRequireForCheck
    const newRequireForCheck = { ...lstBaiTap[currentStep] };
    if (name === "hintCoin") newRequireForCheck.hintCoin = value;
    if (name === "solutionCoin") newRequireForCheck.solutionCoin = value;
    if (name === "videoSolutionCoin")
      newRequireForCheck.videoSolutionCoin = value;

    setLstBaiTap((pr) => {
      const newPr = [...pr];
      newPr[currentStep] = newRequireForCheck;
      return [...newPr];
    });
  };

  // get props reducer

  // life cycle
  useEffect(() => {
    setOpen(true);

    setTimeout(() => {

      setOpen(false);
    }, 1);

    setTimeout(() => {
      setHideEditor("unset")

    }, 500);

    config.setStore("requireId", 1);
    setCurrentStep(0);

    // return () => { config.removeStore("dataBT") };
  }, []);

  useEffect(() => {
    if (lstBaiTap[currentStep]) {

      setCoin({
        hintCoin: lstBaiTap[currentStep].hintCoin ? Number(lstBaiTap[currentStep].hintCoin) : 0,
        solutionCoin: lstBaiTap[currentStep].solutionCoin ? Number(lstBaiTap[currentStep].solutionCoin) : 0,
        videoSolutionCoin: lstBaiTap[currentStep].videoSolutionCoin ? Number(lstBaiTap[currentStep].videoSolutionCoin) : 0,
      });


    }
  }, [currentStep]);

  // function else

  const itemsRequire = [];

  for (let i = 0; i < lstBaiTap.length; i++) {
    itemsRequire.push({
      title: (
        <>
          {"Yêu cầu " + (i + 1)}{" "}
          <button
            className="btn btn-sm btn-danger"
            onClick={() => removeListBaiTap(i)}
          >
            <i className="fas fa-minus"></i>
          </button>
        </>
      ),
    });
  }
  // thêm phần tử cho baiTap
  const pushListBaiTap = () => {
    let getId =
      lstBaiTap.length == 0
        ? 1
        : lstBaiTap.sort((a, b) => a.requireId - b.requireId)[
          lstBaiTap.length - 1
        ].requireId + 1;

    if (getId == 1) {
      config.setStore("requireId", 1);
    }

    let newBaiTap: baiTapType = {
      requireId: getId,
      noiDung: "",
      ketQua: "",
      hint: "",
      solution: "",
      videoSolution: "",
    };
    setLstBaiTap([...lstBaiTap, newBaiTap]);
  };

  // xóa phần tử cho baiTap
  const removeListBaiTap = (index: number) => {
    //remove
    lstBaiTap.splice(index, 1);

    let newLstBaiTap: baiTapType[] = [];
    //chạy lại requireId
    lstBaiTap
      .sort((a, b) => a.requireId - b.requireId)
      .map((item, index) => {
        newLstBaiTap.push({ ...item, requireId: ++index });
      });

    setLstBaiTap(newLstBaiTap);
  };

  const lstBaiTapTemp: baiTapType[] = [...lstBaiTap];
  const requireId = +config.getStore("requireId");

  const baiTap: baiTapType | undefined = lstBaiTapTemp.find(
    (item: baiTapType) => item.requireId == requireId
  );

  const setData = (value: string) => {

    if (baiTap) {

      const updatedBaiTap = { ...baiTap, [keyState]: value };

      lstBaiTapTemp.splice(lstBaiTapTemp.indexOf(baiTap), 1, updatedBaiTap);
      console.log(lstBaiTapTemp[0])
      setLstBaiTap(lstBaiTapTemp);
    }
  };

  if (baiTap) {
    baiTap.noiDung = changeUrlImage(baiTap.noiDung);
  }

  return (
    <>
      <div className="row mb-3">
        <div className="col-12">
          {/* <p className='mb-3'><strong>Ký tự nộp tài nguyên:</strong></p>
                    <div className="description-icons">
                        <Tag color="magenta">♥️: Github</Tag>
                        <Tag color="red">♦️: Deploy</Tag>
                        <Tag color="green">♣️: Youtube</Tag>
                        <Tag color="blue">♠️: Upload</Tag>
                    </div> */}


          {["CAPS", "DUAN"].indexOf(data.maLoai) != -1 &&
            <div className="row">
              <button
                className="btn btn-warning col-1"
                onClick={() => setOpenTask(true)}
              >
                Phân task
              </button>

              <div className="col-2">
                <label >Số thành viên: </label>
                <InputNumber min={0} max={4} value={teamSize} onChange={(value) => { setTeamSize(value) }} />
              </div>

            </div>
          }
        </div>
      </div>
      <div className="row">
        <div className="col-2 mt-4">
          <Steps
            current={currentStep}
            onChange={(value) => {
              setCurrentStep(value);

              config.setStore("requireId", value + 1);
            }}
            direction="vertical"
            items={itemsRequire}
          />

          <button className="btn btn-sm btn-primary" onClick={pushListBaiTap}>
            <i className="fas fa-plus"></i> Thêm yêu cầu{" "}
          </button>
        </div>
        {lstBaiTap.length > 0 && (
          <>
            <div className="col-10 row ">
              <div className="col-6 ">
                <button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    if (baiTap) setDataEditor(baiTap.noiDung);
                    setKeyState("noiDung");
                    setOpen(true);
                  }}
                >
                  <i className="fas fa-pencil-ruler"></i> Sửa nội dung
                </button>

                <div className="overflow-auto" style={{ height: "80vh" }}>
                  {baiTap && parse(baiTap.noiDung)}
                </div>
              </div>
              <div className="col-6">
                <Tabs
                  defaultActiveKey="1"
                  type="card"
                  onTabClick={(key: any) => {
                    if (key == 4) setKeyState("videoSolution")
                  }}
                  items={[
                    {
                      label: "Kết quả",
                      key: "1",
                      children: (
                        <>
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              if (baiTap) setDataEditor(baiTap.ketQua);
                              setKeyState("ketQua");
                              setOpen(true);
                            }}
                          >
                            <i className="fas fa-pencil-ruler"></i> Sửa nội dung
                          </button>
                          <div
                            className="overflow-auto"
                            style={{ height: "80vh" }}
                          >
                            {baiTap && parse(baiTap.ketQua)}
                          </div>
                        </>
                      ),
                    },
                    {
                      label: "Hint",
                      key: "2",
                      children: (
                        <>
                          <div className="my-2">
                            <label htmlFor="hintCoin" style={{ cursor: 'pointer' }}>Số coint để xem hind :</label>
                            <input
                              id="hintCoin"
                              name="hintCoin"
                              className="form-control mt-2"
                              type="number"
                              value={coint.hintCoin}
                              min={0}
                              max={100}
                              onChange={changCoin}
                            />
                          </div>
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              if (baiTap) setDataEditor(baiTap.hint);
                              setKeyState("hint");
                              setOpen(true);
                            }}
                          >
                            <i className="fas fa-pencil-ruler"></i> Sửa nội dung
                          </button>

                          <div
                            className="overflow-auto"
                            style={{ height: "80vh" }}
                          >
                            {baiTap && parse(baiTap.hint)}
                          </div>
                        </>
                      ),
                    },
                    {
                      label: "Solution",
                      key: "3",
                      children: (
                        <>
                          <div className="mb-2">
                            <label htmlFor="solutionCoin" style={{ cursor: 'pointer' }}>Số coint để xem solution :</label>
                            <input
                              id="solutionCoin"
                              name="solutionCoin"
                              className="form-control"
                              type="number"
                              value={coint.solutionCoin}
                              onChange={changCoin}
                            />
                          </div>
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() => {
                              if (baiTap) setDataEditor(baiTap.solution);
                              setKeyState("solution");
                              setOpen(true);
                            }}
                          >
                            <i className="fas fa-pencil-ruler"></i> Sửa nội dung
                          </button>
                          <div
                            className="overflow-auto"
                            style={{ height: "80vh" }}
                          >
                            {baiTap && parse(baiTap.solution)}
                          </div>
                        </>
                      ),
                    },
                    {
                      label: "Solution video",
                      key: "4",

                      children: (
                        <>
                          <div className="mb-2">
                            <label htmlFor="videoSolutionCoin" style={{ cursor: 'pointer' }}>Số coint để xem solution :</label>
                            <input
                              id="videoSolutionCoin"
                              name="videoSolutionCoin"
                              className="form-control"
                              type="number"
                              value={coint.videoSolutionCoin}
                              onChange={changCoin}
                            />
                          </div>
                          <label>Link video:</label>
                          {/* <input
                            value={baiTap?.videoSolution}
                            className="form-control"
                            onChange={(event) => {
                              setKeyState("videoSolution");
                              setData(event.target.value);
                            }}
                          /> */}
                          <VideoSolution setData={setData} baiTap={baiTap} />
                          {
                            baiTap?.videoSolution && baiTap?.videoSolution != "" && JSON.parse(baiTap?.videoSolution).map((item: any, index: number) => {

                              return <Card className='mt-2 bg-info '>
                                <div className='d-flex justify-content-between'>
                                  <div>{item?.tieuDe}</div>
                                  <div>
                                    <button className='btn btn-sm btn-success me-2' onClick={() => {


                                      if (item && item.noiDung) {
                                        fileService.layDuongDanSolutionService(item.noiDung).then(res => {
                                          console.log(res.data)
                                          setSourceRecord(res.data)
                                          setOpenVideo(true)
                                        }).catch(err => console.log(err))
                                      }
                                    }}>
                                      <i className='fas fa-eye'></i>
                                    </button>
                                    <Popconfirm
                                      title='Bạn có chắc muốn xóa ?'
                                      onConfirm={() => {
                                        let lstVideo = []

                                        if (baiTap?.videoSolution && baiTap?.videoSolution != "") {

                                          lstVideo = JSON.parse(baiTap?.videoSolution)
                                          lstVideo.splice(index,1)
                                        }


                                        setData(JSON.stringify(lstVideo))
                                      }}
                                    >
                                      <button className='btn btn-sm btn-danger'>
                                        <i className='fas fa-times'></i>
                                      </button>
                                    </Popconfirm>
                                  </div>
                                </div>

                              </Card>


                            })
                          }
                          {/* <VideoSolution /> */}
                        </>
                      ),
                    },
                  ]}
                />
              </div>
              <Modal
                title="Content"
                centered
                open={open}
                onOk={() => setOpen(false)}
                onCancel={() => setOpen(false)}
                width={"90%"}
                style={{ visibility: hideEditor }}
              >
                <TextEditor data={dataEditor} setData={setData} />
              </Modal>
            </div>
          </>
        )}
      </div >

      <Modal
        title="Phân task"
        open={openTask}
        onCancel={() => setOpenTask(false)}
        footer={null}
        width={"50%"}
      >
        <PhanTaskUser
          lstPhanTask={lstPhanTask}
          setLstPhanTask={setLstPhanTask}
          lstBaiTap={lstBaiTap}
        />
      </Modal>


      <Modal
        title="Video"
        open={openVideo}
        onCancel={() => setOpenVideo(false)}
        footer={null}
        width={"50%"}
      >
        {sourceRecord && < >
          <Player playsInline fluid={false} height={550} width={+'100%'}>

            <HLSSource
              isVideoChild
              src={sourceRecord && sourceRecord}
            />
            <ControlBar>
              <PlaybackRateMenuButton rates={[3, 2.5, 2, 1.5, 1]} />
            </ControlBar>

          </Player>
        </>
        }
      </Modal>
    </>
  );
}
