import { DanhSachTagModel } from "../Types/chuyenDeType";
import { http } from "../util/config";

const GET_PATH: string = '/api/danh-sach-tag';

const getDanhSachTag = () => {
    return http.get(GET_PATH);
}

const updateDanhSachTag = (model: DanhSachTagModel | any) => {
    return http.put(`${GET_PATH}/${model.id}`, model);
}

export const danhSachTagService = {
    getDanhSachTag,
    updateDanhSachTag
}