import React, { useEffect, useState } from "react";
import "./style.css";
import { Avatar, Badge, Collapse, Select } from "antd";
import { CloseOutlined, ExclamationOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import {
  ChangeActiveChat1,
  ChangeActiveChat2,
  changeCurrentActive,
  setOpenDrawerAciton,
  updateDataChat1,
  updateDataChat2,
} from "../../redux/StackChatReducer/StackChatReducer";
import LoadAvatar from "./components/LoadAvatar";
import { config } from "../../util/config";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { lastName, randomColor } from "../../pages/Mentor/Dashboard/DashBoard";
import { DataGroupChat } from "../../Types/groupChatType";

const { Panel } = Collapse;
export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  console.log(obj1,obj2);
  if (obj1 === obj2) {
    return true; // Cùng một tham chiếu đến đối tượng
  }

  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false; // Một trong hai không phải là đối tượng
  }

  if (obj1 === null || obj2 === null) {
    return false; // Một trong hai là null
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false; // Số lượng thuộc tính khác nhau
  }

  for (let key of keys1) {
    if (!areObjectsEqual(obj1[key], obj2[key])) {
      return false; // Kiểm tra đệ quy cho các thuộc tính lồng nhau
    }
  }

  return true; // Nếu tất cả các kiểm tra trước đó không trả về false, thì hai đối tượng giống nhau.
};

export const isObjectInArray = (array: any[], element: any): boolean => {
  for (const item of array) {
    if (areObjectsEqual(item, element)) {
      return true;
    }
  }
  return false;
};

export const findObjectIndexInArray = (array: any[], element: any): number => {
  return array.findIndex((item) => areObjectsEqual(item, element));
};
const ColorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
// Tạo một số nguyên ngẫu nhiên từ 0 đến 3
const randomNumber = Math.floor(Math.random() * ColorList.length); // 0, 1, 2 hoặc 3

type DanhSachChatType={
  open:boolean
  closeDrawer:()=>void
}
const DanhSachChat:React.FC<DanhSachChatType> = ({ open, closeDrawer }) => {
  const dispatch = useAppDispatch();
  let myUser = config.getStoreJson("USER_INFOR_TECH_X");
  const { ActiveChat1, ActiveChat2, ActiveCurrent, dataChat1, dataChat2 } =
    useAppSelector((state) => state.StackChatReducer);
  const {dsHocVienByMentorId,dsHocVienByMentorIdSet,}= useAppSelector(state=>state.nopBaiReducer)
  const {DSChuyenDeMentorPhuTrach}= useAppSelector(state=>state.chuyenDeReducer)
  // const { issueHocVienTheoTungChuyenDe } = useAppSelector((state) => state.IssueReducer);
  // const { DSChuyenDeHocVienDangKy } = useAppSelector((state) => state.chuyenDeReducer);
  // const { dsGroupForTaskHocVien } = useAppSelector((state) => state.groupForTaskReducer);
  const [search, setSearch] = useState('')
  const [type, SetType] = useState<number>(0)
  const [typeCurrent, SetTypetypeCurrent] = useState<number>(0)
  const styleDraw = {
    animation: open ? "" : "",
  };

  const onSearchBt = (value: string) => {
    setSearch(value)
  }
  const handleChangeSelect = (value: string) => {
    console.log(`selected ${value}`)
    SetType(Number(value))
  }


  const Showds=()=>{
    console.log("😀 ~ file: DanhSachChat.tsx:93 ~ Showds ~ dsHocVienByMentorIdSet:", dsHocVienByMentorIdSet)
    console.log("😀 ~ file: DanhSachChat.tsx:93 ~ Showds ~ dsHocVienByMentorId:", dsHocVienByMentorId)
    console.log("😀 ~ file: DanhSachChat.tsx:93 ~ Showds ~ DSChuyenDeMentorPhuTrach:", DSChuyenDeMentorPhuTrach)
    const newDs = dsHocVienByMentorIdSet
      .map((e) => {
        const index = dsHocVienByMentorId.findIndex((nd) => nd.id === e.nguoiDungId)
        if (index !== -1) {
          return { ...dsHocVienByMentorId[index], chuyenDeId: e.chuyenDeId }
        } else return null
      })
      .filter((e): e is Exclude<typeof e, null> => e !== null)
    console.log(newDs)
    const renderOptione = () => {
      return DSChuyenDeMentorPhuTrach.map((e) => {
        return { value: e.id, label: e.tenChuyenDe }
      })
    }
    return (
      <div className='NoiDungDsHocVien'>
        {/* <Search
          className='inputSearch'
          placeholder='Filter Theo Tên Học Viên ...'
          onChange={(e) => {
            onSearchBt(e.target.value)
          }}
          value={search}
          style={{ width: '100%' }}
        /> */}
     <div className='inputSearch'>
  <i className='fa-solid fa-magnifying-glass menuItem cursor_pointed btnHover'></i>
  <input
  placeholder='Filter Theo Tên Học Viên ...'
  onChange={(e) => {
    onSearchBt(e.target.value)
  }}
  value={search}
    // onChange={handleInputChange} onKeyPress={handleKeyPress}
  />
</div>

        <Select
          defaultValue={
            DSChuyenDeMentorPhuTrach && DSChuyenDeMentorPhuTrach.length
              ? DSChuyenDeMentorPhuTrach[0].tenChuyenDe
              : 'Chưa có chuyên đề phụ trách'
          }
          // style={{ width: 120 }}
          onChange={handleChangeSelect}
          options={renderOptione() ?? []}
        />
        <div className='dschat'>
          {newDs
            .filter((e) => e.chuyenDeId === type)
            .filter((student) => student.hoTen.toLowerCase().includes(search.toLowerCase()))
            .map((hv, index) => {
              // console.log(hv);
              return (
                <div key={'hvmentor phu trac' + index} className='person'>
                  <Badge count={0}>
                    {hv?.avatar ? (
                      <Avatar shape='circle' src={hv?.avatar} />
                    ) : (
                      <Avatar
                        shape='circle'
                        style={{
                          backgroundColor: randomColor(),
                          fontSize: '10px'
                        }}
                      >
                        {lastName(hv?.hoTen)}
                      </Avatar>
                    )}
                  </Badge>
                  <p className='person__name'>{hv?.hoTen}</p>
                  <div className='icon mess'>
                    <MessageOutlined />
                    <ExclamationOutlined />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }

  // const renderGroupAvatar= (arr)=>{
  //  return arr.map((e,i)=>{
  //     return    <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
  //   })
  // }

  const PushDataTabChat =async (e:DataGroupChat) => {
    // if(myUser){
    //   if(e?.dsMentor)
    //   await config.setStoreJson("ApiChat2",{chuyenDeId:e?.id,hocVienId:myUser?.id})
    //   else if(e?.danhSachHocVien)
    //   await config.setStoreJson("ApiChat1",{gftId:e?.id})
    // }
    if (areObjectsEqual(e, dataChat1)) {
      dispatch(ChangeActiveChat1());
    } else if (areObjectsEqual(e, dataChat2)) {
       dispatch(ChangeActiveChat2());
    } else {
      if (ActiveCurrent) {
        dispatch(updateDataChat2(e));
        !ActiveChat2 &&  dispatch(ChangeActiveChat2());
        dispatch(changeCurrentActive());
      } else {
        dispatch(updateDataChat1(e));
        !ActiveChat1 && dispatch(ChangeActiveChat1());
        dispatch(changeCurrentActive());
      }
    }
    // if (areObjectsEqual(e, dataChat1)) {
    //   !ActiveChat1 && dispatch(ChangeActiveChat1());
    // } else if (areObjectsEqual(e, dataChat2)) {
    //   !ActiveChat2 && dispatch(ChangeActiveChat2());
    // } else {
    //   if (ActiveCurrent) {
    //     dispatch(updateDataChat2(e));
    //     dispatch(ChangeActiveChat2());
    //     dispatch(changeCurrentActive());
    //   } else {
    //     dispatch(updateDataChat1(e));
    //     dispatch(ChangeActiveChat1());
    //     dispatch(changeCurrentActive());
    //   }
    // }

  
  };

  const handleClickGroupChuyenDe =async (e:DataGroupChat) => {
   
   await PushDataTabChat(e);
    const danhSachPopup = config.getStoreJson("LIST_POPUP") as DataGroupChat[];
    if (danhSachPopup) {

      if (!isObjectInArray(danhSachPopup,e)) {
        // console.log('check popup',!danhSachPopup.includes(e),!isObjectInArray(danhSachPopup,e));
        // console.log('thêm vào ' );
        danhSachPopup.unshift(e);}
      if (danhSachPopup.length > 4) {
        // console.log('xoa di');
        danhSachPopup.pop()}
        config.setStoreJson("LIST_POPUP", [...danhSachPopup]);
    } 
    else
    config.setStoreJson("LIST_POPUP", [e]);

     
  

    //CheckTab

    // Nếu ko có issue
    //Đóng draw
    closeDrawer();
  };
  // if(open)
  //The goupTask
  const renderDsChatG = () => {
    // return dsGroupForTaskHocVien?.map((e, i) => {
    //   return (
    //     <div
    //       onClick={() => {
    //         handleClickGroupChuyenDe(e);
    //       }}
    //       key={i + " draw_group_chat_item"}
    //       className="draw_group_chat_item p-2 row items-center"
    //     >
    //      <div className="col-1">
    //      <Avatar
    //         style={{
    //           backgroundColor: ColorList[randomNumber],
    //           verticalAlign: "middle",
    //         }}
    //       >
    //         {e?.id}
    //       </Avatar>
    //      </div>
    //       <div className="col flex flex-col gap-1">
    //         <p>Chuyên Đề : {e?.tenChuyenDe}</p>
    //         {e.tenBaiHoc && <p>{`Task: ${e.tenBaiHoc}`}</p>}
    //       </div>
    //     </div>
    //   );
    // });
  };
  //! Theo tung chuyen de
  const renderDsChatCd = () => {
    // return DSChuyenDeHocVienDangKy?.map((e, i) => {
    //   const index = issueHocVienTheoTungChuyenDe?.findIndex(
    //     (issue) => issue?.chuyenDeId == e?.id
    //   );
    //   return (
    //     <div
    //       onClick={() => {
    //         handleClickGroupChuyenDe(e);
    //       }}
    //       key={i + " draw_group_chat_item"}
    //       className="draw_group_chat_item p-2 row items-center"
    //     >
    //     <div className="col-1">
    //      {<LoadAvatar url={e.hinhAnh} conent={e.id} />}
    //     </div>
    //       <div className="col flex flex-col gap-1">
    //         <p>Chuyên Đề : {e?.tenChuyenDe}</p>
    //         {index >= 0 ? (
    //           <p>Issue: {issueHocVienTheoTungChuyenDe[index]?.title}</p>
    //         ) : (
    //           <></>
    //         )}
    //       </div>
    //     </div>
    //   );
    // });
  };

  useEffect(() => {
    if (
      DSChuyenDeMentorPhuTrach &&
      DSChuyenDeMentorPhuTrach.length > 0 &&
      typeCurrent !== DSChuyenDeMentorPhuTrach[0].id
    ) {
      SetType(DSChuyenDeMentorPhuTrach[0].id)
      SetTypetypeCurrent(DSChuyenDeMentorPhuTrach[0].id)
    }
  }, [DSChuyenDeMentorPhuTrach])
  return (
    <div
      className={`danhsachchat`}
      style={{
        zIndex: open ? "10000" : "-1",
        opacity: open ? "1" : "0",
      }}
    >
      <div
        style={{
          opacity: open ? "1" : "0",
          display: open ? "block" : "none",
          transition: "all 1s",
        }}
        className="overLay_dschat"
        onClick={closeDrawer}
      >
        DanhSachChat
      </div>
      <div
        className={`draw_dsChat`}
        style={{
          transform: open ? "translateX(0)" : "translateX(100%)",
          transition: "all 0.3s",
        }}
      >
        <div className="draw_dsChat_head">
          <h5 className="font-bold text-white uppercase">Danh Sách Chat</h5>
          <button
            
            className="d-flex btn btn-outline-warning rounded-sm"
            onClick={closeDrawer}
          >
           <CloseOutlined/>
          </button>
        </div>
        <div className="draw_dsChat_content text-lg font-bold text-[#7E65D0]">
          {/* <Collapse
            accordion
            defaultActiveKey={
              DSChuyenDeHocVienDangKy && DSChuyenDeHocVienDangKy?.length > 0
                ? ["1"]
                : []
            }
          >
            {DSChuyenDeHocVienDangKy && DSChuyenDeHocVienDangKy?.length > 0 ? (
              <Panel
                className="draw_group_chat"
                header="Danh sách theo chuyên đề"
                key="1"
              >
                <div>{renderDsChatCd()}</div>
              </Panel>
            ) : (
              <></>
            )}
            {dsGroupForTaskHocVien && dsGroupForTaskHocVien?.length > 0 ? (
              <Panel
                className="draw_group_chat"
                header="Danh sách theo group task"
                key="2"
              >
                <div>{renderDsChatG()}</div>
              </Panel>
            ) : (
              <></>
            )}
          </Collapse> */}
          {Showds()}
        </div>
      </div>
    </div>
  );
  // else
  // return <></>
};

export default DanhSachChat;
