import { Progress } from "antd";
import { ModalCSS } from "../../GroupChat/GroupChat";
import { NguoiDungType } from "../../../Types/nguoiDungType";
import React from "react";
import { NguoiDungGroupForTask } from "../../../Types/groupForTask";
interface props{
    hovVien:NguoiDungGroupForTask
}
const thongTinHocVien:React.FC<props> = ({hovVien}) => {
    return <>
    <ModalCSS>
      <div className="tilte">
        <i className="fa-solid fa-user"></i>
        <p>Thông Tin Học Tập Của {hovVien.hoTen} </p>
      </div>
      <div className="content">
        <div className="left">
          <div className="head">
            <div className="d-flex justify-content-between align-items-center">
              <p>Front End Từ Zero Tới Đi làm</p>
              <p>75%</p>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p>Dự Kiến Hoàn Thành </p>
            <p>29/09/2023</p>
          </div>
          <Progress
            strokeColor={{ from: "#EEBE47", to: "#e9e0cb" }}
            trailColor={"#8C5D31"}
            showInfo={false}
            percent={75}
            status="active"
          />
          <div className="thongTin">
            <div className="item">
              <i className="fa-solid fa-star"></i>
              <p>Tổng số task đã hoàn thành (45/50) </p>
            </div>{" "}
            <div className="item">
              <i className="fa-solid fa-star"></i>
              <p>Tổng số bài trắc nghiệm đã hoàn thành (4/5) </p>
            </div>{" "}
            <div className="item">
              <i className="fa-solid fa-star"></i>
              <p>Tổng số bài test run code (4/5) </p>
            </div>{" "}
            <div className="item">
              <i className="fa-solid fa-star"></i>
              <p>Số capstone project đã hoàn thành (2/3) </p>
            </div>{" "}
            <div className="item">
              <i className="fa-solid fa-star"></i>
              <p>Số capstone project đã hoàn thành (2/3) </p>
            </div>
          </div>
        </div>
        <div className="right">
          <button
            className="btnThongTin main"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#thongtin"
            aria-expanded="true"
            aria-controls="thongtin"
          >
            Front End Từ Zero Tới Đi Làm
          </button>
          <div className="collapse show" id="thongtin">
            <button
              className="btnThongTin sub"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#captone"
              aria-expanded="true"
              aria-controls="captone"
            >
              Capstone
            </button>
            <div className="collapse show" id="captone">
              <div className="noidung">
                <div className="item">
                  <p>Capstone HTML5-CSS3 </p>
                  <p>100</p>
                </div>{" "}
                <div className="item">
                  <p>Capstone BOOTSTRAP </p>
                  <p>100</p>
                </div>{" "}
                <div className="item">
                  <p>Capstone REACTJS </p>
                  <p>100</p>
                </div>{" "}
                <div className="item">
                  <p>Capstone Cuối Khóa </p>
                  <p>100</p>
                </div>
              </div>
            </div>{" "}
            <button
              className="btnThongTin sub "
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#baitaptuan"
              aria-expanded="true"
              aria-controls="baitaptuan"
            >
              Bài Tập Tuần
            </button>
            <div className="collapse show" id="baitaptuan">
              <div className="noidung">
                <div className="item">
                  <p>Bài Tập Tuần 1</p>
                  <p>100</p>
                </div>{" "}
                <div className="item">
                  <p>Bài Tập Tuần 3</p>
                  <p>100</p>
                </div>{" "}
                <div className="item">
                  <p>ài Tập Tuần 4 </p>
                  <p>100</p>
                </div>{" "}
                <div className="item">
                  <p>Bài Tập Tuần 5</p>
                  <p>100</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalCSS>
  </>
}

export default thongTinHocVien;


