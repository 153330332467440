import { Segmented } from "antd";
import React, { useState } from "react";
import parse from "html-react-parser";

type Props = {
  current: number;
  danhSachYeuCau: any;
};

export default function SegementItemBaiTap({ danhSachYeuCau, current }: Props) {
  const [sege, setSege] = useState("hint");

  return (
    <>
      <Segmented
        value={sege}
        options={[
          { label: "Hint", value: "hint" },
          { label: "Kết quả", value: "ketQua" },
          { label: "Solution", value: "solution" },
          { label: "Video Solution", value: "videoSolution" },
        ]}
        onChange={(value) => {
          setSege(value as string);
        }}
      />
      <div className="w-100 overflow-auto">
        {danhSachYeuCau[current] && parse(danhSachYeuCau[current][sege])}
      </div>
    </>
  );
}
