import { DraggableId, DraggableLocation, TypeId } from "react-beautiful-dnd";
import { IData } from "../../../../Types/groupChatType";

/**
 *
 */
export interface DraggableRubric {
  draggableId: DraggableId;
  type: TypeId;
  source: DraggableLocation;
}

export const clone = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const onChange = (source: any, destination: any) => {
  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return true;
  }
  return false;
};
/**
 *
 */
export const deleteTask = (data: IData, { droppableId, index }: any) => {
  data = clone(data);
  data.columns[droppableId].taskIds.splice(index, 1);
  return data;
};
/**
 *
 */
export const addTask = (
  data: IData,
  { droppableId, index }: any,
  taskId: string
) => {
  data = clone(data);
  data.tasks[taskId].content.studentId =(droppableId);
  data.columns[droppableId].taskIds.splice(index, 0, taskId);
  return data;
};
