import { FC } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import styled from "styled-components";

import XDrag from "./drag";
import XDrop from "./drop";
import { IColumn, ITask } from "../../../../Types/groupChatType";


const CSS = styled.div`
  /* width: 100px; */
  /* min-height: 300px; */


  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  .backGround {
    border: 1px solid #31374a;
    border-radius: 5%;
    width: 100%;
    padding: 5px;
    min-height: 200px;
    /* background-color: wheat; */

    .tittle {
      text-align: center;
      word-wrap: break-word;
      width: 100%;
      min-height: 50px;
      line-height: 1.4;
      transition: all 0.2s;

      :hover {
        color: rgb(238, 190, 71);
        transform: scale(1.05);
       
      }
    }
    .task-drop {
      /* padding: 5px; */
      width: 100%;

      .card {
        border: 1px solid #31374a;
        margin-bottom: 0.5em;
        background-color: transparent;
        color: white;
        transition: all 0.2s;

        :hover {
          border: 2px solid #b16e4b;
          background-color: #1c1c1c;
          /* font-weight: bolder; */
          color: rgb(238, 190, 71);
        }
        :focus {
          background-color: black;
        }
        .content {
          text-align: center;
          padding: 5px;
        }
      }
    }
  }
`;

interface IXColumn {
  className?: string;
  column: IColumn;
  tasks: ITask[];
  provided?: DraggableProvided;
}

const XColumn: FC<IXColumn> = ({ column, tasks, provided }) => {

  return (
    <CSS>
      <div className="backGround">
        <h6 className="tittle" {...provided?.dragHandleProps}>
           {column.title}
        </h6>
        <XDrop droppableId={column.id} type="TASK" className="task-drop">
          {tasks.map((task, index) => (
            <XDrag draggableId={task.id} index={index} key={task.id}>
              <div className="card">
                <p className="content">{'Yêu cầu '+ task.id}</p>
              </div>
            </XDrag>
          ))}
        </XDrop>
      </div>
    </CSS>
  );
};

export default XColumn;
