import React from 'react'

type Props = {}

const TaoDocument = (props: Props) => {
  return (
    <div>TaoDocument</div>
  )
}

export default TaoDocument
