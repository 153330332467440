import React, { useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import "../../../assets/css/editor.css";
import { isJSON } from './EditorJS';

type Props = {
  onChange: (value: string) => void;
  value: string;
};

const EditorJava = (props: Props) => {
  const [code, setCode] = useState(() => {
    try {
      const parsedValue = JSON.parse(props.value);
      return typeof parsedValue === 'string' ? parsedValue : props.value;
    } catch (error) {
      return props.value;
    }
  });

  const handleEditorChange = (value: string | undefined, event: any) => {
    if (value !== undefined) {
      setCode(value);
      props.onChange(value);
    }
  };

  useEffect(() => {
    if (isJSON(props.value)) {
      try {
        const parsedValue = JSON.parse(props.value);
        setCode(parsedValue);
      } catch (error) {
        // Xử lý lỗi parse JSON nếu cần
        console.error('Error parsing JSON:', error);
      }
    } else {
      setCode(props.value);
    }
  }, [props.value]);

  return (
    <div className="custom-monaco-editor-wrapper">
      <Editor
        height="400px"
        defaultLanguage="java"
        theme="dark"
        value={code}
        onChange={handleEditorChange}
        options={{
          wordWrap: 'on',
          fontSize: 16,  // Kích thước font (mặc định là 14)
          fontWeight: 'bold'  // Đậm (có thể là 'normal' hoặc 'bold')
        }}
      />
    </div>
  );
};

export default EditorJava;
