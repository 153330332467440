import React, { useEffect } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { history } from "../../../index";
import { UsergroupAddOutlined, MessageOutlined } from "@ant-design/icons";
import { config } from "../../../util/config";

import styled from "styled-components";
import { Skeleton } from "antd";
import { NguoiDungGroupForTask } from "../../../Types/groupForTask";
import { NguoiDungType } from "../../../Types/nguoiDungType";

const ChatRoomCSS = styled.div`
  .Member__bg {
    background-color: wheat;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 15px 10px 15px;
    border-radius: 5%;

    .member {
      display: flex;
      height: 3rem;
      align-items: center;
      justify-content: space-between;

      color: wheat;
      background-color: #c69379;
      padding: 10px 5px;
      width: 100%;
      max-width: 250px;
      font-size: 1rem;
      cursor: pointer;

      border-radius: 15px;
      margin-bottom: 5px;
      transition: all 0.5s;

      span {
        font-size: 1rem;
        color: wheat;
        transition: all 0.5s;
      }

      :hover {
        font-size: 1.1rem;
        color: white;
        span {
          font-size: 1.1rem;
          color: white;
        }
      }
    }
  }
`;

const ChatRoom: React.FC = () => {
  const { arrDanhSachHocVien, arrDSNguoiDung,groupForTask,thongTinMentor,baiHoc } = useAppSelector(
    (state) => state.groupForTaskReducer
  );
  const myUser = config.getStoreJson("USER_LOGIN") as NguoiDungType;


  const handleOnClick = (room:string) => {
   const gftId= config.getStore("GROUP_FOR_TASK");
    const query = { taskId: gftId, room: room  };
    history.push({
      pathname: "/chat",
      search: new URLSearchParams(query).toString(),
    });
  };


  // const showDsThanhVien2 = (arr: string[]) => {
  //   return arr.map((item, index) => {
  //     if (index !== arr.length)
  //       return (
  //         <p
  //           className="member"
  //           key={`thanh vien ${item}`}
  //           onClick={() => {
  //             handleOnClick();
  //           }}
  //         >
  //           {" "}
  //           {item} <MessageOutlined />
  //         </p>
  //       );
  //   });
  // };

  const showMenber = (
    userId: string,
    dsThanhVien: string[],
    thongTinThanhVien: NguoiDungGroupForTask[]
  ) => {
    // console.log(userId,dsThanhVien,thongTinThanhVien)
    const index = dsThanhVien.findIndex((e) => e === userId);

    const arr =
      myUser.id !== groupForTask.mentorId
        ? thongTinThanhVien
            .map((tv, ind) => {
              if (ind > index)
                return {
                  name: tv.hoTen,
                  room: JSON.stringify([userId, tv.id]),
                };
              else if (ind < index)
                return {
                  name: tv.hoTen,
                  room: JSON.stringify([tv.id, userId]),
                };
            })
            .filter((a) => a?.name !== undefined)
        : thongTinThanhVien.map((tv) => {
            return {
              name: tv.hoTen,
              room: JSON.stringify([tv.id, thongTinMentor.id]),
            };
          });
    // console.log(arr)
    return [...arr];
  };

  const showDsThanhVien = (
    arr: ({ name: string; room: string } | undefined)[]
  ) => {
    return arr.map((nd, index) => {
      // console.log(nd)
      return (
        <h6
          className="member"
          key={`nd ${index}`}
          onClick={() => {
            if (
              arrDanhSachHocVien.includes(myUser.id) ||
              thongTinMentor.id === myUser.id 
            )
            {
              if(nd!==undefined)
              handleOnClick(nd.room)}
              // handleClickRoom(myUser.id, nd?.room!, taskId);
          }}
        >
          {nd?.name} <MessageOutlined />
        </h6>
      );
    });
  };



  return (
    <ChatRoomCSS>
      <h4 className="heading">
        <UsergroupAddOutlined /> Thành Viên
      </h4>
      {arrDanhSachHocVien.length ? (
        <div className="Member__bg">
       {showDsThanhVien(
          showMenber(myUser.id, arrDanhSachHocVien, arrDSNguoiDung)
        )}
        
          {/* {maNhomQuyen !== "MENTOR" ? (
        <>
          {" "}
          <h3>Phân Công Review Task</h3>
          <h4>{groupForTask.mentorId}</h4>
        </>
      ) : (
        ""
      )} */}
        </div>
      ) : (
        <Skeleton className="Member__bg" active />
      )}
      <h4 className="heading mt-3">
        <UsergroupAddOutlined /> NHÓM
      </h4>
       <div className="Member__bg mt-3">
        <h6
          className="member"
          onClick={() => {
            if (
              arrDanhSachHocVien.includes(myUser.id) ||
              thongTinMentor.id === myUser.id
            ) {
              // console.log(
              //   JSON.stringify([...arrDanhSachHocVien, groupForTask.mentorId])
              // );
              handleOnClick(JSON.stringify([...arrDanhSachHocVien, groupForTask.mentorId]));
            }
          }}
        >
          Nhóm Chat TASK {baiHoc.tenBaiHoc} <MessageOutlined />
        </h6>
      </div>
    </ChatRoomCSS>
  );
};
export default ChatRoom;
