import { DanhSachTagModel } from "../Types/chuyenDeType"

type listType = {
    value: string | number
    label: string
    time?: string
}

export const listLoaiTaskCreate: listType[] = [
    { value: "VIDEO", label: 'Video', time: "phút" },
    { value: "BAITAP", label: 'Bài tập nộp', time: "ngày" },
    { value: "HOMEWORK", label: 'Về nhà', time: "ngày" },
    // { value: "DISCUSS", label: 'Thảo luận', time: "giờ" },
    { value: "CAPS", label: 'Capstone', time: "ngày" },
    { value: "DUAN", label: 'Dự án cuối khóa', time: "ngày" },
    { value: "TAILIEU", label: 'Tài liệu', time: "phút" },
    { value: "QUIZ", label: 'Trắc nghiệm', time: "phút" },
    { value: "RUNCODE", label: 'Runcode', time: "phút" },
    { value: "RECORD", label: 'Record', time: "phút" },

]

export const listLoaiTask: listType[] = [
    { value: "VIDEO", label: 'Video' },
    { value: "BAITAP", label: 'Bài tập nộp' },
    { value: "HOMEWORK", label: 'Về nhà', time: "ngày" },
    // { value: "DISCUSS", label: 'Thảo luận' },
    { value: "CAPS", label: 'Capstone' },
    { value: "DUAN", label: 'Dự án cuối khóa' },
    { value: "TAILIEU", label: 'Tài liệu' },
    { value: "QUIZ", label: 'Trắc nghiệm' },
    { value: "RUNCODE", label: 'Run code' },
    { value: "RECORD", label: 'Record', time: "phút" },
    { value: "EVENT", label: 'Sự kiện' },

]
export const getListTag = (danhSachTag: DanhSachTagModel[], maTag: string) => {

    let tag: DanhSachTagModel | undefined = danhSachTag.find(n => n.maTag == maTag);
    let tagList: listType[] = [];
    if (tag && tag.noiDung) {
        tagList = JSON.parse(tag?.noiDung);
    }

    return tagList;
}


// export const listTag: listType[] = [
//     { value: "HTML", label: 'HTML' },
//     { value: "CSS", label: 'Css' },
//     { value: "HTML5", label: 'HTML 5' },
//     { value: "CSS3", label: 'Css 3' },
//     { value: "SASS", label: 'Sass' },
//     { value: "GIT", label: 'Git' },
//     { value: "BS", label: 'Bootstrap' },
//     { value: "JS", label: 'JavaScript' },
//     { value: "ES", label: 'ES6' },
//     { value: "REACT", label: 'React' },
//     { value: "TS", label: 'TypeScript' },
//     { value: "NEXT", label: 'NextJS' },
//     { value: "SQL", label: 'SQL' },
//     { value: "EXJ", label: 'ExpressJS' },
//     { value: "ORM", label: 'ORM' },
//     { value: "NODE", label: 'NodeJS' },
//     { value: "DOCKER", label: 'Docker' },
//     { value: "NEST", label: 'NestJS' },
//     { value: "JAVA", label: 'Java' },

// ]
