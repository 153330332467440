import { message, Popconfirm, Segmented, Select, Table, Tag } from "antd";
import { SegmentedValue } from "antd/es/segmented";
import { ColumnsType } from "antd/es/table";
import React, { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getBaiHocApi } from "../../redux/BaiHocReducer/BaiHocReducer";
import { DispatchType, RootState } from "../../redux/configStore";
import { baiHocService } from "../../services/baiHocService";
import { BaiHocModel } from "../../Types/baiHocType";

import { getListTag, listLoaiTaskCreate } from "../../util/SelectOption";
import { NoiDungChuongHoc, NoiDungMonHoc } from "../../Types/monHocType";
import { categories, InitCode, RuncodeModel } from "../../Types/runCodeType";

type Props = {
  taskDetail: NoiDungMonHoc;
  updateTaskVaoMonHoc?: (
    baiHoc: BaiHocModel,
    maLoai: string,
    isRemove: boolean,
    chuongId: number
  ) => void;
  updateBestPracticeVaoChuyenDe?: (
    baiHoc: BaiHocModel,
    maLoai: string,
    isRemove: boolean,
    chuongId: number
  ) => void;
  modalNguonClose?: () => void
};

type listType = {
  value: string | number;
  label: string | JSX.Element;
};

const NguonThemTask = ({
  taskDetail,
  updateTaskVaoMonHoc,
  updateBestPracticeVaoChuyenDe,
  modalNguonClose
}: Props) => {
  const { arrBaiHoc } = useSelector((state: RootState) => state.baiHocReducer);

  let [searchParams, setSearchParams] = useSearchParams();
  const [maLoaiLoc, setMaLoaiLoc] = useState<SegmentedValue>("");

  const dispatch: DispatchType = useDispatch();
  const { arrTag } = useSelector((state: RootState) => state.chuyenDeReducer);

  const { arrQuestion } = useSelector(
    (state: RootState) => state.runcodeReducer
  );
  const { arrCategory } = useSelector(
    (state: RootState) => state.runcodeReducer
  );

  const arrCategoryOptions = arrCategory.map((category) => ({
    label: category.name,
    value: category.id,
    tag: "Category",
  }));

  const arrLanguageOptions = [
    {
      label: "Javascript",
      value: "js",
      tag: "Language",
    },
    {
      label: "C++",
      value: "cpp",
      tag: "Language",
    },
    {
      label: "Python",
      value: "py",
      tag: "Language",
    },
    {
      label: "Java",
      value: "java",
      tag: "Language",
    },
  ];

  const columns: ColumnsType<BaiHocModel> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Tiêu đề",
      dataIndex: "tenBaiHoc",
    },
    {
      title: "Level",
      dataIndex: "capDo",
    },
    {
      title: "Thời lượng",
      render: (_, record) => {
        let loaiTask = listLoaiTaskCreate.find((n) => n.value == record.maLoai);
        if (loaiTask)
          return (
            <Tag>
              {record.thoiLuong} {loaiTask.time}
            </Tag>
          );
      },
    },

    {
      title: "Tag",
      render: (_, record) => {
        const listTag = getListTag(arrTag, "EXTEND");
        try {
          let arrTagJson: string[] = record.tag && JSON.parse(record.tag);
          return arrTagJson?.map((item, index) => {
            return (
              <Tag key={index + " tag "} color="magenta">
                {listTag.find((n) => n.value === item)?.label}
              </Tag>
            );
          });
        } catch (error) {
          console.log(error, "Error parsing JSON:");
          return <Tag color="magenta">{record.tag}</Tag>;
        }
      },
    },
    {
      title: "Loại task",
      render: (_, record) => {
        let loaiTask = listLoaiTaskCreate.find((n) => n.value == record.maLoai);
        if (loaiTask) return <Tag color="blue">{loaiTask.label}</Tag>;
      },
    },
    {
      title: "Action",
      render: (_, record) => {
        return (
          <>
            <button
              className="btn btn-sm btn-success mx-2"
              onClick={() => {
                if (updateTaskVaoMonHoc && record && record.maLoai)
                  updateTaskVaoMonHoc(record, record.maLoai, false, 0);
                if (updateBestPracticeVaoChuyenDe && record && record.maLoai)
                  updateBestPracticeVaoChuyenDe(
                    record,
                    record.maLoai,
                    false,
                    0
                  );

                if (record.maLoai == "BAITAP" ||
                  record.maLoai == "DISCUSS" ||
                  record.maLoai == "CAPS" ||
                  record.maLoai == "DUAN") {
                  if (modalNguonClose)
                    modalNguonClose()
                }
              }}
            >
              <i className="fas fa-plus"></i> Thêm
            </button>
          </>
        );
      },
    },
  ];

  const columns_runcode: ColumnsType<RuncodeModel> = [
    {
      title: "ID",
      key: "id",
      align: "center",
      children: [
        {
          title: "Runcode",
          dataIndex: "question_id",
          key: "question_id",
          width: 50,
          align: "center",
        },
        {
          title: "TechX",
          dataIndex: "id",
          key: "id",
          width: 50,
          align: "center",
        }
      ],
    },
    {
      title: "Tiêu đề",
      dataIndex: ["vi", "question_title"],
      key: "question_title",
      width: 200,
      align: "center",
    },
    {
      title: "Problem",
      dataIndex: ["vi", "problem"],
      key: "problem",
      align: "center",
    },
    {
      title: "Category",
      dataIndex: "categories",
      key: "categories",
      width: 200,
      align: "center",
      render: (categories, record, index) => {
        return (
          <div key={index}>
            {categories?.map((item: categories) => (
              <Tag
                color="blue"
                key={item?.id}
                className="my-1"
                style={{ width: "100px", textAlign: "center" }}
              >
                {item?.name
                  ? item.name.length > 15
                    ? item.name.substring(0, 13) + "..."
                    : item.name
                  : ""}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "Ngôn ngữ hỗ trợ",
      dataIndex: "init_code",
      key: "init_code",
      width: 200,
      align: "center",
      render: (init_code, record, index) => {
        return (
          <div key={index}>
            {init_code?.map((lang: InitCode) => (
              <Tag
                color="blue"
                key={lang?._language}
                className="my-1"
                style={{ width: "100px", textAlign: "center" }}
              >
                {lang?._language}
              </Tag>
            ))}
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "action",
      key: "action",
      width: 250,
      render: (_, record) => {
        let baiHoc: BaiHocModel | undefined = arrBaiHoc?.find(
          (item) => item.id === record.id
        );
        // const defaultBaiHoc: BaiHocModel = {
        //     id: Number(record.question_id), // Gán giá trị của question_id
        //     tenBaiHoc: "",
        //     capDo: 0,
        //     tagSkill: "",
        //     tag: "",
        //     ghiChu: "",
        //     moTa: "",
        //     noiDung: JSON.stringify(record), // Gán giá trị của record
        //     thoiLuong: "",
        //     xemDemo: false,
        //     maLoai: "RUNCODE",
        //     coin: 0,
        //     kinhNghiem: 0,
        //     tichCuc: 0,
        //     phanYeuCau: "",
        //     loaiBaiTap: "",
        //     metaData: "",
        //     ngayTao: "",
        // };

        return (
          <>
            {/* <button
                            className="btn btn-sm btn-success mx-2"
                            onClick={() => {
                                if (updateTaskVaoMonHoc && record && defaultBaiHoc.maLoai) {
                                    updateTaskVaoMonHoc(defaultBaiHoc, defaultBaiHoc.maLoai, false, 0);
                                }
                            }}
                        >
                            <i className="fas fa-plus"></i> Thêm
                        </button> */}
            <button
              className="btn btn-sm btn-success mx-2"
              onClick={() => {
                if (updateTaskVaoMonHoc && baiHoc && baiHoc.maLoai)
                  updateTaskVaoMonHoc(baiHoc, baiHoc.maLoai, false, 0);
                if (updateBestPracticeVaoChuyenDe && baiHoc && baiHoc.maLoai)
                  updateBestPracticeVaoChuyenDe(
                    baiHoc,
                    baiHoc.maLoai,
                    false,
                    0
                  );
              }}
            >
              <i className="fas fa-plus"></i> Thêm
            </button>
          </>
        );
      },
    },
  ];

  let dataTask = arrBaiHoc;

  let tieuDe = searchParams.get("td");
  let tagFilter = searchParams.get("tag");
  let idCauHoi = searchParams.get("idCauHoi");

  let userLogin = localStorage.getItem("USER_LOGIN");
  let nguoiDung = userLogin ? JSON.parse(userLogin) : null;

  if (nguoiDung && nguoiDung.maNhomQuyen == "LECTURE" || nguoiDung.maNhomQuyen == "MENTOR") {
    if (dataTask) {
      dataTask = dataTask?.filter(item => {
        let metaData = item.metaData ? JSON.parse(item.metaData) : null;
        
        if (metaData && nguoiDung.id == metaData.nguoiTao) {

          
          return item

        }
      })

    }
  }
  dataTask =
    dataTask &&
    dataTask?.filter((n) => n.tenBaiHoc?.toLowerCase().indexOf(tieuDe ? tieuDe.toLowerCase() : "") != -1
    );

  if (taskDetail && taskDetail.maLoaiNhiemVu) {
    switch (taskDetail.maLoaiNhiemVu) {
      case "VIDEO": {
        dataTask =
          dataTask &&
          dataTask?.filter((n) => n.maLoai == taskDetail.maLoaiNhiemVu);

        break;
      }
      case "TAILIEU": {
        dataTask =
          dataTask &&
          dataTask?.filter((n) => n.maLoai == taskDetail.maLoaiNhiemVu);
        break;
      }
      case "QUIZ": {
        dataTask =
          dataTask &&
          dataTask?.filter((n) => n.maLoai == taskDetail.maLoaiNhiemVu);

        break;
      }
      case "RUNCODE": {
        dataTask =
          dataTask &&
          dataTask?.filter((n) => n.maLoai == taskDetail.maLoaiNhiemVu);
        break;
      }
      default: {
        dataTask =
          dataTask &&
          dataTask?.filter(
            (n) =>
              n.maLoai == "BAITAP" ||
              n.maLoai == "DISCUSS" ||
              n.maLoai == "CAPS" ||
              n.maLoai == "DUAN"
          );

        break;
      }
    }
  }

  let cusListTag = [
    { label: "Tất cả", value: "n" },
    { label: "Chưa có tag", value: "" },
    ...getListTag(arrTag, "EXTEND"),
  ];

  if (tagFilter != "n") {
    if (tagFilter == "")
      dataTask = dataTask && dataTask.filter((n) => n.tag == "[]");
    else
      dataTask =
        dataTask &&
        dataTask.filter(
          (n) => n.tag?.indexOf(tagFilter ? tagFilter : "") != -1
        );
  }

  if (idCauHoi) {
    dataTask = dataTask && dataTask.filter((n) => n.id == Number(idCauHoi));
  }

  // let filteredQuestions = arrQuestion;

  let dataRuncode = arrQuestion;

  if (taskDetail.maLoaiNhiemVu === "RUNCODE" && dataTask) {
    let noiDung = dataTask?.map((task) => {

      let data = {
        id: task.id,
        question_id: Number(task.noiDung && JSON.parse(task.noiDung)[0]),
      };
      return data;
    });
    console.log(noiDung)

    dataRuncode = arrQuestion?.filter((runcode) =>{

     return noiDung.some((data) => data.question_id === runcode.question_id)}
    );


    // Gộp các trường 'id' từ 'noiDung' vào 'dataRuncode'
    dataRuncode = dataRuncode.map((runcode) => {
      const correspondingData = noiDung.find(
        (data) => data.question_id === runcode.question_id
      );
      if (correspondingData) {
        return { ...runcode, id: correspondingData.id };
      }
      return runcode;
    });

    // Sắp xếp lại mảng 'dataRuncode' theo thuộc tính 'id'
    dataRuncode.sort((a, b) => a.id - b.id);
  }

  // let languageFilter: any = null;
  // let categoryFilter: any = null;

  // if (tagFilter) {
  //     const parsedTagFilter = JSON.parse(tagFilter);
  //     // Tiếp tục xử lý với parsedTagFilter...
  //     languageFilter = parsedTagFilter.tag === "Language" ? parsedTagFilter.value : null;
  //     categoryFilter = parsedTagFilter.tag === "Category" ? parsedTagFilter.label : null;
  // }

  // if (tieuDe) {
  //     filteredQuestions =
  //         filteredQuestions &&
  //         filteredQuestions?.filter(
  //             (question) =>
  //                 question?.vi?.question_title
  //                     ?.toLowerCase()
  //                     .indexOf(tieuDe ? tieuDe.toLowerCase() : "") != -1 ||
  //                 question?.en?.question_title
  //                     ?.toLowerCase()
  //                     .indexOf(tieuDe ? tieuDe.toLowerCase() : "") != -1

  //         );
  // }

  // if (languageFilter) {
  //     filteredQuestions =
  //         filteredQuestions &&
  //         filteredQuestions.filter((question) =>
  //             question?.init_code?.some((lang) => lang?._language?.toLowerCase() === languageFilter?.toLowerCase())
  //         );
  // }

  // if (categoryFilter) {
  //     filteredQuestions =
  //         filteredQuestions &&
  //         filteredQuestions.filter((question) =>
  //             question?.categories?.some((category) => category.name === categoryFilter)
  //         );
  // }

  return (
    <>
      <div className=" row">
        <div className="col-2 py-2">
          <label className="form-label">Lọc tiêu đề: </label>

          <div className="input-group mb-3">
            <input
              id="txt-search"
              type="text"
              className="form-control"
              placeholder="Tiêu đề"
            />
            <button
              className="btn btn-primary"
              onClick={() => {
                let element: any = document.querySelector("#txt-search");
                searchParams.set("td", element.value);
                setSearchParams(searchParams);
              }}
            >
              Search
            </button>
          </div>
        </div>
        <div className="col-2 py-2">
          <label className="form-label">Lọc Tag: </label>

          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Chọn tag skill"
            optionFilterProp="children"
            onChange={(value) => {
              searchParams.set("tag", value);
              setSearchParams(searchParams);
            }}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={cusListTag}
          />
        </div>
        <div className="col-12">
          {dataTask && (
            <Table
              columns={
                taskDetail.maLoaiNhiemVu === "RUNCODE"
                  ? columns_runcode
                  : columns
              }
              dataSource={
                taskDetail.maLoaiNhiemVu === "RUNCODE" ? dataRuncode : dataTask
              }
            />
          )}
          {/* {taskDetail.maLoaiNhiemVu === "RUNCODE" ? (
                        <>
                            <Table columns={columns_runcode} dataSource={filteredQuestions || []} pagination={{ pageSize: 5 }} />
                        </>
                    ) : (
                        <Table columns={columns} dataSource={dataTask || []} />
                    )} */}
        </div>
      </div>
    </>
  );
};

export default NguonThemTask;
