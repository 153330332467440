import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { http } from '../../util/config';
import { DispatchType } from '../configStore';
import { PracticeModel } from '../../Types/practiceType';
import { practiceService } from '../../services/practiceService';


type practiceState = {
    arrPractice: PracticeModel[] | null,
}
const initialState: practiceState = {
    arrPractice: [],
}

const practiceReducer = createSlice({
    name: 'practiceReducer',
    initialState,
    reducers: {
        getPracticeAction: (state: practiceState, action: PayloadAction<PracticeModel[]>) => {
            state.arrPractice = action.payload;
        }
    }
});

export const { getPracticeAction } = practiceReducer.actions
export default practiceReducer.reducer
//--------- action async ------------


export const getPracticeApi = () => {

    return async (dispatch: DispatchType) => {
        try {
            const result = await practiceService.layDanhSachPractice();
        const action: PayloadAction<PracticeModel[]> = getPracticeAction(result.data.content);
        dispatch(action);
        } catch (error) {
            console.log('lỗi getPracticeApi');
            console.log(error);
        }
    }
}
