import React, { FC, ReactNode, useEffect, useState } from "react";
import { Draggable, DraggableProps, DraggableProvided } from "react-beautiful-dnd";
import { useAppSelector } from "../../../../redux/hooks";
import { config } from "../../../../util/config";

interface IXDrag extends Omit<DraggableProps, "children"> {
  className?: string;
  children: React.ReactElement<{ provided: DraggableProvided }>;
  dragAll?: boolean;
}

const XDrag: FC<IXDrag> = ({ className, children, dragAll, ...props }) => {
  // console.log(React.isValidElement(children));
  const [isAllowedToDrop,setIsAllowedToDrop] = useState(false)
  const {groupForTask}= useAppSelector(state=>state.groupForTaskReducer)
  const myUser = config.getStoreJson('USER_LOGIN');
  // console.log(isAllowedToDrop)
  useEffect(()=>{
    if(groupForTask.mentorId&&groupForTask.mentorId===myUser.id)
    setIsAllowedToDrop(true)
    else
    setIsAllowedToDrop(false)
  },[groupForTask])

  if (!React.isValidElement(children)) return <div />;
  return (
    <Draggable {...props} isDragDisabled={!isAllowedToDrop}>
      {(provided) => {
        const dragHandleProps = dragAll ? provided.dragHandleProps : {};
        return (
          <div
            
            // style={{width:'200px'}}
            className={className}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...dragHandleProps}
            
          >
            {React.cloneElement(children, { provided })}
          </div>
        );
      }}
    </Draggable>
  );
};

XDrag.defaultProps = {
  dragAll: true
};

export default XDrag;
