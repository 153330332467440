import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./UserReducer/userReducer";
import chuyenDeReducer from "./ChuyenDeReducer/chuyenDeReducer";
import monHocReducer from "./MonHocReducer/monHocReducer";
import baiHocReducer from "./BaiHocReducer/BaiHocReducer";
import groupForTaskReducer from "./GroupForTaskReducer/GroupForTaskReducer";
import nopBaiReducer from "./NopBaiReducer/nopBaiReducer"
import runcodeReducer from "./RunCodeReducer/runcodeReducer"
import messageReducer from "./MessageReducer/MessageReducer";
import LuyenTapReducer from "./LuyenTapReducer/LuyenTapReducer";
import IssueReducer from "./IssueReducer/IssueReducer";
import groupChatReducer from "./GroupChatReducer/groupChatReducer";

import practiceReducer from "./PracticeReducer/practiceReducer";
import heThongReducer from "./HeThongReducer/heThongReducer";

import vatPhamReducer from "./VatPhamReducer/vatPhamReducer";
import danhGiaReducer from "./DanhGiaReducer/danhGiaReducer";
import StackChatReducer from "./StackChatReducer/StackChatReducer";

export const store = configureStore({
  reducer: {
    baiHocReducer,
    monHocReducer,
    practiceReducer,
    chuyenDeReducer,
    userReducer,
    groupChatReducer,
    groupForTaskReducer,
    nopBaiReducer,
    messageReducer,
    runcodeReducer,
    LuyenTapReducer,
    IssueReducer,
    heThongReducer,
    vatPhamReducer,
    danhGiaReducer,
    StackChatReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type DispatchType = typeof store.dispatch;
