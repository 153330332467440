import { InputNumber, message } from 'antd'
import { CKEditor } from 'ckeditor4-react'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { MentorChamBaiModel, NopBaiViewMentor, trangThai } from '../../../Types/nopBaiType'
import { set } from 'lodash'
import { Link } from 'react-router-dom'
import { config } from '../../../util/config'
import { NguoiDungType } from '../../../Types/nguoiDungType'
import { LoadingOutlined } from '@ant-design/icons'
import { useAppDispatch } from '../../../redux/hooks'
import { GetDsNopBaiByMentorIdApi, putMentorChamBai } from '../../../redux/NopBaiReducer/nopBaiReducer'

function ChamDiem({ data, handleCancelModal, callAPI }: { data: NopBaiViewMentor; handleCancelModal: () => void; callAPI: () => Promise<void> }) {
  const user = config.getStoreJson('USER_LOGIN') as NguoiDungType
  
  const dispatch = useAppDispatch();
  const { baiLam } = data
  const [loadingChamBai, setLoadingChamBai] = useState(true)
  const [loadingNopLại, setLoadingNopLai] = useState(true)
  const myUser = config.getStoreJson('USER_LOGIN') as NguoiDungType
  let dsBaiLam: string[] = []
  try {
    dsBaiLam = [...(JSON.parse(baiLam) as string[])]
  } catch (error) {
    console.log(error)
  }

  const [nhanXet, setNhanXet] = useState(data.nhanXet)
  const [diem, setDiem] = useState(data.diem)

  const onChangeChamDiem = (value: number | null) => {

    if (value || value === 0) setDiem(value)
  }
  const onEditorChange = (evt: any) => {
    // console.log(evt.editor.getData());

    setNhanXet(evt.editor.getData())
  }

  const chamDiem = async () => {
    if (loadingNopLại) {

      setLoadingChamBai(false)
      if (myUser && data) {
        const MentorChamBai: MentorChamBaiModel = {
          id: data.id,
          diem: Number(diem) ?? 0,
          nguoiCham: myUser.id,
          nhanXet: nhanXet ?? '',
          trangThai: 'DA_CHAM'
        }
        try {

          await dispatch(putMentorChamBai(myUser.id, MentorChamBai));
          await callAPI()
          message.success("Đã lưu !")

          
        } catch (error) {
          message.error("Không lưu được !")

          console.error(error);
        }
        setLoadingChamBai(true)

      }
    }
  }
  const nopLai = async () => {
    if (loadingChamBai) {
      setLoadingNopLai(false)
      if (myUser && data) {
        const MentorChamBai: MentorChamBaiModel = {
          id: data.id,
          diem: Number(diem) ?? 0,
          nguoiCham: myUser.id,
          nhanXet: nhanXet ?? '',
          trangThai: 'NOP_LAI'
        }
        try {

          await dispatch(putMentorChamBai(myUser.id, MentorChamBai));
          await callAPI()
          setLoadingNopLai(true)
          
          message.success("Đã lưu !")

        } catch (error) {
          console.error(error);
          setLoadingNopLai(true)
        }
        setLoadingNopLai(true)

      }
    }
  }
  const huy = () => {
    setDiem(0)
    setNhanXet('')
    handleCancelModal()
  }


  useEffect(() => {

    setDiem(data.diem ?? 0)
    setNhanXet(data.nhanXet ?? '')


  }, [data, data.id])
  useEffect(() => {
    return () => {
      // setNhanXet('')
      // setDiem(0)
      console.log('đóng')
    }
  }, [])

  return (
    <Fragment>
      <div className='chamdiem_title'>Danh sách file nộp</div>
      <div className='filenop_table my-3'>
        {dsBaiLam.length > 0 ? <table className='table text-light'>
          <thead>
            <tr>
              <th scope='col'>Loại file nộp</th>
              <th scope='col'>File nộp</th>
            </tr>
          </thead>
          <tbody>
            {dsBaiLam.length > 0 && dsBaiLam[0] && (
              <tr>
                <td>Source code</td>
                <td>
                  <Link target='_blank' to={dsBaiLam[0]}>
                    Xem
                  </Link>
                </td>
              </tr>
            )}
            {dsBaiLam.length > 1 && dsBaiLam[1] && (
              <tr>
                <td>Video</td>
                <td>
                  <Link target='_blank' to={dsBaiLam[1]}>
                    Xem
                  </Link>
                </td>
              </tr>
            )}
            {dsBaiLam.length > 2 && dsBaiLam[2] && (
              <tr>
                <th>Deploy</th>
                <td>
                  <Link target='_blank' to={dsBaiLam[2]}>
                    Xem
                  </Link>
                </td>
              </tr>
            )}
          </tbody>
        </table> : <span>Học viên chưa nộp link bài tập</span>}
      </div>
      <div className='chamdiem_inpt'>
        <span className='chamdiem_title'>Điểm</span>
        <InputNumber key={'input ' + data.id ?? 'a'} min={0} max={100} defaultValue={diem} value={diem} onChange={onChangeChamDiem} />
      </div>
      <div className='nhanxet mt-3'>
        <span className='chamdiem_title'>Nhận xét</span>
        <div className='nhanxet_content'>
          <div className='nhanxet'>
            {
              <CKEditor
                key={data.id ?? 'ck'}
                initData={data.nhanXet}
                data={nhanXet}
                name='nhanXet'
                config={{
                  toolbarGroups: [
                    {
                      name: 'document',
                      groups: ['mode', 'document', 'doctools']
                    },
                    { name: 'clipboard', groups: ['clipboard', 'undo'] },
                    {
                      name: 'editing',
                      groups: ['find', 'selection', 'spellchecker']
                    },
                    { name: 'forms' },
                    '/',
                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                    {
                      name: 'paragraph',
                      groups: ['list', 'indent', 'blocks', 'align', 'bidi']
                    },
                    { name: 'links' },
                    { name: 'insert' },
                    '/',
                    { name: 'styles' },
                    { name: 'colors' },
                    { name: 'tools' },
                    { name: 'others' },
                    { name: 'about' }
                  ],
                  extraPlugins: 'codesnippet' // Thêm plugin codesnippet vào danh sách extraPlugins
                }}
                onChange={onEditorChange}
              />
            }
          </div>
        </div>
      </div>
      <div className='chamdiem_action mt-3'>
        <button onClick={chamDiem} className='btn-cham'>
          {loadingChamBai ? 'Chấm' : <LoadingOutlined />}
        </button>
        <button onClick={nopLai} className='btn-noplai'>
          {' '}
          {loadingNopLại ? 'Nộp lại' : <LoadingOutlined />}
        </button>
        <button onClick={huy} className='btn-huy'>
          Hủy
        </button>
      </div>
    </Fragment>
  )
}

export default ChamDiem
