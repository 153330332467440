import React, { useEffect } from 'react'

type Props = {
    data: any
    setData: any
}
export const TextEditor = ({
    data,
    setData
}: Props) => {


    // life cycle
    useEffect(() => {

        
        fillDataFrame("#iframe_noiDung", data, 'react-editor-data');


    }, [data]);

    const fillDataFrame = (id: string, value: string, type: string) => {
        const iframe: any = document.querySelector(id);

        iframe && iframe.contentWindow.postMessage({
            type,
            message: value,
        }, "*");
    }


    // lắng nghe từ ckeditor server khác khi đã tạo iframe
    // set data từ react lên iframe
    window.addEventListener('message', (ev: MessageEvent<{ type: string, message: string }>) => {


        if (ev.data.type == 'react-editor-fill') {
            fillDataFrame("#iframe_noiDung", "", 'react-editor-data');
        }
        else {
            return
        }
    })


    // lay data tu iframe ve react
    // set data cho ckEditor có ckFinder

    const handleFrame = () => {

        //get data từ iframe về react
        const handler = (ev: MessageEvent<{ type: string, message: string }>) => {

            if (ev.data.type != "react-editor-fill" && ev.data.message != undefined) {

                setData(ev.data.message);


            }
        }

        window.addEventListener('message', handler);

        // Don't forget to remove addEventListener
        return () => window.removeEventListener('message', handler)
    }

    handleFrame();


    return (
        <div className='contaier' style={{ width: "100%", height: "85vh" }}>
            <iframe id="iframe_noiDung" width="100%" height="100%" src='https://datatext.cybersoft.edu.vn?id=noiDung'></iframe>
        </div>
    )
}