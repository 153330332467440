import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  DatePickerProps,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Grid,
  Input,
  InputNumber,
  List,
  Modal,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Slider,
  Space,
  Steps,
  Switch,
  Tag,
  Tooltip,
  Typography,
  message
} from 'antd'
import { StepProps } from 'antd/es/steps'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MonHocModel, NoiDungChuongHoc, NoiDungMonHoc } from '../../Types/monHocType'
import { listObject_move } from '../../util/adminUti'
import { monHocService } from '../../services/monHocService'
import { getMonHocApi } from '../../redux/MonHocReducer/monHocReducer'
import { DispatchType, RootState } from '../../redux/configStore'
import { useDispatch } from 'react-redux'

import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { SortableItem } from './SortableItem'
import BaiHocAbove from '../HocTap/BaiHocAbove'
import { BaiHocModel } from '../../Types/baiHocType'
import { useSelector } from 'react-redux'
import NguonThemTask from './NguonThemTask'
import { useNavigate, useParams } from 'react-router-dom'
import { RuncodeModel } from '../../Types/runCodeType'
import { CreatePracticeModel, PracticeModel } from '../../Types/practiceType'
import {
  ArrowLeftOutlined,
  BackwardOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { practiceService } from '../../services/practiceService'
import { getPracticeApi } from '../../redux/PracticeReducer/practiceReducer'
import { chuyenDeService } from '../../services/chuyenDeService'
import { getChuyenDeApi } from '../../redux/ChuyenDeReducer/chuyenDeReducer'
import { getListTag } from '../../util/SelectOption'
import { getBaiHocAction, getBaiHocApi } from '../../redux/BaiHocReducer/BaiHocReducer'
import { BoxItem, Reward } from '../../Types/vatPhamType'
import Title from 'antd/es/typography/Title'

import { fileService } from '../../services/fileService'
import { ControlBar, PlaybackRateMenuButton, Player } from 'video-react'
import HLSSource from '../VideoPlayer/HLSSource'

const { Panel } = Collapse

type TypeData = {
  [key: string]: any
}

type listType = {
  value: string | number
  label: string | JSX.Element
}

type Props = {
  chuyenDeID: number
  isPractice?: boolean | null | undefined
}

// interface Item {
//     id: string;
//     value: number;
//     percent: number;
// }

interface RewardHook {
  point: number
  box: BoxItem[]
}

const availableItems = [
  { id: 'coin', value: 0, percent: 0 },
  { id: 'exp', value: 0, percent: 0 },
  { id: 'ene', value: 0, percent: 0 }
]

// const { useBreakpoint } = Grid;
const ThemTaskForm = ({ chuyenDeID, isPractice }: Props) => {
  // const [data, setData] = useState<TypeData>({});

  const [sourceRecord, setSourceRecord] = useState("");

  const [drawerVisible, setDrawerVisible] = useState(false)

  const { items } = useSelector((state: RootState) => state.vatPhamReducer)

  const [chuongHoc, setChuongHoc] = useState<NoiDungChuongHoc>({
    chuongHoc: 0,
    tenChuong: '',
    danhSachBaiHoc: []
  })
  const [baiHocDetail, setBaiHocDetail] = useState<BaiHocModel>({
    id: 0,
    maLoai: 'BAITAP'
  })

  const [taskDetail, setTaskDetail] = useState<NoiDungMonHoc>({
    id: 0,
    maLoaiNhiemVu: 'VIDEO'
  })
  const [runcodeDetail, setRuncodeDetail] = useState<RuncodeModel>({ id: 0 })


  let { monHocId, chuyenDeId } = useParams()
  const navigate = useNavigate()

  const { arrTag } = useSelector((state: RootState) => state.chuyenDeReducer)

  const { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer)
  const { arrMonHoc } = useSelector((state: RootState) => state.monHocReducer)
  const { arrPractice } = useSelector((state: RootState) => state.practiceReducer)
  const { arrBaiHoc } = useSelector((state: RootState) => state.baiHocReducer)
  const { arrQuestion } = useSelector((state: RootState) => state.runcodeReducer)

  // get redux
  const dispatch: DispatchType = useDispatch()

  // function else

  // drawer
  const [open, setOpen] = useState(false)

  const showDrawer = (task: NoiDungMonHoc | null) => {
    if (task) {

      setTaskDetail(task)
    }

    setOpen(true)
  }

  const drawerClose = () => {
    //?Đóng popup
    setOpen(false)

    if (chuyenDeID === -1) {
      //? Luw chương tieu de && tag skill
      let noiDung = monHoc && JSON.parse(monHoc.danhSachNoiDung)
      if (noiDung) {
        const findIndex = noiDung.findIndex((e: { id: number }) => e.id === taskDetail.id)
        //* Kt chương học có tồn tại trong môn học không, mã loại có giống không
        if (findIndex != -1 && taskDetail.maLoaiNhiemVu === noiDung[findIndex].maLoaiNhiemVu) {
          //* kt
          if (
            taskDetail.tenNhiemVu !== noiDung[findIndex].tenNhiemVu ||
            taskDetail.tagSkill !== noiDung[findIndex].tagSkill
          )
            luuChuongHoc()
        }
      }
    } else if (isPractice) {
      let content = practice && JSON.parse(practice.danhSachNoiDung)
      if (content) {
        const findIndex = content.findIndex((e: { id: number }) => e.id === taskDetail.id)
      }
      luuChuongHocPractice()
    } else {
      console.log('best practice')
    }

    //TODO: fix lỗi re-render bằng đặt lại trạng thái
    setChuongHoc({ chuongHoc: 0, tenChuong: '', danhSachBaiHoc: [] })
    setBaiHocDetail({ id: 0, maLoai: 'BAITAP' })
    setTaskDetail({ id: 0, maLoaiNhiemVu: 'VIDEO' })
    setRuncodeDetail({ id: 0 })
  }

  const drawerVisibleClose = () => {
    setDrawerVisible(!!!drawerVisible)
  }

  // modal thêm bài học

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = (item: number = 0) => {

    setIsModalOpen(true)

    let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == item)

    if (baiHoc && baiHoc?.id != baiHocDetail.id) {
      setBaiHocDetail(baiHoc)
    }
  }

  const modalClose = () => {

    setIsModalOpen(false)
  }

  // modal nguồn thêm

  const [isModalNguonOpen, setIsModalNguonOpen] = useState(false)

  const showNguonModal = () => {
    setIsModalNguonOpen(true)
  }

  const modalNguonClose = () => {
    setIsModalNguonOpen(false)
  }

  useEffect(() => { }, [])

  let chuyende = arrChuyenDe?.find((item) => Number(item.id) === Number(chuyenDeId))

  let danhSach = chuyende && JSON.parse(chuyende.danhSachNoiDung)

  let danhSachPractice: number[] | undefined = danhSach?.danhSachPractice

  let monHoc = arrMonHoc?.find((n) => n.id === Number(monHocId))

  let practiceId: number | undefined

  if (danhSachPractice && danhSachPractice.length > 0) {
    practiceId = danhSachPractice[0]
  }

  const practice: PracticeModel | undefined =
    practiceId !== undefined ? arrPractice?.find((item: PracticeModel) => practiceId === item.id) : undefined


  let phanThuong = practice && JSON.parse(practice.phanThuong)

  let contentLoad = practice ? JSON.parse(practice.danhSachNoiDung) : []

  // xu ly them task

  let opSegmentedTask = [

    {
      label: (
        <>
          <i className='fas fa-file-signature'></i> Nộp bài
        </>
      ),
      value: 'BAITAP'
    },
    {
      label: (
        <>
          <i className='fab fa-dochub'></i> Đọc tài liệu
        </>
      ),
      value: 'TAILIEU'
    },
    {
      label: (
        <>
          <i className='fas fa-check-double'></i> Trắc nghiệm
        </>
      ),
      value: 'QUIZ'
    },
    {
      label: (
        <>
          <i className='fa fa-code'></i> Runcode
        </>
      ),
      value: 'RUNCODE',
    },
    {
      label: (
        <>
          <i className='fas fa-video'></i> Xem Video
        </>
      ),
      value: 'VIDEO',
    },
    {
      label: (
        <>
          <i className="fa-solid fa-clapperboard"></i> Record
        </>
      ),
      value: 'RECORD',
    }
  ];

  let opSegmentedTaskPractice = [
    {
      label: (
        <>
          <i className='fas fa-file-signature'></i> Nộp bài
        </>
      ),
      value: 'BAITAP'
    },
    {
      label: (
        <>
          <i className='fab fa-dochub'></i> Đọc tài liệu
        </>
      ),
      value: 'TAILIEU'
    },
    {
      label: (
        <>
          <i className='fas fa-check-double'></i> Trắc nghiệm
        </>
      ),
      value: 'QUIZ'
    },
    {
      label: (
        <>
          <i className='fa fa-code'></i> Runcode
        </>
      ),
      value: 'RUNCODE'
    }
  ]
  //Chi
  const luuChuongHoc = () => {
    let noiDung = monHoc && JSON.parse(monHoc.danhSachNoiDung)

    const findIndex = noiDung.findIndex((e: { id: number }) => e.id === taskDetail.id)

    if (findIndex != -1 && taskDetail.maLoaiNhiemVu === noiDung[findIndex].maLoaiNhiemVu) {
      noiDung[findIndex] = { ...taskDetail }
      let setMonHoc = { ...monHoc }
      setMonHoc.danhSachNoiDung = JSON.stringify(noiDung)
      monHocService
        .suaMonHoc(setMonHoc)
        .then((res) => {
          dispatch(getMonHocApi())
          dispatch(getBaiHocApi())
          message.success('Đã lưu')
        })
        .catch((err) => {
          message.error('Lỗi Báo IT', err)
        })
    } else {
      message.error('Hãy thêm nội dung')
    }
  }

  const luuChuongHocPractice = () => {
    let content = practice && JSON.parse(practice.danhSachNoiDung)

    //Xử lý lại chuong practice
    const findIndex = content.findIndex((e: { id: number }) => e.id === taskDetail.id)

    if (
      findIndex !== -1 &&
      taskDetail?.maLoaiNhiemVu === content?.[findIndex]?.maLoaiNhiemVu
      // &&taskDetail?.diemHoatDong !== content?.[findIndex]?.diemHoatDong
    ) {
      if (
        taskDetail?.tenNhiemVu !== content?.[findIndex]?.tenNhiemVu ||
        taskDetail?.tagSkill !== content?.[findIndex]?.tagSkill ||
        taskDetail?.diemHoatDong !== content?.[findIndex]?.diemHoatDong
      ) {
        content[findIndex] = { ...taskDetail }
        let setPractice = { ...practice }
        setPractice.danhSachNoiDung = JSON.stringify(content)
        practiceService
          .suaPractice(setPractice)
          .then((res) => {
            dispatch(getPracticeApi())
            message.success('Đã lưu cho practice')
          })
          .catch((err) => {
            message.error('Lỗi Báo IT', err)
          })
      }
    } else {
      // message.error("Hãy thêm nội dung");
    }
  }

  const updateHanNop = (task: NoiDungMonHoc) => {

    let noiDung = monHoc ? JSON.parse(monHoc.danhSachNoiDung) : []


    noiDung.splice(noiDung.findIndex((n: any) => n.id == task.id), 1, task)


    // Cập nhật và lưu monHoc
    let setMonHoc = { ...monHoc }
    setMonHoc.danhSachNoiDung = JSON.stringify(noiDung)

    monHocService.suaMonHoc(setMonHoc).then((res) => {
      dispatch(getMonHocApi())
      dispatch(getBaiHocApi())
      message.success("Đã lưu !")
    })
      .catch((err) => {
        message.error('Đã xảy ra lổi')
        console.log(err)
      })
  }

  // xu ly loading data tu api khi nao co thi tat

  let div_loading = document.getElementsByClassName('div_loading')
  if (arrBaiHoc?.length == 0) {
    div_loading[0]?.removeAttribute('hidden')
  } else {
    div_loading[0]?.setAttribute('hidden', '')

  }

  //TODO:
  const updateTaskVaoMonHoc = (baiHoc: BaiHocModel, maLoai: string, isRemove: boolean, chuongId: number) => {

    let noiDung = monHoc ? JSON.parse(monHoc.danhSachNoiDung) : []

    if (chuyenDeID === -1) {
      let noiDungSort = [...noiDung];

      let sortListTemp = noiDungSort.sort((a: any, b: any) => a.id - b.id)
      let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

      switch (maLoai) {
        case 'VIDEO': {
          // them task moi
          // them chuong moi
          if (taskDetail.id == 0) {
            // let noiDungSort = [...noiDung];

            // let sortListTemp = noiDungSort.sort((a: any, b: any) => a.id - b.id)
            // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

            let newNoiDungChuong = {
              chuongHoc: 1,
              tenChuong: 'Chương 1',
              danhSachBaiHoc: []
            }

            let newTask: any = {
              id: getId,
              tenNhiemVu: taskDetail.tenNhiemVu,
              maLoaiNhiemVu: maLoai,
              noiDungNhiemVu: JSON.stringify([newNoiDungChuong]),
              tagSkill: taskDetail.tagSkill,
              diemHoatDong: taskDetail.diemHoatDong
            }

            noiDung.push(newTask)

            setTaskDetail(newTask)
          } else {
            // update task cua chuong va bai hoc

            let listChuong: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

            if (isRemove == true) {
              //xoa chuong
              if (baiHoc.id == 0) {
                listChuong.splice(
                  listChuong.findIndex((n: any) => n.chuongHoc == chuongId),
                  1
                )
              } else {
                let getChuongHoc: NoiDungChuongHoc = listChuong.find((n: NoiDungChuongHoc) => n.chuongHoc == chuongId)

                getChuongHoc.danhSachBaiHoc.splice(
                  getChuongHoc.danhSachBaiHoc.findIndex((n: any) => n == baiHoc.id),
                  1
                )
              }
            } else {
              // them chuong moi
              if (baiHoc.id == 0) {
                let sortChuongTemp = listChuong.sort((a: any, b: any) => a.chuongHoc - b.chuongHoc)
                let getChuongId =
                  sortChuongTemp.length == 0 ? 1 : sortChuongTemp[sortChuongTemp.length - 1].chuongHoc + 1


                let newNoiDungChuong = {
                  chuongHoc: getChuongId,
                  tenChuong: 'Chương ' + getChuongId,
                  danhSachBaiHoc: []
                }

                listChuong.push(newNoiDungChuong)
              } else {
                let getChuongHoc: NoiDungChuongHoc = listChuong.find(
                  (n: NoiDungChuongHoc) => n.chuongHoc == chuongHoc.chuongHoc
                )

                console.log(chuongHoc)

                getChuongHoc.danhSachBaiHoc.push(baiHoc.id)
              }
            }
            taskDetail.noiDungNhiemVu = JSON.stringify(listChuong)

            noiDung.splice(
              noiDung.findIndex((n: any) => n.id == taskDetail.id),
              1,
              taskDetail
            )
          }

          break
        }
        case 'RECORD': {
          if (taskDetail.id == 0) {
            // let sortListTemp = noiDung.sort((a: any, b: any) => a.id - b.id)
            // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

            let newTask: any = {
              id: getId,
              tenNhiemVu: taskDetail.tenNhiemVu,
              maLoaiNhiemVu: maLoai,
              noiDungNhiemVu: JSON.stringify([baiHoc.id]),
              tagSkill: taskDetail.tagSkill,
              diemHoatDong: taskDetail.diemHoatDong
            }

            noiDung.push(newTask)

            setTaskDetail(newTask)
          } else {
            let listTaiLieu: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

            if (isRemove == true) {
              listTaiLieu.splice(
                listTaiLieu.findIndex((n: any) => n == baiHoc.id),
                1
              )
            } else {
              listTaiLieu.push(baiHoc.id)
            }
            taskDetail.noiDungNhiemVu = JSON.stringify(listTaiLieu)

            noiDung.splice(
              noiDung.findIndex((n: any) => n.id == taskDetail.id),
              1,
              taskDetail
            )
          }

          break
        }
        case 'TAILIEU': {
          if (taskDetail.id == 0) {
            // let sortListTemp = noiDung.sort((a: any, b: any) => a.id - b.id)
            // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

            let newTask: any = {
              id: getId,
              tenNhiemVu: taskDetail.tenNhiemVu,
              maLoaiNhiemVu: maLoai,
              noiDungNhiemVu: JSON.stringify([baiHoc.id]),
              tagSkill: taskDetail.tagSkill,
              diemHoatDong: taskDetail.diemHoatDong
            }

            noiDung.push(newTask)

            setTaskDetail(newTask)
          } else {
            let listTaiLieu: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

            if (isRemove == true) {
              listTaiLieu.splice(
                listTaiLieu.findIndex((n: any) => n == baiHoc.id),
                1
              )
            } else {
              listTaiLieu.push(baiHoc.id)
            }
            taskDetail.noiDungNhiemVu = JSON.stringify(listTaiLieu)

            noiDung.splice(
              noiDung.findIndex((n: any) => n.id == taskDetail.id),
              1,
              taskDetail
            )
          }

          break
        }
        case 'QUIZ': {
          if (taskDetail.id == 0) {
            // let sortListTemp = noiDung.sort((a: any, b: any) => a.id - b.id)
            // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

            let newTask: any = {
              id: getId,
              tenNhiemVu: taskDetail.tenNhiemVu,
              maLoaiNhiemVu: maLoai,
              noiDungNhiemVu: JSON.stringify([baiHoc.id]),
              tagSkill: taskDetail.tagSkill,
              diemHoatDong: taskDetail.diemHoatDong
            }

            noiDung.push(newTask)

            setTaskDetail(newTask)
          } else {
            let listTracNghiem: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

            const isBaiHocIdExists = listTracNghiem.includes(baiHoc.id)

            if (isRemove == true) {
              listTracNghiem.splice(
                listTracNghiem.findIndex((n: any) => n == baiHoc.id),
                1
              )
            } else {

              if (!isBaiHocIdExists) {

                listTracNghiem.push(baiHoc.id)
              }
            }
            taskDetail.noiDungNhiemVu = JSON.stringify(listTracNghiem)

            noiDung.splice(
              noiDung.findIndex((n: any) => n.id == taskDetail.id),
              1,
              taskDetail
            )
          }

          break
        }
        case 'RUNCODE': {
          if (taskDetail.id == 0) {
            // let sortListTemp = noiDung.sort((a: any, b: any) => a.id - b.id)
            // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

            let newTask: any = {
              id: getId,
              tenNhiemVu: taskDetail.tenNhiemVu,
              maLoaiNhiemVu: maLoai,
              noiDungNhiemVu: JSON.stringify([baiHoc.id]),
              tagSkill: taskDetail.tagSkill,
              diemHoatDong: taskDetail.diemHoatDong
            }

            noiDung.push(newTask)

            setTaskDetail(newTask)
          } else {
            let listRuncode: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

            const isBaiHocIdExists = listRuncode.includes(baiHoc.id)

            if (isRemove === true) {
              listRuncode.splice(
                listRuncode.findIndex((n: number) => n == baiHoc.id),
                1
              )
            } else {
              if (!isBaiHocIdExists && baiHoc.id !== 0) {
                listRuncode.push(baiHoc.id)
              }
            }
            taskDetail.noiDungNhiemVu = JSON.stringify(listRuncode)

            noiDung.splice(
              noiDung.findIndex((n: any) => n.id == taskDetail.id),
              1,
              taskDetail
            )
          }

          break
        }
        default: {
          if (taskDetail.id == 0) {
            // let sortListTemp = noiDung.sort((a: any, b: any) => a.id - b.id)
            // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

            let newTask = {
              id: getId,
              tenNhiemVu: baiHoc.tenBaiHoc,
              maLoaiNhiemVu: maLoai,
              noiDungNhiemVu: baiHoc.id.toString(),
              tagSkill: taskDetail.tagSkill,
              diemHoatDong: taskDetail.diemHoatDong,
              hanNop: taskDetail.hanNop
            }

            noiDung.push(newTask)

            setTaskDetail(newTask)
          } else {
            let baiTap: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : ''

            if (isRemove == true) {
              baiTap = ''
            } else {
              baiTap = String(baiHoc.id)
            }

            taskDetail.tenNhiemVu = baiHoc.tenBaiHoc;

            taskDetail.noiDungNhiemVu = baiTap;
            taskDetail.maLoaiNhiemVu = maLoai;


            noiDung.splice(
              noiDung.findIndex((n: any) => n.id == taskDetail.id),
              1,
              taskDetail
            )
          }

          // console.log(newTask)

          // noiDung.push(newTask);
          break
        }
      }

      // Cập nhật và lưu monHoc
      let setMonHoc = { ...monHoc }
      setMonHoc.danhSachNoiDung = JSON.stringify(noiDung)

      monHocService.suaMonHoc(setMonHoc).then((res) => {

        dispatch(getBaiHocAction([]))

        dispatch(getMonHocApi())
        dispatch(getBaiHocApi())

        // message.success(`${taskDetail?.maLoaiNhiemVu} đã lưu`)
      })
        .catch((err) => {
          message.error('Lỗi Báo IT', err)
        })
    } else {
      // Xử lý cho "Practice"
      if (!practice) {
        let content: any = []

        // Gọi API để cập nhật danh sách "Practice" trước khi xử lý
        let newPractice: CreatePracticeModel = {
          tieuDe: 'Danh sách các bài luyện tập',
          danhSachNoiDung: '[]',
          phanThuong: '[]'
        }
        let newChuyenDe = { ...chuyende }
        practiceService
          .themPractice(newPractice)
          .then((res) => {
            let newDanhSach = {
              ...danhSach,
              danhSachPractice: [res.data.content.id]
            }
            newChuyenDe.danhSachNoiDung = JSON.stringify(newDanhSach)
            if (res.data.statusCode === 201) {
              dispatch(getPracticeApi())
              chuyenDeService
                .suaChuyenDe(newChuyenDe)
                .then((res1) => {
                  if (res1.data.statusCode === 200) {
                    dispatch(getChuyenDeApi())
                    let noiDungSort = [...noiDung];

                    let sortListTemp = noiDungSort.sort((a: any, b: any) => a.id - b.id)
                    let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

                    switch (maLoai) {
                      case 'VIDEO': {
                        // them task moi
                        // them chuong moi
                        if (taskDetail.id == 0) {
                          // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
                          // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

                          let newNoiDungChuong = {
                            chuongHoc: 1,
                            tenChuong: 'Chương 1',
                            danhSachBaiHoc: []
                          }

                          let newTask: any = {
                            id: getId,
                            tenNhiemVu: taskDetail.tenNhiemVu,
                            maLoaiNhiemVu: maLoai,
                            noiDungNhiemVu: JSON.stringify([newNoiDungChuong]),
                            tagSkill: taskDetail.tagSkill,
                            diemHoatDong: taskDetail.diemHoatDong
                          }

                          content.push(newTask)

                          setTaskDetail(newTask)
                        } else {
                          // update task cua chuong va bai hoc

                          let listChuong: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

                          if (isRemove == true) {
                            //xoa chuong
                            if (baiHoc.id == 0) {
                              listChuong.splice(
                                listChuong.findIndex((n: any) => n.chuongHoc == chuongId),
                                1
                              )
                            } else {
                              let getChuongHoc: NoiDungChuongHoc = listChuong.find(
                                (n: NoiDungChuongHoc) => n.chuongHoc == chuongId
                              )

                              getChuongHoc.danhSachBaiHoc.splice(
                                getChuongHoc.danhSachBaiHoc.findIndex((n: any) => n == baiHoc.id),
                                1
                              )
                            }
                          } else {
                            // them chuong moi
                            if (baiHoc.id == 0) {
                              let sortChuongTemp = listChuong.sort((a: any, b: any) => a.chuongHoc - b.chuongHoc)
                              let getChuongId =
                                sortChuongTemp.length == 0 ? 1 : sortChuongTemp[sortChuongTemp.length - 1].chuongHoc + 1

                              let newNoiDungChuong = {
                                chuongHoc: getChuongId,
                                tenChuong: 'Chương ' + getChuongId,
                                danhSachBaiHoc: []
                              }

                              listChuong.push(newNoiDungChuong)
                            } else {
                              let getChuongHoc: NoiDungChuongHoc = listChuong.find(
                                (n: NoiDungChuongHoc) => n.chuongHoc == chuongHoc.chuongHoc
                              )
                              getChuongHoc.danhSachBaiHoc.push(baiHoc.id)
                            }
                          }
                          taskDetail.noiDungNhiemVu = JSON.stringify(listChuong)

                          content.splice(
                            content.findIndex((n: any) => n.id == taskDetail.id),
                            1,
                            taskDetail
                          )
                        }

                        break
                      }
                      case 'TAILIEU': {
                        break
                      }
                      case 'QUIZ': {
                        if (taskDetail.id == 0) {
                          // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
                          // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

                          let newTask: any = {
                            id: getId,
                            tenNhiemVu: taskDetail.tenNhiemVu,
                            maLoaiNhiemVu: maLoai,
                            noiDungNhiemVu: JSON.stringify([baiHoc.id]),
                            tagSkill: taskDetail.tagSkill,
                            diemHoatDong: taskDetail.diemHoatDong
                          }

                          content.push(newTask)

                          setTaskDetail(newTask)
                        } else {
                          let listTracNghiem: any = taskDetail.noiDungNhiemVu
                            ? JSON.parse(taskDetail.noiDungNhiemVu)
                            : []

                          if (isRemove == true) {
                            listTracNghiem.splice(
                              listTracNghiem.findIndex((n: any) => n == baiHoc.id),
                              1
                            )
                          } else {
                            listTracNghiem.push(baiHoc.id)
                          }
                          taskDetail.noiDungNhiemVu = JSON.stringify(listTracNghiem)

                          content.splice(
                            content.findIndex((n: any) => n.id == taskDetail.id),
                            1,
                            taskDetail
                          )
                        }

                        break
                      }
                      case 'RUNCODE': {
                        if (taskDetail.id == 0) {
                          // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
                          // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

                          let newTask: any = {
                            id: getId,
                            tenNhiemVu: taskDetail.tenNhiemVu,
                            maLoaiNhiemVu: maLoai,
                            noiDungNhiemVu: JSON.stringify([baiHoc.id]),
                            tagSkill: taskDetail.tagSkill,
                            diemHoatDong: taskDetail.diemHoatDong
                          }

                          content.push(newTask)

                          setTaskDetail(newTask)
                        } else {
                          let listRuncode: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []
                          if (isRemove === true) {
                            listRuncode.splice(
                              listRuncode.findIndex((n: any) => n == baiHoc.id),
                              1
                            )
                          } else {
                            listRuncode.push(baiHoc.id)
                          }
                          taskDetail.noiDungNhiemVu = JSON.stringify(listRuncode)

                          content.splice(
                            content.findIndex((n: any) => n.id == taskDetail.id),
                            1,
                            taskDetail
                          )
                        }

                        break
                      }
                      default: {
                        // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
                        // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

                        let newTask = {
                          id: getId,
                          tenNhiemVu: baiHoc.tenBaiHoc,
                          maLoaiNhiemVu: maLoai,
                          noiDungNhiemVu: baiHoc.id.toString(),
                          tagSkill: taskDetail.tagSkill,
                          diemHoatDong: taskDetail.diemHoatDong
                        }

                        content.push(newTask)
                        break
                      }
                    }
                    // Cập nhật và lưu practice
                    // let setPractice = { ...practice };
                    // setPractice.danhSachNoiDung = JSON.stringify(content);

                    // practiceService.suaPractice(setPractice)
                    //     .then((res) => {
                    //         console.log(res)
                    //         dispatch(getPracticeApi());
                    //         message.success("Đã lưu");
                    //     })
                    //     .catch((err) => {
                    //         message.error("Lỗi Báo IT", err);
                    //     });
                  }
                })
                .catch((error) => {
                  console.error('Lỗi khi cập nhật danh sách Practice:', error)
                })
            }
          })
          .catch((err) => { })
      } else {
        let content = practice && JSON.parse(practice.danhSachNoiDung)
        let noiDungSort = [...noiDung];

        let sortListTemp = noiDungSort.sort((a: any, b: any) => a.id - b.id)
        let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

        switch (maLoai) {
          case 'VIDEO': {
            // them task moi
            // them chuong moi
            if (taskDetail.id == 0) {
              // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
              // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

              let newNoiDungChuong = {
                chuongHoc: 1,
                tenChuong: 'Chương 1',
                danhSachBaiHoc: []
              }

              let newTask: any = {
                id: getId,
                tenNhiemVu: taskDetail.tenNhiemVu,
                maLoaiNhiemVu: maLoai,
                noiDungNhiemVu: JSON.stringify([newNoiDungChuong]),
                tagSkill: taskDetail.tagSkill,
                diemHoatDong: taskDetail.diemHoatDong
              }

              content.push(newTask)

              setTaskDetail(newTask)
            } else {
              // update task cua chuong va bai hoc

              let listChuong: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

              if (isRemove == true) {
                //xoa chuong
                if (baiHoc.id == 0) {
                  listChuong.splice(
                    listChuong.findIndex((n: any) => n.chuongHoc == chuongId),
                    1
                  )
                } else {
                  let getChuongHoc: NoiDungChuongHoc = listChuong.find((n: NoiDungChuongHoc) => n.chuongHoc == chuongId)

                  getChuongHoc.danhSachBaiHoc.splice(
                    getChuongHoc.danhSachBaiHoc.findIndex((n: any) => n == baiHoc.id),
                    1
                  )
                }
              } else {
                // them chuong moi
                if (baiHoc.id == 0) {
                  let sortChuongTemp = listChuong.sort((a: any, b: any) => a.chuongHoc - b.chuongHoc)
                  let getChuongId =
                    sortChuongTemp.length == 0 ? 1 : sortChuongTemp[sortChuongTemp.length - 1].chuongHoc + 1

                  let newNoiDungChuong = {
                    chuongHoc: getChuongId,
                    tenChuong: 'Chương ' + getChuongId,
                    danhSachBaiHoc: []
                  }

                  listChuong.push(newNoiDungChuong)
                } else {
                  let getChuongHoc: NoiDungChuongHoc = listChuong.find(
                    (n: NoiDungChuongHoc) => n.chuongHoc == chuongHoc.chuongHoc
                  )
                  getChuongHoc.danhSachBaiHoc.push(baiHoc.id)
                }
              }
              taskDetail.noiDungNhiemVu = JSON.stringify(listChuong)

              content.splice(
                content.findIndex((n: any) => n.id == taskDetail.id),
                1,
                taskDetail
              )
            }

            break
          }
          case 'TAILIEU': {
            //Thêm task mới
            //Thêm Chương mới

            if (taskDetail.id == 0) {
              // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
              // //lấy số thứ tự tiếp theo
              // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1
              //tạo list tài liệu mới
              let newTask: any = {
                id: getId,
                tenNhiemVu: taskDetail.tenNhiemVu,
                maLoaiNhiemVu: maLoai,
                noiDungNhiemVu: JSON.stringify([baiHoc.id]),
                tagSkill: taskDetail.tagSkill,
                diemHoatDong: taskDetail.diemHoatDong
              }
              content.push(newTask)
              setTaskDetail(newTask)
            }
            //Nếu task list tai liệu đã có
            else {
              let listTaiLieu = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

              if (isRemove == true) {
                listTaiLieu.splice(
                  listTaiLieu.findIndex((n: any) => n == baiHoc.id),
                  1
                )
              } else {
                listTaiLieu.push(baiHoc.id)
              }
              taskDetail.noiDungNhiemVu = JSON.stringify(listTaiLieu)
              content.splice(
                content.findIndex((n: any) => n.id == taskDetail.id),
                1,
                taskDetail
              )
            }

            break
          }
          case 'QUIZ': {
            if (taskDetail.id == 0) {
              // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
              // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

              let newTask: any = {
                id: getId,
                tenNhiemVu: taskDetail.tenNhiemVu,
                maLoaiNhiemVu: maLoai,
                noiDungNhiemVu: JSON.stringify([baiHoc.id]),
                tagSkill: taskDetail.tagSkill,
                diemHoatDong: taskDetail.diemHoatDong
              }

              content.push(newTask)

              setTaskDetail(newTask)
            } else {
              let listTracNghiem: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

              if (isRemove == true) {
                listTracNghiem.splice(
                  listTracNghiem.findIndex((n: any) => n == baiHoc.id),
                  1
                )
              } else {
                listTracNghiem.push(baiHoc.id)
              }
              taskDetail.noiDungNhiemVu = JSON.stringify(listTracNghiem)

              content.splice(
                content.findIndex((n: any) => n.id == taskDetail.id),
                1,
                taskDetail
              )
            }

            break
          }
          case 'RUNCODE': {
            if (taskDetail.id == 0) {
              // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
              // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

              let newTask: any = {
                id: getId,
                tenNhiemVu: taskDetail.tenNhiemVu,
                maLoaiNhiemVu: maLoai,
                noiDungNhiemVu: JSON.stringify([baiHoc.id]),
                tagSkill: taskDetail.tagSkill,
                diemHoatDong: taskDetail.diemHoatDong
              }

              content.push(newTask)

              setTaskDetail(newTask)
            } else {
              let listRuncode: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []
              const isBaiHocIdExists = listRuncode.includes(baiHoc.id)

              if (isRemove === true) {
                listRuncode.splice(
                  listRuncode.findIndex((n: number) => n == baiHoc.id),
                  1
                )
              } else {
                if (!isBaiHocIdExists && baiHoc.id !== 0) {
                  listRuncode.push(baiHoc.id)
                }
              }
              taskDetail.noiDungNhiemVu = JSON.stringify(listRuncode)

              content.splice(
                content.findIndex((n: any) => n.id == taskDetail.id),
                1,
                taskDetail
              )
            }

            break
          }
          default: {
            if (taskDetail.id == 0) {
              // let sortListTemp = content.sort((a: any, b: any) => a.id - b.id)
              // let getId = sortListTemp.length == 0 ? 1 : sortListTemp[sortListTemp.length - 1].id + 1

              let newTask = {
                id: getId,
                tenNhiemVu: baiHoc.tenBaiHoc,
                maLoaiNhiemVu: maLoai,
                noiDungNhiemVu: baiHoc.id.toString(),
                tagSkill: taskDetail.tagSkill,
                diemHoatDong: taskDetail.diemHoatDong
              }

              content.push(newTask)
              setTaskDetail(newTask)
            } else {

              const findIndex = content.findIndex((e: { id: number }) => e.id === taskDetail.id)
              if (
                findIndex !== -1 &&
                taskDetail?.maLoaiNhiemVu === content?.[findIndex]?.maLoaiNhiemVu
                // &&taskDetail?.diemHoatDong !== content?.[findIndex]?.diemHoatDong
              ) {
                //   console.log('baiHoc',baiHoc);
                //   console.log('taskDetail',taskDetail);
                //   console.log('data',data);
                //  console.log('content[findIndex]', content[findIndex] );
                const newpractive = { ...taskDetail }
                newpractive.tenNhiemVu = baiHoc?.tenBaiHoc
                newpractive.noiDungNhiemVu = baiHoc?.id.toString()
                content[findIndex] = newpractive
              }
            }
            break
          }
        }

        // Cập nhật và lưu practice
        let setPractice = { ...practice }
        setPractice.danhSachNoiDung = JSON.stringify(content)

        practiceService
          .suaPractice(setPractice)
          .then((res) => {
            dispatch(getPracticeApi())
            message.success('Đã lưu')
          })
          .catch((err) => {
            message.error('Lỗi Báo IT', err)
          })
      }
    }
  }
  const xoaTaskKhoiMon = (taskId: number) => {
    if (chuyenDeID === -1) {
      let noiDung = monHoc && JSON.parse(monHoc.danhSachNoiDung)

      noiDung.splice(
        noiDung.findIndex((n: any) => n.id == taskId),
        1
      )

      let setMonHoc = { ...monHoc }
      setMonHoc.danhSachNoiDung = JSON.stringify(noiDung)

      monHocService
        .suaMonHoc(setMonHoc)
        .then((res) => {
          dispatch(getMonHocApi())

          message.success('Đã lưu')
        })
        .catch((err) => {
          message.error('Lỗi Báo IT', err)
        })
    } else {
      let noiDung = practice && JSON.parse(practice.danhSachNoiDung)

      noiDung.splice(
        noiDung.findIndex((n: any) => n.id == taskId),
        1
      )

      let setPractice = { ...practice }
      setPractice.danhSachNoiDung = JSON.stringify(noiDung)

      practiceService
        .suaPractice(setPractice)
        .then((res) => {
          dispatch(getPracticeApi())

          message.success('Đã lưu')
        })
        .catch((err) => {
          message.error('Lỗi Báo IT', err)
        })
    }
  }

  const loadBaiHocAbove = (onClose: () => void) => {
    let opSegmented: listType[] = []
    switch (baiHocDetail.maLoai) {
      case 'VIDEO': {
        opSegmented = [{ value: 'VIDEO', label: 'Video' }]
        break
      }
      case 'QUIZ': {
        opSegmented = [{ value: 'QUIZ', label: 'Trắc nghiệm' }]
        break
      }
      case 'TAILIEU': {
        opSegmented = [{ value: 'TAILIEU', label: 'Tài liệu' }]
        break
      }
      case 'RUNCODE': {
        opSegmented = [{ value: 'RUNCODE', label: 'Runcode' }]
        break
      }
      case 'RECORD': {
        opSegmented = [{ value: 'RECORD', label: 'Record' }]
        break
      }
      default: {
        opSegmented = [
          {
            label: (
              <>
                <i className='fas fa-file-signature'></i> Bài tập nộp
              </>
            ),
            value: 'BAITAP'
          },
          // {
          //     label: (
          //         <>
          //             <i className="fas fa-user-friends"></i> Thảo luận
          //         </>
          //     ),
          //     value: "DISCUSS",
          // },
          {
            label: (
              <>
                <i className='fas fa-object-group'></i> Capstone
              </>
            ),
            value: 'CAPS'
          },
          {
            label: (
              <>
                <i className='fas fa-object-group'></i> Dự án cuối khóa
              </>
            ),
            value: 'DUAN'
          },
          {
            label: (
              <>
                <i className="fa-solid fa-house-laptop"></i> Về nhà
              </>
            ),
            value: 'HOMEWORK'
          }
        ]
      }
    }

    return (
      <div className='col-12 test-12'>
        <BaiHocAbove
          opSegmented={opSegmented}
          onClose={onClose}
          setMaLoaiBaiTap={setMaLoaiBaiTap}
          taskDetail={baiHocDetail}
          runcodeDetail={runcodeDetail}
          updateTaskVaoMonHoc={updateTaskVaoMonHoc}
        />
      </div>
    )
  }

  // gan đúng maLoai cho phan bài tập nộp
  const setMaLoaiBaiTap = (maLoai: string) => {

    setBaiHocDetail({ ...baiHocDetail, maLoai })
  }


  const renderDiemHoatDongForPractice = (chuyendeId: Number) => {
    if (chuyendeId !== -1) {
      return (
        <div className='col-3'>
          <label className='form-label'>Điểm hoạt động</label>
          <br />
          <InputNumber
            min={0}
            placeholder='Điểm'
            value={taskDetail.diemHoatDong}
            onChange={(value) => {
              setTaskDetail({ ...taskDetail, diemHoatDong: Number(value) })
            }}
          />
        </div>
      )
    }
  }
  //TODO:
  const loadNoiDungTaskDetail = () => {
    switch (taskDetail.maLoaiNhiemVu) {
      case 'VIDEO': {
        if (taskDetail) {
          let listBaiHoc: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

          return (
            <div className='row mb-2'>
              <div className='col-5'>
                <label className='form-label'>Tiêu đề nhiệm vụ</label>
                <br />
                <Input
                  placeholder='Tiêu đề nhiệm vụ'
                  value={taskDetail.tenNhiemVu}
                  onChange={(event) => {
                    const { value } = event.target

                    setTaskDetail({ ...taskDetail, tenNhiemVu: value })
                  }}
                />
              </div>

              <div className='col-4'>
                <label className='form-label'>Tag Skill</label>
                <br />
                <Select
                  value={taskDetail.tagSkill}
                  style={{ width: '100%' }}
                  showSearch
                  placeholder='Chọn tag skill'
                  optionFilterProp='children'
                  onChange={async (value) => {
                    await setTaskDetail({ ...taskDetail, tagSkill: value })
                  }}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={getListTag(arrTag, 'SKILL')}
                />
              </div>
              <div className='col-3 d-flex flex-column-reverse'>
                {taskDetail.id !== 0 ? (
                  <button className='btn btn-sm  btn-success ' onClick={drawerClose}>
                    Lưu tác vụ
                  </button>
                ) : (
                  <></>
                )}
              </div>

              {renderDiemHoatDongForPractice(chuyenDeID)}

              <div className='col-12 d-flex gap-3 my-2'>
                <button
                  className='btn btn-sm btn-warning'
                  onClick={() => {
                    if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu?.trim() == '') {
                      message.info('Hãy nhập tên nhiệm vụ !')
                      return
                    }
                    // thêm chương
                    updateTaskVaoMonHoc({ id: 0 }, 'VIDEO', false, 0)
                    setChuongHoc({
                      chuongHoc: 0,
                      tenChuong: '',
                      danhSachBaiHoc: []
                    })
                  }}
                >
                  Thêm chương
                </button>
              </div>

              <div className={sourceRecord ? "col-6 mt-3" : "col-12 mt-3"} >
                {listBaiHoc.map((item: NoiDungChuongHoc) => {
                  return item.chuongHoc == 0 ? (
                    item.danhSachBaiHoc.map((baiHocId: number) => {
                      let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == baiHocId)
                      return <Card className='ms-3 mb-2 bg-light'>{baiHoc?.tenBaiHoc}</Card>
                    })
                  ) : (
                    <>
                      <div className='car-body rounded mb-2 bg-warning px-2'>
                        <div className='d-flex justify-content-between'>
                          <div className='d-flex align-items-center'>{item.tenChuong}</div>
                          <div>
                            <button
                              className='btn btn-sm btn-primary'
                              onClick={() => {
                                if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu?.trim() == '') {
                                  message.info('Hãy nhập tên nhiệm vụ !')
                                  return
                                }
                                setBaiHocDetail({ id: 0, maLoai: 'VIDEO' })
                                setChuongHoc(item)
                                showModal()
                              }}
                            >
                              Thêm video
                            </button>

                            <button
                              className='btn btn-sm btn-outline-primary m-2'
                              onClick={() => {
                                if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu?.trim() == '') {
                                  message.info('Hãy nhập tên nhiệm vụ !')
                                  return
                                }
                                setChuongHoc(item)
                                showNguonModal()
                              }}
                            >
                              Thêm video từ nguồn
                            </button>

                            <Popconfirm
                              title='Bạn có chắc muốn xóa ?'
                              onConfirm={() => {
                                // xóa chương

                                updateTaskVaoMonHoc({ id: 0 }, 'VIDEO', true, item.chuongHoc)
                              }}
                            >
                              <button className='btn btn-sm btn-danger'>
                                <i className='fas fa-times'></i>
                              </button>
                            </Popconfirm>
                          </div>
                        </div>
                      </div>

                      {item.danhSachBaiHoc.map((baiHocId: number) => {
                        let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == baiHocId)
                        return (
                          <Card className='ms-3 mb-2 bg-light'>
                            <div className='d-flex justify-content-between'>
                              <div className='d-flex align-items-center'>{baiHoc?.tenBaiHoc}</div>
                              <div>
                                <button className='btn btn-sm btn-outline-success ' onClick={() => {
                                  if (baiHoc && baiHoc.noiDung) {
                                    fileService.layDuongDanVideoService(baiHoc.noiDung).then(res => {

                                      setSourceRecord(res.data)
                                    }).catch(err => console.log(err))
                                  }
                                }}>
                                  <i className='fas fa-eye'></i>
                                </button>

                                <button
                                  className='btn btn-sm btn-success mx-2'
                                  onClick={() => {
                                    setChuongHoc(item)

                                    showModal(baiHocId)

                                  }}
                                >
                                  <i className='fas fa-edit'></i>
                                </button>
                                <Popconfirm
                                  title='Bạn có chắc muốn xóa ?'
                                  onConfirm={() => {
                                    if (baiHoc && baiHoc?.id != baiHocDetail.id) {
                                      // remove bai hoc ra khoi chuong

                                      updateTaskVaoMonHoc(baiHoc, 'VIDEO', true, item.chuongHoc)
                                    }
                                  }}
                                >
                                  <button className='btn btn-sm btn-danger'>
                                    <i className='fas fa-times'></i>
                                  </button>
                                </Popconfirm>
                              </div>
                            </div>
                          </Card>
                        )
                      })}
                    </>
                  )
                })}
              </div>

              <div hidden={sourceRecord ? false : true} className={sourceRecord ? "col-6" : ""}>
                {sourceRecord && < >
                  <Player playsInline fluid={false} height={550} width={+'100%'}>

                    <HLSSource
                      isVideoChild
                      src={sourceRecord && sourceRecord}
                    />
                    <ControlBar>
                      <PlaybackRateMenuButton rates={[3, 2.5, 2, 1.5, 1]} />
                    </ControlBar>

                  </Player>
                </>
                }
              </div>
            </div>
          )
        }
        break
      }

      case 'RECORD': {
        if (taskDetail) {
          let listBaiHoc: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

          return (
            <div className='row mb-2'>
              <div className='col-5'>
                <label className='form-label'>Tiêu đề nhiệm vụ</label>
                <br />
                <Input
                  placeholder='Tiêu đề nhiệm vụ'
                  value={taskDetail.tenNhiemVu}
                  onChange={(event) => {
                    const { value } = event.target

                    setTaskDetail({ ...taskDetail, tenNhiemVu: value })
                  }}
                />
              </div>

              <div className='col-4'>
                <label className='form-label'>Tag Skill</label>
                <br />
                <Select
                  value={taskDetail.tagSkill}
                  style={{ width: '100%' }}
                  showSearch
                  placeholder='Chọn tag skill'
                  optionFilterProp='children'
                  onChange={async (value) => {
                    await setTaskDetail({ ...taskDetail, tagSkill: value })
                  }}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={getListTag(arrTag, 'SKILL')}
                />
              </div>
              <div className='col-3 d-flex flex-column-reverse'>
                {taskDetail.id !== 0 ? (
                  <button className='btn btn-sm  btn-success ' onClick={drawerClose}>
                    Lưu tác vụ
                  </button>
                ) : (
                  <></>
                )}
              </div>


              <div className='col-12 d-flex gap-3'>
                <button
                  className='btn btn-sm btn-primary my-2'
                  onClick={() => {
                    if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu?.trim() == '') {
                      message.info('Hãy nhập tên nhiệm vụ !')
                      return
                    }
                    setBaiHocDetail({ id: 0, maLoai: 'RECORD' })

                    showModal()
                  }}
                >
                  Thêm video
                </button>

              </div>


              <Space direction='vertical' size='large'>
                {listBaiHoc.map((item: number) => {
                  let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == item)

                  return (<>

                    <Card className='mb-2 bg-info '>
                      <div className='d-flex justify-content-between'>
                        <div>{baiHoc?.tenBaiHoc}</div>
                        <div>
                          <button className='btn btn-sm btn-success me-2' onClick={() => {
                            if (baiHoc && baiHoc.noiDung) {
                              fileService.layDuongDanRecordService(baiHoc.noiDung).then(res => {

                                setSourceRecord(res.data)
                              }).catch(err => console.log(err))
                            }
                          }}>
                            <i className='fas fa-eye'></i>
                          </button>
                          <Popconfirm
                            title='Bạn có chắc muốn xóa ?'
                            onConfirm={() => {
                              let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == item)

                              if (baiHoc && baiHoc?.id != baiHocDetail.id) {
                                updateTaskVaoMonHoc(baiHoc, 'RECORD', true, 0)
                              }
                            }}
                          >
                            <button className='btn btn-sm btn-danger'>
                              <i className='fas fa-times'></i>
                            </button>
                          </Popconfirm>
                        </div>
                      </div>
                    </Card>



                  </>)
                })}

                {sourceRecord && < >
                  <Player playsInline fluid={false} height={550} width={+'100%'}>

                    <HLSSource
                      isVideoChild
                      src={sourceRecord && sourceRecord}
                    />
                    <ControlBar>
                      <PlaybackRateMenuButton rates={[3, 2.5, 2, 1.5, 1]} />
                    </ControlBar>

                  </Player>
                </>
                }
              </Space>
            </div>
          )
        }
        break
      }
      case 'TAILIEU': {
        if (taskDetail) {
          let listTaiLieu: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []


          return (
            <div className='row mt-3'>
              <div className='col-5'>
                <label className='form-label'>Tiêu đề nhiệm vụ</label>
                <br />
                <Input
                  value={taskDetail.tenNhiemVu}
                  onChange={(event) => {
                    const { value } = event.target

                    setTaskDetail({ ...taskDetail, tenNhiemVu: value })
                  }}
                />
              </div>
              <div className='col-4'>
                <label className='form-label'>Tag Skill</label>
                <br />
                <Select
                  value={taskDetail.tagSkill}
                  style={{ width: '100%' }}
                  showSearch
                  placeholder='Chọn tag skill'
                  optionFilterProp='children'
                  onChange={(value) => {
                    setTaskDetail({ ...taskDetail, tagSkill: value })
                  }}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={getListTag(arrTag, 'SKILL')}
                />
              </div>
              <div className='col-3 d-flex flex-column-reverse'>
                {taskDetail.id !== 0 && (
                  <button className='btn btn-sm  btn-success ' onClick={drawerClose}>
                    Lưu tác vụ
                  </button>
                )}
              </div>

              {renderDiemHoatDongForPractice(chuyenDeID)}

              <Space className='my-3'>
                <Button
                  type='primary'
                  onClick={() => {
                    if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu.trim() === '') {
                      message.info('Hãy nhập tên nhiệm vụ!')
                      return
                    }
                    setBaiHocDetail({ id: 0, maLoai: 'TAILIEU' })
                    showModal()
                  }}
                >
                  Thêm mới tài liệu
                </Button>

                <Button
                  onClick={() => {
                    if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu.trim() === '') {
                      message.info('Hãy nhập tên nhiệm vụ!')
                      return
                    }
                    // setBaiHocDetail({ id: 0, maLoai: "TAILIEU" });
                    showNguonModal()
                  }}
                >
                  Thêm từ nguồn
                </Button>
              </Space>

              {/* <Divider /> */}

              <Space direction='vertical' size='large'>
                {listTaiLieu.map((item: number) => {
                  let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == item)

                  return (
                    <Card className='mb-2 bg-info '>
                      <div className='d-flex justify-content-between'>
                        <div>{baiHoc?.tenBaiHoc}</div>
                        <div>
                          <button
                            className='btn btn-sm btn-success mx-2'
                            onClick={() => {
                              let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == item)

                              if (baiHoc && baiHoc?.id != baiHocDetail.id) {
                                setBaiHocDetail(baiHoc)
                                showModal()
                              }
                            }}
                          >
                            <i className='fas fa-edit'></i>
                          </button>
                          <Popconfirm
                            title='Bạn có chắc muốn xóa ?'
                            onConfirm={() => {
                              let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == item)

                              if (baiHoc && baiHoc?.id != baiHocDetail.id) {
                                updateTaskVaoMonHoc(baiHoc, 'QUIZ', true, 0)
                              }
                            }}
                          >
                            <button className='btn btn-sm btn-danger'>
                              <i className='fas fa-times'></i>
                            </button>
                          </Popconfirm>
                        </div>
                      </div>
                    </Card>
                  )
                })}
              </Space>
            </div>
          )
        }
        break
      }
      case 'QUIZ': {
        if (taskDetail) {
          let listTracNghiem: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

          return (
            <div className='row mt-3 '>
              <div className='col-5'>
                <label className='form-label'>Tiêu đề nhiệm vụ</label>
                <Input
                  value={taskDetail.tenNhiemVu}
                  onChange={(event) => {
                    const { value } = event.target

                    setTaskDetail({ ...taskDetail, tenNhiemVu: value })
                  }}
                />
              </div>

              <div className='col-4'>
                <label className='form-label'>Tag Skill</label>
                <br />
                <Select
                  value={taskDetail.tagSkill}
                  style={{ width: '100%' }}
                  showSearch
                  placeholder='Chọn tag skill'
                  optionFilterProp='children'
                  onChange={(value) => {
                    setTaskDetail({ ...taskDetail, tagSkill: value })
                  }}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={getListTag(arrTag, 'SKILL')}
                />
              </div>
              <div className='col-3 d-flex flex-column-reverse'>
                {taskDetail.id !== 0 ? (
                  <button className='btn btn-sm  btn-success ' onClick={drawerClose}>
                    Lưu tác vụ
                  </button>
                ) : (
                  <></>
                )}
              </div>
              {renderDiemHoatDongForPractice(chuyenDeID)}

              <Space className='my-3'>
                <Button
                  type='primary'
                  onClick={() => {
                    if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu.trim() === '') {
                      message.info('Hãy nhập tên nhiệm vụ!')
                      return
                    }
                    setBaiHocDetail({ id: 0, maLoai: 'QUIZ' })
                    showModal()
                  }}
                >
                  Thêm mới trắc nghiệm
                </Button>

                <Button
                  onClick={() => {
                    if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu.trim() === '') {
                      message.info('Hãy nhập tên nhiệm vụ!')
                      return
                    }
                    showNguonModal()
                  }}
                >
                  Thêm từ nguồn
                </Button>
              </Space>

              <div className='row ' >
                {listTracNghiem.map((item: number, index: number) => {
                  let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == item)
                  let bgClass = ""

                  if (baiHoc?.capDo == 2)
                    bgClass = "bg-success text-white"
                  if (baiHoc?.capDo == 3)
                    bgClass = "bg-danger text-white"


                  return (

                    <div className={`col-2 p-2 m-2 rounded border ${bgClass} `}>

                      <div>{index + 1} - {baiHoc?.tenBaiHoc}</div>
                      <div>
                        <button
                          className='btn btn-sm btn-primary mx-2'
                          onClick={() => {
                            showModal(item)

                          }}
                        >
                          <i className='fas fa-edit'></i>
                        </button>
                        <Popconfirm
                          title='Bạn có chắc muốn xóa ?'
                          onConfirm={() => {
                            let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id == item)

                            if (baiHoc && baiHoc?.id != baiHocDetail.id) {
                              updateTaskVaoMonHoc(baiHoc, 'QUIZ', true, 0)
                            }
                          }}
                        >
                          <button className='btn btn-sm text-white' style={{ backgroundColor: "red" }}>
                            <i className='fas fa-times'></i>
                          </button>
                        </Popconfirm>
                      </div>
                    </div>

                  )
                })}
              </div>
            </div>
          )
        }
        break
      }

      case 'RUNCODE': {
        if (taskDetail) {
          let listRuncode: any = taskDetail.noiDungNhiemVu ? JSON.parse(taskDetail.noiDungNhiemVu) : []

          return (
            <div className='row mt-3'>
              <div className='col-5'>
                <label className='form-label'>Tiêu đề nhiệm vụ</label>
                <br />
                <Input
                  value={taskDetail.tenNhiemVu}
                  onChange={(event) => {
                    const { value } = event.target
                    setTaskDetail({ ...taskDetail, tenNhiemVu: value })
                  }}
                />
              </div>

              <div className='col-4'>
                <label className='form-label'>Tag Skill</label>
                <br />
                <Select
                  value={taskDetail.tagSkill}
                  style={{ width: '100%' }}
                  showSearch
                  placeholder='Chọn tag skill'
                  optionFilterProp='children'
                  onChange={(value) => {
                    setTaskDetail({ ...taskDetail, tagSkill: value })
                  }}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={getListTag(arrTag, 'SKILL')}
                />
              </div>
              <div className='col-3 d-flex flex-column-reverse'>
                {taskDetail.id !== 0 ? (
                  <button
                    className='btn btn-sm  btn-success '
                    onClick={() => {
                      let tempBaiHoc: BaiHocModel = { id: 0 }
                      updateTaskVaoMonHoc(tempBaiHoc, taskDetail.maLoaiNhiemVu || '', false, 0)
                      drawerClose()
                    }}
                  >
                    Lưu tác vụ
                  </button>
                ) : (
                  <></>
                )}
              </div>

              {renderDiemHoatDongForPractice(chuyenDeID)}

              <Space className='my-3'>
                <Button
                  type='primary'
                  onClick={() => {
                    if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu.trim() === '') {
                      message.info('Hãy nhập tên nhiệm vụ!')
                      return
                    }
                    setBaiHocDetail({ id: 0, maLoai: 'RUNCODE' })
                    showModal()
                  }}
                >
                  Thêm mới runcode
                </Button>

                <Button
                  onClick={() => {
                    if (!taskDetail.tenNhiemVu || taskDetail.tenNhiemVu.trim() === '') {
                      message.info('Hãy nhập tên nhiệm vụ!')
                      return
                    }
                    showNguonModal()
                  }}
                >
                  Thêm từ nguồn
                </Button>
              </Space>

              {/* <Divider /> */}

              <Space direction='vertical' size='large'>
                {listRuncode?.map((item: any, index: number) => {
                  let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id === item)

                  let runcode =
                    arrBaiHoc &&
                    arrQuestion.find((n) => {
                      const parsedNoiDung = baiHoc && baiHoc.noiDung && JSON.parse(baiHoc.noiDung)
                      return parsedNoiDung && n.question_id === parsedNoiDung[0]
                    })

                  if (baiHoc) {
                    runcode = { ...runcode, id: baiHoc.id }
                  }

                  return (
                    <Card
                      key={index}
                      style={{
                        borderRadius: '8px',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
                      }}
                    >
                      <Space
                        style={{
                          width: '100%',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Title className='fs-5' color='blue'>
                          {baiHoc?.tenBaiHoc}
                        </Title>
                        <Space>
                          <Button
                            type='primary'
                            onClick={() => {
                              let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id === item)
                              if (baiHoc && baiHoc?.id !== baiHocDetail.id) {
                                setBaiHocDetail(baiHoc)
                                setRuncodeDetail(runcode!)
                              }
                              showModal()
                            }}
                          >
                            <i className='fas fa-edit'> Edit</i>
                          </Button>
                          <Popconfirm
                            title='Bạn có chắc muốn xóa?'
                            onConfirm={() => {
                              let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => n.id === item)

                              if (baiHoc && baiHoc?.id !== baiHocDetail.id) {
                                updateTaskVaoMonHoc(baiHoc, 'RUNCODE', true, 0)
                              }
                            }}
                          >
                            <Button type='primary' danger>
                              <i className='fas fa-trash'> Delete</i>
                            </Button>
                          </Popconfirm>
                        </Space>
                      </Space>
                    </Card>
                  )
                })}
              </Space>
            </div>
          )
        }
        break
      }

      default: {
        if (taskDetail) {
          let baiTapId: any = taskDetail.noiDungNhiemVu ? taskDetail.noiDungNhiemVu : ''

          let baiHoc = arrBaiHoc && arrBaiHoc.find((n) => Number(n.id) === Number(baiTapId))

          if (baiHoc && baiHoc?.id != baiHocDetail.id) {
            setBaiHocDetail(baiHoc)
          }

          return (
            <div className='row mt-3'>
              <div className='col-4'>
                <label className='form-label'>Tag Skill</label>
                <br />
                <Select
                  value={taskDetail.tagSkill}
                  style={{ width: '100%' }}
                  showSearch
                  placeholder='Chọn tag skill'
                  optionFilterProp='children'
                  onChange={(value) => {
                    setTaskDetail({ ...taskDetail, tagSkill: value })
                  }}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={getListTag(arrTag, 'SKILL')}
                />
              </div>

              {/* <div className='col-4'>
                <label className='form-label'>Hạn nộp bài:</label>
                <br />
                <DatePicker format={"DD/MM/YYYY"} onChange={onChangeHanNop} />

              </div> */}

              {renderDiemHoatDongForPractice(chuyenDeID)}

              <div className='col-12'>
                {taskDetail.id == 0 && (
                  <button className='btn btn-sm btn-outline-primary my-3' onClick={showNguonModal}>
                    Thêm từ nguồn
                  </button>
                )}
              </div>

              {loadBaiHocAbove(drawerClose)}
            </div>
          )
        }
      }
    }
  }

  const onChangeHanNop: DatePickerProps['onChange'] = (date, dateString) => {
    setTaskDetail({ ...taskDetail, hanNop: date })
  };

  //TODO:
  const formTask = () => {
    return (
      <div className='row'>
        {taskDetail.id == 0 && (
          <Segmented
            size='large'
            options={isPractice ? opSegmentedTaskPractice : opSegmentedTask}
            onChange={(value: any) => {
              setTaskDetail({ ...taskDetail, maLoaiNhiemVu: value })
              setBaiHocDetail({ id: 0, maLoai: value })
            }}
          />
        )}
        {loadNoiDungTaskDetail()}
      </div>
    )
  }

  const [rewardHooks, setRewardHooks] = useState<Reward[]>(phanThuong ? phanThuong : [])
  const [selectedHookIndex, setSelectedHookIndex] = useState<number | null>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [currentHook, setCurrentHook] = useState<RewardHook>({
    point: 0,
    box: []
  })
  const [modalTitle, setModalTitle] = useState<string>('')

  const showModalReward = (index?: number, title?: string) => {
    if (typeof index === 'number' && rewardHooks[index]) {
      setCurrentHook({ ...rewardHooks[index] })
      setSelectedHookIndex(index)
      setModalTitle(`Móc phần thưởng ${index + 1}`)
    } else {
      setCurrentHook({ point: 0, box: [] }) // Reset the current hook if creating new
      setSelectedHookIndex(null)
      setModalTitle(`Thêm móc phần thưởng`)
    }
    setIsModalVisible(true)
  }

  const handleOk = () => {
    let updatedHooks

    if (typeof selectedHookIndex === 'number') {
      // Update the existing rewardHook
      updatedHooks = [...rewardHooks]
      updatedHooks[selectedHookIndex] = currentHook
    } else {
      // Add new rewardHook
      updatedHooks = [...rewardHooks, currentHook]
    }

    setRewardHooks(updatedHooks) // Update the state

    let rewardHooksJson = updatedHooks && JSON.stringify(updatedHooks) // Use updatedHooks here
    let newPractice = { ...practice, phanThuong: rewardHooksJson }

    // Update
    practiceService
      .suaPractice(newPractice)
      .then((response) => {
        // console.log('Update success:', response);
        if (response.data.statusCode === 200) {
          message.success('Cập nhật phần thưởng thành công !')
          dispatch(getPracticeApi())
        }
      })
      .catch((error) => {
        message.error('Lỗi báo BE')
      })

    setIsModalVisible(false)
  }

  const handleAddItem = () => {
    // Kiểm tra tính hợp lệ của điểm
    if (currentHook.point === null || currentHook.point <= 0) {
      message.info('Hãy nhập điểm !!!')
      return
    }

    // Kiểm tra điểm của móc hiện tại so với móc liền trước
    if (rewardHooks.length > 0 && currentHook.point <= rewardHooks[rewardHooks.length - 1].point) {
      message.info('Điểm của móc phần thưởng hiện tại phải lớn hơn móc liền trước.')
      return
    }

    setCurrentHook({
      ...currentHook,
      box: [...currentHook.box, { id: '', value: 0, percent: 0 }]
    })
  }

  const handleRemoveItem = (index: number) => {
    setCurrentHook((prev) => {
      const updatedHook = { ...prev }
      updatedHook.box.splice(index, 1)
      return updatedHook
    })
  }

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedValue = Number(e.target.value)
    setCurrentHook((prev) => {
      const updatedBox = [...prev.box]
      updatedBox[index] = { ...updatedBox[index], value: updatedValue }
      return { ...prev, box: updatedBox }
    })
  }

  const handlePercentChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const updatedPercent = Number(e.target.value)
    setCurrentHook((prev) => {
      const updatedBox = [...prev.box]
      updatedBox[index] = { ...updatedBox[index], percent: updatedPercent }
      return { ...prev, box: updatedBox }
    })
  }

  const stepItems: StepProps[] = rewardHooks.map((hook, index) => ({
    title: `Móc phần thưởng ${index + 1}`,
    onClick: () => showModalReward(index)
  }))

  // const stepItems: StepProps[] = rewardHooks.map((hook, index) => ({
  //     title: `Móc phần thưởng ${index + 1}`,
  //     onClick: () => showModalReward(index),
  //     icon: <span>{index + 1}</span>
  // }));

  const isRealNumber = (value: any) => {
    return !Number.isNaN(Number(value))
  }

  const getItemName = (id: any): string => {
    const item = items?.find((item) => item.id == id)
    return item?.tenVatPham || '' // Return the name or an empty string
  }

  const formGift = () => {
    let listItems = items.map((item, index) => ({
      id: String(item.id),
      value: 0,
      percent: 0
    }))

    listItems = [...availableItems, ...listItems]

    return (
      <Row gutter={[16, 16]} style={{ display: 'flex' }}>
        <Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              padding: '10px',
              border: '1px solid #f0f0f0',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              flex: '1',
              display: 'flex', // Thêm dòng này
              flexDirection: 'column' // Thêm dòng này
            }}
          >
            <Steps
              current={Number(selectedHookIndex!)}
              progressDot
              direction='vertical'
              items={stepItems}
              style={{ cursor: 'pointer', marginBottom: '20px', flex: '1' }} // Thêm thuộc tính flex: '1' vào đây
            />
            <Button type='dashed' icon={<PlusOutlined />} style={{ width: '100%' }} onClick={() => showModalReward()}>
              Thêm móc phần thưởng
            </Button>
          </div>
        </Col>
        <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              padding: '10px',
              flex: '1'
            }}
          >
            {selectedHookIndex !== null && (
              <Descriptions bordered title={`Thông tin móc phần thưởng ${selectedHookIndex + 1}`} column={1}>
                <Descriptions.Item label='Điểm'>
                  <strong>{rewardHooks[selectedHookIndex].point}</strong>
                </Descriptions.Item>
                <Descriptions.Item label='Vật phẩm'>
                  <List
                    bordered
                    dataSource={rewardHooks[selectedHookIndex].box}
                    renderItem={(item) => (
                      <List.Item>
                        <Row justify='space-between' align='middle' style={{ width: '100%' }}>
                          <Col>
                            <strong>ID:</strong> {item.id}
                          </Col>
                          <Col>
                            <strong>Value:</strong> {item.value}
                          </Col>
                          <Col>
                            <strong>Percent:</strong> {item.percent}%
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                </Descriptions.Item>
              </Descriptions>
            )}
          </div>
        </Col>

        <Modal
          title={<Tag>{modalTitle}</Tag>}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={() => setIsModalVisible(false)}
          style={{ fontSize: '1.2em' }}
        >
          <div style={{ marginBottom: '20px' }}>
            <label
              style={{
                fontWeight: 'bold',
                display: 'block',
                marginBottom: '10px'
              }}
            >
              Điểm
            </label>
            <InputNumber
              min={0}
              value={currentHook.point}
              onChange={(value) => {
                setCurrentHook((prevHook) => ({
                  ...prevHook,
                  point: Number(value)
                }))
              }}
            />
          </div>
          <div>
            <label
              style={{
                fontWeight: 'bold',
                display: 'block',
                marginBottom: '10px'
              }}
            >
              Vật phẩm
            </label>
            {currentHook.box.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    marginBottom: 15,
                    border: '1px solid #f0f0f0',
                    padding: '10px',
                    borderRadius: '5px'
                  }}
                >
                  <Space
                    style={{
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                    size='middle'
                  >
                    <Select
                      placeholder='Chọn vật phẩm'
                      value={item.id}
                      style={{ width: '220px', fontSize: '1.1em' }} // Tăng chiều dài ở đây
                      onSelect={(value) => {
                        const selectedItem = listItems.find((i) => i.id === value)
                        const box = [...currentHook.box]
                        box[index] = selectedItem || box[index]
                        setCurrentHook({ ...currentHook, box })
                      }}
                    >
                      {listItems.map((i) => (
                        <Select.Option key={i.id} value={i.id}>
                          <Tooltip title={getItemName(i.id)}>{isRealNumber(i.id) ? getItemName(i.id) : i.id}</Tooltip>
                        </Select.Option>
                      ))}
                    </Select>

                    <Tooltip title='Value'>
                      <Input
                        value={item.value}
                        style={{ width: '80px', fontSize: '1.1em' }}
                        onChange={(e) => handleValueChange(e, index)}
                      />
                    </Tooltip>

                    <Tooltip title='Percent'>
                      <Input
                        value={item.percent}
                        onChange={(e) => handlePercentChange(e, index)}
                        style={{ width: '80px', fontSize: '1.1em' }}
                      />
                    </Tooltip>

                    <MinusCircleOutlined
                      onClick={() => handleRemoveItem(index)}
                      style={{ fontSize: '1.2em', color: '#f5222d' }}
                    />
                  </Space>
                </div>
              )
            })}
          </div>

          <Button
            type='dashed'
            onClick={handleAddItem}
            block
            icon={<PlusOutlined style={{ fontSize: '1.2em' }} />}
            style={{
              fontSize: '1.2em',
              marginTop: '10px',
              borderColor: '#1890ff',
              color: '#1890ff'
            }}
          >
            Thêm vật phẩm
          </Button>
        </Modal>
      </Row>
    )
  }

  let danhSachNoiDung = []

  if (chuyenDeID === -1) {
    danhSachNoiDung =
      monHoc && monHoc.danhSachNoiDung != '' && monHoc.danhSachNoiDung != 'null'
        ? JSON.parse(monHoc.danhSachNoiDung)
        : []
  } else {
    danhSachNoiDung =
      practice && practice.danhSachNoiDung != '' && practice.danhSachNoiDung != 'null'
        ? JSON.parse(practice.danhSachNoiDung)
        : []
  }

  // drag drog
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    let iOld = active.data.current?.sortable.index
    let iNew = over?.data.current?.sortable.index

    if (iNew != undefined) {
      if (chuyenDeID === -1) {
        let noiDung = monHoc && JSON.parse(monHoc.danhSachNoiDung)

        let arrNew = listObject_move(noiDung, iOld, iNew)

        // update noi dung danh sach

        noiDung.danhSachNoiDung = arrNew

        let setMonHoc = { ...monHoc }
        setMonHoc.danhSachNoiDung = JSON.stringify(noiDung)

        monHocService
          .suaMonHoc(setMonHoc)
          .then((res) => {
            dispatch(getMonHocApi())

            message.success('Đã lưu')
          })
          .catch((err) => {
            message.error('Lỗi Báo IT')
          })
      } else {
        let content = practice && JSON.parse(practice.danhSachNoiDung)

        let arrNew = listObject_move(content, iOld, iNew)

        // update noi dung danh sach

        content.danhSachNoiDung = arrNew

        let setPractice = { ...practice }
        setPractice.danhSachNoiDung = JSON.stringify(content)

        practiceService
          .suaPractice(setPractice)
          .then((res) => {
            dispatch(getPracticeApi())

            message.success('Đã lưu')
          })
          .catch((err) => {
            message.error('Lỗi Báo IT')
          })
      }
    }
  }

  return (
    <div className='container mt-2'>
      {chuyenDeID === -1 ? (
        <>
          <h1>Môn học: {monHoc?.tenMonHoc}</h1>

          <button
            className='btn btn-success mx-2'
            onClick={() => {
              navigate(`/monhoc/${chuyenDeId}`)
            }}
          >
            <i className='fas fa-backward'></i>
          </button>

          <button className='btn btn-primary my-3' onClick={() => showDrawer({ id: 0, maLoaiNhiemVu: 'BAITAP' })}>
            <i className='fas fa-plus'></i> Thêm mới
          </button>

          <DndContext onDragEnd={onDragEnd}>
            <SortableContext items={danhSachNoiDung} strategy={verticalListSortingStrategy}>
              {danhSachNoiDung?.map((item: any, index: number) => {
                return (
                  <SortableItem
                    key={index + ' key1'}
                    task={item}
                    showDrawer={showDrawer}
                    xoaTaskKhoiMon={xoaTaskKhoiMon}
                    updateHanNop={updateHanNop}

                  />
                )
              })}
            </SortableContext>
          </DndContext>
        </>
      ) : (
        <>
          <Typography.Title level={2} style={{ marginBottom: '20px' }}>
            Luyện tập: <span>{practice?.tieuDe}</span>
          </Typography.Title>

          <Space>
            <Button
              className='d-flex align-items-center justify-content-center bg-success mx-2 p-3'
              type='primary'
              style={{ fontSize: '16px', fontWeight: 'bold' }}
              onClick={() => navigate(`/chuyende`)}
            >
              <BackwardOutlined className='fs-5' />
              <span>Back</span>
            </Button>
            <Button
              className='d-flex align-items-center justify-content-center bg-primary my-3 p-3'
              type='primary'
              style={{ fontSize: '16px', fontWeight: 'bold' }}
              onClick={() => showDrawer({ id: 0, maLoaiNhiemVu: '' })}
            >
              <PlusOutlined />
              <span>Thêm mới</span>
            </Button>
            <Button
              className='d-flex align-items-center justify-content-center my-3 p-3'
              type='primary'
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: '#0a0a23'
              }}
              onClick={() => setDrawerVisible(!!!drawerVisible)}
            >
              <PlusOutlined />
              <span>Phần thưởng</span>
            </Button>
          </Space>

          <DndContext onDragEnd={onDragEnd}>
            <SortableContext items={danhSachNoiDung} strategy={verticalListSortingStrategy}>
              {contentLoad.map((item: any) => (
                <SortableItem task={item}
                  showDrawer={showDrawer}
                  xoaTaskKhoiMon={xoaTaskKhoiMon}
                  updateHanNop={updateHanNop}
                />
              ))}
            </SortableContext>
          </DndContext>
        </>
      )}

      <Drawer
        title='Chi tiết nội dung'
        width='70%'
        onClose={drawerClose}
        open={open}
      // bodyStyle={{ paddingBottom: 80 }}
      >
        {open && formTask()}
      </Drawer>

      <Drawer
        title='Form Gift'
        width='50%'
        onClose={drawerVisibleClose}
        open={drawerVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {formGift()}
      </Drawer>

      <Modal title='Chi tiết task' open={isModalOpen} onCancel={modalClose} width='80%' centered footer={null}>
        {isModalOpen && loadBaiHocAbove(modalClose)}
      </Modal>

      <Modal
        title='Nguồn các task'
        open={isModalNguonOpen}
        onCancel={modalNguonClose}
        width='80%'
        centered
        onOk={modalNguonClose}
      >
        <NguonThemTask
          taskDetail={taskDetail}
          updateTaskVaoMonHoc={updateTaskVaoMonHoc}
          modalNguonClose={modalNguonClose}
        />
      </Modal>
    </div>
  )
}

export default ThemTaskForm
