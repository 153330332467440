import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageModel, NotificationType } from "../../Types/messageType";


interface messageState {
    type:NotificationType
    message:string
    description:string
}

const initialState:messageState={
    type:'success',
    message:"",
    description:""
}

const messageReducer = createSlice({
    name:'message',
    initialState,
    reducers:{
        setMessage:(state:messageState,action:PayloadAction<MessageModel>)=>{
            const {description,type,message} = action.payload
            state.type = type
            state.message = message
            state.description = description;
        }
    }
})

export const {setMessage}=messageReducer.actions;
export default messageReducer.reducer;