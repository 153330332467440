import React from "react";
import ReactDOM from "react-dom/client";
import './index.css'
//Cấu hình react router dom
import {
  BrowserRouter,
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
  Navigate,
} from "react-router-dom";
import { BrowserHistory, createBrowserHistory } from "history";
import Home from "./pages/Home/Home";
import HomeTemplate from "./template/HomeTemplate";
import Login from "./pages/Login/Login";
import { Provider } from "react-redux";
import { store } from "./redux/configStore";
import ResponsiveItem from "./hoc/ResponsiveItem";
import HomeMobile from "./pages/Home/HomeMobile";
import ChuyenDe from "./pages/ChuyenDe/ChuyenDe";
import BaiHoc from "./pages/ChuyenDe/BaiHoc";
import MonHoc from "./pages/ChuyenDe/MonHoc";
import QuanLiUser from "./pages/QuanLiUser/QuanLiUser";
import ReviewTask from "./pages/ReviewTask/ReviewTask";
import GroupForTask from "./components/GroupForTask/GroupForTask";
import Chat from "./pages/Chat/Chat";
import ThemTaskForm from "./components/Task/ThemTaskForm";
import DetailQuestionPage from "./PreviewSrc/DetailQuestionPage";
import ChuyenDeDetail from "./components/ChuyenDe/ChuyenDeDetail";
import DashBoard from "./pages/Mentor/Dashboard/DashBoard";
import Student from "./pages/Mentor/Student/Student";

import '../src/assets/style/style.css'
import Task from "./pages/Mentor/Task/Task";
import BaiTest from "./pages/BaiTest/BaiTest";
import BaiTestPhongVan from "./pages/BaiTest/BaiTestPhongVan";
import PrivateRoute from "./util/PrivateRoute";
import { NguoiDungType } from "./Types/nguoiDungType";
import { getStoreJson } from "./util/config";
import "video-react/dist/video-react.css";
import BaiTap from "./pages/Mentor/BaiTap/BaiTap";
import PopupStackChat from "./components/Popupchat/PopupStackChat";
import Class from "./pages/ChuyenDe/Class";
import QuanLyChuyenDe from "./pages/ChuyenDe/QuanLyChuyenDe";
import Intern from "./pages/ChuyenDe/Intern";

import 'bootstrap/dist/css/bootstrap.min.css'

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//Chuyển hướng trang ở file không phải component
export const history: any = createBrowserHistory();
let user = (getStoreJson("USER_LOGIN") as NguoiDungType);

root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>
      
      <Routes>

        <Route path="" element={<HomeTemplate />}>
          <Route
            index
            path="/"
            element={
              user?.maNhomQuyen === "ADMIN" ||   user?.maNhomQuyen === "LECTURE"?
                <ResponsiveItem component={Home} mobileComponent={HomeMobile} />
                // <Navigate  to={'/home'}/>
                : user?.maNhomQuyen === "MENTOR" ?
                  <DashBoard />
                  // <Navigate to='/mentor/dashboard'/>
                  : <></>
            }
          />

          <Route path="/home" element={<PrivateRoute role="ADMIN" element={<Home />} />} />
          <Route path="/chuyende" element={<ChuyenDe />} />  
          <Route path="/ql-chuyende" element={<QuanLyChuyenDe />} />  
          <Route path="/class" element={<Class />} />
          <Route path="/intern" element={<Intern />} />

          <Route path="/monhoc/:chuyenDeId" element={<ChuyenDeDetail />} />
          <Route path="/themtask/:monHocId/:chuyenDeId" element={<ThemTaskForm chuyenDeID={Number(-1)} />} />
          <Route path="/baihoc" element={<BaiHoc />} />
          <Route path="/monhoc" element={<PrivateRoute role="ADMIN" element={<MonHoc />} />} />
          <Route path="/quan-li-user" element={<PrivateRoute role="ADMIN" element={<QuanLiUser />} />} />
          <Route path="/review-task" element={<PrivateRoute role="ADMIN" element={<ReviewTask />} />} />
          <Route path="/quiz-test-phong-van" element={<PrivateRoute role="ADMIN" element={<BaiTestPhongVan />} />} />
          <Route path="/quiz-test" element={<BaiTest />} />
          <Route path="*" element={<Navigate to="" />} />

          {/* 
          
            path="/chat"
            element={
              <AuthProvider>
                <GroupChat />
              </AuthProvider>
            }
            
          /> */}
          <Route path="/mentor/" element={<PrivateRoute role="MENTOR" element={<DashBoard />} />} />
          <Route path="/mentor/dashboard" element={<PrivateRoute role="MENTOR" element={<DashBoard />} />} />
          <Route path="/mentor/student" element={<PrivateRoute role="MENTOR" element={<Student />} />} />

          <Route path="/chat" element={<PrivateRoute role="MENTOR" element={<Chat />} />} />
          <Route path="/chat/:paramGFTId" element={<PrivateRoute role="MENTOR" element={<Chat />} />} />
          <Route path="/chat/:paramIssueId/:paramChuyenDeId/:paramHocVienId" element={<PrivateRoute role="MENTOR" element={<Chat />} />} />

          <Route path="/task/:gftid" element={<PrivateRoute role="MENTOR" element={<Task />} />} />
          <Route path="/baitap/:baitapid/:idmonhoc/:chuyendeid/:tennhiemvu/:maLoai" element={<PrivateRoute role="MENTOR" element={<BaiTap/>} />} />
          <Route path="/grouptask/:id" element={<PrivateRoute role="MENTOR" element={<GroupForTask />} />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/preview/:question_id" element={<DetailQuestionPage />} />
      </Routes>
    </HistoryRouter>
    {/* <AuthProvider>
      <GroupChat />
    </AuthProvider> */}
    {/* <GroupForTask id={2}/> */}
  </Provider>
);
