import React, { useEffect, useState } from 'react'
import { MonHocModel } from '../../../Types/monHocType';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../../redux/configStore';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, DatePicker, Drawer, Input, Popconfirm, Select, Space, Table, TableColumnsType, Tabs, TabsProps, message } from 'antd';
import { useDispatch } from 'react-redux';
import { Row } from "../../DragDrog/Row";
import { array_move } from '../../../util/adminUti';
import { chuyenDeService } from '../../../services/chuyenDeService';
import { getChuyenDeApi, getUserChuyenDeApi } from '../../../redux/ChuyenDeReducer/chuyenDeReducer';
import { listType } from '../TaoMonHoc';
import { PracticeModel } from '../../../Types/practiceType';
import PracticeForm from '../../Practice/PraticeForm';
import { BackwardOutlined, PlusOutlined } from '@ant-design/icons';
import { nguoiDungService } from '../../../services/nguoiDungService';
import dayjs from 'dayjs';

const { Search } = Input;

type Props = {}

let practiceEmpty: PracticeModel = {
  id: 0,
  tieuDe: "",
  danhSachNoiDung: "[]",
  phanThuong: ""
};

const DanhSachHocVien = (props: Props) => {

  // create state
  const [infoUser, setInfoUser] = useState<any>(null);
  const [data, setData] = useState<any>({});

  const [open, setOpen] = useState(false);
  const [practiceDetail, setPracticeDetail] = useState<PracticeModel>(practiceEmpty);

  let { chuyenDeId } = useParams();
  const navigate = useNavigate();

  const { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);
  const { arrPractice } = useSelector((state: RootState) => state.practiceReducer);
  const { arrUserChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);

  const [search, setSearch] = useState({ hoTen: "", email: "" });
  // get redux
  const dispatch: DispatchType = useDispatch();

  // function else
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getUserChuyenDeApi(Number(chuyenDeId)))

  }, []);

  let chuyenDe = arrChuyenDe?.find(n => n.id == Number(chuyenDeId));

  let metaData = chuyenDe && chuyenDe.metaData ? JSON.parse(chuyenDe.metaData) : {};


  const columns: TableColumnsType<any> = [

    {
      title: "Thông tin học viên",
      render: (_, record) => {
        let thongTinMoRong = JSON.parse(record.thongTinMoRong)

        return <>
          {record?.hoTen}
          <p className='fw-bold'>Email: {record?.email}</p>
          {thongTinMoRong.FacebookUrl && <a target='_blank' href={thongTinMoRong.FacebookUrl} className='btn btn-sm btn-success'><i className="fa-brands fa-facebook"></i> Facebook</a>}
        </>;
      }
    },
    {
      title: "Hạn học",
      render: (_, record) => {



        return <>
          <DatePicker value={dayjs(record.ngayKetThuc, "YYYY-MM-DD hh:mm:ss")} format="DD/MM/YYYY" onChange={(date, dateString) => {
            
            let setDate = date?.set("hour",13)
            
            let model = {
              dangKyHocId: record.dangKyHocId,
              ngayGiaHan: setDate
            }


            chuyenDeService.giaHanHocUser(model).then(res => {
              message.success("Gia hạn thành công");
              dispatch(getUserChuyenDeApi(Number(chuyenDeId)))

            }).catch(err => {

              message.error(err.response.data.message);
            });

          }} />
        </>;
      }
    },
    {
      title: "Action",
      render: (_, record) => {

        return (
          <>

            <Popconfirm
              title="Bạn có chắc muốn tiếp tục ?"
              onConfirm={() => {
                let model = {
                  dangKyHocId: record.dangKyHocId,
                  ngayGiaHan: new Date()
                }

                chuyenDeService.xoaHocUser(model).then(res => {
                  message.info("Xóa thành công");
                  dispatch(getUserChuyenDeApi(Number(chuyenDeId)))

                })
              }}
            >
              <button className="btn btn-sm btn-danger">
                <i className="fas fa-times"></i>
              </button>
            </Popconfirm >
          </>
        );
      },
    },
  ];

  let dataUserChuyenDe: any[] = arrUserChuyenDe;

  if (search.hoTen != "") {
    dataUserChuyenDe = dataUserChuyenDe.filter(n => n.hoTen.toLowerCase().indexOf(search.hoTen.toLowerCase()) != -1)
  }
  if (search.email != "") {
    dataUserChuyenDe = dataUserChuyenDe.filter(n => n.email.toLowerCase().indexOf(search.email.toLowerCase()) != -1)
  }

  let thongTinMoRong = infoUser ? JSON.parse(infoUser.thongTinMoRong) : null;

  const onChange = (value: string | number, name: string) => {
    setData({
      ...data,
      [name]: value
    })
  };
  const optMonth = [{ value: 0, label: "Tháng" }];

  for (let index = 1; index < 13; index++) {
    optMonth.push({
      value: index, label: index + ' tháng'
    })

  }

  return (
    <div className=" p-3">
      <div className="card mb-3 w-100" >

        <div className="card-body text-dark">

          <p className="card-text row">
            <div className='col-2 fw-bold'>
              <button
                className='btn btn-success'

                style={{ fontSize: "16px", fontWeight: "bold" }}
                onClick={() => {

                  showDrawer();
                }}>
                <PlusOutlined />
                Ghi danh học viên
              </button>

            </div>
            <div className='col-2 fw-bold'>
              <input placeholder='Họ tên' value={search.hoTen} className='form-control' onChange={({ target }) => {
                let { value } = target;

                setSearch({ email: "", hoTen: value })
              }} />

            </div>
            <div className='col-2 fw-bold'>
              <input placeholder='Email' value={search.email} className='form-control' onChange={({ target }) => {
                let { value } = target;

                setSearch({ email: value, hoTen: "" })
              }} />

            </div>
          </p>
        </div>
      </div>




      <Table

        columns={columns}
        dataSource={dataUserChuyenDe}
      />

      <Drawer
        title="Ghi danh"
        width="40%"
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <div className='row'>
          <div className='col-12'>

            <Search
              placeholder="Nhập email học viên"
              allowClear
              enterButton="Tìm"
              onSearch={value => {
                nguoiDungService.getUserByEmail(value).then(res => {

                  setInfoUser(res.data.content)
                }).catch(err => {
                  message.error("Không tìm thấy thông tin !")
                })

              }}
            />
          </div>

          <div className='col-12'>
            {
              infoUser && <>
                <h4 className='mt-3'><b>Họ tên:</b> {infoUser.hoTen}</h4>
                <h4 className='mt-3'><b>Email:</b> {infoUser.email} </h4>
                <h4 className='mt-3'><b>Số ĐT:</b> {infoUser.soDt} </h4>
                {
                  thongTinMoRong && thongTinMoRong.FacebookUrl && <a target='_blank' href={thongTinMoRong.FacebookUrl} className='btn btn-sm btn-primary my-3'><i className="fa-brands fa-facebook"></i> Facebook</a>
                }
                <br />
                {
                  metaData.isChuyenDe && <Select
                    defaultValue={0}
                    style={{ width: "30%" }}
                    options={optMonth}
                    onChange={(value) => onChange(value, "soThang")}

                  />
                }

                <Popconfirm
                  title="Bạn chắc có muốn tiếp tục ?"
                  onConfirm={() => {
                    if (chuyenDe) {

                      let model = {
                        chuyenDeId: chuyenDe.id,
                        nguoiDungId: infoUser.id,
                        soThang: data.soThang
                      }

                      chuyenDeService.ghiDanhUser(model).then(res => {
                        message.success("Ghi danh thành công");
                        dispatch(getUserChuyenDeApi(Number(chuyenDeId)))


                      }).catch(err => {

                        message.error(err.response.data.message);
                      });
                    }
                  }}
                >

                  <button className='btn btn-success '>Ghi danh</button>

                </Popconfirm>
              </>
            }

          </div>
        </div>


      </Drawer>
    </div>
  )
}

export default DanhSachHocVien