import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { chuyenDeService } from "../../services/chuyenDeService";
import { http } from "../../util/config";
import { DispatchType } from "../configStore";
import {
  ChuyenDeMentorPhuTrachModel,
  ChuyenDeModel,
  DanhSachTagModel,
} from "../../Types/chuyenDeType";
import { danhSachTagService } from "../../services/danhSachTagService";
import { classService } from "../../services/classService";

type chuyenDeState = {
  arrChuyenDe: ChuyenDeModel[] | null;
  listClass: ChuyenDeModel[] | null;
  arrTag: DanhSachTagModel[];
  DSChuyenDeMentorPhuTrach: ChuyenDeMentorPhuTrachModel[];
  dsIdMentor: string[]
  arrChuyenDeHoc: ChuyenDeModel[] | null;
  arrUserChuyenDe: any | [] | null
  arrDiemDanhLop: any | [];
  chuyenDeChiTiet: any | null;

};
const initialState: chuyenDeState = {
  arrChuyenDe: [],
  listClass: [],
  arrTag: [],
  DSChuyenDeMentorPhuTrach: [],
  dsIdMentor: [],
  arrChuyenDeHoc: [],
  arrUserChuyenDe: null,
  arrDiemDanhLop: [],
  chuyenDeChiTiet: {}
};

const chuyenDeReducer = createSlice({
  name: "chuyenDeReducer",
  initialState,
  reducers: {
    getListClassAction: (
      state: chuyenDeState,
      action: PayloadAction<ChuyenDeModel[]>
    ) => {
      state.listClass = [...action.payload];
    },
    getChuyenDeAction: (
      state: chuyenDeState,
      action: PayloadAction<ChuyenDeModel[]>
    ) => {
      state.arrChuyenDe = [...action.payload];
    },
    getChuyenDeHocAction: (
      state: chuyenDeState,
      action: PayloadAction<ChuyenDeModel[]>
    ) => {
      state.arrChuyenDeHoc = [...action.payload];
    },
    getListTagAction: (
      state: chuyenDeState,
      action: PayloadAction<DanhSachTagModel[]>
    ) => {
      state.arrTag = [...action.payload];
    },
    getDSChuyenDeMentorPhuTrach: (
      state: chuyenDeState,
      action: PayloadAction<ChuyenDeMentorPhuTrachModel[]>
    ) => {
      state.DSChuyenDeMentorPhuTrach = [...action.payload];
      if (state.DSChuyenDeMentorPhuTrach.length) {
        const dsIdMentor = state.DSChuyenDeMentorPhuTrach.map(e => e.dsMentor).flat()
        if (dsIdMentor.filter(e => e !== null).length)
          state.dsIdMentor = [...dsIdMentor]
      }
    },
    getUserChuyenDeAction: (
      state: chuyenDeState,
      action: PayloadAction<any[]>
    ) => {
      state.arrUserChuyenDe = [...action.payload];
    },
    getDiemDanhLopAction: (
      state: chuyenDeState,
      action: PayloadAction<any[]>
    ) => {
      state.arrDiemDanhLop = [...action.payload];
    },
    getChuyenDeChiTietAction: (
      state: chuyenDeState,
      action: PayloadAction<any[]>
    ) => {
      state.chuyenDeChiTiet = {...action.payload};
    },
  },
});

export const {
  getListClassAction,
  getChuyenDeAction,
  getListTagAction,
  getDSChuyenDeMentorPhuTrach,
  getChuyenDeHocAction,
  getUserChuyenDeAction,
  getDiemDanhLopAction,
  getChuyenDeChiTietAction
} = chuyenDeReducer.actions;
export default chuyenDeReducer.reducer;

export const getDiemDanhLopApi = (chuyenDeId: number) => {
  return async (dispatch: DispatchType) => {
    const result = await classService.getDiemDanhLop(chuyenDeId);

    const action: PayloadAction<any[]> = getDiemDanhLopAction(
      result.data.content
    );
    dispatch(action);
  };
};

//--------- action async ------------
export const getChuyenDeChiTietApi = (chuyenDeId: number) => {
  return async (dispatch: DispatchType) => {
    const result = await chuyenDeService.layChuyenDeChiTiet(chuyenDeId);

    const action: PayloadAction<any[]> = getChuyenDeChiTietAction(
      result.data.content
    );
    dispatch(action);
  };
};

export const getUserChuyenDeApi = (chuyenDeId: number) => {
  return async (dispatch: DispatchType) => {
    const result = await chuyenDeService.getUserChuyenDe(chuyenDeId);

    const action: PayloadAction<any[]> = getUserChuyenDeAction(
      result.data.content
    );
    dispatch(action);
  };
};

export const getChuyenDeApi = () => {
  return async (dispatch: DispatchType) => {
    const result = await chuyenDeService.layDanhSachChuyenDe();

    const action: PayloadAction<ChuyenDeModel[]> = getChuyenDeAction(
      result.data.content
    );
    dispatch(action);
  };
};

export const getChuyenDeHocApi = () => {
  return async (dispatch: DispatchType) => {
    const result = await chuyenDeService.layDanhSachChuyenDeHoc();

    const action: PayloadAction<ChuyenDeModel[]> = getChuyenDeHocAction(
      result.data.content
    );
    dispatch(action);
  };
};

// get danh sach tag

export const getListTagApi = () => {
  return async (dispatch: DispatchType) => {
    const result = await danhSachTagService.getDanhSachTag();

    const action: PayloadAction<DanhSachTagModel[]> = getListTagAction(
      result.data.content
    );

    dispatch(action);
  };
};

export const getDSChuyenDeMentorApi = (mentorId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await chuyenDeService.getChuyenDeMentor(mentorId);
      const action: PayloadAction<ChuyenDeModel[]> = getChuyenDeAction(
        result.data.content
      );
      dispatch(action);
    } catch (error) {
      console.log("getDSChuyenDeMentorPhuTrachApi", error);
    }
  };
};
export const getDSChuyenDeMentorPhuTrachApi = (mentorId: string) => {
  return async (dispatch: DispatchType) => {
    try {
      const result = await chuyenDeService.getChuyenDeMentorPhuTrach(mentorId);
      const action: PayloadAction<ChuyenDeMentorPhuTrachModel[]> =
        getDSChuyenDeMentorPhuTrach(result.data.content);
      dispatch(action);
    } catch (error) {
      console.log("getDSChuyenDeMentorPhuTrachApi", error);
    }
  };
};

export const getListClass = () => {
  return async (dispatch: DispatchType) => {
    const result = await classService.getListClass();

    const action: PayloadAction<ChuyenDeModel[]> = getListClassAction(
      result.data.content
    );
    dispatch(action);
  };
};