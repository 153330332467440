import React, { useEffect } from 'react'
import { AndroidOutlined, AuditOutlined, CalendarOutlined, CheckOutlined, FileOutlined, TrophyOutlined, UserOutlined } from '@ant-design/icons';
import { Tabs, Tag, Typography } from 'antd';
import TaoBestPractice from './TaoBestPractice';
import TaoPractice from './TaoPractice';
import TaoCompetitions from './TaoCompetitions';
import TaoLiveEvent from './TaoLiveEvent';
import TaoMonHoc from './TaoMonHoc';
import TaoDocument from './TaoDocument';
import '../../assets/css/tabs-antd-custom.css'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DispatchType, RootState } from '../../redux/configStore';
import ThemTaskForm from '../Task/ThemTaskForm';
import DanhSachHocVien from './DanhSachHocVien/DanhSachHocVien';



const { Text, Title } = Typography;

type Props = {}

const ChuyenDeDetail = (props: Props) => {

  let { chuyenDeId } = useParams();
  const navigate = useNavigate();

  const { arrChuyenDe } = useSelector((state: RootState) => state.chuyenDeReducer);

  const { danhSachMentor } = useSelector((state: RootState) => state.userReducer);
  const { danhSachGiangVien } = useSelector((state: RootState) => state.userReducer);

  let chuyenDe = arrChuyenDe?.find(n => n.id == Number(chuyenDeId));

  let metaData = chuyenDe && chuyenDe.metaData ? JSON.parse(chuyenDe.metaData) : {};


  useEffect(() => {

  }, [])

  const items = [
    {
      label: (
        <span className="d-flex align-items-center">
          <CheckOutlined style={{ marginRight: "8px" }} />
          <Text strong>Task</Text>
        </span>
      ),
      key: "1",
      children: <TaoMonHoc />,
    },
    {
      label: (
        <span className="d-flex align-items-center">
          <UserOutlined />
          <Text strong>Danh sách học viên</Text>
        </span>
      ),
      key: "2",
      children: <DanhSachHocVien />,
    },
    {
      label: (
        <span className="d-flex align-items-center">
          <AndroidOutlined style={{ marginRight: "8px" }} />
          <Text strong>Practice</Text>
        </span>
      ),
      key: "3",
      children: <ThemTaskForm chuyenDeID={Number(chuyenDeId)} isPractice={true} />,
    },
    {
      label: (
        <span className="d-flex align-items-center">
          <FileOutlined style={{ marginRight: "8px" }} />
          <Text strong>Document</Text>
        </span>
      ),
      key: "4",
      children: <TaoDocument />,
    },
    {
      label: (
        <span className="d-flex align-items-center">
          <AuditOutlined style={{ marginRight: "8px" }} />
          <Text strong>Best Practice</Text>
        </span>
      ),
      key: "5",
      children: <TaoBestPractice chuyenDeId={Number(chuyenDeId)} />,
    },
    {
      label: (
        <span className="d-flex align-items-center">
          <TrophyOutlined style={{ marginRight: "8px" }} />
          <Text strong>Competitions</Text>
        </span>
      ),
      key: "6",
      children: <TaoCompetitions />,
    },
    {
      label: (
        <span className="d-flex align-items-center">
          <CalendarOutlined style={{ marginRight: "8px" }} />
          <Text strong>Live Event</Text>
        </span>
      ),
      key: "7",
      children: <TaoLiveEvent />,
    },
  ];

  let listGianVien = chuyenDe?.danhSachGiangVien ? JSON.parse(chuyenDe.danhSachGiangVien) : [];
  let listMentor = chuyenDe?.danhSachMentor ? JSON.parse(chuyenDe.danhSachMentor) : [];


  return (
    <div style={{ padding: '24px' }}>


      <div className="card border-dark mb-3 w-100" >
        <div className="card-header">
          <h2 className="card-title">{chuyenDe?.tenChuyenDe}</h2>
        </div>
        <div className="card-body text-dark">

          <p className="card-text row">
            <div className='col-4 fw-bold'>Giảng viên:
              {listGianVien.map((item: string) =>
                <Tag color='orange'> {danhSachGiangVien.find(n => n.id == item)?.hoTen}</Tag>
              )}
            </div>
            <div className='col-4 fw-bold'>Mentor:
              {listMentor.map((item: string) =>
                <Tag color='magenta'> {danhSachMentor.find(n => n.id == item)?.hoTen}</Tag>
              )}
            </div>

            <div className='col-4 fw-bold'>
              Ngày bắt đầu:
              <Tag> {metaData.ngayBatDau} </Tag>
              - kết thúc:
              <Tag> {metaData.ngayKetThuc} </Tag>
            </div>

          </p>
        </div>
      </div>



      <div >
        <Tabs size='large' type="line" defaultActiveKey="1" items={items} />
      </div>
    </div >
  );
}

export default ChuyenDeDetail
